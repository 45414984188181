/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { useHistory } from "react-router";
import { CBadge, CDataTable } from "@coreui/react";
import moment from "moment";
import { toast, ToastContainer } from "react-toastify";
import { useDispatch } from "react-redux";
import { contentSuccess, contentError } from "../../_utils/api";
import {
  CButton,
  CCard,
  CCardBody,
  CCol,
  CContainer,
  CRow,
  CModal,
  CModalHeader,
  CModalBody,
  CLabel,
  CInputGroup,
  CInput,
  CSpinner,
  CForm
} from "@coreui/react";
import { deleteUser, getUser } from "src/redux/_services/adminService";

const getStatusBadge = (status) => {
  let deleted;
  if(status === null){
    deleted = "false"
  }else {
    deleted = "true"
  }
  switch (deleted) {
    case "false" :
      return "success";
    case "true" :
      return "danger";
    default:
      return "";
  }
};

const AdminDataTable = ({
  items,
  loading,
  itemsPerPage,
}) => {
  
  let fields = [
    {
      key: "full_name",
      _style: { width: "25%", textTransform: "capitalize" },
    },
    { key: "email", _style: { width: "22%" } },
    { key: "telephone", _style: { width: "15%" } },
    { key: "role", _style: { width: "15%" } },
    { key: "account_status", label: "Status" },
    { key: "gender", _style: { width: "15%", textTransform: "capitalize" } },
    { key: "created_at", label: "Date Registered (DD/MM/YYYY)" },
  
  ];

  const [modal, setModal] = useState(false);
  const [userDetailsLoading, setUserDetailsLoading] = useState(false);
  const [userDetails, setUserDetails] = useState([]);
  const [userDetailsErr, setUserDetailsErr] = useState([]);
  const [password, setPassword] = useState("");
  const [passwordType, setPasswordType] = useState("password");
  const [deleteLoading, setDeleteLoading] = useState("");
  const toggle = () => {
    setModal(!modal);
    setPassword("");
  };

  const accountDetails2 = (item) => {
    toggle();

    setUserDetailsLoading(true);
    getUser(item.id)
      .then((res) => {
        setUserDetails(res.data.data.user);
      })
      .catch((err) => {
        setUserDetailsErr(err.message);
      });
  };

  const submitHandler = (e) => {
    e.preventDefault();
    const id = userDetails.id;
    const payload = {
      password,
      id,
    };

    deleteUser(payload)
      .then((res) => {
        setDeleteLoading(true);
        toast(contentSuccess(res.data));
        setTimeout(() => history.push('/admin/create-account'), 6000);
      })
      .catch((err) => {
        if (err.response.data.error.message) {
          toast(contentError(err.response.data.error.message));
        } else {
          toast(contentError("An Error Occured"));
        }
      })
      .finally(setDeleteLoading(false));
  };

  const history = useHistory();
  return (
    <>
      <CDataTable
        items={items}
        fields={fields}
        columnFilter
        // tableFilter
        loading={loading}
        footer
        responsive={true}
        // itemsPerPageSelect={{
        //   label: "Items per page:",
        //   values: [5, 10, 20, 30, 50],
        // }}
        hover
        sorter
        itemsPerPage={itemsPerPage}
        // activePage
        clickableRows
        // This will be modified to reflect the specific user clicked
        // onRowClick={(item) => accountDetails2(item)}
        onRowClick={(item) => history.push(`/admin/account-details/${item.id}`)}
        // /admin/account-details/:id
        scopedSlots={{
          telephone: (item) => (
            <td>
              {item.telephone === null ? "Not Available" : item.telephone}
            </td>
          ),
          created_at: (item) => (
            <td>
              <>{moment(item.created_at).format("DD-MMM-YYYY")}</>
            </td>
          ),
          gender: (item) => (
            <td className="text-capitalize">
              <>{item.gender === null ? "Not Available" : item.gender}</>
            </td>
          ),
          full_name: (item) => (
            <td className="text-capitalize">
              <>
                {item &&
                  item.full_name &&
                  item.full_name.replace(/(^\w)|([-\s]\w)/g, (match) =>
                    match.toUpperCase()
                  )}
              </>
            </td>
          ),
          email: (item) => (
            <td>
              <>{item && item.email && item.email == null ? "Not Available" : item && item.email && item.email}</>
            </td>
          ),
          role: (item) => (
            <td>
              <>{item && item.roles == null ? "Not Available" : item && item.roles && item.roles[0] && item.roles[0].name}</>
            </td>
          ),
          account_status: (item) => (
            <td>
              <CBadge
                color={getStatusBadge(item.deleted_at)}
                size="md"
                className="text-capitalize"
              >
                {item.deleted_at == null ? "Active" : "Deleted"}
              </CBadge>
            </td>
          ),
        }}
       />
     <CModal show={modal} onClose={toggle}>
        <CModalHeader closeButton className="text-danger">
          CANCEL
        </CModalHeader>
        <CModalBody>
          <CContainer>
            <CRow className="justify-content-center">
              <CCol>
                <CCard className="mx-4">
                <CForm autoComplete="off">
                  <CCardBody className="">
                    <div className="d-flex justify-content-between">
                      <CLabel className="mr-4"> Name </CLabel>
                      <p>{userDetails.full_name || "Not Availabel"}</p>
                    </div>
                    <div className="d-flex justify-content-between">
                      <CLabel className="mr-4"> Email </CLabel>
                      <p>{userDetails.email || "Not Availabel"}</p>
                    </div>
                    <div className="d-flex justify-content-between">
                      <CLabel className="mr-4"> Gender </CLabel>
                      <p>{userDetails.gender || "Not Availabe"}</p>
                    </div>
                    <div className="d-flex justify-content-between">
                      <CLabel className="mr-4"> Telephone </CLabel>
                      <p>{userDetails.telephone || "Not Availabe"}</p>
                    </div>
                    <div className="d-flex justify-content-between">
                      <CLabel className="mr-4"> Role</CLabel>
                      <p>{userDetails.referral_code || "Not Availabe"}</p>
                    </div>
                    <div className="d-flex justify-content-between">
                      <CLabel className="mr-4"> Date Created </CLabel>
                      <p>
                        {userDetails.created_at
                          ? userDetails.created_at.slice(0, 10)
                          : "Not Availabe"}
                      </p>
                    </div>
                  </CCardBody>

                  <div className="px-4">
                  
                    <CInputGroup className="mb-1">
                      <CInput
                        type={passwordType}
                        placeholder="Enter Password to Delete Account"
                        autoComplete="false"
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      {passwordType === "password" ? (
                        <CButton
                          type="button"
                          color="secondary"
                          variant="outline"
                          onClick={() => setPasswordType("text")}
                        >
                          Show
                        </CButton>
                      ) : (
                        <CButton
                          type="button"
                          color="secondary"
                          variant="outline"
                          onClick={() => setPasswordType("password")}
                        >
                          Hide
                        </CButton>
                      )}
                    </CInputGroup>
                  </div>
                  {deleteLoading ? (
                    <CButton
                      onClick={(e) => submitHandler(e)}
                      className="m-4 mb-4"
                      color="danger"
                    >
                      <CSpinner color="white" size="sm" /> Loading ...
                    </CButton>
                  ) : (
                    <CButton
                      onClick={(e) => submitHandler(e)}
                      className="m-4 mb-4"
                      color="danger"
                    >
                      Delete
                    </CButton>
                  )}
                  </CForm>
                </CCard>
              </CCol>
            </CRow>
            <ToastContainer autoClose={5000} />{" "}
          </CContainer>
        </CModalBody>
      </CModal>
    </>
  );
};

export default AdminDataTable;
