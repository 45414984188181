export const ACCOUNT_VERIFY_REQUEST = "ACCOUNT_VERIFY_REQUEST";
export const ACCOUNT_VERIFY_SUCCESS = "ACCOUNT_VERIFY_SUCCESS";
export const ACCOUNT_VERIFY_FAIL = "ACCOUNT_VERIFY_FAIL";

export const ACCOUNT_UPDATE_REQUEST = "ACCOUNT_UPDATE_REQUEST";
export const ACCOUNT_UPDATE_SUCCESS = "ACCOUNT_UPDATE_SUCCESS";
export const ACCOUNT_UPDATE_FAIL = "ACCOUNT_UPDATE_FAIL";

export const ACCOUNT_UNBLOCK_REQUEST = "ACCOUNT_UNBLOCK_REQUEST";
export const ACCOUNT_UNBLOCK_SUCCESS = "ACCOUNT_UNBLOCK_SUCCESS";
export const ACCOUNT_UNBLOCK_FAIL = "ACCOUNT_UNBLOCK_FAIL";

export const UNBLOCK_FUND_REQUEST = "UNBLOCK_FUND_REQUEST";
export const UNBLOCK_FUND_SUCCESS = "UNBLOCK_FUND_SUCCESS";
export const UNBLOCK_FUND_FAIL = "UNBLOCK_FUND_FAIL";

export const BLOCK_FUND_REQUEST = "BLOCK_FUND_REQUEST";
export const BLOCK_FUND_SUCCESS = "BLOCK_FUND_SUCCESS";
export const BLOCK_FUND_FAIL = "BLOCK_FUND_FAIL";

export const ACCOUNT_BLOCK_REQUEST = "ACCOUNT_BLOCK_REQUEST";
export const ACCOUNT_BLOCK_SUCCESS = "ACCOUNT_BLOCK_SUCCESS";
export const ACCOUNT_BLOCK_FAIL = "ACCOUNT_BLOCK_FAIL";

export const ACCOUNT_STATEMENT_REQUEST = "ACCOUNT_STATEMENT_REQUEST";
export const ACCOUNT_STATEMENT_SUCCESS = "ACCOUNT_STATEMENT_SUCCESS";
export const ACCOUNT_STATEMENT_FAIL = "ACCOUNT_STATEMENT_FAIL";

export const ACCOUNT_OVERDRAFT_REQUEST = "ACCOUNT_OVERDRAFT_REQUEST";
export const ACCOUNT_OVERDRAFT_SUCCESS = "ACCOUNT_OVERDRAFT_SUCCESS";
export const ACCOUNT_OVERDRAFT_FAIL = "ACCOUNT_STATEMENT_FAIL";

export const ACCOUNT_LIST_REQUEST = "ACCOUNT_LIST_REQUEST";
export const ACCOUNT_LIST_SUCCESS = "ACCOUNT_LIST_SUCCESS";
export const ACCOUNT_LIST_FAIL = "ACCOUNT_LIST_FAIL";
