import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CButton,
  CCard,
  CCardBody,
  CCol,
  CContainer,
  CForm,
  CInput,
  CInputGroup,
  CInputGroupPrepend,
  CInputGroupText,
  CRow,
  CModal,
  CModalHeader,
  CModalBody,
  CSelect,
  CPagination,
  CCardHeader,
  CSpinner,
  CDropdown,
  CDropdownDivider,
  CDropdownToggle,
  CDropdownMenu,
  CLabel,
} from "@coreui/react";
import { Overlay } from "../../components";
import AdminDataTable from "src/components/DataTables/AdminDataTable";
import CIcon from "@coreui/icons-react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CSVLink } from "react-csv";
import moment from "moment";
import { useHistory, useLocation } from "react-router-dom";

import { createAccount } from "src/redux/_actions/adminActions";
import {
  getRoles,
  getUsers,
  getUsersFilter,
} from "src/redux/_services/adminService";
import { contentError } from "src/_utils/api";
import AccountsDataTable from "src/components/DataTables/AccountsDataTable";

const Register = (props) => {
  const [users, setUsers] = useState([]);
  const [roles, setRoles] = useState([]);
  const [filterName, set_filter_name] = useState();
  const [filterVal, set_filter_value] = useState();
  const [query, set_query] = useState();
  const [showOverlay, set_showOverlay] = useState(false);
  const [pages, set_pages] = useState(1);
  const [loading, setLoading] = useState("");
  const [currentpage, set_currentpage] = useState(1);
  const [prevPageNumber, setPrevPageNumber] = useState("");
  const [lastPage, set_lastpage] = useState(1);
  const [modal, setModal] = useState(false);
  const history = useHistory();

  const createAccountData = useSelector((state) => state.createAccount);

  const update = (e) => {
    if (e.target.name === "email") {
      set_filter_name(e.target.name);
      set_filter_value(e.target.value);
    } else if (e.target.name === "trashed") {
      set_filter_name(e.target.name);
      set_filter_value(e.target.value);
    } else if (e.target.name === "full_name") {
      set_filter_name(e.target.name);
      set_filter_value(e.target.value);
    }
  };

  const toggle = () => {
    setModal(!modal);
  };

  const refreshFilter = (e) => {
    e.preventDefault();
    set_filter_name();
    set_filter_value();
    set_query();
    sessionStorage.removeItem("internalUserPageFilter");
    document.getElementById("create-filter-form").reset();
  };

  const submitHandler = (e) => {
    e.preventDefault();
    const queries = `filter[${filterName}]=${filterVal}`;
    if (filterName == undefined) {
      set_query();
      set_showOverlay(true);
      getUsers(currentpage)
        .then((response) => {
          setUsers(response.data.data.users.data);
          set_pages(response.data.data.users.meta.last_page);
          set_currentpage(response.data.data.users.meta.current_page);
        })
        .finally(function () {
          set_showOverlay(false);
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      set_query(queries);
      const filterObj = {
        query: queries,
        filterName: filterName,
        filterVal: filterVal,
      };
      sessionStorage.setItem("internalPageFilter", JSON.stringify(filterObj));
    }
  };

  const pageChange = (newPage) => {
    setPrevPageNumber("");
    set_currentpage(newPage);

  };

 
  let previousPage = sessionStorage.getItem("internalUserPage");
  let pageLink = props.match.url;

  useEffect(() => {
    if (
      createAccountData.loading === false
      // &&
      // createAccountData.createAccountData.status === "success"
    ) {
      toggle();
      set_showOverlay(true);
      setLoading(true);
      getUsers(1)
        .then((res) => {
          setUsers(res.data.data.users.data);
          set_pages(res.data.data.users.meta.last_page);
          if (prevPageNumber) {
            set_currentpage(+prevPageNumber);
          } else {
            set_currentpage(res.data.data.users.meta.current_page);
          }
        })
        .finally(() => {
          set_showOverlay(false);
          setLoading(false);
        })
        .catch((err) => {});
    }
  }, [
    createAccountData.createAccountData &&
      createAccountData.createAccountData.status,
  ]);

  useEffect(() => {
    if (previousPage) {
      setPrevPageNumber(+previousPage);
      set_currentpage(+previousPage);
    }
  }, []);

  

  useEffect(() => {
    const retrieveUsersFilter = () => {
      if (query) {
        previousPage = 1;
        set_showOverlay(true);
        getUsersFilter(query, currentpage)
          .then((response) => {
            setUsers(response.data.data.users.data);
            set_pages(response.data.data.users.meta.last_page);
            set_currentpage(response.data.data.users.meta.current_page);
          })
          .finally(function () {
            set_showOverlay(false);
          })
          .catch((e) => {
            console.log(e);
          });
      }
    };
    retrieveUsersFilter();
  }, [query]);

  useEffect(() => {
   
    const retrieveUsers = () => {
      set_showOverlay(true);
      setLoading(true);
      if (query) {
        previousPage = 1;
        set_showOverlay(true);
        getUsersFilter(query, currentpage)
          .then((response) => {
            setUsers(response.data.data.users.data);
            set_pages(response.data.data.users.meta.last_page);
            set_currentpage(response.data.data.users.meta.current_page);
          })
          .finally(function () {
            set_showOverlay(false);
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        getUsers(currentpage)
          .then((res) => {
            setUsers(res.data.data.users.data);
            set_pages(res.data.data.users.meta.last_page);
            if (prevPageNumber) {
              set_currentpage(+prevPageNumber);
             
            } else {
              set_currentpage(res.data.data.users.meta.current_page);
             
            }
          })
          .finally(() => {
            set_showOverlay(false);
            setLoading(false);
          })
          .catch((err) => {});
      }
    };
    const retrieveRoles = () => {
      getRoles()
        .then((res) => {
          setRoles(res.data.data.roles.data);
        })
        .catch();
    };
    retrieveUsers();
    retrieveRoles();
  }, [currentpage]);

  if (loading) {
    return (
      <CSpinner
        color="primary"
        style={{
          width: "4rem",
          height: "4rem",
          marginTop: "300px",
          marginLeft: "600px",
        }}
      />
    );
  }

  return (
    <>
      <div className="bg-white p-3">
        <div className="d-flex justify-content-between align-items-center p-3">
          <h4 className="text-dark">Admin Users</h4>
        
        </div>
        <div className="p-3">
          <NewAccount roles={roles} toggle={toggle} modal={modal} />
        </div>
        <CRow>
          <Overlay active={showOverlay} marginTop="200px">
            <CCol>
              <CDropdown variant="dropdown" className="mb-3 ml-3">
                <CDropdownToggle
                  color="secondary"
                  size="md"
                  className="px-2 px-md-3 "
                >
                  Advanced Filter
                </CDropdownToggle>
                <CDropdownMenu
                  style={{
                    minWidth: "250px",
                    width: "100%",
                    maxWidth: "380px",
                  }}
                >
                  <h6 className="px-3">Advanced Filter</h6>
                  <CDropdownDivider />
                  <CForm id="create-filter-form">
                    <CRow className="px-3 py-2">
                      <CCol className="col-12 mb-2">
                        <CLabel className="font-weight-bold text-dark w-100">
                          Email
                        </CLabel>
                        <CInput
                          type="text"
                          className="w-100"
                          name="email"
                          onChange={update}
                          value={filterName !== "email" ? "" : filterVal}
                        />
                      </CCol>
                      <CCol className="col-12 mb-2">
                        <CLabel className="font-weight-bold text-dark w-100">
                          Name
                        </CLabel>
                        <CInput
                          type="text"
                          className="w-100"
                          name="full_name"
                          onChange={update}
                          value={filterName !== "full_name" ? "" : filterVal}
                        />
                      </CCol>

                      <CCol className="col-12 mb-2">
                        <CLabel className="font-weight-bold text-dark w-100">
                          Account Status
                        </CLabel>
                        <CSelect
                          className="w-100"
                          name="trashed"
                          onChange={update}
                        >
                          {filterName == "trashed" ? (
                            <option value={filterVal}>
                              {filterVal === ""
                                ? "All"
                                : filterVal === "active"
                                ? "Active"
                                : "Deleted"}
                            </option>
                          ) : (
                            <option value="">All</option>
                          )}
                          {filterVal !== "active" && (
                            <option value="active">Active</option>
                          )}
                          {filterVal !== "only" && (
                            <option value="only">Deleted</option>
                          )}
                        </CSelect>
                      </CCol>
                    </CRow>
                    <CDropdownDivider />
                    <div className="d-flex align-items-center justify-content-between px-3 py-1">
                      <a
                        className="cursor-pointer text-primary"
                        role="button"
                        onClick={(e) => refreshFilter(e)}
                      >
                        Reset Filter
                      </a>
                      <CButton
                        color="dark"
                        type="submit"
                        onClick={(e) => submitHandler(e)}
                      >
                        Submit
                      </CButton>
                    </div>
                  </CForm>
                </CDropdownMenu>
              </CDropdown>
            </CCol>
          </Overlay>
          <AdminDataTable
            items={users}
            loading={showOverlay}
            itemsPerPage={30}
          />
          <div className="p-3  col-sm-12">
            <CPagination
              activePage={currentpage}
              onActivePageChange={pageChange}
              pages={pages}
              doubleArrows={false}
              align="end"
            />
          </div>
        </CRow>
      </div>
    </>
  );
};

export default Register;

const NewAccount = ({ roles, toggle, modal }) => {
  const dispatch = useDispatch();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [gender, setGender] = useState("");
  const [referralCode, setReferralCode] = useState("");
  const [role, setRole] = useState("");
  const [lead, setLead] = useState("");

  const createAccountData = useSelector((state) => state.createAccount);

  let disableSubmit = true;
  if (
    firstName !== "" &&
    lastName !== "" &&
    email !== "" &&
    role !== "" &&
    gender !== ""
  ) {
    disableSubmit = false;
  }

  const submitHandler = (e) => {
    e.preventDefault();
    if (role === "account-manager") {
      toast(
        contentError(
          "You must include referral code to create an account manager account"
        )
      );
      return;
    }
    let payload
    if(role === 2 || role === 10){
       payload = {
        first_name: firstName,
        last_name: lastName,
        referral_code: referralCode,
        role,
        make_team_lead: lead,
        email,
        gender,
      };
    } else {
      payload = {
        first_name: firstName,
        last_name: lastName,
        role,
        email,
        gender,
      };
    }
    dispatch(createAccount(payload));
  };

  return (
    <>
      <CButton onClick={toggle} className="mr-1 mb-4" color="success">
        Create Internal User
      </CButton>
      <CModal show={modal} onClose={toggle} centered>
        <CModalHeader closeButton className="text-danger">
          CANCEL
        </CModalHeader>
        <CModalBody>
          <CContainer>
            <CRow className="justify-content-center">
              <CCol>
                <CCard className="mx-4">
                  <CCardBody className="">
                    <CForm onSubmit={submitHandler}>
                      <p className="text-success">CREATE A NEW ACCOUNT</p>
                      <CInputGroup className="mb-3">
                        <CInputGroupPrepend>
                          <CInputGroupText>
                            <CIcon name="cil-user" />
                          </CInputGroupText>
                        </CInputGroupPrepend>
                        <CInput
                          type="text"
                          placeholder="First Name"
                          onChange={(e) => setFirstName(e.target.value)}
                        />
                      </CInputGroup>
                      <CInputGroup className="mb-3">
                        <CInputGroupPrepend>
                          <CInputGroupText>
                            <CIcon name="cil-user" />
                          </CInputGroupText>
                        </CInputGroupPrepend>
                        <CInput
                          type="text"
                          placeholder="Last Name"
                          onChange={(e) => setLastName(e.target.value)}
                        />
                      </CInputGroup>
                      <CInputGroup className="mb-3">
                        <CInputGroupPrepend>
                          <CInputGroupText>@</CInputGroupText>
                        </CInputGroupPrepend>
                        <CInput
                          type="email"
                          placeholder="Email"
                          required
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </CInputGroup>

                      <CInputGroup className="mb-3">
                        <CSelect
                          className="w-100"
                          name="status"
                          onChange={(e) => setGender(e.target.value)}
                        >
                          <option value="">Choose Gender</option>
                          <option value="male">Male</option>
                          <option value="female">Female</option>
                        </CSelect>{" "}
                      </CInputGroup>
                      <CInputGroup className="mb-3">
                        <CSelect
                          className="w-100"
                          name="status"
                          defaultValue={''}
                          onChange={(e) => setRole(e.target.value)}
                        >
                          <option value="">
                            Select Role
                          </option>
                          {roles &&
                            roles.map((roles, index) => (
                              <option
                                key={index}
                                value={roles.id}
                                className="text-capitalize"
                              >
                                {roles.name.replace("-", " ")}
                              </option>
                            ))}
                        </CSelect>{" "}
                      </CInputGroup>

                      {role === 2 || role === 10 ? (
                        <div>
                          <CInputGroup className="">
                            <CInputGroupPrepend>
                              <CInputGroupText>
                                <CIcon name="cil-user" />
                              </CInputGroupText>
                            </CInputGroupPrepend>
                            <CInput
                              type="text"
                              placeholder="Referral Code"
                              onChange={(e) => setReferralCode(e.target.value)}
                            />
                           
                          </CInputGroup>
                          <p className="text-danger mb-3">
                  
                              referral code cannot be empty
                            </p>
                          <CInputGroup className="mb-3">
                            <CSelect
                              className="w-100"
                              name="lead"
                              onChange={(e) => setLead(e.target.value)}
                            >
                              <option value="">Make Team Lead</option>
                              <option value="yes">Yes</option>
                              <option value="no">No</option>
                            </CSelect>{" "}
                          </CInputGroup>
                        </div>
                      ) : (
                        ""
                      )}

                      {disableSubmit ? (
                        <CButton color="secondary" block type="submit" disabled>
                          Create Account
                        </CButton>
                      ) : createAccountData.loading ? (
                        <CButton color="success" block type="submit">
                          <CSpinner color="white" size="sm" /> Loading ...
                        </CButton>
                      ) : (
                        <CButton color="success" block type="submit">
                          Create Account
                        </CButton>
                      )}
                    </CForm>
                  </CCardBody>
                </CCard>
              </CCol>
            </CRow>
            <ToastContainer autoClose={5000} />{" "}
          </CContainer>
        </CModalBody>
      </CModal>
    </>
  );
};
