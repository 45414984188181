/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useHistory } from "react-router-dom";
import { CBadge, CDataTable } from "@coreui/react";
import moment from "moment";

const getBadge = (verification_status) => {
  switch (verification_status) {
    case "verified":
      return "success";
    case null:
      return "warning";
    case "failed":
      return "danger";
    default:
      return "warning";
  }
};

const UsersDataTable = ({
  items,
  loading,
  itemsPerPage,
  shouldIncludeReferral,
  page
}) => {
  let fields;

  if (shouldIncludeReferral) {
    fields = [
      {
        key: "full_name",
        _style: { width: "24%", textTransform: "capitalize" },
      },
      { key: "email", _style: { width: "22%" } },
      { key: "telephone", _style: { width: "13%" } },
      { key: "referral_code", _style: { width: "10%" } },
      { key: "gender", _style: { width: "10%", textTransform: "capitalize" } },
      {
        key: "verification_status",
        _style: { width: "12%", textTransform: "capitalize" },
      },
      { key: "created_at", label: "Date Registered (DD/MM/YYYY)" },
    ];
  } else {
    fields = [
      { key: "full_name", _style: { width: "20%" } },
      { key: "email", _style: { width: "22%" } },
      { key: "telephone", _style: { width: "13%" } },
      { key: "gender", _style: { width: "10%" } },
      { key: "verification_status", _style: { width: "20%" } },
      { key: "date_registered", label: "Date Registered (DD/MM/YYYY)" },
    ];
  }
  
  const history = useHistory();
  return (
    <CDataTable
      items={items}
      fields={fields}
      columnFilter
      // tableFilter
      loading={loading}
      footer
      responsive={true}
      hover
      sorter
      itemsPerPage={itemsPerPage}
      // activePage
      clickableRows
      // This will be modified to reflect the specific user clicked
      onRowClick={(item) => history.push({pathname:`/users/user-details/${item.id}`,state: page})}
      scopedSlots={
        shouldIncludeReferral
          ? {
              telephone: (item) => (
                <td>
                  {item.telephone === null ? "Not Available" : item.telephone}
                </td>
              ),
              verification_status: (item) => (
                <td className="text-capitalize">
                  <CBadge
                    color={
                      item.verification
                        ? getBadge(item.verification.status)
                        : getBadge(null)
                    }
                  >
                    {item.verification === null
                      ? "pending"
                      : item && item.verification && item.verification.status}
                  </CBadge>
                </td>
              ),
              created_at: (item) => (
                <td>
                  <>{moment(item.created_at).format("DD-MMM-YYYY")}</>
                </td>
              ),
              gender: (item) => (
                <td className="text-capitalize">
                  <>{item.gender === null ? "Not Available" : item.gender}</>
                </td>
              ),
              full_name: (item) => (
                <td className="text-capitalize">
                  <>
                    {item &&
                      item.full_name &&
                      item.full_name.replace(/(^\w)|([-\s]\w)/g, (match) =>
                        match.toUpperCase()
                      )}
                  </>
                </td>
              ),
              email: (item) => (
                <td>
                  <>{item && item.email && item.email}</>
                </td>
              ),
              referral_code: (item) => (
                <td>
                  <>{item && item.referral_code && item.referral_code}</>
                </td>
              ),
            }
          : {
              telephone: (item) => (
                <td>
                  {item.telephone === null ? "Not Available" : item.telephone}
                </td>
              ),
              verification_status: (item) => (
                <td className="text-capitalize">
                  <CBadge
                    color={
                      item.verification
                        ? getBadge(item.verification.status)
                        : getBadge(null)
                    }
                  >
                    {item.verification === null
                      ? "pending"
                      : item && item.verification && item.verification.status}
                  </CBadge>
                </td>
              ),
              date_registered: (item) => (
                <td>
                  <>{moment(item.created_at).format("DD-MMM-YYYY")}</>
                </td>
              ),
              gender: (item) => (
                <td className="text-capitalize">
                  <>{item.gender === null ? "Not Available" : item.gender}</>
                </td>
              ),
              full_name: (item) => (
                <td className="text-capitalize">
                  <>
                    {item &&
                      item.full_name &&
                      item.full_name.replace(/(^\w)|([-\s]\w)/g, (match) =>
                        match.toUpperCase()
                      )}
                  </>
                </td>
              ),
              email: (item) => (
                <td>
                  <>{item && item.email && item.email}</>
                </td>
              ),
            }
      }
    />
  );
};

export default UsersDataTable;
