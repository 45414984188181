// api.js
import Axios from "axios";
import Cookie from "js-cookie";
import { CAlert } from "@coreui/react";
import { logout } from "../redux/_actions/userActions";

const getURL = () => {
  const testURL = process.env.REACT_APP_AP_URL;
  const baseURL = process.env.REACT_APP_API_URL;
  if (process.env.NODE_ENV === "production") {
    console.log(baseURL);
    return baseURL;
  } else if (process.env.NODE_ENV === "development") {
    console.log(testURL);
    return testURL;
  }
  console.log("")
  return "";
};

const baseURL = getURL();

export const axiosWithAuth = Axios.create({
  baseURL,
});

axiosWithAuth.interceptors.request.use((req) => {
  const token = Cookie.get("token");
  if (token) {
    req.headers.authorization = `Bearer ${token}`;
  }
  return req;
});

axiosWithAuth.interceptors.response.use(
  (response) => response,
  async (err) => {
    const status = err.response ? err.response.status : null;
    if (status === 401 || status === 403) {
      try {
        const response = await axiosWithAuth.get("/refresh", {
          headers: {
            "X-refresh-Token": `${Cookie.get("refreshToken")}`,
          },
        });
        const { data } = response;

        // Log the response structure for debugging
        console.log("Token refresh response:", data);

        // Check if the necessary tokens are present in the response
        if (data.data && data.data.token && data.data.refresh_token) {
          Cookie.set("token", data.data.token);
          Cookie.set("isAuthenticated", true);
          Cookie.set("refreshToken", data.data.refresh_token);
          window.location.reload();
        } else {
          throw new Error("Token refresh response does not contain the necessary tokens.");
        }
      } catch (error) {
        console.error("Token refresh error:", error);
        logout();
      }
    } else {
      console.error("API response error:", err);
    }
    return Promise.reject(err);
  }
);

export const axios = Axios.create({
  baseURL,
  mode: "no-cors",
  withCredentials: false,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
    "Access-Control-Allow-Credentials": true,
    "Access-Control-Allow-Headers": "Content-Type, Authorization",
  },
});

export const selectToken = Cookie.get("token");

export const isLogin = () => {
  return Cookie.get("token") && Cookie.get("isAuthenticated") === "true";
};

export const contentError = (error) => (
  <div className="mt-2">
    <CAlert color="danger">{error}</CAlert>
  </div>
);

export const contentSuccess = () => (
  <div className="mt-2">
    <CAlert color="success">{"Successful"}</CAlert>
  </div>
);
