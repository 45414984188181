import { toast } from "react-toastify";
import {
  CREATE_ACCOUNT_FAIL,
  CREATE_ACCOUNT_REQUEST,
  CREATE_ACCOUNT_SUCCESS,
} from "../_constants/adminConstants";

import { axiosWithAuth, contentSuccess, contentError } from "../../_utils/api";

const createAccount = (payload) => async (dispatch) => {
  dispatch({ type: CREATE_ACCOUNT_REQUEST });
  try {
    const response = await axiosWithAuth.post(`/admin/internal-users`, payload);
    dispatch({ type: CREATE_ACCOUNT_SUCCESS, payload: response.data });
    toast(contentSuccess("Success"));
  } catch (error) {
   
    if(error.response.data){
      const {data} = error.response
      const errorMesg = data.error.message
      dispatch({ type: CREATE_ACCOUNT_FAIL, payload: error.message });
      toast(contentError(errorMesg));
    }else{
      dispatch({ type: CREATE_ACCOUNT_FAIL, payload: error.message });
      toast(contentError("An Error Occured"));
    }
   
  }
};

export { createAccount };

