/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { CSVLink } from "react-csv";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CPagination,
  CDropdown,
  CDropdownDivider,
  CDropdownToggle,
  CDropdownMenu,
  CForm,
  CInput,
  CLabel,
  CSelect,
  CModal,
  CModalBody,
  CModalHeader,
  CContainer,
  CSpinner,
} from "@coreui/react";
import { ToastContainer, toast } from "react-toastify";
import CIcon from "@coreui/icons-react";
import MessagesDataTable from "src/components/DataTables/MessagesDataTable";
import { Overlay } from "../../components";
import { sendAccountList } from "src/redux/_actions/accountActions";
import { getAllMessages, messagePage, messageSearch} from "src/redux/_services/MessageService";
import AccountService from "../../redux/_services/AccountService";
import { useAccountTypes } from "../../_hooks/accounts";
import { Printer } from "react-feather";

const Messages = (props) => {
  const [messages, setMessages] = useState();
  const [filterName, set_filter_name] = useState();
  const [filterVal, set_filter_value] = useState();
  const [query, set_query] = useState();
  const [lastPage, set_lastpage] = useState("");
  const [firstPage, setFirstPage] = useState("");
  const [nextPage, setNextPage] = useState("");
  const [prevPage, setPrevPage] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [status, setStatus] = useState("")
 
  const [showOverlay, set_showOverlay] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();


  const pageNext = (e) => {
    e.preventDefault();
    if(nextPage == null){
        return
    }
    set_showOverlay(true);
    messagePage(nextPage)
    .then(response => {
        set_showOverlay(false);
        setMessages(response.data.data.messages);
        set_lastpage(response.data.data.links.last);
        setFirstPage(response.data.data.links.first);
        setPrevPage(response.data.data.links.prev);
        setNextPage(response.data.data.links.next);
    })
    .catch((err) => {
        set_showOverlay(false);
    })
  };

  const pagePrev = (e) => {
    e.preventDefault();
    if(prevPage == null){
        return
    }
    set_showOverlay(true);
    messagePage(prevPage)
    .then(response => {
        set_showOverlay(false);
        setMessages(response.data.data.messages);
        set_lastpage(response.data.data.links.last);
        setFirstPage(response.data.data.links.first);
        setPrevPage(response.data.data.links.prev);
        setNextPage(response.data.data.links.next);
    })
    .catch((err) => {
        console.log(err)
        set_showOverlay(false);
    })
  };

  const pageFirst = (e) => {
    e.preventDefault();
    if(firstPage == null){
        return
    }
    set_showOverlay(true);
    messagePage(firstPage)
    .then(response => {
        set_showOverlay(false);
        setMessages(response.data.data.messages);
        set_lastpage(response.data.data.links.last);
        setFirstPage(response.data.data.links.first);
        setPrevPage(response.data.data.links.prev);
        setNextPage(response.data.data.links.next);
    })
    .catch((err) => {
        set_showOverlay(false);
    })
  };

  const pageLast = (e) => {
    e.preventDefault();
    if(lastPage == null){
        return
    }
    set_showOverlay(true);
    messagePage(lastPage)
    .then(response => {
        set_showOverlay(false);
        setMessages(response.data.data.messages);
        set_lastpage(response.data.data.links.last);
        setFirstPage(response.data.data.links.first);
        setPrevPage(response.data.data.links.prev);
        setNextPage(response.data.data.links.next);
    })
    .catch((err) => {
        console.log(err)
        set_showOverlay(false);
    })
  };

  const refreshFilter = (e) => {
    e.preventDefault();
    getAllMessages()
          .then((response) => {
            setMessages(response.data.data.messages);
            set_lastpage(response.data.data.links.last);
            setFirstPage(response.data.data.links.first);
            setPrevPage(response.data.data.links.prev);
            setNextPage(response.data.data.links.next);
          })
          .finally(function () {
            set_showOverlay(false);
          })
          .catch((e) => {
            console.log(e);
          });
  };


  const submitHandler = (e) => {
    e.preventDefault();
    set_showOverlay(true);
    if(phoneNumber){
      messageSearch(phoneNumber, "telephone")
        .then((response) => {
            set_showOverlay(false);
            setMessages(response.data.data.message);
            set_lastpage(response.data.data.links.last);
            setFirstPage(response.data.data.links.first);
            setPrevPage(response.data.data.links.prev);
            setNextPage(response.data.data.links.next);
        })
        .finally(function () {
          set_showOverlay(false);
        })
        .catch((e) => {
          console.log(e);
        });

    }else{
      messageSearch(status)
      .then((response) => {
          set_showOverlay(false);
          setMessages(response.data.data.messages);
          set_lastpage(response.data.data.links.last);
          setFirstPage(response.data.data.links.first);
          setPrevPage(response.data.data.links.prev);
          setNextPage(response.data.data.links.next);
      })
      .finally(function () {
        set_showOverlay(false);
      })
      .catch((e) => {
        console.log(e);
      });
      
    }
  
    // setStatus("")
  };

  useEffect(() => {
    const retrieveMessage = () => {
      set_showOverlay(true);
        getAllMessages()
          .then((response) => {
            setMessages(response.data.data.messages);
            set_lastpage(response.data.data.links.last);
            setFirstPage(response.data.data.links.first);
            setPrevPage(response.data.data.links.prev);
            setNextPage(response.data.data.links.next);
          })
          .finally(function () {
            set_showOverlay(false);
          })
          .catch((e) => {
            console.log(e);
          });
    };

    retrieveMessage();
  }, []);

  return (
    <>
      <CRow>
        <Overlay active={showOverlay} marginTop="200px">
          <CCol>
            <CCard>
              <CCardHeader>
                <div className="d-flex justify-content-between align-items-center">
                  <h4>Messages</h4>
                </div>
              </CCardHeader>
              <CCardBody>
                <CDropdown variant="dropdown" className="mb-3">
                  <CDropdownToggle
                    color="secondary"
                    size="md"
                    className="px-2 px-md-3"
                  >
                    Advanced Filter
                  </CDropdownToggle>
                  <CDropdownMenu
                    style={{
                      minWidth: "250px",
                      width: "100%",
                      maxWidth: "380px",
                    }}
                  >
                    <h6 className="px-3">Advanced Filter</h6>
                    <CDropdownDivider />
                    <CForm id="create-filter-form">
                      <CRow className="px-3 py-2">
                        <CCol className="col-12 mb-2">
                          <CLabel className="font-weight-bold text-dark w-100">
                            Phone Number
                          </CLabel>
                          <CInput
                            type="text"
                            className="w-100"
                            name="phone_number"
                            value={
                             status === "" ? phoneNumber : ""
                            }
                            onChange={(e)=> {setPhoneNumber(e.target.value); setStatus("")}}
                          />
                        </CCol>
                        <CCol className="col-12 mb-2">
                          <CLabel className="font-weight-bold text-dark w-100">
                           Status
                          </CLabel>
                          <CSelect
                            className="w-100"
                            name="status"
                            onChange={(e) => setStatus(e.target.value)}
                           
                          > 
                            <option value="">All</option> 
                          {phoneNumber === "" && (
                             <option value="delivered">Delivered</option>
                          )}    
                            {phoneNumber === "" && (
                            <option value="delivery_failed">Failed</option>
                          )}   
                           {phoneNumber === "" && (
                             <option value="sent">Sent</option>
                          )}  
                              {phoneNumber === "" && (
                              <option value="expired">Expired</option>
                          )}     
                                
                          {/* <option value="">All</option> */}
                          {/* <option value="delivered">Delivered</option>
                          <option value="delivery_failed">Failed</option>
                          <option value="sent">Sent</option>
                          <option value="expired">Expired</option> */}
                          </CSelect>
                        </CCol>
                      </CRow>
                      <CDropdownDivider />
                      <div className="d-flex align-items-center justify-content-between px-3 py-1">
                        <a
                          className="cursor-pointer text-primary"
                          role="button"
                          onClick={(e) => refreshFilter(e)}
                        >
                          Reset Filter
                        </a>
                        <CButton
                          color="dark"
                          type="submit"
                          onClick={(e) => submitHandler(e)}
                        >
                          Submit
                        </CButton>
                      </div>
                    </CForm>
                  </CDropdownMenu>
                </CDropdown>

                <MessagesDataTable
                  items={messages}
                //   page={currentpage}
                  loading={showOverlay}
                  itemsPerPage={50}
                />

                <nav aria-label="Page navigation example">
                  <ul className="pagination justify-content-end">
                  <li className={`page-item ${firstPage == null ? "disabled" : ""}`}>
                      <a className="page-link" href="#" onClick={pageFirst}>
                      &laquo;
                      </a>
                    </li>
                    <li className={`page-item ${prevPage == null ? "disabled" : ""}`}>
                      <a className="page-link" href="#" onClick={pagePrev}>
                      &lsaquo;
                      </a>
                    </li>
                    <li className={`page-item ${nextPage == null ? "disabled" : ""}`}>
                      <a className="page-link" href="#" onClick={pageNext}>
                      &rsaquo;
                      </a>
                    </li>
                    <li className="page-item">
                      <a className="page-link" href="#" onClick={pageLast}>
                      &raquo;
                      </a>
                    </li>
                  </ul>
                </nav>
              </CCardBody>
            </CCard>
          </CCol>
        </Overlay>
      </CRow>
    </>
  );
};

export default Messages;
