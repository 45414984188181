import { combineReducers } from "redux";

import {
  userSigninReducer,
  verifyUserReduer,
  userUpdatePasswordReducer,
  requestPasswordTokenReducer,
} from "./_reducer/userReducers";
import {
  accountUpdateReducer,
  accountBlockReducer,
  accountUnBlockReducer,
  unBlockFundReducer,
  accountStatementReducer,
  accountVerifyReducer,
  accountOverdraftReducer,
  accountListReducer,
  blockFundReducer
} from "./_reducer/accountReducers";
import {
  transactionCreateReducer,
  transactionSaveReducer,
  updatePinReducer,
  requestTokenReducer,
  resetPinReducer,
} from "./_reducer/transactionReducers";
import {
  investmentCreateReducer,
  saveInvestmmentAccountReducer,
  investmentTopupReducer,
} from "./_reducer/investmentReducers";
import { createAccountReducer } from "./_reducer/adminReducer";


const initialState = {
    sidebarShow: "responsive",
  };


const changeState = (state = { initialState }, { type, ...rest }) => {
    switch (type) {
      case "set":
        return { ...state, ...rest };
      default:
        return state;
    }
  };

const reducer = combineReducers({
  userSignin: userSigninReducer,
  userVerify: verifyUserReduer,
  accountUpdate: accountUpdateReducer,
  accountBlock: accountBlockReducer,
  accountStatement: accountStatementReducer,
  accountVerify: accountVerifyReducer,
  accountOverdraft: accountOverdraftReducer,
  accountList: accountListReducer,
  createAccount: createAccountReducer,
  pnbUnBlock: accountUnBlockReducer,
  blockFund: blockFundReducer,
  unBlockFund: unBlockFundReducer,
  transactionSave: transactionSaveReducer,
  transactionCreate: transactionCreateReducer,
  investmentCreate: investmentCreateReducer,
  investmentSaveAccount: saveInvestmmentAccountReducer,
  userUpdatePassword: userUpdatePasswordReducer,
  updatePin: updatePinReducer,
  requestToken: requestTokenReducer,
  resetPin: resetPinReducer,
  requestpasswordToken: requestPasswordTokenReducer,
  topUpInvestment: investmentTopupReducer,
  changeState,
});
  
  export default reducer;