/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import moment from "moment";
import {
  CCard,
  CCardBody,
  CCol,
  CRow,
  CButton,
  CSelect,
  CForm,
  CLabel,
  CInput,
} from "@coreui/react";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import { ToastContainer } from "react-toastify";
import { Overlay } from "../../components";
import styles from "../../scss/details.module.scss";
import commonStyles from "../../scss/common-form.module.scss";
import "../../css/common-form.css";
import {
  saveInvestmentAccount,
  CreateInvestmentAccounts,
  CreateInvestmentAccountsRollover,
  CreateInvestmentAccountsWRollover,
  CreateInvestmentWDateRollover,
  CreateInvestmentStandardCWRollover,
  CreateInvestmentCustom,
} from "../../redux/_actions/investmentActions";
import { useInvestmentTypes, useRolloverTypes } from "../../_hooks/investments";
import { axiosWithAuth, selectToken } from "../../_utils/api";

// const re = /^[0-9\b]+$/;

const CreateInvestment = () => {
  const bank_code = "000000";
  const token = selectToken;

  const [step, setStep] = useState(1);

 

  const [input, setInput] = useState({
    amount: "",
    title: "",
    investmentClass: "",
    interestRate: "",
    investmentDuration: "",
    sourceAccount: "",
    sourceAccountName: "",
    beneficiaryAccount: "",
    beneficiaryAccountName: "",
    rolloverStatus: "",
    rolloverType: "",
    startDate: "",
    duration: "",
  });
  const [source_account_name, set_source_account_name] = useState("");
  const [beneficiary_account_name, set_beneficiary_account_name] = useState("");
  const [investment_name, set_investment_name] = useState("");
  const [investment_type, set_investment_type] = useState("");
  const [showOverlay, set_showOverlay] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [pin, set_pin] = useState("");

  const dispatch = useDispatch();

  const calculateDaysLeft = (startDate, endDate) => {
    if (!moment.isMoment(startDate)) startDate = moment(startDate);
    if (!moment.isMoment(endDate)) endDate = moment(endDate);

    return endDate.diff(startDate, "months");
  };
  const monthsLeft = calculateDaysLeft(startDate, endDate);
  const duration = monthsLeft;

  const [investmentTypesData, fetchInvestmentTypesData] = useInvestmentTypes();
  useEffect(() => {
    fetchInvestmentTypesData();
  }, [fetchInvestmentTypesData]);
  investmentTypesData.sort(function (a, b) {
    return a.interest_rate - b.interest_rate;
  });
  const [rolloverTypesData, fetchRolloverTypesData] = useRolloverTypes();
  useEffect(() => {
    fetchRolloverTypesData();
  }, [fetchRolloverTypesData]);

  useEffect(() => {
    var accountdigit = /^\d{10}$/;
    const account_number = input.sourceAccount;

    if (input.sourceAccount.match(accountdigit)) {
      set_showOverlay(true);
      axiosWithAuth
        .post("/accounts/validate", {
          source_account_number:account_number,
          account_number,
          bank_code,
        })
        .then((res) => {
          const cb_account_name = res.data.data.account_name;
          set_source_account_name(cb_account_name);
        })
        .finally(function () {
          set_showOverlay(false);
        })
        .catch((error) => {
          // toast.error(contentError(error));
        });
    } else if (!input.sourceAccount.match(accountdigit)) {
      set_source_account_name("");
    }
  }, [input.sourceAccount]);

  useEffect(() => {
    var accountdigit = /^\d{10}$/;
    const account_number = input.beneficiaryAccount;
    const source_account_number = input.sourceAccount;
    if (input.beneficiaryAccount.match(accountdigit)) {
      set_showOverlay(true);

      axiosWithAuth
        .post("/accounts/validate", {
          source_account_number,
          account_number,
          bank_code,
        })
        .then((res) => {
          const account_name = res.data.data.account_name;
          set_beneficiary_account_name(account_name);
        })
        .finally(function () {
          set_showOverlay(false);
        })
        .catch(() => {
          // toast.error(contentError);
        });
    } else if (!input.beneficiaryAccount.match(accountdigit)) {
      set_beneficiary_account_name("");
    }

    return () => {
      //
    };
  }, [input.beneficiaryAccount]);

  const update = (e) => {
    if (e.target.name === "pin") {
      set_pin(e.target.value);
    } else if (e.target.name === "investment_type") {
      set_investment_type(e.target.value.slice(0, 36));
      set_investment_name(e.target.value.slice(37));
    }
  };

  const nextStep = () => {
    if (step === 1) {
      setStep(step + 1);
    }
    dispatch(
      saveInvestmentAccount({
        source_account_name,
        beneficiary_account_name,
        duration,
        investment_type,
        investment_name,
      })
    );
  };
  const investmentSaveAccount = useSelector(
    (state) => state.investmentSaveAccount
  );
  const { saveInvestmentAccountData } = investmentSaveAccount;
  const investmentCreate = useSelector((state) => state.investmentCreate);
  const { success, loadin } = investmentCreate;
  const prevStep = () => {
    if (step === 2) {
      setStep(step - 1);
    }
  };

  useEffect(() => {
    setStep(step);
  }, [step]);
  useEffect(() => {
    if (success === true) {
      // setStep(step - 1);
      setInput({
        amount: "",
        title: "",
        investmentClass: "",
        interestRate: "",
        investmentDuration: "",
        sourceAccount: "",
        sourceAccountName: "",
        beneficiaryAccount: "",
        beneficiaryAccountName: "",
        rolloverStatus: "",
        rolloverType: "",
        startDate: "",
        duration: "",
      });
      set_source_account_name("");
    }
  }, [success]);
  const formatPrice = (value) => {
    if (value === 0 || !value) {
      return;
    } else {
      let val = (value / 1).toFixed(2).replace(",", ".");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  };

  const destrollover =
    input.rolloverStatus && input.rolloverStatus.replace(/["']/g, "");

  const transactionHandler = () => {
    const rollover = Boolean(destrollover);
    const amount = input.amount;
    const beneficiary_account = input.beneficiaryAccount;
    const source_account = input.sourceAccount;
    const title = input.title;
    const rollover_type = input.rolloverType;
    const start_date = input.startDate;
    const interest_rate = input.interestRate;

    if (
      input.rolloverStatus === "false" &&
      input.investmentClass === "standard-investment" &&
      saveInvestmentAccountData.start_date === ""
    ) {
     
      dispatch(
        CreateInvestmentAccountsRollover(
          amount,
          beneficiary_account,
          pin,
          investment_type,
          source_account,
          title
        )
      );
    } else if (
      input.rolloverStatus === "true" &&
      input.investmentClass === "standard-investment" &&
      saveInvestmentAccountData.start_date === ""
    ) {
      // console.log("create tx 2")
      dispatch(
        CreateInvestmentWDateRollover(
          amount,
          beneficiary_account,
          pin,
          rollover,
          rollover_type,
          investment_type,
          source_account,
          title
        )
      );
    } else if (
      input.rolloverStatus === "false" &&
      input.investmentClass === "standard-investment"
    ) {
      // console.log("create tx 3")
      dispatch(
        CreateInvestmentAccounts(
          amount,
          beneficiary_account,
          pin,
          investment_type,
          source_account,
          start_date,
          title
        )
      );
    } else if (
      input.rolloverStatus === "true" &&
      input.investmentClass === "standard-investment"
    ) {
      // console.log("create tx 4")
      dispatch(
        CreateInvestmentAccountsWRollover(
          amount,
          beneficiary_account,
          pin,
          rollover,
          rollover_type,
          investment_type,
          source_account,
          start_date,
          title
        )
      );
    } else if (
      input.rolloverStatus === "true" &&
      input.investmentClass === "custom-investment"
    ) {
      // console.log("create tx 5")
      dispatch(
        CreateInvestmentStandardCWRollover(
          amount,
          beneficiary_account,
          duration,
          interest_rate,
          pin,
          rollover,
          rollover_type,
          source_account,
          start_date,
          title
        )
      );
    } else if (
      input.rolloverStatus === "false" &&
      input.investmentClass === "custom-investment"
    ) {
      // console.log("create tx 6")
      dispatch(
        CreateInvestmentCustom(
          amount,
          beneficiary_account,
          duration,
          interest_rate,
          pin,
          source_account,
          start_date,
          title
        )
      );
    }
  };

  const handleOnChange = (prop) => (event) => {
    setInput({
      ...input,
      [prop]: event.target.value,
    });
  };

  return (
    <>
      <Overlay active={showOverlay} marginTop="200px">
        <h4 className="mb-2">Create Investments</h4>
        <div className="d-flex align-items-center justify-content-center mt-4 mt-md-0 mb-4">
          <span
            className={step === 1 ? "stepper current" : "stepper"}
            onClick={prevStep}
          >
            1
          </span>
          <span
            className={step === 2 ? "stepper current" : "stepper"}
            onClick={nextStep}
          >
            2
          </span>
        </div>
        <CForm className="px-md-4">
          {step === 1 && (
            <CRow className={commonStyles.container}>
              <CCol sm="10" md="8" className="mx-auto px-0 px-md-3">
                <CCard>
                  <CCardBody>
                    <div className="mb-3">
                      <CLabel htmlFor="amount">Amount</CLabel>
                      <CInput
                        type="number"
                        id="amount"
                        min="1"
                        placeholder="Enter Amount"
                        value={input.amount}
                        onChange={handleOnChange("amount")}
                      />
                    </div>

                    <div className="mb-3">
                      <CLabel htmlFor="title">Title</CLabel>
                      <CInput
                        id="title"
                        placeholder="Enter Title"
                        value={input.title}
                        onChange={handleOnChange("title")}
                      />
                    </div>

                    <div className="mb-3">
                      <CLabel htmlFor="investmentClass">
                        Class of Investment
                      </CLabel>
                      <CSelect
                        id="investmentClass"
                        value={input.investmentClass}
                        onChange={handleOnChange("investmentClass")}
                      >
                        <option value="" disabled>
                          Please select an option
                        </option>
                        <option value="standard-investment">
                          Standard Investment
                        </option>
                        <option value="custom-investment">
                          Custom Investment
                        </option>
                      </CSelect>
                    </div>

                    {input.investmentClass === "standard-investment" && (
                      <div className="mb-3">
                        <CLabel htmlFor="investmentType">
                          Investment Type
                        </CLabel>
                        <CSelect
                          id="investmentType"
                          name="investment_type"
                          onChange={update}
                        >
                          <option disabled value="">
                            Select Investment Type
                          </option>
                          {investmentTypesData.map(
                            ({ duration, interest_rate, name, id }, index) => (
                              <option key={index} value={[id, name]}>
                                {name} plan for {duration} months @
                                {interest_rate} %
                              </option>
                            )
                          )}
                        </CSelect>
                      </div>
                    )}

                    {input.investmentClass === "custom-investment" && (
                      <div className="mb-3">
                        <CLabel htmlFor="investmentDuration">Duration</CLabel>
                        <div className="d-flex">
                          <DatePicker
                            selected={startDate}
                            selectsStart
                            startDate={startDate}
                            endDate={endDate}
                            onChange={(date) => setStartDate(date)}
                          />

                          <DatePicker
                            className="ml-2"
                            selected={endDate}
                            selectsEnd
                            startDate={startDate}
                            endDate={endDate}
                            onChange={(date) => setEndDate(date)}
                          />
                        </div>
                        <h6 className="mt-4">{monthsLeft} Months</h6>
                      </div>
                    )}
                    {input.investmentClass === "custom-investment" && (
                      <div className="mb-3">
                        <CLabel htmlFor="investmentDuration">
                          {" "}
                          Interest Rate (%)
                        </CLabel>
                        <CInput
                          type="number"
                          id="duration"
                          name="interest_rate"
                          placeholder="Enter Interest Rate"
                          value={input.interestRate}
                          onChange={handleOnChange("interestRate")}
                        ></CInput>
                      </div>
                    )}

                    <div className="mb-3">
                      <CLabel htmlFor="sourceAccount">Source Account</CLabel>
                      <CInput
                        type="number"
                        id="sourceAccount"
                        placeholder="Enter Source Account"
                        value={input.sourceAccount}
                        onChange={handleOnChange("sourceAccount")}
                        maxLength={10}
                      />
                    </div>

                    <div className="mb-3">
                      <CLabel htmlFor="sourceAccountName">
                        Source Account Name
                      </CLabel>
                      <CInput
                        id="sourceAccountName"
                        value={source_account_name}
                        readOnly
                      />
                    </div>

                    <div className="mb-3">
                      <CLabel htmlFor="beneficiaryAccount">
                        Beneficiary Account Number
                      </CLabel>
                      <CInput
                        type="number"
                        id="beneficiaryAccount"
                        placeholder="Enter Account Number"
                        value={input.beneficiaryAccount}
                        onChange={handleOnChange("beneficiaryAccount")}
                        maxLength={10}
                      />
                    </div>

                    <div className="mb-3">
                      <CLabel>Beneficiary Account Name</CLabel>
                      <CInput
                        id="beneficiaryAccountName"
                        value={beneficiary_account_name}
                        readOnly
                      />
                    </div>

                    <div className="mb-3">
                      <CLabel htmlFor="rolloverStatus">Rollover</CLabel>
                      <CSelect
                        id="rolloverStatus"
                        value={input.rolloverStatus}
                        onChange={handleOnChange("rolloverStatus")}
                      >
                        <option value="" disabled>
                          Select Rollover
                        </option>
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                      </CSelect>
                    </div>

                    {input.rolloverStatus === "true" && (
                      <div className="mb-3">
                        <CLabel htmlFor="rolloverType">Rollover Type</CLabel>
                        <CSelect
                          id="rolloverType"
                          value={input.rolloverType}
                          onChange={handleOnChange("rolloverType")}
                        >
                          <option value="" disabled>
                            Select Rollover type
                          </option>
                          {rolloverTypesData.map(({ id, name }, index) => (
                            <option key={index} value={id}>
                              {name}
                            </option>
                          ))}
                        </CSelect>
                      </div>
                    )}
                    <div className="mb-3">
                      <CLabel htmlFor="startDate">Start Date </CLabel>
                      <small> specified date must be a date in the past.</small>
                      {/* <CInput
                        id="startDate"
                        type="date"
                        value={input.startDate}
                        onChange={handleOnChange("startDate")}
                      /> */}
                    <DatePicker
                    dateFormat="dd-MM-yyyy"
                    className="form-control"
                    selected={startDate}
                    isClearable
                    placeholderText="DD/MM/YY"
                    // maxDate={accountStatementEndDate}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    selectsStart
                    // startDate={accountStatementStartDate}
                    // endDate={accountStatementEndDate}
                    onChange={(date) => {
                      setStartDate(date);
                    }}
                  />
                    </div>

                    <CButton
                      color="primary"
                      block
                      className="mt-4"
                      onClick={nextStep}
                    >
                      Continue
                    </CButton>
                  </CCardBody>
                </CCard>
              </CCol>
            </CRow>
          )}

          {step === 2 && (
            <CRow className={commonStyles.container}>
              <CCol sm="10" md="8" className="col-12 mx-auto px-0 px-md-3">
                <CCard className="px-3 py-2 mb-3">
                  <h5 className="py-3 mb-0 text-center">Review Investment</h5>

                  <CRow className="py-2">
                    <CCol xs="5" className={styles.field_label}>
                      Title:
                    </CCol>
                    <CCol xs="7" className={styles.field_value}>
                      {input.title}
                    </CCol>
                  </CRow>

                  <CRow className="py-2">
                    <CCol xs="5" className={styles.field_label}>
                      Amount:
                    </CCol>
                    <CCol xs="7" className={styles.field_value}>
                      {input.amount && <>&#8358;{formatPrice(input.amount)}</>}
                    </CCol>
                  </CRow>

                  <CRow className="py-2">
                    <CCol xs="5" className={styles.field_label}>
                      Source Account Number:
                    </CCol>
                    <CCol xs="7" className={styles.field_value}>
                      {input.sourceAccount}
                    </CCol>
                  </CRow>

                  <CRow className="py-2">
                    <CCol xs="5" className={styles.field_label}>
                      Source Account Name:
                    </CCol>
                    <CCol xs="7" className={styles.field_value}>
                      {saveInvestmentAccountData.source_account_name}
                    </CCol>
                  </CRow>

                  <CRow className="py-2">
                    <CCol xs="5" className={styles.field_label}>
                      Beneficiary Account Number:
                    </CCol>
                    <CCol xs="7" className={styles.field_value}>
                      {input.beneficiaryAccount}
                    </CCol>
                  </CRow>

                  <CRow className="py-2">
                    <CCol xs="5" className={styles.field_label}>
                      Beneficiary Account Name:
                    </CCol>
                    <CCol xs="7" className={styles.field_value}>
                      {saveInvestmentAccountData.beneficiary_account_name}
                    </CCol>
                  </CRow>
                  {saveInvestmentAccountData.investment_type ? (
                    <CRow className="py-2">
                      <CCol xs="5" className={styles.field_label}>
                        Investment Type:
                      </CCol>
                      <CCol xs="7" className={styles.field_value}>
                        {saveInvestmentAccountData.investment_name}
                      </CCol>
                    </CRow>
                  ) : (
                    ""
                  )}
                  {saveInvestmentAccountData.interest_rate ? (
                    <CRow className="py-2">
                      <CCol xs="5" className={styles.field_label}>
                        Interest Rate:
                      </CCol>
                      <CCol xs="7" className={styles.field_value}>
                        {saveInvestmentAccountData.interest_rate}%
                      </CCol>
                    </CRow>
                  ) : (
                    ""
                  )}
                  {saveInvestmentAccountData.duration ? (
                    <CRow className="py-2">
                      <CCol xs="5" className={styles.field_label}>
                        Duration:
                      </CCol>
                      <CCol xs="7" className={styles.field_value}>
                        {saveInvestmentAccountData.duration} Months
                      </CCol>
                    </CRow>
                  ) : (
                    ""
                  )}
                  {input.rolloverStatus === "true" && (
                    <CRow className="py-2">
                      <CCol xs="5" className={styles.field_label}>
                        Rollover Status:
                      </CCol>
                      <CCol xs="7" className={styles.field_value}>
                        {input.rolloverStatus === true ? "No" : "Yes"}
                      </CCol>
                    </CRow>
                  )}
                  {input.rolloverStatus === "true" && (
                    <CRow className="py-2">
                      <CCol xs="5" className={styles.field_label}>
                        Rollover Type:
                      </CCol>
                      <CCol xs="7" className={styles.field_value}>
                        {input.rollover_type ===
                        "c02a7a37-39f4-4209-933c-edbefaaef595"
                          ? "Investment and Interest"
                          : "Investment"}
                      </CCol>
                    </CRow>
                  )}

                  <CRow className="py-2">
                    <CCol xs="5" className={styles.field_label}>
                      Start Date:
                    </CCol>
                    <CCol xs="7" className={styles.field_value}>
                      {input.startDate}
                    </CCol>
                  </CRow>

                  <CRow className="py-3">
                    <CCol xs="4" className={styles.field_label}>
                      <p className="text-primary">Transaction Pin</p>
                    </CCol>
                    <CCol xs="8">
                      <CInput
                        placeholder="4 digit pin"
                        name="pin"
                        onChange={update}
                        type="password"
                      />
                    </CCol>
                  </CRow>
                </CCard>

                <div className="mt-0 d-flex justify-content-between">
                  <CButton color="danger" onClick={prevStep}>
                    Previous
                  </CButton>
                  <CButton color="primary" onClick={transactionHandler}>
                    {loadin && <i className="fa fa-ellipsis-h fa-spin"> </i>}{" "}
                    {loadin && (
                      <span>
                        <i className="fa fa-angle-right ml-1"> </i>{" "}
                      </span>
                    )}{" "}
                    {!loadin && (
                      <span>
                        Create Investment
                        <i className="fa fa-angle-right ml-1"> </i>{" "}
                      </span>
                    )}
                  </CButton>
                </div>
              </CCol>
            </CRow>
          )}
        </CForm>
        <ToastContainer autoClose={5000} />{" "}
      </Overlay>
    </>
  );
};

export default CreateInvestment;
