import { axiosWithAuth } from "../../_utils/api";

const getAllBanks = async (source) => {
    return axiosWithAuth
      .get(
        `/banks/nip?source=${source}`
      )
      .catch(function (error) {
        if (error.response) {
          // logout();
        }
      });
  };


  export default {
      getAllBanks
  }