import Cookies from "js-cookie";

export default function logout() {
  const url = `${process.env.REACT_APP_API_URL}/logout`;

  const request = new Request(url, {
    method: "POST",
    body: JSON.stringify(),
    headers: new Headers({
      "Content-Type": "application/json",
    }),
  });
  fetch(request, { mode: "no-cors" })
    .then(() => {
      Cookies.remove("token");
      Cookies.remove("refreshToken");
      Cookies.remove("isAuthenticated");
      Cookies.remove("persist:root");
      window.location = "/login";
      window.location.reload(true);
    }).catch((err) => {
      Cookies.remove("token");
      Cookies.remove("refreshToken");
      Cookies.remove("isAuthenticated");
      Cookies.remove("persist:root");
      window.location = "/login";
      window.location.reload(true);
    });
}
