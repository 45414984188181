import Cookies from "js-cookie";
import { toast } from "react-toastify";
import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAIL,
  VERIFY_USER_REQUEST,
  VERIFY_USER_SUCCESS,
  VERIFY_USER_FAIL,
  USER_UPDATEPASSWORD_REQUEST,
  USER_UPDATEPASSWORD_FAIL,
  USER_UPDATEPASSWORD_SUCCESS,
  USER_RESETPASSWORD_REQUEST,
  USER_RESETPASSWORD_SUCCESS,
  USER_RESETPASSWORD_FAIL,
  USER_RESETPASSWORDTOKEN_REQUEST,
  USER_RESETPASSWORDTOKEN_SUCCESS,
  USER_RESETPASSWORDTOKEN_FAIL,
  USER_LOGOUT,
} from "../_constants/userConstants";
import {
  axiosWithAuth,
  axios,
  contentSuccess,
  contentError,
} from "../../_utils/api";

const erro422 = "Login credentials are not valid.";

const login = (email, password, history) => async (dispatch) => {
  dispatch({ type: USER_LOGIN_REQUEST, payload: { email, password } });

  console.log(email, password, history);

  try {
    console.log("attempting to log in...")
    const { data } = await axios.post("/admin/login", { email, password });
    console.log("logged in, data: ", data)
    dispatch({ type: USER_LOGIN_SUCCESS, payload: data });
    Cookies.set("token", data.data.token);
    Cookies.set("isAuthenticated", true);
    Cookies.set("refreshToken", data.data.refresh_token);
    toast(contentSuccess);
    history.replace("/");
  } catch (error) {
    console.error("Login Error:", error);
    dispatch({ type: USER_LOGIN_FAIL, payload: error.message });
    if (error.message === "Request failed with status code 422") {
      toast(contentError(erro422));
    } else {
      toast(contentError(error.message));
    }
  }
};

const logout = (history) => (dispatch) => {
  Cookies.remove("token");
  Cookies.remove("isAuthenticated");
  Cookies.remove("refreshToken");
  history.push("/login");
  dispatch({ type: USER_LOGOUT });
  toast.success("You have been logged out successfully.");
  try {
    axios.post("/logout");
  } catch (error) {
    console.error("Logout Error:", error);
    // handle error if necessary
  }
};

const passwordEmailToken = (email) => async (dispatch) => {
  try {
    dispatch({
      type: USER_RESETPASSWORDTOKEN_REQUEST,
      payload: { email },
    });
    const { data } = await axiosWithAuth.post("/password-reset-token", {
      email,
    });
    dispatch({ type: USER_RESETPASSWORDTOKEN_SUCCESS, payload: data });
    toast(contentSuccess);
  } catch (error) {
    console.error("Password Email Token Error:", error);
    dispatch({ type: USER_RESETPASSWORDTOKEN_FAIL, payload: error.message });
    toast(contentError(error.message));
  }
};

const resetPassword = (email, password, token) => async (dispatch) => {
  try {
    dispatch({
      type: USER_RESETPASSWORD_REQUEST,
      payload: { email, password, token },
    });
    const { data } = await axiosWithAuth.post("/password/reset", {
      email,
      password,
      token,
    });
    dispatch({ type: USER_RESETPASSWORD_SUCCESS, payload: data });
    toast(contentSuccess);
    window.location = "/login";
  } catch (error) {
    console.error("Reset Password Error:", error);
    dispatch({ type: USER_RESETPASSWORD_FAIL, payload: error.message });
    toast(contentError(error.message));
  }
};

const verifyFailedUser = (status, comment, id) => async (dispatch) => {
  const user = id;

  dispatch({
    type: VERIFY_USER_REQUEST,
    payload: { status, comment },
  });
  try {
    const { data } = await axiosWithAuth.patch(
      `/admin/users/users/${user}/verify`,
      {
        status,
        comment,
      }
    );
    dispatch({ type: VERIFY_USER_SUCCESS, payload: data });
    toast(contentSuccess);
  } catch (error) {
    console.error("Verify Failed User Error:", error);
    const errorMessage = error.response?.data?.error?.message || error.message;
    dispatch({ type: VERIFY_USER_FAIL, payload: errorMessage });
    toast(contentError(errorMessage));
  }
};

const verifyUser = (id, status) => async (dispatch) => {
  const user = id;

  dispatch({
    type: VERIFY_USER_REQUEST,
    payload: { status },
  });
  try {
    const { data } = await axiosWithAuth.patch(
      `/admin/users/users/${user}/verify`,
      {
        status,
      }
    );
    dispatch({ type: VERIFY_USER_SUCCESS, payload: data });
    toast(contentSuccess);
  } catch (error) {
    console.error("Verify User Error:", error);
    const errorMessage = error.response?.data?.error?.message || error.message;
    dispatch({ type: VERIFY_USER_FAIL, payload: errorMessage });
    toast(contentError(errorMessage));
  }
};

const updateUserPassword =
  (current_password, password, history) => async (dispatch) => {
    dispatch({
      type: USER_UPDATEPASSWORD_REQUEST,
      payload: { current_password, password, history },
    });
    try {
      const { data } = await axiosWithAuth.patch("/security/password", {
        current_password,
        password,
        history,
      });
      dispatch({ type: USER_UPDATEPASSWORD_SUCCESS, payload: data });
      toast(contentSuccess);
      window.location = "/login";
    } catch (error) {
      console.error("Update User Password Error:", error);
      dispatch({ type: USER_UPDATEPASSWORD_FAIL, payload: error.message });
      toast(contentError(error.message));
    }
  };

export {
  logout,
  login,
  verifyUser,
  verifyFailedUser,
  updateUserPassword,
  passwordEmailToken,
  resetPassword,
};
