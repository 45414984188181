import { axiosWithAuth } from "../../_utils/api";

export const getRoles = async () => {
  return await axiosWithAuth({
    url: `/admin/roles`,
    method: "get",
  });
};

export const getPermissions = async (page) => {
  return await axiosWithAuth({
    url: `/admin/permissions?page%5Bnumber%5D=${page}`,
    method: "get",
  });
};

export const getPermissionsDropDown = async (page) => {
  return await axiosWithAuth({
    url: `/admin/permissions/create`,
    method: "get",
  });
};

export const getPermissionsDetails = async (id) => {
  return await axiosWithAuth({
    url: `/admin/permissions/${id}`,
    method: "get",
  });
};

export const getRolesDetails = async (id) => {
  return await axiosWithAuth({
    url: `/admin/roles/${id}`,
    method: "get",
  });
};

export const getUsers = async (page) => {
  return await axiosWithAuth.get(
    `/admin/internal-users?include=roles&0=1&page%5Bnumber%5D=${page}`
  );
};

export const getUser = async (id) => {
  return await axiosWithAuth({
    url: `admin/internal-users/${id}?include=roles`,
    method: "get",
  });
};

export const getUsersFilter = async (query) => {
  return await axiosWithAuth({
    url: `admin/internal-users?${query}`,
    method: "get",
  });
};

export const deleteUser = async (payload) => {
  const { id, deleteUserPassword } = payload;
  return await axiosWithAuth({
    url: `/admin/internal-users/${id}`,
    method: "delete",
    data: { password: deleteUserPassword },
  });
};

export const deleteUserRole = async (id, role) => {
  return await axiosWithAuth({
    url: `/admin/internal-users/${id}/roles`,
    method: "delete",
    data: { roles: [role] },
  });
};

export const addUserRole = async (id, role, code) => {
  if (code === undefined) {
    return await axiosWithAuth({
      url: `/admin/internal-users/${id}/roles`,
      method: "patch",
      data: { roles: [role] },
    });
  } else {
    return await axiosWithAuth({
      url: `/admin/internal-users/${id}/roles`,
      method: "patch",
      data: { roles: [role], referral_code: code },
    });
  }
};

export const getUserPermission = async (id) => {
  return await axiosWithAuth({
    url: `/admin/internal-users/${id}/permissions`,
    method: "get",
  });
};

export const addUserPermission = async (id, permission) => {
  return await axiosWithAuth({
    url: `/admin/internal-users/${id}/permissions`,
    method: "patch",
    data: { permissions: [permission] },
  });
};

export const deleteUserPermission = async (id, permission) => {
  return await axiosWithAuth({
    url: `/admin/internal-users/${id}/permissions`,
    method: "delete",
    data: { permissions: [permission] },
  });
};

export const addRolesPermission = async (id, permission) => {
  return await axiosWithAuth({
    url: `/admin/roles/${id}/permission`,
    method: "patch",
    data: { permissions: [permission] },
  });
};

export const deleteRolesPermission = async (id, permission) => {
  return await axiosWithAuth({
    url: `/admin/roles/${id}/permission`,
    method: "delete",
    data: { permissions: [permission] },
  });
};

export const restoreUser = async (payload) => {
  const { id, restorePassword } = payload;
  return await axiosWithAuth({
    url: `/admin/internal-users/${id}/restore`,
    method: "patch",
    data: { password: restorePassword },
  });
};

export const getTeams = async (page) => {
  if (page === undefined) {
    page = 1;
  }
  return await axiosWithAuth({
    url: `/admin/teams?page%5Bnumber%5D=${page}`,
    method: "get",
  });
};

export const getSingleTeams = async (id) => {
  return await axiosWithAuth({
    url: `/admin/teams/${id}`,
    method: "get",
  });
};

export const getTeamMembers = async (id, page) => {
  if (page === undefined) {
    page = 1;
  }
  return await axiosWithAuth({
    url: `/admin/teams/${id}/members?page%5Bnumber%5D=${page}`,
    method: "get",
  });
};

export const switchTeams = async (payload) => {
  const { id, email, password } = payload;
  return await axiosWithAuth({
    url: `/admin/teams/${id}/switch?include=roles`,
    data: { email, password },
    method: "post",
  });
};

export const getTeamLead = async () => {
  return await axiosWithAuth({
    url: `/admin/team-leads?include=accountManagerUser,generalMarketerUser`,
    method: "get",
  });
};

export const getTeamDetails = async (id) => {
  return await axiosWithAuth({
    url: `/admin/team-leads/${id}/team`,
    method: "get",
  });
};

export const getTeamLeadMembers = async (id, page) => {
  return await axiosWithAuth({
    url: `/admin/team-leads/${id}/manager/team-members?page%5Bnumber%5D=${page}`,
    method: "get",
  });
};

export const getTeamLeadAnalytics = async () => {
  return await axiosWithAuth({
    url: `/admin/manager-dashboard`,
    method: "get",
  });
};

export const adminDebitAccount = async (payload) => {
  return await axiosWithAuth.post(`/admin/credit-debit-account`, payload);
};

// export const adminDebitAccount = async (payload) => {

//   return await axiosWithAuth({
//     url: `/admin/credit-debit-account`,
//     data: payload,
//     method: "post",
//   });
// };
