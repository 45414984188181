/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useHistory } from "react-router-dom";
import { CBadge, CDataTable } from "@coreui/react";
import moment from "moment";

const getBadge = (verification_status) => {
  switch (verification_status) {
    case "true":
      return "success";
    case null:
      return "warning";
    default:
      return "warning";
  }
};

const PartnersDataTable = ({
  items,
  loading,
  itemsPerPage,
  shouldIncludeReferral,
  page,
}) => {


  let fields = [
    { key: "full_name", label: "Business Name", _style: { width: "20%" } },
    { key: "licenced", label: "Licenced", _style: { width: "20%" } },
    { key: "industry", label: "Industry", _style: { width: "10%" } },
    { key: "email", _style: { width: "22%" } },
    { key: "telephone", _style: { width: "13%" } },
    
    { key: "turn_over",label: "Expected Turn Over", _style: { width: "20%" } },
    { key: "date_registered", label: "Date Registered (DD/MM/YYYY)" },
  ];

  const history = useHistory();
  return (
    <CDataTable
      items={items}
      fields={fields}
      columnFilter
      // tableFilter
      loading={loading}
      footer
      responsive={true}
      hover
      sorter
      itemsPerPage={itemsPerPage}
      activePage
      clickableRows
      // This will be modified to reflect the specific user clicked
      onRowClick={(item) =>
        history.push({
          pathname: `/partners/${item.id}`,
          state: page,
        })
      }
      scopedSlots={{
        telephone: (item) => (
          <td>
            {item?.owner?.telephone ? item?.owner?.telephone : "Not Available"}
          </td>
        ),
        date_registered: (item) => (
          <td>
            <>{moment(item.created_at).format("DD-MMM-YYYY")}</>
          </td>
        ),
        industry: (item) => (
          <td className="text-capitalize">
            <>{item?.business_industry}</>
          </td>
        ),
        full_name: (item) => (
          <td className="text-capitalize">
            <>
              {item?.business_name.replace(/(^\w)|([-\s]\w)/g, (match) =>
                match.toUpperCase()
              )}
            </>
          </td>
        ),
        licenced: (item) => (
          <td className="text-capitalize">
            <CBadge
              color={item?.has_license ? getBadge("true") : getBadge("false")}
            >
              {item?.has_license ? "true" : "false"}
            </CBadge>
          </td>
        ),
        turn_over: (item) => (
            <td>
              <>{item?.expected_turnover}</>
            </td>
          ),
        email: (item) => (
          <td>
            <>{item?.owner?.email}</>
          </td>
        ),
      }}
    />
  );
};

export default PartnersDataTable;
