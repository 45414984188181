import React from "react";
import CIcon from "@coreui/icons-react";

const _nav = [
  {
    _tag: "CSidebarNavItem",
    name: "Analytics",
    to: "/analytics",
    icon: <CIcon name="cil-home" customClasses="c-sidebar-nav-icon" />,
  },
  {
    _tag: "CSidebarNavItem",
    name: "Users",
    to: "/users/all/1",
    icon: "cil-user-plus",
  },
  {
    _tag: "CSidebarNavDropdown",
    name: "Accounts",
    icon: "cil-graph",
    _children: [
  {
    _tag: "CSidebarNavItem",
    name: "Accounts",
    to: "/accounts",
    icon: "cil-graph",
  },
  {
    _tag: "CSidebarNavItem",
    name: "Direct Debit",
    to: "/direct-debit",
    icon: "cil-notes"
    
  },
]
},
  {
    _tag: "CSidebarNavItem",
    name: "Message Bird",
    icon: "cilEnvelopeLetter",
    to: "/messages", 
  },
  {
    _tag: "CSidebarNavDropdown",
    name: "Transactions",
    icon: "cil-list-rich",

    _children: [
      {
        _tag: "CSidebarNavItem",
        name: "View Transactions",
        to: "/transactions",
        icon: "cil-notes"
      },
     
   
     
    ],
  },
  {
    _tag: "CSidebarNavDropdown",
    name: "Investments",
    icon: "cil-list-numbered",
    _children: [
      {
        _tag: "CSidebarNavItem",
        name: "View Investments",
        to: "/investments",
      },
    
    ],
  },

  {
    _tag: "CSidebarNavDivider",
    className: "mt-md-auto",
  },

];

export default _nav;
