/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CSpinner,
} from "@coreui/react";
import moment from "moment";
import { Printer, ChevronLeft } from "react-feather";

import styles from "../../scss/general.module.scss";

import DebitMandateService from "src/redux/_services/DebitMandateService";
import { thousandOperator } from "../../components";
import { toast } from "react-toastify";
import { contentError, contentSuccess } from "src/_utils/api";
const TransactionDetail = (props) => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);

  const [mandateTransaction, setMandateTransaction] = useState("");

  const history = useHistory();

  const printReceipt = () => window.print();

  useEffect(() => {
    sessionStorage.setItem("madateTransactionPage", props.location.state);
  }, []);

  const deleteMandate = (e) => {
    e.preventDefault();
    setLoading(true);
    DebitMandateService.deleteDebitMandate(id)
      .then((res) => {
        setLoading(false);
        setMandateTransaction(res.data.data.mandate);
      })
      .catch((err) => {
        toast(contentError("an error occured"));
      });
  };

  useEffect(() => {
    setLoading(true);
    DebitMandateService.getSingleDebitMandate(id)
      .then((res) => {
        setLoading(false);
        setMandateTransaction(res.data.data.mandate);
      })
      .catch((err) => console.log(err));
  }, []);


  if (loading) {
    return (
      <CSpinner
        color="primary"
        style={{
          width: "4rem",
          height: "4rem",
          marginTop: "300px",
          marginLeft: "600px",
        }}
      />
    );
  }

  return (
    <>
      <div className="d-flex justify-content-end">
        <CButton
          onClick={() => history.goBack()}
          className="px-4 mb-4"
          color="success"
        >
          <ChevronLeft />
          Back
        </CButton>
      </div>
      <CCard className={styles.card_height}>
        <CCardHeader className="d-flex align-items-center justify-content-between">
          <h4>Direct Debit Transaction Details</h4>
          <div className="d-flex">
            <CButton variant="outline" color="success" onClick={printReceipt}>
              Print Receipt
            </CButton>
            {loading ? (
              <CButton className={"btn btn-danger ml-4"}>loading...</CButton>
            ) : (
              <CButton
                className={
                  mandateTransaction &&
                  mandateTransaction.status === "terminated"
                    ? "btn btn-secondary ml-4 "
                    : "btn btn-danger ml-4"
                }
                onClick={
                  mandateTransaction &&
                  mandateTransaction.status === "terminated"
                    ? null
                    : deleteMandate
                }
              >
                Stop Mandate
              </CButton>
            )}
          </div>
        </CCardHeader>
        <CCardBody>
          <CRow className="mb-2">
            <CCol size="6">
              <h6 className="mb-2">Sender's Details</h6>
              <p className="mb-1 text-capitalize">
                {" "}
                {mandateTransaction &&
                  mandateTransaction.source &&
                  mandateTransaction.source.account_name &&
                  mandateTransaction.source.account_name.replace(
                    /(^\w)|([-\s]\w)/g,
                    (match) => match.toUpperCase()
                  )}
              </p>
              <p className="mb-1">
                {mandateTransaction &&
                  mandateTransaction.source &&
                  mandateTransaction.source.account_number}
              </p>
              <p className="mb-1 text-capitalize">
                CloudBank Microfinance Bank
              </p>
            </CCol>
            <CCol size="6" className="text-right">
              <h6 className="mb-2">Receiver's Details</h6>
              <p className="mb-1 text-capitalize">
                {mandateTransaction &&
                  mandateTransaction.beneficiary_account_name &&
                  mandateTransaction.beneficiary_account_name.replace(
                    /(^\w)|([-\s]\w)/g,
                    (match) => match.toUpperCase()
                  )}
              </p>
              <p className="mb-1">
                {mandateTransaction &&
                  mandateTransaction.beneficiary_account_number}
              </p>
              <p className="mb-1 text-capitalize">
                {mandateTransaction && mandateTransaction.beneficiary_bank_name}
              </p>
            </CCol>
          </CRow>

          <CRow>
            <CCol size="12" className="my-5">
              <p className="font-weight-bold mb-2 font-2xl text-uppercase">
                Transaction Status:{" "}
                <span
                  className={
                    mandateTransaction && mandateTransaction.status === "success"
                      ? "text-success"
                      : "text-danger"
                  }
                >
                  {" "}
                  {mandateTransaction.status}
                </span>
              </p>
              <p className="font-weight-bold">
                Transaction Reference: {mandateTransaction.reference}
              </p>
            </CCol>
          </CRow>

          <div className="table-responsive-xl">
            <table className="table border-bottom">
              <thead>
                <tr>
                  <th scope="col">Amount in words</th>
                  <th scope="col">Start Date</th>
                  <th scope="col">End Date</th>
                  <th scope="col">Schedule</th>
                  <th scope="col" className="text-right">
                    Amount(₦)
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">{mandateTransaction.amount_in_words}</th>
                  <td>
                    {" "}
                    {moment(mandateTransaction.start_date).format(
                      "DD-MMM-YYYY"
                    )}
                  </td>
                  <td>
                    {" "}
                    {moment(mandateTransaction.end_date).format("DD-MMM-YYYY")}
                  </td>
                  <td>
                    <span
                      className={`text-capitalize text-white badge bg-danger`}
                    >
                      {mandateTransaction.schedule}
                    </span>
                  </td>
                  <td className="text-right">
                    {mandateTransaction &&
                      mandateTransaction.amount &&
                      mandateTransaction.amount
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </td>
                </tr>
                <tr>
                  <td className="w-50">{mandateTransaction.narration}</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
              </tbody>
            </table>
          </div>
        </CCardBody>
      </CCard>
    </>
  );
};

export default TransactionDetail;
