/* eslint-disable import/no-anonymous-default-export */
import { axiosWithAuth} from "../../_utils/api";

// const getAllUsers = (params) => {
//   return axiosWithAuth
//     .get("admin/users?include=verification", { params })
//     .catch(function (error) {
//       return error
//     });
// };
const getAllUsers = (page) => {
  return axiosWithAuth 
    .get(`admin/users?include=verification&&page[number]=${page}`)
    .catch(function (error) {
      return error
    });
};

// const getAllUsersFilter = async (query) => {
  
//   return await axiosWithAuth
//     .get(`/admin/users?` + query + `&include=verification`)
//     .catch(function (error) {});
// };

const getAllUsersFilter = async (query, page) => {
  
  return await axiosWithAuth
    .get(`/admin/users?` + query + `&include=verification&&page[number]=${page}`)
    .catch(function (error) {});
};

const getAllAccountsTransactions = (params, id) => {
 
  const user = id;

  return axiosWithAuth
    .get(`/admin/users/${user}/transactions`, { params })
    .catch(function (error) {});
};

const getAllUserTransactions = (params, id) => {
  const user = id;

  return axiosWithAuth
    .get(
      `/admin/users/${user}/transactions?include=source,beneficiary,creditBank,debitBank`,
      {
        params,
      }
    )
    .catch(function (error) {});
};

const getAllUserTransactionsFilter = async (query, id) => {
  const user = id;

  return await axiosWithAuth
    .get(
      `/admin/users/${user}/transactions?` +
        query +
        `&include=source,beneficiary,creditBank,debitBank`
    )
    .catch(function (error) {});
};

const getAllUserReferrals = (params, id) => {
  const user = id;

  return axiosWithAuth
    .get(`/admin/users/${user}/referrals?include=verification`, {
      params,
    })
    .catch(function (error) {});
};

const getAllUsersAccounts = (params, id) => {

  return axiosWithAuth
    .get(`/admin/users/${id}?include=accounts`, { params })
    .catch(function (error) {});
};
const getAllUsersAccountsFilter = async (query, id) => {
  return await axiosWithAuth
    .get(
      `/admin/users/${id}?include=accounts?` + query + `&include=verification`
    )
    .catch(function (error) {});
};

export default {
  getAllUsers,
  getAllUsersFilter,
  getAllAccountsTransactions,
  getAllUserTransactions,
  getAllUserReferrals,
  getAllUserTransactionsFilter,
  getAllUsersAccounts,
  getAllUsersAccountsFilter,
};
