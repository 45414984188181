/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";

import { CBadge, CDataTable } from "@coreui/react";

const getStatusBadge = (status) => {
  switch (status) {
    case "true":
      return "success";
    case "false":
      return "danger";
    default:
      return "danger";
  }
};

const getStateBadge = (state) => {
  if (state) {
    return "danger";
  } else {
    return "success";
  }
};

const EmailMessagesDataTable = ({ items, loading, itemsPerPage, page }) => {
  const history = useHistory();

  const fields = [
    { key: "email", label: "Email", _style: { width: "10%" } },
    { key: "phone_number", label: "Phone Number", _style: { width: "10%" } },
    { key: "verified", label: "Verified", _style: { width: "10%" } },
    { key: "token", label: "Token", _style: { width: "10%" } },
    { key: "type", label: "Type", _style: { width: "10%" } },
    { key: "date", label: "Date", _style: { width: "10%" } },
  ];

  return (
    <CDataTable
      items={items}
      fields={fields}
      columnFilter
      //   tableFilter
      footer
      responsive={true}
      // itemsPerPageSelect={{
      //   label: "Items per page:",
      //   values: [5, 10, 20, 30],
      // }}
      hover
      loading={loading}
      sorter
      itemsPerPage={itemsPerPage}
      activePage
      //   clickableRows
      // This will be modified to reflect the specific account details clicked
      //   onRowClick={
      //       (item) =>
      //     history.push({
      //       pathname: `/accounts/account-details/${item.id}`,
      //       state: page,
      //     })
      //   }
      scopedSlots={{
        email: (item) => (
          <td className="text-capitalize">{item && item.email}</td>
        ),
        phone_number: (item) => (
          <td className="text-capitalize">
            {item && item.phone_number
              ? item && item.phone_number
              : "Not Available"}
          </td>
        ),
        verified: (item) => (
          <td>
            <CBadge
              color={getStatusBadge(item && item.verified)}
              size="md"
              className="text-capitalize"
            >
              {item && item.verified ? "True" : "False"}
            </CBadge>
          </td>
        ),
        token: (item) => (
          <td>
            <>{item && item.token}</>
          </td>
        ),
        type: (item) => (
          <td>
            <>{item && item.type}</>
          </td>
        ),
        date: (item) => (
          <td>
            <>{moment(item && item.created_at).format("DD-MMM-YYYY LTS")}</>
          </td>
        ),
      }}
    />
  );
};

export default EmailMessagesDataTable;
