import {
  CButton,
  CForm,
  CInput,
  CLabel,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
} from "@coreui/react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { unblockFund } from "src/redux/_actions/accountActions";

export const UnblockFund = (props) => {
  const { onClose, show, fundId, accountId } = props;
  const [password, setPassword] = useState("");
  const unblockFundState = useSelector((state) => state?.unBlockFund);
  const dispatch = useDispatch();

  const updatePassword = (e) => setPassword(e.target.value);

  const resetPassword = () => setPassword("");

  const handleSubmit = () => {
    dispatch(unblockFund(fundId, accountId, password));
  };

  useEffect(() => {
    if (unblockFundState?.success) {
      // reset on successful call
      resetPassword();
      onClose();
    }
  }, [onClose, unblockFundState?.success]);

  return (
    <CModal show={show} onClose={onClose} centered>
      <CModalHeader closeButton>Unblock Fund</CModalHeader>
      <CForm>
        <CModalBody>
          <div className="mb-3">
            <CLabel htmlFor="unblockFundPassword">Password</CLabel>
            <CInput
              id="unblockFundPassword"
              type="password"
              onChange={updatePassword}
              value={password}
              placeholder="Enter Password"
            ></CInput>
          </div>
        </CModalBody>
        <CModalFooter>
          {password ? (
            <CButton
              onClick={handleSubmit}
              color="warning"
              variant="outline"
              block
            >
              {unblockFundState?.loading
                ? "Loading..."
                : "Yes I am sure, I want to unblock this fund"}
            </CButton>
          ) : null}
        </CModalFooter>
      </CForm>
    </CModal>
  );
};
