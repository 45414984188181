/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useHistory, useParams, Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";

import {
  CBadge,
  CNav,
  CNavItem,
  CNavLink,
  CTabPane,
  CTabContent,
  CButton,
  CSpinner,
} from "@coreui/react";
import {ChevronLeft} from "react-feather";
import styles from "../../scss/details.module.scss";
import "../../css/details.css";
import { getPermissionsDetails } from "src/redux/_services/adminService";


const PermissionDetails = (props) => {
  const { id } = useParams();
  const [activeKey, setActiveKey] = useState(1);
  const [loading, setLoading] = useState("");
  const [permission, setPermission] = useState([]);

  const history = useHistory();

  useEffect(() => {
    setLoading(true);
    getPermissionsDetails(id)
      .then((res) => {
        setPermission(res.data.data.permission);
      })
      .catch((err) => err)
      .finally(() => {
        setLoading(false);
      });
  }, []);

  if (loading) {
    return (
      <CSpinner
        color="primary"
        style={{
          width: "4rem",
          height: "4rem",
          marginTop: "300px",
          marginLeft: "600px",
        }}
      />
    );
  }

  return (
    <>
      <div className="d-flex justify-content-end">
        <CButton
          onClick={() => history.goBack()}
          className="px-4 mb-4"
          color="success"
        >
          <ChevronLeft />
          Back
        </CButton>
      </div>
      <CNav variant="tabs">
        <CNavItem>
          <CNavLink
            href="#"
            active={activeKey === 1}
            onClick={() => setActiveKey(1)}
          >
            Permission Details
          </CNavLink>
        </CNavItem>
      </CNav>

      <CTabContent className="bg-white brand-tab-content py-3 px-4">
        <CTabPane active={activeKey === 1}>
          <p className="mb-5">Basic information about a permission</p>
          <div className={styles.field_info}>
            <div className={styles.field_item}>
              <div className={styles.field_wrapper}>
                <span className={styles.field_label}>Permission Name</span>

                <p className={styles.field_value_link}>
                  {permission?.display_name}
                </p>
              </div>
            </div>
          </div>
          <div className={styles.field_info}>
            <div className={styles.field_item}>
              <div className={styles.field_wrapper}>
                <span className={styles.field_label}>Description</span>

                <p className={styles.field_value_link}>
                  {permission?.description
                    ? permission.description
                    : "Not Available"}
                </p>
              </div>
            </div>
          </div>
        </CTabPane>
      </CTabContent>

      <ToastContainer autoClose={3000} />
    </>
  );
};

export default PermissionDetails;
