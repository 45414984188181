import React from "react";
import CIcon from "@coreui/icons-react";

const _nav = [
  {
    _tag: "CSidebarNavItem",
    name: "Analytics",
    to: "/analytics",
    icon: <CIcon name="cil-home" customClasses="c-sidebar-nav-icon" />,
  },
  {
    _tag: "CSidebarNavItem",
    name: "Users",
    to: "/users",
    icon: "cil-user-plus",
  },
  {
    _tag: "CSidebarNavItem",
    name: "Accounts",
    to: "/accounts",
    icon: "cil-graph",
  },
  {
    _tag: "CSidebarNavDropdown",
    name: "Transactions",
    icon: "cil-list-rich",

    _children: [
      {
        _tag: "CSidebarNavItem",
        name: "View Transactions",
        to: "/transactions",
      },
      {
        _tag: "CSidebarNavItem",
        name: "Create Transactions",
        to: "/transactions/create-transaction",
        icon: "cil-pencil",
      },
    ],
  },
  {
    _tag: "CSidebarNavDropdown",
    name: "Investments",
    icon: "cil-list-numbered",
    _children: [
      {
        _tag: "CSidebarNavItem",
        name: "View Investments",
        to: "/investments",
      },
      {
        _tag: "CSidebarNavItem",
        name: "Create Invesments",
        to: "/investments/create-investment",
        icon: "cil-pencil",
      },
    ],
  },

  // {
  //   _tag: "CSidebarNavDropdown",
  //   name: "Treasury",
  //   icon: "cil-money",
  //   _children: [
  //     {
  //       _tag: "CSidebarNavDropdown",
  //       name: "Transactions",
  //       icon: "cil-calculator",
  //       _children: [],
  //     },
  //     {
  //       _tag: "CSidebarNavDropdown",
  //       name: "Investments",
  //       icon: "cil-graph",
  //       _children: [],
  //     },
  //   ],
  // },
  {
    _tag: "CSidebarNavDropdown",
    name: "Security",
    icon: "cil-lock-locked",
    _children: [
      {
        _tag: "CSidebarNavItem",
        name: "Change Password",
        to: "/security/change-password",
      },
      {
        _tag: "CSidebarNavItem",
        name: "Change Transaction Pin",
        to: "/security/change-transaction-pin",
      },
      {
        _tag: "CSidebarNavItem",
        name: "Reset Transaction Pin",
        to: "/security/reset-transaction-pin",
      },
    ],
  },
  // {
  //   _tag: "CSidebarNavDropdown",
  //   name: "Admin",
  //   icon: "cil-user",
  //   _children: [
  //     {
  //       _tag: "CSidebarNavItem",
  //       name: "Accounts",
  //       to: "/admin/create-account",
  //     }
  //   ],
  // },
 
  {
    _tag: "CSidebarNavDivider",
    className: "mt-md-auto",
  },
  // {
  //   _tag: "CSidebarNavItem",
  //   name: "Logout",
  //   className: "text-danger",
  //   to: "",
  //   icon: {
  //     name: "cil-account-logout",
  //     className: "text-danger mb-5 mb-lg-0",
  //   },
  //   label: true,
  // },
];

export default _nav;
