/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useHistory, useParams, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { CSVLink } from "react-csv";
import { contentSuccess, contentError } from "../../_utils/api";
import { toast, ToastContainer } from "react-toastify";
import DatePicker from "react-datepicker";
import {
  CNav,
  CNavItem,
  CNavLink,
  CTabPane,
  CTabContent,
  CButton,
  CForm,
  CLabel,
  CInput,
  CInputGroup,
  CContainer,
  CListGroup,
  CListGroupItem,
  CRow,
  CCol,
  CSpinner,
  CSelect,
} from "@coreui/react";
import commonStyles from "../../scss/common-form.module.scss";
import CIcon from "@coreui/icons-react";
import { Printer, ChevronLeft, Eye, EyeOff } from "react-feather";
import styles from "../../scss/details.module.scss";
import "../../css/details.css";
import {
  sendMessageDate,
  prepareAccountMail,
} from "src/redux/_services/MessageService";
import AccountService from "../../redux/_services/AccountService";

const re = /^[0-9\b]+$/;

const SendMessages = (props) => {
  const { id } = useParams();
  const [activeKey, setActiveKey] = useState(1);
  const [loading, setLoading] = useState("");
  const [sendLoading, setSendLoading] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [sendSuccess, setSendSuccess] = useState();
  const [endDate, setEndDate] = useState(new Date());
  const [accountNumber, setAccountNumber] = useState("");
  const [accountName, setAccountName] = useState("");
  const [accountId, setAccountId] = useState("");
  const [accountStatus, setAccountStatus] = useState("")

  const sendEmailByDate = (e) => {
    e.preventDefault();

    if (startDate === "" || endDate === "") {
      toast(contentError("Date Cannot be Empty"));
      return;
    }

    const startDate = moment(startDate).format("YYYY-MM-DD");
    const endDate = moment(endDate).format("YYYY-MM-DD");

    const payload = {
      "start_date":startDate,
      "end_date":endDate,
      "account_status": accountStatus
    };
    setSendLoading(true);

    sendMessageDate(payload)
      .then((res) => {
        setSendLoading(false);
        toast(contentSuccess(res.data.data.message));
        setSendSuccess(res);
      })
      .catch((err) => {
        if (err.response.data.error.message) {
          toast(contentError(err.response.data.error.message));
        } else {
          toast(contentError("An Error Occured"));
        }
        setLoading(false);
      });
  };

  const sendEmailById = (e) => {
    e.preventDefault();

    if (accountId === "") {
      toast(contentError("Account Number cannot be empty"));
      return;
    }

    const payload = {
      type: "account",
      account_id: accountId,
    };
    setSendLoading(true);

    sendMessageDate(payload)
      .then((res) => {
        setSendLoading(false);
        toast(contentSuccess(res.data.data.message));
        setSendSuccess(res);
        setAccountName("");
        setAccountNumber("");
        setAccountId("");
      })
      .catch((err) => {
        if (err.response.data.error.message) {
          toast(contentError(err.response.data.error.message));
        } else {
          toast(contentError("An Error Occured"));
        }
        setSendLoading(false);
      });
  };

  useEffect(() => {
    var accountdigit = /^\d{10}$/;

    const payload = `filter[account_number]=${accountNumber}`;

    if (accountNumber.match(accountdigit)) {
      setAccountName("loading");
      AccountService.getAllAccountsFilter(payload)
        .then((res) => {
          if (res.data.data.accounts.data.length === 0) {
            setAccountName("Account doesn't exist");
          } else {
            setAccountName(res.data.data.accounts.data[0].account_name);
            setAccountId(res.data.data.accounts.data[0].id);
          }
        })
        .catch((err) => {
          setAccountName("Something went wrong");
        });
    }
  }, [accountNumber]);

  if (loading) {
    return (
      <CSpinner
        color="primary"
        style={{
          width: "4rem",
          height: "4rem",
          marginTop: "300px",
          marginLeft: "600px",
        }}
      />
    );
  }

  return (
    <>
      <CNav variant="tabs">
        <CNavItem>
          <CNavLink
            href="#"
            active={activeKey === 1}
            onClick={() => setActiveKey(1)}
          >
            Email to Single
          </CNavLink>
        </CNavItem>
        <CNavItem>
          <CNavLink
            href="#"
            active={activeKey === 2}
            onClick={() => setActiveKey(2)}
          >
            Email By Date
          </CNavLink>
        </CNavItem>
      </CNav>

      <CTabContent className="bg-white brand-tab-content py-3 px-4">
        <CTabPane active={activeKey === 1}>
          <CRow className="justify-content-center mt-4">
            <CCol sm="10" md="4" className="mx-auto px-0 px-md-3 mt-5">
              <CListGroup className="mb-3">
                <CListGroupItem href="#" color="warning">
                  Send Marketing Emails to Single Account
                </CListGroupItem>
              </CListGroup>
              <CForm autoComplete="off">
                <div className>
                  <div className="mb-3">
                    <CLabel className="font-weight-bold text-dark w-100">
                      Enter Account Number
                    </CLabel>
                    <div className="mb-3">
                      <CInput
                        value={accountNumber}
                        onChange={(e) => setAccountNumber(e.target.value)}
                        type="number"
                        placeholder="Account Number"
                        autoComplete="false"
                        onWheel={() => document.activeElement.blur()}
                      />{" "}
                    </div>{" "}
                  </div>
                  <div className="mb-3">
                    <CInput
                      type="text"
                      readOnly
                      value={accountName}
                      placeholder="Account Name"
                    />{" "}
                  </div>{" "}
                  <div className="mb-3">
                    <CSelect
                      id="status"
                      name="status"
                        onChange={(e) => setAccountStatus(e.target.value)}
                    >
                      <option> Choose Status </option>{" "}
                      <option value="active"> Active </option>
                      <option value="inactive"> Inactive </option>{" "}
                      <option value="dormant"> Domant </option>{" "}
                    </CSelect>
                  </div>
                </div>
                {sendLoading ? (
                  <CButton className="mt-4 mb-4" color="success" block>
                    <CSpinner color="white" size="sm" /> Loading ...
                  </CButton>
                ) : (
                  <CButton
                    onClick={sendEmailById}
                    className="mt-4 mb-4"
                    color="success"
                    block
                  >
                    Send
                  </CButton>
                )}
              </CForm>
            </CCol>
          </CRow>
        </CTabPane>
        <CTabPane active={activeKey === 2}>
          <>
            <CRow className="justify-content-center mt-4">
              <CCol sm="10" md="4" className="mx-auto px-0 px-md-3 mt-5">
                <CListGroup className="mb-3">
                  <CListGroupItem href="#" color="warning">
                    Send Marketing Emails by Date
                  </CListGroupItem>
                </CListGroup>
                <CForm autoComplete="off">
                  <div className>
                    <div className="mb-3">
                      <CLabel className="font-weight-bold text-dark w-100">
                        Start Date
                      </CLabel>
                      <DatePicker
                        dateFormat="dd-MM-yyyy"
                        className="form-control"
                        selected={startDate}
                        isClearable
                        placeholderText="DD/MM/YY"
                        minDate={startDate}
                        maxDate={endDate}
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        selectsStart
                        startDate={startDate}
                        endDate={endDate}
                        onChange={(date) => {
                          setStartDate(date);
                        }}
                      />
                    </div>
                    <div className="mb-3">
                      <CLabel className="font-weight-bold text-dark w-100">
                        End Date
                      </CLabel>
                      <DatePicker
                        className="form-control"
                        dateFormat="dd-MM-yyyy"
                        isClearable
                        placeholderText="DD/MM/YY"
                        selected={endDate}
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        selectsEnd
                        startDate={startDate}
                        // endDate={accountsEndDate}
                        minDate={startDate}
                        onChange={(date) => setEndDate(date)}
                      />
                    </div>
                  </div>
                  {sendLoading ? (
                    <CButton className="mt-4 mb-4" color="success" block>
                      <CSpinner color="white" size="sm" /> Loading ...
                    </CButton>
                  ) : (
                    <CButton
                      onClick={sendEmailByDate}
                      className="mt-4 mb-4"
                      color="success"
                      block
                    >
                      Send
                    </CButton>
                  )}
                </CForm>
              </CCol>
            </CRow>
          </>
        </CTabPane>
      </CTabContent>

      <ToastContainer autoClose={3000} />
    </>
  );
};

export default SendMessages;
