import React, { lazy, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import // CBadge,
// CButton,
// CButtonGroup,
// CCard,
// CCardBody,
// CCardHeader,
// CCol,
// CProgress,
// CRow,
// CCallout,
// CDataTable,
// CPagination,
// CDropdown,
// CDropdownDivider,
// CDropdownToggle,
// CDropdownMenu,
// CForm,
// CInput,
// CLabel,
// CSelect,
"@coreui/react";

// import usersData from "../users/UsersData";
// import CIcon from "@coreui/icons-react";

// import MainChartExample from "../charts/MainChartExample.js";

const WidgetsDropdown = lazy(() => import("../widgets/WidgetsDropdown.js"));

const Dashboard = () => {
  // const fields = [
  //   { key: "full_name", _style: { width: "20%" } },
  //   { key: "email", _style: { width: "22%" } },
  //   { key: "phone_number", _style: { width: "13%" } },
  //   { key: "gender", _style: { width: "10%" } },
  //   { key: "verification_status", _style: { width: "20%" } },
  //   { key: "date_registered", label: "Date Registered (DD/MM/YYYY)" },
  // ];

  // const getBadge = (verification_status) => {
  //   switch (verification_status) {
  //     case "Approved":
  //       return "success";
  //     case "Pending":
  //       return "warning";
  //     case "Failed":
  //       return "danger";
  //     default:
  //       return "primary";
  //   }
  // };
  // const userSignin = useSelector((state) => state.userSignin);
  // const { userInfo } = userSignin;

  // const userRole =
  //   userInfo &&
  //   userInfo.data &&
  //   userInfo.data.user &&
  //   userInfo.data.user.roles &&
  //   userInfo.data.user.roles[0].name;
  // const history = useHistory();
  const queryPage = useLocation().search.match(/page=([0-9]+)/, "");
  const currentPage = Number(queryPage && queryPage[1] ? queryPage[1] : 1);
  const [page, setPage] = useState(currentPage);

  // const pageChange = (newPage) => {
  //   currentPage !== newPage && history.push(`/users?page=${newPage}`);
  // };

  useEffect(() => {
    currentPage !== page && setPage(currentPage);
  }, [currentPage, page]);

  // const [dates, setDate] = useState({
  //   startDate: "",
  //   endDate: "",
  // });

  // const setDateValue = (prop) => (event) => {
  //   event.preventDefault();
  //   setDate({
  //     ...dates,
  //     [prop]: event.target.value,
  //   });
  // };

  return (
    <>
      <WidgetsDropdown />
      {/* <CRow>
        <CCol lg="8">
          <CCard>
            <CCardBody>
              <CRow>
                <CCol sm="5">
                  <h4 id="traffic" className="card-title mb-0">
                    Daily Sales
                  </h4>
                  <div className="small text-muted">June 2021</div>
                </CCol>
                <CCol sm="7" className="d-none d-md-block">
                  <CButton color="primary" className="float-right">
                    <CIcon name="cil-cloud-download" />
                  </CButton>
                  <CButtonGroup className="float-right mr-3">
                    {["Day", "Month", "Year"].map((value) => (
                      <CButton
                        color="outline-secondary"
                        key={value}
                        className="mx-0"
                        active={value === "Month"}
                      >
                        {value}
                      </CButton>
                    ))}
                  </CButtonGroup>
                </CCol>
              </CRow>
              <MainChartExample
                style={{ height: "394px", marginTop: "40px" }}
              />
            </CCardBody>
          </CCard>
        </CCol>
        <CCol lg="4">
          <CCard>
            <CCardHeader>Profits</CCardHeader>
            <CCardBody>
              <CRow>
                <CCol sm="6">
                  <CCallout color="info">
                    <small className="text-muted">New Clients</small>
                    <br />
                    <strong className="h4">9,123</strong>
                  </CCallout>
                </CCol>
                <CCol sm="6">
                  <CCallout color="danger">
                    <small className="text-muted">Recurring Clients</small>
                    <br />
                    <strong className="h4">22,643</strong>
                  </CCallout>
                </CCol>
              </CRow>

              <hr className="mt-0" />

              <div className="progress-group mb-4">
                <div className="progress-group-prepend">
                  <span className="progress-group-text">Monday</span>
                </div>
                <div className="progress-group-bars">
                  <CProgress className="progress-xs" color="info" value="34" />
                  <CProgress
                    className="progress-xs"
                    color="danger"
                    value="78"
                  />
                </div>
              </div>
              <div className="progress-group mb-4">
                <div className="progress-group-prepend">
                  <span className="progress-group-text">Tuesday</span>
                </div>
                <div className="progress-group-bars">
                  <CProgress className="progress-xs" color="info" value="56" />
                  <CProgress
                    className="progress-xs"
                    color="danger"
                    value="94"
                  />
                </div>
              </div>
              <div className="progress-group mb-4">
                <div className="progress-group-prepend">
                  <span className="progress-group-text">Wednesday</span>
                </div>
                <div className="progress-group-bars">
                  <CProgress className="progress-xs" color="info" value="12" />
                  <CProgress
                    className="progress-xs"
                    color="danger"
                    value="67"
                  />
                </div>
              </div>
              <div className="progress-group mb-4">
                <div className="progress-group-prepend">
                  <span className="progress-group-text">Thursday</span>
                </div>
                <div className="progress-group-bars">
                  <CProgress className="progress-xs" color="info" value="43" />
                  <CProgress
                    className="progress-xs"
                    color="danger"
                    value="91"
                  />
                </div>
              </div>
              <div className="progress-group mb-4">
                <div className="progress-group-prepend">
                  <span className="progress-group-text">Friday</span>
                </div>
                <div className="progress-group-bars">
                  <CProgress className="progress-xs" color="info" value="22" />
                  <CProgress
                    className="progress-xs"
                    color="danger"
                    value="73"
                  />
                </div>
              </div>
              <div className="progress-group mb-4">
                <div className="progress-group-prepend">
                  <span className="progress-group-text">Saturday</span>
                </div>
                <div className="progress-group-bars">
                  <CProgress className="progress-xs" color="info" value="53" />
                  <CProgress
                    className="progress-xs"
                    color="danger"
                    value="82"
                  />
                </div>
              </div>
              <div className="progress-group mb-4">
                <div className="progress-group-prepend">
                  <span className="progress-group-text">Sunday</span>
                </div>
                <div className="progress-group-bars">
                  <CProgress className="progress-xs" color="info" value="9" />
                  <CProgress
                    className="progress-xs"
                    color="danger"
                    value="69"
                  />
                </div>
              </div>
              <div className="legend text-center">
                <small>
                  <sup className="px-1">
                    <CBadge shape="pill" color="info">
                      &nbsp;
                    </CBadge>
                  </sup>
                  New clients &nbsp;
                  <sup className="px-1">
                    <CBadge shape="pill" color="danger">
                      &nbsp;
                    </CBadge>
                  </sup>
                  Recurring clients
                </small>
              </div>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow> */}
    </>
  );
};

export default Dashboard;
