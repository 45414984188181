import React from "react";
import { useSelector } from "react-redux";
import { CCard, CCardBody, CCardHeader } from "@coreui/react";
import globalStyles from "../../scss/general.module.scss";
import styles from "../../scss/details.module.scss";
import profileStyles from "../../scss/profile.module.scss";

const Profile = () => {
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;
  const user = userInfo.data.user;
  return (
    <CCard className={globalStyles.card_height}>
      <CCardHeader>
        <h4>Profile</h4>
      </CCardHeader>
      <CCardBody>
        <div className={styles.tab_info}>
          <h5>User Account</h5>
          <p className="mb-4">
            Basic information about user like name, email, role and mobile
            number
          </p>

          <div className={profileStyles.avatar}>{userInfo.image}</div>

          <div className={styles.field_info}>
            <div className={styles.field_item}>
              <div className={styles.field_wrapper}>
                <span className={styles.field_label}>First Name</span>
                <span className={styles.field_value}>{user.first_name} </span>
              </div>
            </div>
            <div className={styles.field_item}>
              <div className={styles.field_wrapper}>
                <span className={styles.field_label}>Last Name</span>
                <span className={styles.field_value}>{user.last_name}</span>
              </div>
            </div>
            <div className={styles.field_item}>
              <div className={styles.field_wrapper}>
                <span className={styles.field_label}>Email</span>
                <span className={styles.field_value}>{user.email}</span>
              </div>
            </div>

            <div className={styles.field_item}>
              <div className={styles.field_wrapper}>
                <span className={styles.field_label}>Role</span>
                <span className={styles.field_value}>
                  {" "}
                  {user && user.roles && user.roles[0] && user.roles[0].name}
                </span>
              </div>
            </div>

            <div className={styles.field_item}>
              <div className={styles.field_wrapper}>
                <span className={styles.field_label}>Mobile Number</span>
                <span className={styles.field_value}>{user.telephone}</span>
              </div>
            </div>
            <div className={styles.field_item}>
              <div className={styles.field_wrapper}>
                <span className={styles.field_label}>Gender</span>
                <span className={styles.field_value}>
                  {user.gender === null ? "Not Available " : user.gender}
                </span>
              </div>
            </div>
          </div>
        </div>
      </CCardBody>
    </CCard>
  );
};

export default Profile;
