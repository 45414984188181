/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useHistory, useParams, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { CSVLink } from "react-csv";
import { contentSuccess, contentError } from "../../_utils/api";
import { toast, ToastContainer } from "react-toastify";
import Select from "react-select";

import {
  CNav,
  CNavItem,
  CNavLink,
  CTabPane,
  CTabContent,
  CModal,
  CModalBody,
  CModalHeader,
  CButton,
  CSelect,
  CForm,
  CDataTable,
  CTooltip,
  CInput,
  CInputGroup,
  CListGroup,
  CListGroupItem,
  CRow,
  CCol,
  CSpinner,
} from "@coreui/react";
import commonStyles from "../../scss/common-form.module.scss";
import CIcon from "@coreui/icons-react";
import { Printer, ChevronLeft, Eye, EyeOff } from "react-feather";
import styles from "../../scss/details.module.scss";
import "../../css/details.css";
import {
  deleteUser,
  getUser,
  restoreUser,
  deleteUserRole,
  getRoles,
  addUserRole,
  getUserPermission,
  addUserPermission,
  getPermissionsDropDown,
  deleteUserPermission
} from "src/redux/_services/adminService";

const editEnableOptions = [
  { label: "Disabled", value: false },
  { label: "Enabled", value: true },
];
const re = /^[0-9\b]+$/;

const AdminAccountDetails = (props) => {
  const { id } = useParams();
  const [activeKey, setActiveKey] = useState(1);
  const [loading, setLoading] = useState(false);
  const [currentAccount, setCurrentAccount] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [addRoleModal, setAddRoleModal] = useState(false);
  const [addPermissionModal, setAddPermissionModal] = useState(false);
  const [userDetailsLoading, setUserDetailsLoading] = useState(false);
  const [userDetails, setUserDetails] = useState([]);
  const [userDetailsErr, setUserDetailsErr] = useState([]);
  const [password, setPassword] = useState("");
  const [passwordType, setPasswordType] = useState("password");
  const [deleteLoading, setDeleteLoading] = useState("");
  const [deleteUserPassword, setDeleteUserPassword] = useState("");
  const [deleteUserPasswordErr, setDeleteUserPasswordErr] = useState("");
  const [restorePassword, setRestorePassword] = useState("");
  const [restoreLoading, setRestoreLoading] = useState("");
  const [roles, setRoles] = useState([]);
  const [addRoles, setAddRoles] = useState("");
  const [addRolesLoading, setAddRolesLoading] = useState(false);
  const [rolesDropDown, setRolesDropDown] = useState([]);
  const [permissionDropDown, setPermissionDropDown] = useState([]);
  const [delRolesLoading, setDelRolesLoading] = useState(false);
  const [permissions, setPermissions] = useState([]);
  const [addPermission, setAddPermission] = useState("");
  const [referralCode, setReferralCode] = useState("");

  useEffect(() => {
    sessionStorage.setItem("internalUserPage", props.location.state);
  }, []);

  const toggleDeleteModal = () => {
    setDeleteModal(!deleteModal);
  };
  const toggleAddRoleModal = () => {
    setAddRoleModal(!addRoleModal);
  };
  const toggleAddPermissionModal = () => {
    setAddPermissionModal(!addPermissionModal);
  };
  const dispatch = useDispatch();
  const history = useHistory();

  const deletePermissionHandler = (e, role) => {
    e.preventDefault();

    setUserDetailsLoading(true);
    deleteUserPermission(id, role)
      .then((res) => {
        getUserPermission(id)
        .then((res) => {
          setPermissions(res.data.data.permissions.data);
        })
      })
      .catch((err) => {
        if (err.response.data.error.message) {
          toast(contentError(err.response.data.error.message));
        } else {
          toast(contentError("An Error Occured"));
        }
      })
      .finally(() => setUserDetailsLoading(false));
  };
  const addPermissionHandler = (e) => {
    e.preventDefault();
    if (addPermission === "") {
      toast(contentError("Permission cannot be empty"));
      return;
    }

    setAddRolesLoading(true);
    addUserPermission(id, addPermission)
      .then((res) => {
        getUserPermission(id)
          .then((res) => {
            setPermissions(res.data.data.permissions.data);
          })
          .catch((err) => err)
          .finally(() => {
            setUserDetailsLoading(false);
          });
      })
      .catch((err) => {
        if (err.response.data.error.message) {
          toast(contentError(err.response.data.error.message));
        } else {
          toast(contentError("An Error Occured"));
        }
      })
      .finally(() => setAddRolesLoading(false));
  };
  const addRoleHandler = (e) => {
    e.preventDefault();
    if(roles.length > 0){
      toast(contentError("Users can only have one role. Please delete previous role before adding another"));
      return;
    }
    if (addRoles === "") {
      toast(contentError("Roles cannot be empty"));
      return;
    }

    if ((addRoles == 2 || addRoles == 10) && referralCode == "") {
      toast(contentError("Referral code cannot be empty"));
      return;
    }
    setAddRolesLoading(true);
    addUserRole(id, addRoles, referralCode)
      .then((res) => {
        refreshPage(res);
        toggleAddRoleModal()
      })
      .catch((err) => {
        if (err.response.data.error.message) {
          toast(contentError(err.response.data.error.message));
        } else {
          toast(contentError("An Error Occured"));
        }
      })
      .finally(() => setAddRolesLoading(false));
  };
  const deleteRoleHandler = (e, role) => {
    e.preventDefault();

    setDelRolesLoading(true);
    deleteUserRole(id, role)
      .then((res) => {
        refreshPage(res);
      })
      .catch((err) => {
        if (err.response.data.error.message) {
          toast(contentError(err.response.data.error.message));
        } else {
          toast(contentError("An Error Occured"));
        }
      })
      .finally(() => setDelRolesLoading(false));
  };

  const deleteUserHandler = (e) => {
    e.preventDefault();

    if (deleteUserPassword === "") {
      toast(contentError("Password cannot be empty"));
      return;
    }

    const payload = {
      deleteUserPassword,
      id,
    };
    setDeleteLoading(true);
    deleteUser(payload)
      .then((res) => {
        toast(contentSuccess(res.data), {
          onClose: history.push("/admin/create-account"),
        });
      })
      .catch((err) => {
        if (err.response.data.error.message) {
          toast(contentError(err.response.data.error.message));
        } else {
          toast(contentError("An Error Occured"));
        }
        setDeleteLoading(false);
      });
  };

  const restoreUserHandler = (e) => {
    e.preventDefault();

    if (restorePassword === "") {
      toast(contentError("Password cannot be empty"));
      return;
    }

    const payload = {
      restorePassword,
      id,
    };
    setRestoreLoading(true);
    restoreUser(payload)
      .then((res) => {
        refreshPage(res);
      })
      .catch((err) => {
        if (err.response.data.error.message) {
          toast(contentError(err.response.data.error.message));
        } else {
          toast(contentError("An Error Occured"));
        }
      })
      .finally(() => setRestoreLoading(false));
  };

  const refreshPage = () => {
    setUserDetailsLoading(true);
    getUser(id)
      .then((res) => {
        setUserDetails(res.data.data.user);
        setRoles(res.data.data.user.roles);
      })
      .catch((err) => {
        setUserDetailsErr(err.response.data.error.message);
      })
      .finally(() => {
        setUserDetailsLoading(false);
      });
  };

  useEffect(() => {
    setUserDetailsLoading(true);
    getUser(id)
      .then((res) => {
        setUserDetails(res.data.data.user);
        setRoles(res.data.data.user.roles);
      })
      .catch((err) => {
        setUserDetailsErr(err.response.data.error.message);
      })
      .finally(() => {
        setUserDetailsLoading(false);
      });
    getUserPermission(id)
      .then((res) => {
        setPermissions(res.data.data.permissions.data);
      })
      .catch((err) => err)
      .finally(() => {
        setUserDetailsLoading(false);
      });
    getRoles()
      .then((res) => {
        setRolesDropDown(res.data.data.roles.data);
      })
      .catch((err) => err);
    getPermissionsDropDown()
      .then((res) => {
        setPermissionDropDown(res.data.data.permissions);
      })
      .catch((err) => err);
  }, [userDetails && userDetails.deleted_at]);

  if (userDetailsLoading) {
    return (
      <CSpinner
        color="primary"
        style={{
          width: "4rem",
          height: "4rem",
          marginTop: "300px",
          marginLeft: "600px",
        }}
      />
    );
  }

  return (
    <>
      <div className="d-flex justify-content-end">
        <CButton
          onClick={() => history.push(`/admin/create-account`)}
          className="px-4 mb-4"
          color="success"
        >
          <ChevronLeft />
          Back
        </CButton>
      </div>
      <CNav variant="tabs">
        <CNavItem>
          <CNavLink
            href="#"
            active={activeKey === 1}
            onClick={() => setActiveKey(1)}
          >
            Internal User Information
          </CNavLink>
        </CNavItem>
      </CNav>

      <CTabContent className="bg-white brand-tab-content py-3 px-4">
        <CTabPane active={activeKey === 1}>
          {userDetails.deleted_at !== null ? (
            <>
              <CRow className="justify-content-center mt-4">
                <CCol sm="10" md="3" className="mx-auto px-0 px-md-3 mt-5">
                  <CListGroup className="mb-3">
                    <CListGroupItem href="#" color="success">
                      Restore Internal Dashboard User
                    </CListGroupItem>
                  </CListGroup>
                  <CForm autoComplete="off">
                    <div className>
                      <CInputGroup className="mb-1">
                        <CInput
                          type={passwordType}
                          placeholder="Enter Password to Delete Account"
                          autoComplete="false"
                          onChange={(e) => setRestorePassword(e.target.value)}
                        />

                        {passwordType === "password" ? (
                          <CButton
                            type="button"
                            color="secondary"
                            variant="outline"
                            onClick={() => setPasswordType("text")}
                          >
                            Show
                          </CButton>
                        ) : (
                          <CButton
                            type="button"
                            color="secondary"
                            variant="outline"
                            onClick={() => setPasswordType("password")}
                          >
                            Hide
                          </CButton>
                        )}
                      </CInputGroup>
                    </div>
                    {restoreLoading ? (
                      <CButton
                        className="mt-4 mb-4"
                        color="success"
                        block
                        onClick={null}
                      >
                        <CSpinner color="white" size="sm" /> Loading ...
                      </CButton>
                    ) : (
                      <CButton
                        onClick={(e) => restoreUserHandler(e)}
                        className="mt-4 mb-4"
                        color="success"
                        block
                      >
                        Restore Account
                      </CButton>
                    )}
                  </CForm>
                </CCol>
              </CRow>
            </>
          ) : (
            <>
              <div className={styles.tab_info}>
                <div className="d-flex justify-content-between align-items-center">
                  <h3>User Information</h3>
                  <div className={styles.tab_tooltip}>
                    {/* Only One of Delete User and Edit User will show at a time depending on the current state */}
                    {userDetails.deleted_at === null ? (
                      <CTooltip
                        content="Delete Account"
                        placement="bottom"
                        className="pr-2"
                      >
                        <CButton
                          color="danger"
                          size="sm"
                          onClick={toggleDeleteModal}
                        >
                          <CIcon name="cilTrash" size="sm" />
                        </CButton>
                      </CTooltip>
                    ) : (
                      ""
                    )}
                    {userDetails.deleted_at === null ? (
                      ""
                    ) : (
                      <CTooltip
                        content="Restore Account"
                        placement="bottom"
                        className=""
                      >
                        <CButton color="success" size="sm" onClick={""}>
                          <CIcon name="cilReload" size="sm" />
                        </CButton>
                      </CTooltip>
                    )}
                  </div>
                </div>

                <p className="mb-5">
                  Basic information about the account like the name, email and
                  number.
                </p>

                <div className={styles.field_info}>
                  <div className={styles.field_item}>
                    <div className={styles.field_wrapper}>
                      <span className={styles.field_label}>Name</span>
                      <span className={styles.field_value}>
                        {userDetails &&
                          userDetails.full_name &&
                          userDetails.full_name.replace(
                            /(^\w)|([-\s]\w)/g,
                            (match) => match.toUpperCase()
                          )}
                      </span>
                    </div>
                  </div>

                  <div className={styles.field_item}>
                    <div className={styles.field_wrapper}>
                      <span className={styles.field_label}>Gender</span>
                      <span className={styles.field_value}>
                        {userDetails && userDetails.gender
                          ? userDetails && userDetails.gender
                          : "Not Available"}
                      </span>
                    </div>
                  </div>
                  <div className={styles.field_item}>
                    <div className={styles.field_wrapper}>
                      <span className={styles.field_label}>Email</span>
                      <span className={styles.field_value}>
                        {userDetails && userDetails.email}
                      </span>
                    </div>
                  </div>
                  <div className={styles.field_item}>
                    <div className={styles.field_wrapper}>
                      <span className={styles.field_label}>Phone Number</span>
                      <span className={styles.field_value}>
                        {userDetails && userDetails.telephone
                          ? userDetails && userDetails.telephone
                          : "Not Available"}
                      </span>
                    </div>
                  </div>
                  <div className={styles.field_item}>
                    <div className={styles.field_wrapper}>
                      <span className={styles.field_label}>Role</span>
                      <span className={styles.field_value}>
                        {userDetails && userDetails.role
                          ? userDetails && userDetails.role
                          : "Not Available"}
                      </span>
                    </div>
                  </div>

                  <div className={styles.field_item}>
                    <div className={styles.field_wrapper}>
                      <span className={styles.field_label}>Date Created</span>
                      <span className={styles.field_value}>
                        {userDetails && userDetails.created_at
                          ? moment(
                              userDetails && userDetails.created_at
                            ).format("DD-MMM-YYYY")
                          : "Not Available"}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="my-5">
                <CButton
                  color="danger"
                  onClick={toggleDeleteModal}
                  className="mr-5"
                >
                  Delete User
                </CButton>
                <CButton
                  color="success"
                  onClick={toggleAddRoleModal}
                  className="mr-5"
                >
                  Add Role
                </CButton>
                <CButton color="success" onClick={toggleAddPermissionModal}>
                  Add Permission
                </CButton>
              </div>
              {/* delete user modal */}
              <CModal show={deleteModal} onClose={toggleDeleteModal} centered>
                <CModalHeader closeButton className="text-danger">
                  CLOSE
                </CModalHeader>
                <CModalBody>
                  <CListGroup className="mb-3">
                    <CListGroupItem href="#" color="danger">
                      Delete Internal Dashboard User
                    </CListGroupItem>
                  </CListGroup>
                  <CRow className="justify-content-center">
                    <CCol>
                      <CForm autoComplete="off">
                        <div className>
                          <CInputGroup className="mb-1">
                            <CInput
                              type={passwordType}
                              placeholder="Enter Password to Delete Account"
                              autoComplete="false"
                              onChange={(e) =>
                                setDeleteUserPassword(e.target.value)
                              }
                            />

                            {passwordType === "password" ? (
                              <CButton
                                type="button"
                                color="secondary"
                                variant="outline"
                                onClick={() => setPasswordType("text")}
                              >
                                Show
                              </CButton>
                            ) : (
                              <CButton
                                type="button"
                                color="secondary"
                                variant="outline"
                                onClick={() => setPasswordType("password")}
                              >
                                Hide
                              </CButton>
                            )}
                          </CInputGroup>
                        </div>
                        {deleteLoading ? (
                          <CButton className="mt-4 mb-4" color="danger" block>
                            <CSpinner color="white" size="sm" /> Loading ...
                          </CButton>
                        ) : (
                          <CButton
                            onClick={(e) => deleteUserHandler(e)}
                            className="mt-4 mb-4"
                            color="danger"
                            block
                          >
                            Delete
                          </CButton>
                        )}
                      </CForm>
                    </CCol>
                  </CRow>
                  <ToastContainer autoClose={5000} />{" "}
                </CModalBody>
              </CModal>
              {/* add role modal */}
              <CModal show={addRoleModal} onClose={toggleAddRoleModal} centered>
                <CModalHeader closeButton className="text-danger">
                  CLOSE
                </CModalHeader>
                <CModalBody>
                  <CListGroup className="mb-3">
                    <CListGroupItem href="#" color="success">
                      Add Roles to Internal an User
                    </CListGroupItem>
                  </CListGroup>
                  <CRow className="justify-content-center">
                    <CCol>
                      <CForm autoComplete="off">
                        <div className>
                          <CInputGroup className="mb-3">
                            <CSelect
                              className="w-100"
                              name="status"
                              defaultValue={""}
                              onChange={(e) => setAddRoles(e.target.value)}
                            >
                              <option value="">Select Role</option>
                              {rolesDropDown &&
                                rolesDropDown.map((roles, index) => (
                                  <option
                                    key={index}
                                    value={roles.id}
                                    className="text-capitalize"
                                  >
                                    {roles?.name?.replace("-", " ")}
                                  </option>
                                ))}
                            </CSelect>{" "}
                          </CInputGroup>
                          {addRoles === 2 || addRoles === 10 ? (
                            <div>
                              <CInput
                                type="text"
                                placeholder="Referral Code"
                                onChange={(e) =>
                                  setReferralCode(e.target.value)
                                }
                              />

                              <p className="text-danger mb-3">
                                referral code cannot be empty
                              </p>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        {addRolesLoading ? (
                          <CButton className="mt-4 mb-4" color="success" block>
                            <CSpinner color="white" size="sm" /> Loading ...
                          </CButton>
                        ) : (
                          <CButton
                            onClick={addRoleHandler}
                            className="mt-4 mb-4"
                            color="success"
                            block
                          >
                            Add Role
                          </CButton>
                        )}
                      </CForm>
                    </CCol>
                  </CRow>
                  <ToastContainer autoClose={5000} />{" "}
                </CModalBody>
              </CModal>
              {/* add permission modal */}
              <CModal
                show={addPermissionModal}
                onClose={toggleAddPermissionModal}
                centered
              >
                <CModalHeader closeButton className="text-danger">
                  CLOSE
                </CModalHeader>
                <CModalBody>
                  <CListGroup className="mb-3">
                    <CListGroupItem href="#" color="success">
                      Add Permissions to Internal an User
                    </CListGroupItem>
                  </CListGroup>
                  <CRow className="justify-content-center">
                    <CCol>
                      <CForm autoComplete="off">
                        <div className>
                          <CInputGroup className="mb-3">
                            <CSelect
                              className="w-100"
                              name="status"
                              defaultValue={""}
                              onChange={(e) => setAddPermission(e.target.value)}
                            >
                              <option value="">Select Permission</option>
                              {permissionDropDown &&
                                permissionDropDown.map((permission, index) => (
                                  <option
                                    key={index}
                                    value={permission.id}
                                    className="text-capitalize"
                                  >
                                    {permission?.display_name}
                                  </option>
                                ))}
                            </CSelect>{" "}
                          </CInputGroup>
                        </div>
                        {addRolesLoading ? (
                          <CButton className="mt-4 mb-4" color="success" block>
                            <CSpinner color="white" size="sm" /> Loading ...
                          </CButton>
                        ) : (
                          <CButton
                            onClick={addPermissionHandler}
                            className="mt-4 mb-4"
                            color="success"
                            block
                          >
                            Add Permission
                          </CButton>
                        )}
                      </CForm>
                    </CCol>
                  </CRow>
                  <ToastContainer autoClose={5000} />{" "}
                </CModalBody>
              </CModal>
              <CRow>
                <div className="col-12 col-md-8">
                  <h3>Internal User Roles</h3>
                  <DataTable
                    key1="roles"
                    label1="Roles"
                    key2="description"
                    label2="Description"
                    key3="remove"
                    label3="Remove"
                    items={roles}
                    loading={loading}
                    itemsPerPage={30}
                    deleteAction={deleteRoleHandler}
                  />
                </div>
                <div className="col-12 col-md-8 mt-5">
                  <h3>Internal User Permissions</h3>
                  <DataTable
                    key1="permissions"
                    label1="Permissions"
                    key2="description"
                    label2="Description"
                    key3="remove"
                    label3="Remove"
                    items={permissions}
                    loading={loading}
                    itemsPerPage={30}
                    deleteAction={deletePermissionHandler}
                  />
                </div>
              </CRow>
            </>
          )}
        </CTabPane>
      </CTabContent>

      <ToastContainer autoClose={3000} />
    </>
  );
};

export default AdminAccountDetails;

const DataTable = ({
  items,
  loading,
  itemsPerPage,
  deleteAction,
  key1,
  label1,
  key2,
  label2,
  key3,
  label3,
}) => {
  const history = useHistory();

  const fields = [
    { key: "name", label: label1 },
    { key: key2, label: label2 },
    { key: key3, label: label3 },
  ];


  return (
    <CDataTable
      items={items}
      fields={fields}
      columnFilter
      footer
      responsive={true}
      hover
      loading={loading}
      sorter
      itemsPerPage={itemsPerPage}
      // activePage
      // clickableRows
      This
      will
      be
      modified
      to
      reflect
      the
      specific
      account
      details
      // clicked
      // onRowClick={(item) =>
      //   history.push({
      //     pathname: `/admin/roles/${item.id}`,
      //     state: page,
      //   })
      // }
      scopedSlots={{
        name: (item) => (
          <td className="text-capitalize">{key1 === "roles" ? item && item.name : item && item.display_name}</td>
        ),
        description: (item) => (
          <td className="text-capitalize">
            {item && item.description ? item.description : "Not Available"}
          </td>
        ),
        remove: (item) => (
          <td className="text-capitalize text-danger">
            <CButton
              color="danger"
              size="sm"
              onClick={(e) => deleteAction(e, item.id)}
            >
              Delete
            </CButton>
          </td>
        ),
      }}
    />
  );
};
