import React from 'react'
import { CFooter } from '@coreui/react'

const TheFooter = () => {
  return (
    <CFooter fixed={false} className="pt-3 pt-md-0">
      <div>
        <span>Cloudbank Internal Dashboard</span>
        <span className="ml-1">&copy; 2021.</span>
      </div>
      <div className="mfs-auto">
        <span className="mr-1">Powered by</span>
        <span>MMG</span>
      </div>
    </CFooter>
  )
}

export default TheFooter;
