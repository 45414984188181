/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useHistory, useParams, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { CSVLink } from "react-csv";
import { contentSuccess, contentError } from "../../_utils/api";
import { toast, ToastContainer } from "react-toastify";

import {
  CBadge,
  CNav,
  CNavItem,
  CNavLink,
  CTabPane,
  CTabContent,
  CModal,
  CModalBody,
  CModalHeader,
  CModalFooter,
  CButton,
  CSelect,
  CTextarea,
  CForm,
  CDataTable,
  CPagination,
  CTooltip,
  CLabel,
  CInput,
  CInputGroup,
  CContainer,
  CListGroup,
  CListGroupItem,
  CDropdown,
  CDropdownDivider,
  CDropdownToggle,
  CDropdownMenu,
  CRow,
  CCol,
  CSpinner,
  CCard,
} from "@coreui/react";
import commonStyles from "../../scss/common-form.module.scss";
import CIcon from "@coreui/icons-react";
import { Printer, ChevronLeft, Eye, EyeOff } from "react-feather";
import styles from "../../scss/details.module.scss";
import "../../css/details.css";
import {
  getSingleTeams,
  switchTeams,
  getTeamMembers,
} from "src/redux/_services/adminService";

const re = /^[0-9\b]+$/;

const AdminAccountDetails = (props) => {
  const { id } = useParams();
  const [activeKey, setActiveKey] = useState(1);
  const [loading, setLoading] = useState("");
  const [team, setTeam] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordType, setPasswordType] = useState("password");
  const [switchLoading, setSwitchLoading] = useState("");
  const [reload, setReload] = useState(null);
  const [teamMembers, setTeamMembers] = useState("");
  const [currentpage, set_currentpage] = useState();
  const [lastPage, set_lastpage] = useState(1);
  const [searchPage, setSearchPage] = useState("");
  const [searchPageErr, setSearchPageErr] = useState("");

  const history = useHistory();

  const pageChange = (newPage) => {
    set_currentpage(newPage);
  };

  const switchHandler = (e) => {
    e.preventDefault();

    if (password === "" || email == "") {
      toast(contentError("Password or Email cannot be empty "));
      return;
    }

    const payload = {
      id,
      password,
      email,
    };
    setSwitchLoading(true);
    switchTeams(payload)
      .then((res) => {
        setSwitchLoading(false);
        toast(contentSuccess(res.data), {
          onClose: setTimeout(() => setReload(true), 4000),
        });
      })
      .catch((err) => {
        if (err.response.data.error.message) {
          toast(contentError(err.response.data.error.message));
        } else {
          toast(contentError("An Error Occured"));
        }
        setSwitchLoading(false);
      });
  };

  const searchPageHandler = (e) => {
    e.preventDefault();
    if (searchPage > lastPage) {
      setSearchPageErr("Selected Number cannot be greater than last page");
      return;
    }
    setSearchPageErr("");
    setLoading(true);
    getTeamMembers(id, searchPage)
      .then((res) => {
      
        setTeamMembers(res.data.data);
        set_currentpage(res.data.data.members.meta.current_page);
        set_lastpage(res.data.data.members.meta.last_page);
      })
      .finally(function () {
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
       
      });
  };

  useEffect(() => {
    setLoading(true);
    getSingleTeams(id)
      .then((res) => {
        setTeam(res.data.data);
      })
      .catch((err) => err)
      .finally(() => {
        setLoading(false);
      });
    getTeamMembers(id, currentpage)
      .then((res) => {
        setTeamMembers(res.data.data);
        set_currentpage(res.data.data.members.meta.current_page);
        set_lastpage(res.data.data.members.meta.last_page);
      })
      .catch((err) => err)
      .finally();
  }, [currentpage]);

  if (loading) {
    return (
      <CSpinner
        color="primary"
        style={{
          width: "4rem",
          height: "4rem",
          marginTop: "300px",
          marginLeft: "600px",
        }}
      />
    );
  }

  const date = new Date();
  const downloadDate = moment(date).format("DD-MMM-YYYY");
  const internalUserFileName = `cloudbank_internal_users_${downloadDate}.csv`;

  return (
    <>
      <div className="d-flex justify-content-end">
        <CButton
          onClick={() => history.goBack()}
          className="px-4 mb-4"
          color="success"
        >
          <ChevronLeft />
          Back
        </CButton>
      </div>
      <CNav variant="tabs">
        <CNavItem>
          <CNavLink
            href="#"
            active={activeKey === 1}
            onClick={() => setActiveKey(1)}
          >
            Team Details
          </CNavLink>
        </CNavItem>
        <CNavItem>
          <CNavLink
            href="#"
            active={activeKey === 2}
            onClick={() => setActiveKey(2)}
          >
            Teams Members
          </CNavLink>
        </CNavItem>
        <CNavItem>
          <CNavLink
            href="#"
            active={activeKey === 3}
            onClick={() => setActiveKey(3)}
          >
            Switch Teams
          </CNavLink>
        </CNavItem>
      </CNav>

      <CTabContent className="bg-white brand-tab-content py-3 px-4">
        <CTabPane active={activeKey === 1}>
          <p className="mb-5">
            Basic information about the team like the name, date created etc
          </p>
          <div className={styles.field_info}>
            <div className={styles.field_item}>
              <div className={styles.field_wrapper}>
                <span className={styles.field_label}>Team Name</span>

                <p className={styles.field_value_link}>
                  {team && team.team && team.team.name}
                </p>
              </div>
            </div>

            <div className={styles.field_item}>
              <div className={styles.field_wrapper}>
                <span className={styles.field_label}>Date Created</span>

                <p className={styles.field_value_link}>
                  {moment(team && team.team && team.team.created_at).format(
                    "DD-MMM-YYYY LTS"
                  )}
                </p>
              </div>
            </div>

            <div className={styles.field_item}>
              <div className={styles.field_wrapper}>
                <span className={styles.field_label}>Date Deleted</span>

                <p className={styles.field_value_link}>
                  {team && team.deleted_at
                    ? moment(team && team.team && team.team.deleted_at).format(
                        "DD-MMM-YYYY LTS"
                      )
                    : "Not Available"}
                </p>
              </div>
            </div>

            <div className={styles.field_item}>
              <div className={styles.field_wrapper}>
                <span className={styles.field_label}>Date Updated</span>

                <p className={styles.field_value_link}>
                  {team && team.updateded_at
                    ? moment(
                        team && team.team && team.team.updateded_at
                      ).format("DD-MMM-YYYY LTS")
                    : "Not Available"}
                </p>
              </div>
            </div>

            <div className={styles.field_item}>
              <div className={styles.field_wrapper}>
                <span className={styles.field_label}>Total Member</span>

                <p className={styles.field_value_link}>
                  {team && team.totalMemberCount}
                </p>
              </div>
            </div>
          </div>
        </CTabPane>
        <CTabPane active={activeKey === 2}>
          <div className="d-flex align-items-center justify-content-between mb-5">
            <div>
              <h3>Team Members</h3>
              <p>A look at all the accounts that belong to a team</p>
            </div>
          </div>
          <DataTable
            items={
              teamMembers && teamMembers.members && teamMembers.members.data
            }
            itemsPerPage={30}
            loading={loading}
          />
          <CPagination
            activePage={currentpage}
            onActivePageChange={pageChange}
            pages={lastPage}
            doubleArrows={true}
            align="end"
          />
          <div className="d-flex justify-content-end">
            <div className="d-flex justify-content-end w-25">
              <CInput
                type="number"
                className=" ml-2 w-25"
                onChange={(e) => setSearchPage(e.target.value)}
              />
              <CButton
                color="primary"
                className="ml-2"
                onClick={searchPageHandler}
              >
                Search
              </CButton>
            </div>
          </div>
          <p className="d-flex justify-content-end text-danger">
            {searchPageErr}
          </p>
          <p className="d-flex justify-content-end text-muted">
            search by page number
          </p>
        </CTabPane>
        <CTabPane active={activeKey === 3}>
          <>
            <CRow className="justify-content-center mt-4">
              <CCol sm="10" md="4" className="mx-auto px-0 px-md-3 mt-5">
                <CListGroup className="mb-3">
                  <CListGroupItem href="#" color="warning">
                    Switch Users or Accounts between Teams
                  </CListGroupItem>
                </CListGroup>
                <CForm autoComplete="off">
                  <div className>
                    <div className="mb-3">
                      <CLabel>Email</CLabel>
                      <CInput
                        type="email"
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Enter Email"
                        autocomplete="new-password"
                        value={email}
                      />
                    </div>
                    <CInputGroup className="mb-1">
                      <CInput
                        type={passwordType}
                        placeholder="Enter Password"
                        value={password}
                        autocomplete="new-password"
                        onChange={(e) => setPassword(e.target.value)}
                      />

                      {passwordType === "password" ? (
                        <CButton
                          type="button"
                          color="secondary"
                          variant="outline"
                          onClick={() => setPasswordType("text")}
                        >
                          Show
                        </CButton>
                      ) : (
                        <CButton
                          type="button"
                          color="secondary"
                          variant="outline"
                          onClick={() => setPasswordType("password")}
                        >
                          Hide
                        </CButton>
                      )}
                    </CInputGroup>
                  </div>
                  {switchLoading ? (
                    <CButton className="mt-4 mb-4" color="success" block>
                      <CSpinner color="white" size="sm" /> Loading ...
                    </CButton>
                  ) : (
                    <CButton
                      onClick={(e) => switchHandler(e)}
                      className="mt-4 mb-4"
                      color="success"
                      block
                    >
                      Switch Account
                    </CButton>
                  )}
                </CForm>
              </CCol>
            </CRow>
          </>
        </CTabPane>
      </CTabContent>

      <ToastContainer autoClose={3000} />
    </>
  );
};

export default AdminAccountDetails;

const DataTable = ({ items, loading, itemsPerPage, page }) => {
  const history = useHistory();

  const fields = [
    { key: "name", label: "Full Name" },
    { key: "email", label: "Email" },
    { key: "gender", label: "Gender" },
    { key: "created_at", label: "Created Date" },
    { key: "updated_at", label: "Updated Date" },
  ];

  return (
    <CDataTable
      items={items}
      fields={fields}
      columnFilter
      footer
      responsive={true}
      hover
      loading={loading}
      sorter
      itemsPerPage={itemsPerPage}
      activePage
      clickableRows
      This
      will
      be
      modified
      to
      reflect
      the
      specific
      account
      details
      clicked
      onRowClick={(item) =>
        history.push({
          // pathname: `/admin/teams/${item.id}`,
          state: page,
        })
      }
      scopedSlots={{
        name: (item) => (
          <td className="text-capitalize">{item && item.full_name}</td>
        ),
        email: (item) => (
          <td className="text-capitalize">{item && item.email}</td>
        ),
        gender: (item) => (
          <td className="text-capitalize">{item && item.gender}</td>
        ),
        created_at: (item) => (
          <td>
            <>
              {item && item.created_at
                ? moment(item && item.created_at).format("DD-MMM-YYYY LTS")
                : "Not Available"}
            </>
          </td>
        ),
        updated_at: (item) => (
          <td>
            <>
              {item && item.updated_at
                ? moment(item && item.updated_at).format("DD-MMM-YYYY LTS")
                : "Not Available"}
            </>
          </td>
        ),
      }}
    />
  );
};
