import React, { useEffect, useState } from "react";

import { CWidgetDropdown, CRow, CCol, CSpinner, CButton } from "@coreui/react";
import { Printer, ChevronLeft } from "react-feather";
import { useHistory } from "react-router-dom";

import { getTeamLeadAnalytics } from "src/redux/_services/adminService";

const WidgetsDropdown = () => {
  const [dashboard, setDashboard] = useState([]);
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  useEffect(() => {
    const dashboard = () => {
      setLoading(true);
      getTeamLeadAnalytics()
        .then((res) => {
          setDashboard(res.data.data);
        })
        .finally(() => {
          setLoading(false);
        })
        .catch((err) => {});
    };

    dashboard();
  }, []);
  if (loading) {
    return (
      <CSpinner
        color="primary"
        style={{
          width: "4rem",
          height: "4rem",
          marginTop: "300px",
          marginLeft: "600px",
        }}
      />
    );
  }
  return (
    <>
      <CRow>
        <CCol sm="6" lg="4">
          <CWidgetDropdown
            color="gradient-success"
            className="pb-4"
            header={
              dashboard &&
              dashboard.totalAccountBalance &&
              dashboard.totalAccountBalance
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            text="Total Amount"
          ></CWidgetDropdown>
        </CCol>

        <CCol sm="6" lg="4">
          <CWidgetDropdown
            color="gradient-warning"
            className="pb-4"
            header={
              dashboard &&
              dashboard.totalNumberOfAccounts &&
              dashboard.totalNumberOfAccounts
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            text="Total Number of Accounts"
          ></CWidgetDropdown>
        </CCol>

        <CCol sm="6" lg="4">
          <CWidgetDropdown
            color="gradient-info"
            className="pb-4"
            header={dashboard && dashboard.totalNumberOfUser}
            text="Number of Users"
          ></CWidgetDropdown>
        </CCol>

        <CCol sm="6" lg="4">
          <CWidgetDropdown
            color="gradient-primary"
            className="pb-4"
            header={
              dashboard &&
              dashboard.totalInvestmentSum &&
              dashboard.totalInvestmentSum
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            text="Total Investment Sum"
          ></CWidgetDropdown>
        </CCol>
        <CCol sm="6" lg="4">
          <CWidgetDropdown
            color="gradient-warning"
            className="pb-4"
            header={
              dashboard &&
              dashboard.totalInvestments &&
              dashboard.totalInvestments
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            text="Total Investments"
          ></CWidgetDropdown>
        </CCol>
      </CRow>
    </>
  );
};

export default WidgetsDropdown;
