import {
  CREATE_TRANSACTION_REQUEST,
  CREATE_TRANSACTION_SUCCESS,
  CREATE_TRANSACTION_FAIL,
  USER_UPDATEPIN_REQUEST,
  USER_UPDATEPIN_SUCCESS,
  USER_UPDATEPIN_FAIL,
  USER_RESETPIN_REQUEST,
  USER_RESETPIN_SUCCESS,
  USER_RESETPIN_FAIL,
  USER_REQUESTTOKEN_REQUEST,
  USER_REQUESTTOKEN_SUCCESS,
  USER_REQUESTTOKEN_FAIL,

  // CREATE_TRANSACTION_PIN_REQUEST,
  // CREATE_TRANSACTION_PIN_SUCCESS,
  // CREATE_TRANSACTION_PIN_FAIL,
  SAVE_TRANSACTION,
} from "../_constants/transactionConstants";
function transactionSaveReducer(state = { transactionSaveData: {} }, action) {
  switch (action.type) {
    case SAVE_TRANSACTION:
      return { loading: false, ...state, transactionSaveData: action.payload };
    default:
      return state;
  }
}
function transactionCreateReducer(
  state = { transactionCreateData: {} },
  action
) {
  switch (action.type) {
    case CREATE_TRANSACTION_REQUEST:
      return { loading: true };
    case CREATE_TRANSACTION_SUCCESS:
      return {
        loading: false,
        transactionCreateData: action.payload,
        success: true,
      };
    case CREATE_TRANSACTION_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

function updatePinReducer(state = {}, action) {
  switch (action.type) {
    case USER_UPDATEPIN_REQUEST:
      return { loading: true };
    case USER_UPDATEPIN_SUCCESS:
      return { loading: false, userInfo: action.payload, success: true };
    case USER_UPDATEPIN_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

function resetPinReducer(state = {}, action) {
  switch (action.type) {
    case USER_RESETPIN_REQUEST:
      return { loading: true };
    case USER_RESETPIN_SUCCESS:
      return { loading: false, userInfo: action.payload, success: true };
    case USER_RESETPIN_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

function requestTokenReducer(state = {}, action) {
  switch (action.type) {
    case USER_REQUESTTOKEN_REQUEST:
      return { loading: true };
    case USER_REQUESTTOKEN_SUCCESS:
      return {
        loading: false,
        requestTokenData: action.payload,
        success: true,
      };
    case USER_REQUESTTOKEN_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

export {
  transactionCreateReducer,
  transactionSaveReducer,
  updatePinReducer,
  requestTokenReducer,
  resetPinReducer,
};
