/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import moment from "moment";

import { CButton, CDataTable } from "@coreui/react";
import { UnblockFund } from "src/views/accounts/account-detail-actions/UnblockFund";
import { BlockFund } from "src/views/accounts/account-detail-actions/BlockFund";

const getStatusBadge = (status) => {
  switch (status) {
    case false:
      return "bg-danger";
    default:
      return "bg-success";
  }
};

const AccountBlockFundDataTable = (props) => {
  const {
    items,
    loading,
    onClose,
    accountId,
    itemsPerPage,
    openBlockFundModal,
  } = props;

  const [openModal, setOpenModal] = useState(false);
  const [fundId, setFundId] = useState(null);

  const handleCloseModal = () => setOpenModal(false);
  const handleOpenModal = () => setOpenModal(true);

  const handleSelect = (item) => () => {
    setFundId(item.id);
    if (item.status) {
      handleOpenModal();
    }
  };

  const fields = [
    {
      key: "reference",
      label: "Reference",
      _style: { width: "15%" },
    },

    {
      key: "amount",
      label: "Amount (₦)",
      _style: { width: "15%" },
    },
    { key: "status", label: "Status" },
    {
      key: "reason",
      label: "Reason",
      _style: { width: "24%" },
    },
    { key: "action", label: "Action", _style: { width: "10%" } },
    { key: "created_at", label: "Date Created", _style: { width: "18%" } },
    { key: "updated_at", label: "Date Updated", _style: { width: "18%" } },
  ];

  return (
    <>
      <CDataTable
        items={items}
        fields={fields}
        columnFilter
        tableFilter
        footer
        responsive={true}
        // itemsPerPageSelect={{
        //   label: "Items per page:",
        //   values: [5, 10, 20, 30],
        // }}
        hover
        loading={loading}
        sorter
        itemsPerPage={itemsPerPage}
        scopedSlots={{
          reference: (item) => (
            <td className="text-capitalize">{item.reference}</td>
          ),
          status: (item) => (
            <td>
              <span
                className={`badge rounded-pill text-white py-1 px-2 ${getStatusBadge(
                  item.status
                )}`}
              >
                {item.status === true ? "True" : "False"}
              </span>
            </td>
          ),

          amount: (item) => <td>{item.amount}</td>,
          created_at: (item) => (
            <td>
              <>{moment(item && item.created_at).format("DD-MMM-YYYY LTS")}</>
            </td>
          ),
          updated_at: (item) => (
            <td>
              <>{moment(item && item.updated_at).format("DD-MMM-YYYY LTS")}</>
            </td>
          ),
          reason: (item) => <td>{item.reason}</td>,
          action: (item) => (
            <td>
              <CButton
                style={{ width: "100px" }}
                className={`btn text-white ${
                  item.status === true ? "btn-success" : "btn-danger"
                }`}
                disabled={!item.status}
                onClick={handleSelect(item)}
              >
                {item.status === true ? "Unblock" : "Block"}
              </CButton>
            </td>
          ),
        }}
      />
      {fundId && openModal ? (
        <UnblockFund
          show={openModal}
          fundId={fundId}
          onClose={handleCloseModal}
        />
      ) : null}

      {openBlockFundModal ? (
        <BlockFund
          accountId={accountId}
          show={openBlockFundModal}
          onClose={onClose}
        />
      ) : null}
    </>
  );
};

export default AccountBlockFundDataTable;
