import React from "react";
import { CBadge, CDataTable } from "@coreui/react";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { thousandOperator, capitalizeName } from "../../components";

const transactionFields = [
  {
    key: "reference",
    label: "Reference",
    _style: { width: "5%" },
  },
  {
    key: "credit_bank",
    label: "Credit Bank",
    _style: { width: "5%" },
  },
  {
    key: "credit_account_number",
    label: "Credit Account",
    _style: { width: "10%" },
  },

  {
    key: "credit_account_name",
    label: "Credit Account Name",
    _style: { width: "5%" },
  },
  {
    key: "debit_account_number",
    label: "Debit Account",
    _style: { width: "10%" },
  },
  {
    key: "debit_account_name",
    label: "Debit Account Name",
    _style: { width: "10%" },
  },
  { key: "amount", label: "Amount (₦)", _style: { width: "5%" } },
  { key: "balance_before", label: "Balance Before(₦)" },
  { key: "balance_after", label: "Balance After(₦)" },
  { key: "debit_bank", label: "Debit Bank", _style: { width: "5%" } },
  "type",
  "status",
  { key: "created_at", label: "Date", _style: { width: "5%" } },
];

const getTransactionTypeBadge = (transactionType) => {
  if (transactionType === "credit") {
    return "success";
  }

  if (transactionType === "debit") {
    return "danger";
  }
};

const getTransactionStatusBadge = (transactionStatus) => {
  if (transactionStatus === "success") {
    return "success";
  }

  if (transactionStatus === "failed") {
    return "danger";
  }

  if (transactionStatus === "pending") {
    return "warning";
  }
};

function TransactionDataTable({ data, loading, itemsPerPage, page }) {
  const history = useHistory();
  return (
    <CDataTable
      items={data}
      fields={transactionFields}
      loading={loading}
      columnFilter
      footer
      responsive
      className="table-responsive"
      // itemsPerPageSelect={{
      //   label: "Items per page:",
      //   values: [5, 10, 20, 30],
      // }}
      hover
      sorter
      itemsPerPage={itemsPerPage}
      activePage
      clickableRows
      // This will be modified to reflect the transaction details clicked
      onRowClick={(item) =>
        history.push({
          pathname: `/transactions/transaction-details/${item.id}`,
          state: page,
        })
      }
      scopedSlots={{
        reference: (item) => (
          <td>
            <>
              {item &&
                item.transaction_reference &&
                item.transaction_reference.slice(0, 7) + "xxxxx"}
            </>
          </td>
        ),
        type: (item) => (
          <td className="text-capitalize">
            <CBadge color={getTransactionTypeBadge(item.type)} size="md">
              {item.type}
            </CBadge>
          </td>
        ),
        status: (item) => (
          <td className="text-capitalize">
            <CBadge color={getTransactionStatusBadge(item.status)} size="md">
              {item.status}
            </CBadge>
          </td>
        ),
        amount: (item) => (
          <td>
            <>{thousandOperator(item.amount)}</>
          </td>
        ),
        balance_before: (item) => (
          <td>
            <>
              {
                // thousandOperator(item.source && item.source.available_balance || item.beneficiary && item.beneficiary.available_balance)

                thousandOperator(item && item.balance_before)
              }
            </>
          </td>
        ),
        balance_after: (item) => (
          <td>
            <>
              {
                // thousandOperator(item.source && item.source.available_balance || item.beneficiary && item.beneficiary.available_balance)

                thousandOperator(item && item.balance_after)
              }
            </>
          </td>
        ),
        credit_bank: (item) => (
          <td>
            <>{item && item.credit_bank && item.credit_bank.bank_name}</>
          </td>
        ),
        credit_account_number: (item) => (
          <td>
            <>
              {item.beneficiary
                ? item && item.beneficiary && item.beneficiary.account_number
                : item && item.external_account_number}
            </>
          </td>
        ),

        credit_account_name: (item) => (
          <td>
            <>
              {item.beneficiary
                ? item &&
                  item.beneficiary &&
                  item.beneficiary.account_name
                    .toString()
                    .replace(/(^\w)|([-\s]\w)/g, (match) => match.toUpperCase())
                : item.external_account_name
                ? item &&
                  item.external_account_name
                    .toString()
                    .replace(/(^\w)|([-\s]\w)/g, (match) => match.toUpperCase())
                : "Not Available"}
            </>
          </td>
        ),
        debit_account_number: (item) => (
          <td>
            <>
              {item.source
                ? item && item.source && item.source.account_number
                : item && item.external_account_number}
            </>
          </td>
        ),
        debit_bank: (item) => (
          <td>
            <>{item && item.debit_bank && item.debit_bank.bank_name}</>
          </td>
        ),
        debit_account_name: (item) => (
          <td>
            <>
              {item.source
                ? item &&
                  item.source &&
                  item.source.account_name
                    .toString()
                    .replace(/(^\w)|([-\s]\w)/g, (match) => match.toUpperCase())
                : item.external_account_name}
            </>
          </td>
        ),
        created_at: (item) => (
          <td>
            <>{moment(item.created_at).format("DD-MMM-YYYY LTS")}</>
          </td>
        ),
      }}
    />
  );
}

export default TransactionDataTable;
