import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ToastContainer } from "react-toastify";
import '../../security/security.scss'

import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CInputGroup,
  CInputGroupPrepend,
  CInputGroupText,
  CCol,
  CRow,
  CForm,
  CLabel,
  CInput,
  CFormGroup,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import {
  passwordEmailToken,
  resetPassword,
} from "../../../redux/_actions/userActions";

const emailRegEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const ResetPassword = () => {
  const dispatch = useDispatch();
  const [activeSection, setActiveSection] = useState({
    activeSection: "reset-password-email",
  });
  const [email, setEmail] = useState("");
  const [password, set_password] = useState("");
  const [token, set_token] = useState("");
  const [disabled, setDisabled] = useState(true);

  const requestpasswordToken = useSelector(
    (state) => state.requestpasswordToken
  );
  const { loading } = requestpasswordToken;

  const changeSection = (prop) => (event) => {
    event.preventDefault();
    dispatch(passwordEmailToken(email));
    setActiveSection({
      activeSection: prop,
    });
  };
  useEffect(() => {
    if (emailRegEx.test(email)) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [email]);

  const update = (e) => {
    if (e.target.name === "email") {
      setEmail(e.target.value);
    } else if (e.target.name === "password") {
      set_password(e.target.value);
    } else if (e.target.name === "token") {
      set_token(e.target.value);
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(resetPassword(email,
      password,
      token));
  };

  return (
      <div className="c-app c-default-layout flex-row align-items-center">
      <div className="container-sm">
        <CRow className="justify-content-center">
          <CCol md="6" lg="5" className={`${activeSection.activeSection === "reset-password-main" ? "offset-lg-1" : ""} order-2 order-md-1`}>
            <CCardGroup>
              <CCard className="p-4">
                <CCardBody>
                  {/* Start of Reset Password Email Step */}

                  {activeSection.activeSection === "reset-password-email" && (
                    <CForm>
                      <h1>Reset Password</h1>
                      <p className="text-muted"> We will email you a verification code after you click
                      submit.</p>
                      <CInputGroup className="mb-3">
                        <CInputGroupPrepend>
                          <CInputGroupText>
                            <CIcon name="cil-envelope-closed" />
                          </CInputGroupText>
                        </CInputGroupPrepend>
                        <CInput
                          type="email"
                          className="form-control"
                          name="email"
                          onChange={update}
                        />
                      </CInputGroup>
                      <CRow>
                        <CCol xs="12">
                          <CButton 
                            block 
                            color="primary" 
                            className="px-4" 
                            type="submit"
                            onClick={changeSection("reset-password-main")}
                            disabled={disabled}
                          >
                            {loading && <i className="fa fa-ellipsis-h fa-spin"></i>}
                            {loading && (
                              <span>
                                <i className="fa fa-angle-right ml-1"></i>
                              </span>
                            )}
                            {!loading && (
                              <span>
                                Submit
                                <i className="fa fa-angle-right ml-1"></i>
                              </span>
                            )}
                          </CButton>
                        </CCol>
                      </CRow>
                    </CForm>
                  )}

                  {activeSection.activeSection === "reset-password-main" && (
                    <CForm>
                      <h1>Reset your password</h1>
                      <p className="text-muted">We have sent a verification code to your mail, input it in
                    the verification code input below</p>
                      <CFormGroup className="mb-3">
                        <CLabel>Verificiation Code</CLabel>
                        <CInputGroup>
                          <CInputGroupPrepend>
                            <CInputGroupText>
                              <CIcon name="cil-envelope-closed" />
                            </CInputGroupText>
                          </CInputGroupPrepend>
                          <CInput
                            type="number"
                            className="form-control"
                            name="token"
                            onChange={update}
                          />
                        </CInputGroup>
                      </CFormGroup>
                      <CFormGroup className="mb-3">
                        <CLabel>Confirm New Password</CLabel>
                        <CInputGroup>
                          <CInputGroupPrepend>
                            <CInputGroupText>
                              <CIcon name="cil-envelope-closed" />
                            </CInputGroupText>
                          </CInputGroupPrepend>
                          <CInput
                            type="password"
                            className="form-control"
                            name="password"
                            onChange={update}
                          />
                        </CInputGroup>
                      </CFormGroup>
                      
                      <CRow>
                        <CCol xs="12">
                          <CButton 
                            block 
                            color="primary"
                            type="submit"
                            onClick={(e) => submitHandler(e)}
                          >
                            Reset password
                          </CButton>
                        </CCol>
                      </CRow>
                    </CForm>
                  )}
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>

          {activeSection.activeSection === "reset-password-main" && (
            <CCol className="col-12 order-1 order-md-2 offset-lg-1" md="6" lg="5">
              <CCard className="bg-light border ms-md-4">
                <CCardBody>
                  <p className="mb-2 text-body">Password requirements</p>
                  <p className="small text-muted mb-2">
                    To create a new password, you have to meet all of the
                    following requirements:
                  </p>
                  <ul className="small text-muted ps-4 mb-0">
                    <li>Minimum of 8 characters</li>
                    <li>At least one special character</li>
                    <li>At least one number</li>
                    <li>Can't be the same as a previous password</li>
                  </ul>
                </CCardBody>
              </CCard>
            </CCol>
          )}
        </CRow>
      </div>
      <ToastContainer autoClose={5000} />{" "}
    </div>
  );
};

export default ResetPassword;
