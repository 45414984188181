/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { CSVLink } from "react-csv";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CPagination,
  CDropdown,
  CDropdownDivider,
  CDropdownToggle,
  CDropdownMenu,
  CForm,
  CInput,
  CLabel,
  CSelect,
  CModal,
  CModalBody,
  CModalHeader,
  CContainer,
  CSpinner,
} from "@coreui/react";
import { ToastContainer, toast } from "react-toastify";
import CIcon from "@coreui/icons-react";
import AccountsDataTable from "src/components/DataTables/AccountsDataTable";
import PartnersDataTable from "src/components/DataTables/PartnersDataTable";
import { Overlay } from "../../components";
import { sendAccountList } from "src/redux/_actions/accountActions";
import AccountService from "../../redux/_services/AccountService";
import PartnerService from "src/redux/_services/PartnerService";
import { useAccountTypes } from "../../_hooks/accounts";
import { Printer } from "react-feather";

const Accounts = (props) => {
  const [accounts, setaccounts] = useState([]);
  const [pages, set_pages] = useState(1);
  const [filterName, set_filter_name] = useState();
  const [filterVal, set_filter_value] = useState();
  const [query, set_query] = useState();
  const [currentpage, set_currentpage] = useState(1);
  const [prevPageNumber, setPrevPageNumber] = useState("");
  const [lastPage, set_lastpage] = useState("");
  const [searchPage, setSearchPage] = useState("");
  const [searchPageErr, setSearchPageErr] = useState("");
  const [modal, setModal] = useState(false);
  const [accountsStartDate, setAccountsStartDate] = useState("");
  const [accountsEndDate, setAccountsEndDate] = useState(new Date());
  const [sendAccountsError, setSendAccountsError] = useState(false);
  const [accountsEmail, setAccountsEmail] = useState("");
  const [showOverlay, set_showOverlay] = useState(false);

  const history = useHistory();
  const dispatch = useDispatch();

  const toggle = () => {
    setModal(!modal);
  };

  const pageChange = (newPage) => {
    setPrevPageNumber("");
    set_currentpage(newPage);
  };

  const [dates, setDate] = useState({
    startDate: "",
    endDate: "",
  });
 

  let previousPage = sessionStorage.getItem("partnerPage");
  let previousPageQuery = JSON.parse(
    sessionStorage.getItem("partnerPageFilter")
  );
  const pageLink = props.match.url;

  useEffect(() => {
    if (previousPage) {
      setPrevPageNumber(+previousPage);
      set_currentpage(+previousPage);
    }
    if (previousPageQuery) {
      set_query(previousPageQuery.query);
      set_filter_name(previousPageQuery.filterName);
      set_filter_value(previousPageQuery.filterVal);
    }
  }, []);

  const setDateValue = (prop) => (event) => {
    event.preventDefault();
    setDate({
      ...dates,
      [prop]: event.target.value,
    });
    if (event.target.name === "created_between") {
      set_filter_name(event.target.name);
      set_filter_value(`${dates.startDate}, ${dates.endDate}`);
    }
  };
  const refreshFilter = (e) => {
    e.preventDefault();
    set_filter_name();
    set_filter_value();
    set_query();
    sessionStorage.removeItem("partnerPageFilter");
    document.getElementById("create-filter-form").reset();
  };
  const update = (e) => {
    if (e.target.name === "business_name") {
      set_filter_name(e.target.name);
      set_filter_value(e.target.value);
    } else if (e.target.name === "email") {
      set_filter_name(e.target.name);
      set_filter_value(e.target.value);
    } else if (e.target.name === "licenced") {
      set_filter_name(e.target.name);
      set_filter_value(e.target.value);
    } 
  };

  const submitHandler = (e) => {
    e.preventDefault();
    const queries = `filter[${filterName}]=${filterVal}`;
    if (filterName === undefined) {
      set_query();
      set_showOverlay(true);

      PartnerService.getAllPartnersFilter(query,currentpage)
        .then((response) => {
          setaccounts(response.data.data.partners.data);
          set_lastpage(response.data.data.partners.meta.last_page);
          set_pages(response.data.data.partners.meta.last_page);
          set_currentpage(response.data.data.partners.meta.current_page);
        })
        .finally(function () {
          set_showOverlay(false);
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      set_query(queries);
      // set_currentpage(1)
      const filterObj = {
        query: queries,
        filterName: filterName,
        filterVal: filterVal,
      };
      sessionStorage.setItem("partnersPageFilter", JSON.stringify(filterObj));
    }
  };


  const searchPageHandler = (e) => {
    e.preventDefault();
    if (searchPage > lastPage) {
      setSearchPageErr("Selected Number cannot be greater than last page");
      return;
    }
    setSearchPageErr("");
    set_showOverlay(true);
    PartnerService.getAllPartners(searchPage)
      .then((response) => {
        setaccounts(response.data.data.partners.data);
        set_pages(response.data.data.partners.meta.last_page);
        set_lastpage(response.data.data.partners.meta.last_page);
        set_currentpage(response.data.data.partners.meta.current_page);
      })
      .finally(function () {
        set_showOverlay(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  useEffect(() => {
    const retrieveAccountsFilter = () => {
      if (query) {
        previousPage = 1;
        set_showOverlay(true);
        PartnerService.getAllPartnersFilter(query, currentpage)
          .then((response) => {
            setaccounts(response.data.data.partners.data);
            set_pages(response.data.data.partners.meta.last_page);
            set_currentpage(response.data.data.partners.meta.current_page);
          })
          .finally(function () {
            set_showOverlay(false);
          })
          .catch((e) => {
            console.log(e);
          });
      }
    };
    retrieveAccountsFilter();
  }, [query]);

  useEffect(() => {
    const retrieveAccounts = () => {
      set_showOverlay(true);

      if (query) {
        if (currentpage === undefined) {
          set_currentpage(1);
        }
        PartnerService.getAllPartnersFilter(query)
          .then((response) => {
            setaccounts(response.data.data.partners.data);
            set_pages(response.data.data.partners.meta.last_page);
          })
          .finally(function () {
            set_showOverlay(false);
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        PartnerService.getAllPartners(currentpage)
          .then((response) => {
            setaccounts(response.data.data.partners.data);
            set_pages(response.data.data.partners.meta.last_page);
            set_lastpage(response.data.data.partners.meta.last_page);
            if (prevPageNumber) {
              set_currentpage(+prevPageNumber);
            } else {
              set_currentpage(response.data.data.partners.meta.current_page);
            }
          })
          .finally(function () {
            set_showOverlay(false);
          })
          .catch((e) => {
            console.log(e);
          });
      }
    };

    retrieveAccounts();
  }, [currentpage]);

  return (
    <>
      <CRow>
        <Overlay active={showOverlay} marginTop="200px">
          <CCol>
            <CCard>
              <CCardHeader>
                <h4>Partners</h4>
              </CCardHeader>

              <CCardBody>
                <CDropdown variant="dropdown" className="mb-3">
                  <CDropdownToggle
                    color="secondary"
                    size="md"
                    className="px-2 px-md-3"
                  >
                    Advanced Filter
                  </CDropdownToggle>
                  <CDropdownMenu
                    style={{
                      minWidth: "250px",
                      width: "100%",
                      maxWidth: "380px",
                    }}
                  >
                    <h6 className="px-3">Advanced Filter</h6>

                    <CDropdownDivider />
                    <CForm id="create-filter-form">
                      <CRow className="px-3 py-2">
                        <CCol className="col-12 mb-2">
                          <CLabel className="font-weight-bold text-dark w-100">
                            Business Name
                          </CLabel>
                          <CInput
                            type="text"
                            className="w-100"
                            name="business_name"
                            onChange={update}
                          />
                        </CCol>
                        <CCol className="col-12 mb-2">
                          <CLabel className="font-weight-bold text-dark w-100">
                            Email
                          </CLabel>
                          <CInput
                            type="text"
                            className="w-100"
                            name="owner.email"
                            onChange={update}
                          />
                        </CCol>
                     
                        <CCol className="col-12 col-md-6 mb-2">
                          <CLabel className="font-weight-bold text-dark w-100">
                            Start Date
                          </CLabel>
                          <CInput
                            type="date"
                            className="w-100"
                            name="created_between"
                            onChange={setDateValue("startDate")}
                          />
                        </CCol>

                        <CCol className="col-12 col-md-6 mb-2">
                          <CLabel className="font-weight-bold text-dark w-100">
                            End Date
                          </CLabel>
                          <CInput
                            type="date"
                            className="w-100"
                            name="created_between"
                            onChange={setDateValue("endDate")}
                          />
                        </CCol>
                      </CRow>
                      <CDropdownDivider />
                      <div className="d-flex align-items-center justify-content-between px-3 py-1">
                        <a
                          className="cursor-pointer text-primary"
                          role="button"
                          onClick={(e) => refreshFilter(e)}
                        >
                          Reset Filter
                        </a>
                        <CButton
                          color="dark"
                          type="submit"
                          onClick={(e) => submitHandler(e)}
                        >
                          Submit
                        </CButton>
                      </div>
                    </CForm>
                  </CDropdownMenu>
                </CDropdown>

                <PartnersDataTable
                  items={accounts}
                  page={currentpage}
                  loading={showOverlay}
                  itemsPerPage={30}
                />

                <CPagination
                  activePage={currentpage}
                  onActivePageChange={pageChange}
                  pages={pages}
                  doubleArrows={true}
                  align="end"
                />
                <div className="d-flex justify-content-end">
                  <div className="d-flex justify-content-end w-25">
                    <CInput
                      type="number"
                      className=" ml-2 w-25"
                      onChange={(e) => setSearchPage(e.target.value)}
                    />
                    <CButton
                      color="primary"
                      className="ml-2"
                      onClick={searchPageHandler}
                    >
                      Search
                    </CButton>
                  </div>
                </div>
                <p className="d-flex justify-content-end text-danger">
                  {searchPageErr}
                </p>
                <p className="d-flex justify-content-end text-muted">
                  search by page number
                </p>
              </CCardBody>
            </CCard>
          </CCol>
        </Overlay>
      </CRow>
    </>
  );
};

export default Accounts;
