/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { CSVLink } from "react-csv";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CPagination,
  CDropdown,
  CDropdownDivider,
  CDropdownToggle,
  CDropdownMenu,
  CForm,
  CInput,
  CLabel,
  CSelect,
  CModal,
  CModalBody,
  CModalHeader,
  CContainer,
  CSpinner,
} from "@coreui/react";
import { ToastContainer, toast } from "react-toastify";
import CIcon from "@coreui/icons-react";
import AccountsDataTable from "src/components/DataTables/AccountsDataTable";
import { Overlay } from "../../components";
import { sendAccountList } from "src/redux/_actions/accountActions";
import AccountService from "../../redux/_services/AccountService";
import { useAccountTypes } from "../../_hooks/accounts";
import { Printer } from "react-feather";

const Accounts = (props) => {
  const [accounts, setaccounts] = useState([]);
  const [pages, set_pages] = useState(1);
  const [filterName, set_filter_name] = useState();
  const [filterVal, set_filter_value] = useState();
  const [query, set_query] = useState();
  const [currentpage, set_currentpage] = useState();
  const [prevPageNumber, setPrevPageNumber] = useState("");
  const [lastPage, set_lastpage] = useState("");
  const [searchPage, setSearchPage] = useState("");
  const [searchPageErr, setSearchPageErr] = useState("");
  const [modal, setModal] = useState(false);
  const [accountsStartDate, setAccountsStartDate] = useState("");
  const [accountsEndDate, setAccountsEndDate] = useState(new Date());
  const [sendAccountsError, setSendAccountsError] = useState(false);
  const [accountsEmail, setAccountsEmail] = useState("");
  const [showOverlay, set_showOverlay] = useState(false);

  const [account_types_data, fetchAccountTypesData] = useAccountTypes();
  useEffect(() => {
    fetchAccountTypesData();
  }, [fetchAccountTypesData]);
  const savingsId =
    account_types_data && account_types_data[1] && account_types_data[1].id;
  const businessId =
    account_types_data && account_types_data[0] && account_types_data[0].id;

  const accountListLoading = useSelector((state) => state.accountList.loading);

  const history = useHistory();
  const dispatch = useDispatch();

  const toggle = () => {
    setModal(!modal);
  };

  const pageChange = (newPage) => {
    setPrevPageNumber("");
    set_currentpage(newPage);
  };

  const [dates, setDate] = useState({
    startDate: "",
    endDate: "",
  });
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;
  const userRole =
    userInfo &&
    userInfo.data &&
    userInfo.data.user &&
    userInfo.data.user.roles &&
    userInfo.data.user.roles[0].name;

  let previousPage = sessionStorage.getItem("accountPage");
  let previousPageQuery = JSON.parse(
    sessionStorage.getItem("accountPageFilter")
  );
  const pageLink = props.match.url;

  useEffect(() => {
    if (previousPage) {
      setPrevPageNumber(+previousPage);
      set_currentpage(+previousPage);
    }
    if (previousPageQuery) {
      set_query(previousPageQuery.query);
      set_filter_name(previousPageQuery.filterName);
      set_filter_value(previousPageQuery.filterVal);
    }
  }, []);

  const setDateValue = (prop) => (event) => {
    event.preventDefault();
    setDate({
      ...dates,
      [prop]: event.target.value,
    });
    if (event.target.name === "created_between") {
      set_filter_name(event.target.name);
      set_filter_value(`${dates.startDate}, ${dates.endDate}`);
    }
  };
  const refreshFilter = (e) => {
    e.preventDefault();
    set_filter_name();
    set_filter_value();
    set_query();
    sessionStorage.removeItem("accountPageFilter");
    document.getElementById("create-filter-form").reset();
  };
  const update = (e) => {
    if (e.target.name === "name") {
      set_filter_name(e.target.name);
      set_filter_value(e.target.value);
    } else if (e.target.name === "account_number") {
      set_filter_name(e.target.name);
      set_filter_value(e.target.value);
    } else if (e.target.name === "type.name") {
      set_filter_name(e.target.name);
      set_filter_value(e.target.value);
    } else if (e.target.name === "status") {
      set_filter_name(e.target.name);
      set_filter_value(e.target.value);
    } else if (e.target.name === "post_no_debit") {
      set_filter_name(e.target.name);
      set_filter_value(e.target.value);
    } else if (e.target.name === "trashed") {
      set_filter_name(e.target.name);
      set_filter_value(e.target.value);
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    const queries = `filter[${filterName}]=${filterVal}`;
    if (filterName === undefined) {
      set_query();
      set_showOverlay(true);

      AccountService.getAllAccounts(currentpage)
        .then((response) => {
          setaccounts(response.data.data.accounts.data);
          set_lastpage(response.data.data.accounts.meta.last_page);
          set_pages(response.data.data.accounts.meta.last_page);
          set_currentpage(response.data.data.accounts.meta.current_page);
        })
        .finally(function () {
          set_showOverlay(false);
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      set_query(queries);
      // set_currentpage(1)
      const filterObj = {
        query: queries,
        filterName: filterName,
        filterVal: filterVal,
      };
      sessionStorage.setItem("accountPageFilter", JSON.stringify(filterObj));
    }
  };

  const sendAccountListHandler = (e) => {
    e.preventDefault();
    const payload = {
      start_date: accountsStartDate
        ? accountsStartDate.toISOString().slice(0, 10)
        : "",
      end_date: accountsEndDate
        ? accountsEndDate.toISOString().slice(0, 10)
        : "",

      email: accountsEmail,
    };
    dispatch(sendAccountList(payload));
  };

  const searchPageHandler = (e) => {
    e.preventDefault();
    if (searchPage > lastPage) {
      setSearchPageErr("Selected Number cannot be greater than last page");
      return;
    }
    setSearchPageErr("");
    set_showOverlay(true);
    AccountService.getAllAccounts(searchPage)
      .then((response) => {
        setaccounts(response.data.data.accounts.data);
        set_pages(response.data.data.accounts.meta.last_page);
        set_lastpage(response.data.data.accounts.meta.last_page);
        set_currentpage(response.data.data.accounts.meta.current_page);
      })
      .finally(function () {
        set_showOverlay(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  useEffect(() => {
    const retrieveAccountsFilter = () => {
      if (query) {
        previousPage = 1;
        set_showOverlay(true);
        AccountService.getAllAccountsFilter(query, currentpage)
          .then((response) => {
            setaccounts(response.data.data.accounts.data);
            set_pages(response.data.data.accounts.meta.last_page);
            set_currentpage(response.data.data.accounts.meta.current_page);
          })
          .finally(function () {
            set_showOverlay(false);
          })
          .catch((e) => {
            console.log(e);
          });
      }
    };
    retrieveAccountsFilter();
  }, [query]);

  useEffect(() => {
    const retrieveAccounts = () => {
      set_showOverlay(true);

      if (query) {
        if (currentpage == undefined) {
          set_currentpage(1);
        }
        AccountService.getAllAccountsFilter(query)
          .then((response) => {
            setaccounts(response.data.data.accounts.data);
            set_pages(response.data.data.accounts.meta.last_page);
          })
          .finally(function () {
            set_showOverlay(false);
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        AccountService.getAllAccounts(currentpage)
          .then((response) => {
            setaccounts(response.data.data.accounts.data);
            set_pages(response.data.data.accounts.meta.last_page);
            set_lastpage(response.data.data.accounts.meta.last_page);
            if (prevPageNumber) {
              set_currentpage(+prevPageNumber);
            } else {
              set_currentpage(response.data.data.accounts.meta.current_page);
            }
          })
          .finally(function () {
            set_showOverlay(false);
          })
          .catch((e) => {
            console.log(e);
          });
      }
    };

    retrieveAccounts();
  }, [currentpage]);

  return (
    <>
      <CRow>
        <Overlay active={showOverlay} marginTop="200px">
          <CCol>
            <CCard>
              <CCardHeader>
                <div className="d-flex justify-content-between align-items-center">
                  <h4>Accounts</h4>
                  <div>
                    {(userRole === "super-admin" ||
                      userRole === "admin" ||
                      userRole === "treasury" ||
                      userRole === "internal-control" ||
                      userRole === "customer-experience") && (
                      <Printer
                        size={16}
                        color="blue"
                        onClick={toggle}
                        className="mr-3 mt-2"
                      />
                    )}

                    <CModal
                      show={modal}
                      onClose={toggle}
                      centered
                      fade
                      size="lg"
                    >
                      <CModalHeader closeButton className="text-danger">
                        CANCEL
                      </CModalHeader>
                      <CModalBody>
                        <CContainer>
                          <CRow className="justify-content-center">
                            <CCol>
                              <CCard className="mx-4">
                                <CCardBody className="">
                                  <CForm>
                                    <CRow className="mt-4">
                                      <CCol
                                        sm="10"
                                        className="mx-auto px-0 px-md-3 mt-5"
                                      >
                                        <div>
                                          <h4 className="mb-3">
                                            {" "}
                                            Export Excel
                                          </h4>{" "}
                                        </div>{" "}
                                        <p className="text-danger">
                                          {" "}
                                          If start date and end date is blank.
                                          All accounts are exported to CSV
                                        </p>{" "}
                                        <div className="mb-4">
                                          <CLabel className="font-weight-bold text-dark w-100">
                                            Start Date
                                          </CLabel>
                                          <DatePicker
                                            dateFormat="dd-MM-yyyy"
                                            className="form-control"
                                            selected={accountsStartDate}
                                            isClearable
                                            placeholderText="DD/MM/YY"
                                            maxDate={accountsEndDate}
                                            peekNextMonth
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            selectsStart
                                            startDate={accountsStartDate}
                                            endDate={accountsEndDate}
                                            onChange={(date) => {
                                              setAccountsStartDate(date);
                                            }}
                                          />
                                          <p className="text-danger">
                                            {" "}
                                            If start date is not filled, date
                                            defaults to oldest date.{" "}
                                          </p>{" "}
                                        </div>
                                        <div className="mb-4">
                                          <CLabel className="font-weight-bold text-dark w-100">
                                            End Date
                                          </CLabel>
                                          <DatePicker
                                            className="form-control"
                                            dateFormat="dd-MM-yyyy"
                                            isClearable
                                            placeholderText="DD/MM/YY"
                                            selected={accountsEndDate}
                                            maxDate={new Date()}
                                            peekNextMonth
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            selectsEnd
                                            startDate={accountsStartDate}
                                            // endDate={accountsEndDate}
                                            minDate={accountsStartDate}
                                            onChange={(date) =>
                                              setAccountsEndDate(date)
                                            }
                                          />
                                          <p className="text-danger">
                                            {" "}
                                            If end date is not filled, date
                                            defaults present to date.{" "}
                                          </p>{" "}
                                        </div>
                                        <div className="mb-4">
                                          <CLabel className="font-weight-bold text-dark w-100">
                                            Email
                                          </CLabel>
                                          <CInput
                                            type="email"
                                            onChange={(e) =>
                                              setAccountsEmail(e.target.value)
                                            }
                                            placeholder="Enter Email"
                                          />
                                          <p className="text-danger">
                                            {" "}
                                            Fill if you need to generate user
                                            csv report referred by a user.
                                          </p>{" "}
                                        </div>
                                        {sendAccountsError ? (
                                          <p className="text-danger">
                                            Start Date, End Date and Email must
                                            be filled
                                          </p>
                                        ) : (
                                          ""
                                        )}
                                        <div className="mt-4">
                                          {accountListLoading ? (
                                            <>
                                              <CButton
                                                color="success"
                                                type="submit"
                                                block
                                              >
                                                <CSpinner
                                                  color="white"
                                                  size="sm"
                                                />{" "}
                                                Requesting...
                                              </CButton>
                                            </>
                                          ) : (
                                            <CButton
                                              color="success"
                                              type="submit"
                                              block
                                              onClick={(e) =>
                                                sendAccountListHandler(e)
                                              }
                                            >
                                              Submit Request
                                            </CButton>
                                          )}
                                        </div>
                                      </CCol>
                                    </CRow>
                                  </CForm>
                                </CCardBody>
                              </CCard>
                            </CCol>
                          </CRow>
                          <ToastContainer autoClose={5000} />{" "}
                        </CContainer>
                      </CModalBody>
                    </CModal>
                  </div>
                </div>
              </CCardHeader>

              <CCardBody>
                <CDropdown variant="dropdown" className="mb-3">
                  <CDropdownToggle
                    color="secondary"
                    size="md"
                    className="px-2 px-md-3"
                  >
                    Advanced Filter
                  </CDropdownToggle>
                  <CDropdownMenu
                    style={{
                      minWidth: "250px",
                      width: "100%",
                      maxWidth: "380px",
                    }}
                  >
                    <h6 className="px-3">Advanced Filter</h6>
                    <CDropdownDivider />
                    <CForm id="create-filter-form">
                      <CRow className="px-3 py-2">
                        <CCol className="col-12 mb-2">
                          <CLabel className="font-weight-bold text-dark w-100">
                            Account Name
                          </CLabel>
                          <CInput
                            type="text"
                            className="w-100"
                            name="name"
                            onChange={update}
                            value={filterName !== "name" ? "" : filterVal}
                          />
                        </CCol>

                        <CCol className="col-12 mb-2">
                          <CLabel className="font-weight-bold text-dark w-100">
                            Account Number
                          </CLabel>
                          <CInput
                            type="text"
                            className="w-100"
                            name="account_number"
                            value={
                              filterName !== "account_number" ? "" : filterVal
                            }
                            onChange={update}
                          />
                        </CCol>

                        <CCol className="col-12 mb-2">
                          <CLabel className="font-weight-bold text-dark w-100">
                            Account Type
                          </CLabel>
                          <CSelect
                            className="w-100"
                            name="type.name"
                            onChange={update}
                            defaultValue={filterVal}
                          >
                            {filterName === "type.name" ? (
                              <option value={filterVal}>
                                {filterVal === ""
                                  ? "All"
                                  : filterVal === "savings"
                                  ? "Savings"
                                  : "Business"}
                              </option>
                            ) : (
                              <option value="">All</option>
                            )}
                            {filterVal !== "savings" && (
                              <option value="savings">Savings</option>
                            )}
                            {filterVal !== "business" && (
                              <option value="business">Business</option>
                            )}
                          </CSelect>
                        </CCol>

                        <CCol className="col-12 mb-2">
                          <CLabel className="font-weight-bold text-dark w-100">
                            Account Status
                          </CLabel>
                          <CSelect
                            className="w-100"
                            name="status"
                            onChange={update}
                            defaultValue={filterVal}
                          >
                            {filterName === "status" ? (
                              <option value={filterVal}>
                                {filterVal === ""
                                  ? "All"
                                  : filterVal === "active"
                                  ? "Active"
                                  : "Inactive"}
                              </option>
                            ) : (
                              <option value="">All</option>
                            )}
                            {filterVal !== "active" && (
                              <option value="active">Active</option>
                            )}
                            {filterVal !== "inactive" && (
                              <option value="inactive">Inactive</option>
                            )}
                          </CSelect>
                        </CCol>
                        <CCol className="col-12 mb-2">
                          <CLabel className="font-weight-bold text-dark w-100">
                            Account State
                          </CLabel>
                          <CSelect
                            className="w-100"
                            name="trashed"
                            onChange={update}
                            defaultValue={filterVal}
                          >
                            {filterName === "trashed" ? (
                              <option value={filterVal}>
                                {filterVal === "only"
                                  ? "Deleted"
                                  : filterVal === "with"
                                  ? "All"
                                  : "Available"}
                              </option>
                            ) : (
                              <option value="with">All</option>
                            )}
                            {filterVal !== "available" && (
                              <option value="available">Available</option>
                            )}
                            {filterVal !== "only" && (
                              <option value="only">Deleted</option>
                            )}
                          </CSelect>
                        </CCol>
                        <CCol className="col-12 col-md-6 mb-2">
                          <CLabel className="font-weight-bold text-dark w-100">
                            Start Date
                          </CLabel>
                          <CInput
                            type="date"
                            className="w-100"
                            value={
                              filterName !== "created_between"
                                ? ""
                                : dates.startDate
                            }
                            // value={dates.startDate}
                            name="created_between"
                            onChange={setDateValue("startDate")}
                          />
                        </CCol>

                        <CCol className="col-12 col-md-6 mb-2">
                          <CLabel className="font-weight-bold text-dark w-100">
                            End Date
                          </CLabel>
                          <CInput
                            type="date"
                            className="w-100"
                            // value={dates.endDate}
                            value={
                              filterName !== "created_between"
                                ? ""
                                : dates.endDate
                            }
                            name="created_between"
                            onChange={setDateValue("endDate")}
                          />
                        </CCol>
                      </CRow>
                      <CDropdownDivider />
                      <div className="d-flex align-items-center justify-content-between px-3 py-1">
                        <a
                          className="cursor-pointer text-primary"
                          role="button"
                          onClick={(e) => refreshFilter(e)}
                        >
                          Reset Filter
                        </a>
                        <CButton
                          color="dark"
                          type="submit"
                          onClick={(e) => submitHandler(e)}
                        >
                          Submit
                        </CButton>
                      </div>
                    </CForm>
                  </CDropdownMenu>
                </CDropdown>

                <AccountsDataTable
                  items={accounts}
                  page={currentpage}
                  loading={showOverlay}
                  itemsPerPage={30}
                />

                <CPagination
                  activePage={currentpage}
                  onActivePageChange={pageChange}
                  pages={pages}
                  doubleArrows={true}
                  align="end"
                />
                <div className="d-flex justify-content-end">
                  <div className="d-flex justify-content-end w-25">
                    <CInput
                      type="number"
                      className=" ml-2 w-25"
                      onChange={(e) => setSearchPage(e.target.value)}
                    />
                    <CButton
                      color="primary"
                      className="ml-2"
                      onClick={searchPageHandler}
                    >
                      Search
                    </CButton>
                  </div>
                </div>
                <p className="d-flex justify-content-end text-danger">
                  {searchPageErr}
                </p>
                <p className="d-flex justify-content-end text-muted">
                  search by page number
                </p>
              </CCardBody>
            </CCard>
          </CCol>
        </Overlay>
      </CRow>
    </>
  );
};

export default Accounts;
