import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

const LoadingSpinner = (props) => (
  <div className={classNames("-loading", { "active-active": props.loading })}>
    {/* <div id="spinner">
      <i
        className="fa fa-spinner fa-pulse fa-3x fa-fw"
        style={{ fontSize: 36, color: "#ef6c00" }}
      />
    </div> */}
  </div>
);

LoadingSpinner.propTypes = {
  loading: PropTypes.bool,
};

export default LoadingSpinner;
