import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CButton,
  CCard,
  CCardBody,
  CCol,
  CContainer,
  CForm,
  CInput,
  CInputGroup,
  CInputGroupPrepend,
  CInputGroupText,
  CRow,
  CModal,
  CModalHeader,
  CModalBody,
  CSelect,
  CPagination,
  CCardHeader,
  CSpinner,
  CDropdown,
  CDropdownDivider,
  CDropdownToggle,
  CDropdownMenu,
  CLabel,
  CMediaBody,
  CListGroup,
  CListGroupItem,
} from "@coreui/react";
import { Overlay } from "../../components";
import { Printer, ChevronLeft } from "react-feather";
import AdminDataTable from "src/components/DataTables/AdminDataTable";
import CIcon from "@coreui/icons-react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CSVLink } from "react-csv";
import moment from "moment";
import { useHistory, useLocation } from "react-router-dom";
import { contentSuccess, contentError } from "../../_utils/api";
import { createAccount } from "src/redux/_actions/adminActions";
import {
  // getRoles,
  getTeams,
  switchTeams,
  getTeamLead,
} from "src/redux/_services/adminService";
import TeamLeadDataTable from "src/components/DataTables/TeamLeadDataTable";

const Register = (props) => {
  const [showOverlay, set_showOverlay] = useState(false);
  const [loading, setLoading] = useState("");
  const [currentpage, set_currentpage] = useState();
  const [lastPage, set_lastpage] = useState("");
  const [teamLead, setTeamLead] = useState("");
  const history = useHistory();

  const pageChange = (newPage) => {
    set_currentpage(newPage);
  };

  useEffect(() => {
    const retrieveTeams = () => {
      set_showOverlay(true);
      setLoading(true);
      getTeamLead()
        .then((res) => {
          setTeamLead(res.data.data.managers.data);
        })
        .finally(() => {
          set_showOverlay(false);
          setLoading(false);
        })
        .catch((err) => {});
    };

    retrieveTeams();
  }, []);

  if (loading) {
    return (
      <CSpinner
        color="primary"
        style={{
          width: "4rem",
          height: "4rem",
          marginTop: "300px",
          marginLeft: "600px",
        }}
      />
    );
  }

  return (
    <>
      <div className="d-flex justify-content-end">
        <CButton onClick={history.goBack} className="px-4 mb-4" color="success">
          <ChevronLeft />
          Back
        </CButton>
      </div>
      <div className="bg-white p-3">
        <div className="d-flex justify-content-between align-items-center p-3">
          <h4 className="text-dark">Managers</h4>
        </div>

        <CRow>
          <TeamLeadDataTable
            items={teamLead}
            loading={showOverlay}
            itemsPerPage={30}
          />
          {/* <div className="p-3  col-sm-12">
            <CPagination
              activePage={currentpage}
              onActivePageChange={pageChange}
              pages={lastPage}
              doubleArrows={false}
              align="end"
            />
          </div> */}
        </CRow>
      </div>
    </>
  );
};

export default Register;
