import {
  CButton,
  CForm,
  CInput,
  CLabel,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CSelect,
} from "@coreui/react";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const editEnableOptions = [
  { label: "Disabled", value: false },
  { label: "Enabled", value: true },
];

export const UpdateAccount = (props) => {
  const {
    show,
    onClose,
    interestStatus,
    tranferLimitStatus,
    password,
    loadingUpdate,
    allowLimits,
    transferLimits,
    allowLimitsChange,
    handlePasswordChange,
    submitAccountHandler,
    interestEnabledChange,
    transferLimitStatusChange,
    transferLimitChange,
    changeAccountNameHandler,
    changeName,
    changingName,
  } = props;

  const handleSaveName = () => {
    changeAccountNameHandler();
    toast.success('Account name has been saved!');
  };

  return (
    <CModal show={show} onClose={onClose} centered>
      <CModalHeader closeButton>Edit Account</CModalHeader>
      <CForm>
        <CModalBody>
          <div className="mb-3">
            <CLabel htmlFor="interestStatusSelect">Interest Status</CLabel>
            <CSelect
              id="interestStatusSelect"
              name="interest_status"
              defaultValue={interestStatus}
              onChange={interestEnabledChange}
            >
              {editEnableOptions.map((item, key) => (
                <option key={key} value={item.value}>
                  {item.label}
                </option>
              ))}
            </CSelect>
          </div>
          <div className="mb-3">
            <CLabel htmlFor="transferLimitStatusSelect">
              Transfer Limit Status
            </CLabel>
            <CSelect
              id="transferLimitStatusSelect"
              name="transfer_limit_status"
              defaultValue={tranferLimitStatus}
              onChange={transferLimitStatusChange}
            >
              {editEnableOptions.map((item) => (
                <option value={item.value} key={item.label}>
                  {item.label}
                </option>
              ))}
            </CSelect>
          </div>
          {tranferLimitStatus ? (
            <>
              <div className="mb-3">
                <div className="d-flex justify-content-between mb-2">
                  <CLabel htmlFor="dailyTransferLimitAmount">
                    Daily Transfer Limit Amount
                  </CLabel>
                  <label className="c-switch c-switch-pill c-switch-opposite-primary">
                    <input
                      type="checkbox"
                      name="daily"
                      onChange={allowLimitsChange}
                      className="c-switch-input"
                      checked={allowLimits.daily}
                    />
                    <span className="c-switch-slider"></span>
                  </label>
                </div>
                <CInput
                  type="number"
                  min="1"
                  step="1"
                  id="dailyTransferLimitAmount"
                  placeholder="Enter Amount"
                  name="daily_transfer_limit"
                  onChange={transferLimitChange}
                  defaultValue={transferLimits.daily_transfer_limit}
                ></CInput>
              </div>
              <div className="mb-3">
                <div className="d-flex justify-content-between mb-2">
                  <CLabel htmlFor="weeklyTransferLimitAmount">
                    Weekly Transfer Limit Amount
                  </CLabel>
                  <label className="c-switch c-switch-pill c-switch-opposite-primary">
                    <input
                      type="checkbox"
                      name="weekly"
                      onChange={allowLimitsChange}
                      className="c-switch-input"
                      checked={allowLimits.weekly}
                    />
                    <span className="c-switch-slider"></span>
                  </label>
                </div>
                <CInput
                  type="number"
                  min="1"
                  step="1"
                  id="weeklyTransferLimitAmount"
                  placeholder="Enter Amount"
                  name="weekly_transfer_limit"
                  onChange={transferLimitChange}
                  defaultValue={transferLimits.weekly_transfer_limit}
                ></CInput>
              </div>
              <div className="mb-3">
                <div className="d-flex justify-content-between mb-2">
                  <CLabel htmlFor="monthlyTransferLimitAmount">
                    Monthly Transfer Limit Amount
                  </CLabel>
                  <label className="c-switch c-switch-pill c-switch-opposite-primary">
                    <input
                      type="checkbox"
                      name="monthly"
                      onChange={allowLimitsChange}
                      className="c-switch-input"
                      checked={allowLimits.monthly}
                    />
                    <span className="c-switch-slider"></span>
                  </label>
                </div>
                <CInput
                  type="number"
                  min="1"
                  step="1"
                  id="monthlyTransferLimitAmount"
                  placeholder="Enter Amount"
                  name="monthly_transfer_limit"
                  onChange={transferLimitChange}
                  defaultValue={transferLimits.monthly_transfer_limit}
                ></CInput>
              </div>
            </>
          ) : null}
          <div className="mb-3">
            <CLabel htmlFor="editPassword">Password</CLabel>
            <CInput
              id="editPassword"
              name="password"
              type="password"
              value={password}
              placeholder="Enter Password"
              onChange={handlePasswordChange}
            ></CInput>
          </div>
          <div className="mb-3">
            <CLabel htmlFor="changeName">Change Account Name</CLabel>
            <CInput
              type="text"
              id="changeName"
              placeholder="Enter new account name"
              name="changeName"
              onChange={changingName}
              value={changeName} // Use value instead of defaultValue to be controlled
            ></CInput>
            <CButton onClick={handleSaveName} color="success" block>
              Save name
            </CButton>
          </div>
        </CModalBody>
        <CModalFooter>
          {password === "" ? (
            <CButton color="secondary" disabled block>
              Yes I am sure, I want to update this account
            </CButton>
          ) : (
            <CButton
              color="success"
              variant="outline"
              block
              onClick={submitAccountHandler}
            >
              {loadingUpdate && <i className="fa fa-ellipsis-h fa-spin"></i>}
              {loadingUpdate && (
                <span>
                  <i className="fa fa-angle-right ml-1"></i>
                </span>
              )}
              {!loadingUpdate && (
                <span>
                  Yes I am sure, I want to update this account
                  <i className="fa fa-angle-right ml-1"></i>
                </span>
              )}
            </CButton>
          )}
        </CModalFooter>
      </CForm>
      <ToastContainer />
    </CModal>
  );
};
