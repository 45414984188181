import { axiosWithAuth } from "../../_utils/api";

export const getAllMessages = async () => {
  return await axiosWithAuth({
    url: `/admin/messages`,
    method: "get",
  });
};

export const getEmailTokens = async (page) => {
  if (!page) {
    return await axiosWithAuth({
      url: `/admin/tokens`,
      method: "get",
    });
  } else {
    return await axiosWithAuth({
      url: `/admin/tokens?page%5Bnumber%5D=${page}`,
      method: "get",
    });
  }
};

export const searchEmailTokens = async (email) => {
 
    return await axiosWithAuth({
      url: `/admin/tokens?filter[email]=${email}`,
      method: "get",
    });
  
};


export const messagePage = async (payload) => {
  return await axiosWithAuth({
    url: payload,
    method: "get",
  });
};

export const messageSearch = async (payload, type) => {
  if (type === "telephone") {
    return await axiosWithAuth({
      url: `/admin/messages/recipient/messages?telephone=null&telephone=${payload}`,
      method: "get",
    });
  } else {
    return await axiosWithAuth({
      url: `/admin/messages/status?status=${payload}`,
      method: "get",
    });
  }
};

export const sendMessageDate = async (payload) => {
  return axiosWithAuth({
    url: `/admin/marketting-mail/store`,
    method: "post",
    data: payload,
  });
};

export const prepareAccountMail = async () => {
  return axiosWithAuth({
    url: `/admin/marketting-mail`,
    method: "get",
  });
};
