/* eslint-disable react/prop-types */
import { toast } from "react-toastify";
import {
  ADD_INVESTMENT_REQUEST,
  ADD_INVESTMENT_SUCCESS,
  ADD_INVESTMENT_FAIL,
  INVESTMENT_TOPUP_REQUEST,
  INVESTMENT_TOPUP_SUCCESS,
  INVESTMENT_TOPUP_FAIL,
  SAVE_INVESTMENT_ACCOUNT,
} from "../_constants/investmentConstants";
import {
  axiosWithAuth,
  selectToken,
  contentSuccess,
  contentError,
} from "../../_utils/api";

const saveInvestmentAccount = (data) => (dispatch) => {
  try {
    dispatch({ type: SAVE_INVESTMENT_ACCOUNT, payload: data });
  } catch (error) {
    // error.message;
  }
};

const InvestmentTopUp = (amount, pin, source_account, id) => async (
  dispatch
) => {
  dispatch({
    type: INVESTMENT_TOPUP_REQUEST,
    payload: {
      amount,
      pin,
      source_account,
    },
  });
  try {
   
    const { data } = await axiosWithAuth.patch(
      `/admin/investments/${id}/top-up`,
      {
        amount,
        pin,
        source_account,
      }
    );
    dispatch({ type: INVESTMENT_TOPUP_SUCCESS, payload: data });
    toast(contentSuccess);
  } catch (error) {
    dispatch({ type: INVESTMENT_TOPUP_FAIL, payload: error.message });
    toast(contentError(error.response.data.error.message));
  }
};

const CreateInvestmentAccounts = (
  amount,
  beneficiary_account,
  pin,
  investment_type,
  source_account,
  start_date,
  title
) => async (dispatch) => {
  dispatch({
    type: ADD_INVESTMENT_REQUEST,
    payload: {
      amount,
      beneficiary_account,
      pin,
      investment_type,
      source_account,
      start_date,
      title,
    },
  });
  try {
   
    const { data } = await axiosWithAuth.post("/admin/investments", {
      amount,
      beneficiary_account,
      pin,
      investment_type,
      source_account,
      start_date,
      title,
    });
    dispatch({ type: ADD_INVESTMENT_SUCCESS, payload: data });
    toast(contentSuccess);
  } catch (error) {
    dispatch({ type: ADD_INVESTMENT_FAIL, payload: error.message });
    toast.error(contentError(error.message));
  }
};

const CreateInvestmentAccountsRollover = (
  amount,
  beneficiary_account,
  pin,
  investment_type,
  source_account,
  title
) => async (dispatch) => {
  dispatch({
    type: ADD_INVESTMENT_REQUEST,
    payload: {
      amount,
      beneficiary_account,
      pin,
      investment_type,
      source_account,
      title,
    },
  });
  try {
   
    const { data } = await axiosWithAuth.post("/admin/investments", {
      amount,
      beneficiary_account,
      pin,
      investment_type,
      source_account,
      title,
    });
    dispatch({ type: ADD_INVESTMENT_SUCCESS, payload: data });
    toast(contentSuccess);
  } catch (error) {
    dispatch({ type: ADD_INVESTMENT_FAIL, payload: error.message });
    toast(contentError(error.message));
  }
};

const CreateInvestmentAccountsWRollover = (
  amount,
  beneficiary_account,
  pin,
  rollover,
  rollover_type,
  investment_type,
  source_account,
  start_date,
  title
) => async (dispatch) => {
  dispatch({
    type: ADD_INVESTMENT_REQUEST,
    payload: {
      amount,
      beneficiary_account,
      pin,
      rollover,
      rollover_type,
      investment_type,
      source_account,
      start_date,
      title,
    },
  });
  try {
   
    const { data } = await axiosWithAuth.post("/admin/investments", {
      amount,
      beneficiary_account,
      pin,
      rollover,
      rollover_type,
      investment_type,
      source_account,
      start_date,
      title,
    });
    dispatch({ type: ADD_INVESTMENT_SUCCESS, payload: data });
    toast(contentSuccess);
  } catch (error) {
    dispatch({ type: ADD_INVESTMENT_FAIL, payload: error.message });
    toast(contentError(error.message));
  }
};

const CreateInvestmentStandardCWRollover = (
  amount,
  beneficiary_account,
  duration,
  interest_rate,
  pin,
  rollover,
  rollover_type,
  source_account,
  start_date,
  title
) => async (dispatch) => {
  dispatch({
    type: ADD_INVESTMENT_REQUEST,
    payload: {
      amount,
      beneficiary_account,
      duration,
      interest_rate,
      pin,
      rollover,
      rollover_type,
      source_account,
      start_date,
      title,
    },
  });
  try {
   
    const { data } = await axiosWithAuth.post("/admin/investments", {
      amount,
      beneficiary_account,
      duration,
      interest_rate,
      pin,
      rollover,
      rollover_type,
      source_account,
      start_date,
      title,
    });
    dispatch({ type: ADD_INVESTMENT_SUCCESS, payload: data });
    toast(contentSuccess);
  } catch (error) {
    dispatch({ type: ADD_INVESTMENT_FAIL, payload: error.message });
    toast(contentError(error.message));
  }
};

const CreateInvestmentCustom = (
  amount,
  beneficiary_account,
  duration,
  interest_rate,
  pin,
  source_account,
  start_date,
  title
) => async (dispatch) => {
  dispatch({
    type: ADD_INVESTMENT_REQUEST,
    payload: {
      amount,
      beneficiary_account,
      duration,
      interest_rate,
      pin,
      source_account,
      start_date,
      title,
    },
  });
  try {
   
    const { data } = await axiosWithAuth.post("/admin/investments", {
      amount,
      beneficiary_account,
      duration,
      interest_rate,
      pin,
      source_account,
      start_date,
      title,
    });
    dispatch({ type: ADD_INVESTMENT_SUCCESS, payload: data });
    toast(contentSuccess);
  } catch (error) {
    dispatch({ type: ADD_INVESTMENT_FAIL, payload: error.message });
    toast(contentError(error.message));
  }
};

const CreateInvestmentWDateRollover = (
  amount,
  beneficiary_account,
  pin,
  rollover,
  rollover_type,
  investment_type,
  source_account,
  title
) => async (dispatch) => {
  dispatch({
    type: ADD_INVESTMENT_REQUEST,
    payload: {
      amount,
      beneficiary_account,
      pin,
      rollover,
      rollover_type,
      investment_type,
      source_account,
      title,
    },
  });
  try {
   
    const { data } = await axiosWithAuth.post("/admin/investments", {
      amount,
      beneficiary_account,
      pin,
      rollover,
      rollover_type,
      investment_type,
      source_account,
      title,
    });
    dispatch({ type: ADD_INVESTMENT_SUCCESS, payload: data });
    toast(contentSuccess);
  } catch (error) {
    dispatch({ type: ADD_INVESTMENT_FAIL, payload: error.message });
    toast(contentError(error.message));
  }
};

export {
  CreateInvestmentAccounts,
  saveInvestmentAccount,
  CreateInvestmentAccountsRollover,
  CreateInvestmentAccountsWRollover,
  CreateInvestmentStandardCWRollover,
  CreateInvestmentCustom,
  CreateInvestmentWDateRollover,
  InvestmentTopUp,
};
