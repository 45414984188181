/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";

import { CBadge, CDataTable } from "@coreui/react";

const getStatusBadge = (status) => {
  switch (status) {
    case "delivered":
      return "success";
    case "inactive":
      return "danger";
    default:
      return "danger";
  }
};

const getStateBadge = (state) => {
  if (state) {
    return "danger";
  } else {
    return "success";
  }
};

const TeamDataTable = ({ items, loading, itemsPerPage, page }) => {
   
  const history = useHistory();

  const fields = [
    { key: "team_name", label: "Team Name"},
    { key: "created_at", label: "Created Date"},
    {key: "deleted_at", label: "Deleted Date"},
  ];

  return (
    <CDataTable
      items={items}
      fields={fields}
      columnFilter
    //   tableFilter
      footer
      responsive={true}
      // itemsPerPageSelect={{
      //   label: "Items per page:",
      //   values: [5, 10, 20, 30],
      // }}
      hover
      loading={loading}
      sorter
      itemsPerPage={itemsPerPage}
      activePage
      clickableRows
      This will be modified to reflect the specific account details clicked
      onRowClick={
          (item) =>
        history.push({
          pathname: `/admin/teams/${item.id}`,
          state: page,
        })
      }
      scopedSlots={{
        team_name: (item) => (
          <td className="text-capitalize">
            {
              item &&
                item.name
            }
          </td>
        ),
        created_at: (item) => (
          <td>
            <>
              {
               item &&
               item.created_at ? 
              moment(
                item &&
                  item.created_at
              ).format("DD-MMM-YYYY LTS")
            : "Not Available"
            }
            </>
          </td>
        ),
        deleted_at: (item) => (
            <td>
              <>
                {
                item &&
                item.deleted_at ? 
                moment(
                  item &&
                    item.deleted_at 
                ).format("DD-MMM-YYYY LTS")
              : "Not Available"
              }
              </>
            </td>
          ),
      }}
    />
  );
};

export default TeamDataTable;
