/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { CSVLink } from "react-csv";
import moment from "moment";

import {
  CBadge,
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CDataTable,
  CRow,
  CPagination,
  CForm,
  CDropdown,
  CDropdownDivider,
  CDropdownToggle,
  CDropdownMenu,
  CSelect,
  CLabel,
  CInput,
} from "@coreui/react";
import { Overlay } from "../../components";

import CIcon from "@coreui/icons-react";

import TransactionService from "../../redux/_services/TransactionService";
import InvestmentsDataTable from "src/components/DataTables/InvestmentsDataTable";

const Investments = () => {
  const [investments, setinvestments] = useState([]);
  const [pages, set_pages] = useState(1);
  const [filterName, set_filter_name] = useState();
  const [filterVal, set_filter_value] = useState();
  const [query, set_query] = useState();
  const [showOverlay, set_showOverlay] = useState(false);
  const [searchPage, setSearchPage] = useState("");
  const [searchPageErr, setSearchPageErr] = useState("");

  const history = useHistory();
  const queryPage = useLocation().search.match(/page=([0-9]+)/, "");
  const currentPage = Number(queryPage && queryPage[1] ? queryPage[1] : 1);
  const [page, setPage] = useState(currentPage);

  const pageChange = (newPage) => {
    // This will be modified to reflect the specific pagination page clicked
    // currentPage !== newPage && history.push(`/investments?page=${newPage}`);
    setPage(newPage);
  };

  // useEffect(() => {
  //   currentPage !== page && setPage(currentPage);
  // }, [currentPage, page]);

  const [dates, setDate] = useState({
    investmentStartDate: "",
    investmentEndDate: "",
  });

  const setDateValue = (prop) => (event) => {
    event.preventDefault();
    setDate({
      ...dates,
      [prop]: event.target.value,
    });
    if (event.target.name === "created_between") {
      set_filter_name(event.target.name);
      set_filter_value(
        `${dates.investmentStartDate}, ${dates.investmentEndDate}`
      );
    }
  };
  const refreshFilter = (e) => {
    e.preventDefault();
    document.getElementById("create-filter-form").reset();
  };
  const update = (e) => {
    if (e.target.name === "source_account") {
      set_filter_name(e.target.name);
      set_filter_value(e.target.value);
    } else if (e.target.name === "beneficiary_account") {
      set_filter_name(e.target.name);
      set_filter_value(e.target.value);
    } else if (e.target.name === "title") {
      set_filter_name(e.target.name);
      set_filter_value(e.target.value);
    } else if (e.target.name === "amount") {
      set_filter_name(e.target.name);
      set_filter_value(e.target.value);
    } else if (e.target.name === "rollover_type") {
      set_filter_name(e.target.name);
      set_filter_value(e.target.value);
    } else if (e.target.name === "is_active") {
      set_filter_name(e.target.name);
      set_filter_value(e.target.value);
    }
  };
  const getRequestParams = (page) => {
    let params = {};

    if (page) {
      params[`page[number]`] = page - 0;
    }

    return params;
  };

  const submitHandler = (e) => {
    e.preventDefault();
    const queries = `filter[${filterName}]=${filterVal}`;
    set_query(queries);
  };

  const searchPageHandler = (e) => {
    e.preventDefault();
    if (searchPage > pages) {
      setSearchPageErr("Selected Number cannot be greater than last page");
      return;
    }
    const params = getRequestParams(searchPage);
    setSearchPageErr("");
    set_showOverlay(true);

    TransactionService.getAllInvestments(params)
      .then((response) => {
        setinvestments(response.data.data.investments.data);
        set_pages(response.data.data.investments.meta.last_page);
        setPage(response.data.data.investments.meta.current_page);
      })
      .finally(function () {
        set_showOverlay(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    const retrieveInvestmentsFilter = () => {
      if (query) {
        set_showOverlay(true);
        TransactionService.getAllInvestmentsFilter(query)
          .then((response) => {
            setinvestments(response.data.data.investments.data);
            set_pages(response.data.data.investments.meta.last_page);
          })
          .finally(function () {
            set_showOverlay(false);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    };
    retrieveInvestmentsFilter();
  }, [query]);
  useEffect(() => {
    const retrieveInvestments = () => {
      const params = getRequestParams(page);
      set_showOverlay(true);

      TransactionService.getAllInvestments(params)
        .then((response) => {
          setinvestments(response.data.data.investments.data);
          set_pages(response.data.data.investments.meta.last_page);
        })
        .finally(function () {
          set_showOverlay(false);
        })
        .catch((e) => {
          console.log(e);
        });
    };

    retrieveInvestments();
  }, [page]);

  const date = new Date();
  const downloadDate = moment(date).format("DD-MMM-YYYY");
  const investmentFileName = `cloudbank_investment_information_${downloadDate}.csv`;
  return (
    <CRow>
      <Overlay active={showOverlay} marginTop="200px">
        <CCol>
          <CCard>
            <CCardHeader>
              <div className="d-flex justify-content-between align-items-center">
                <h4>Investments</h4>
                <CSVLink
                  data={
                    investments &&
                    investments.map((x) => ({
                      investment_type: x.type === null ? "Custom" : x.type.name,
                      source_account: x.source && x.source.account_number,
                      beneficial_account:
                        x.beneficiary && x.beneficiary.account_number,
                      title: x.title,
                      amount:
                        x.amount &&
                        x.amount
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                      rollover_type: x.rollover
                        ? x.rollover.name
                        : "Not Available",
                      status: x.is_active ? "Active" : "False",
                      date_created: x.created_at
                        ? x.created_at.slice(0, 10)
                        : "Not Available",
                    }))
                  }
                  filename={investmentFileName}
                >
                  <CIcon name="cil-print" />
                </CSVLink>
              </div>
            </CCardHeader>
            <CCardBody>
              <CDropdown variant="dropdown" className="mb-3">
                <CDropdownToggle
                  color="secondary"
                  size="md"
                  className="px-2 px-md-3"
                >
                  Advanced Filter
                </CDropdownToggle>
                <CDropdownMenu
                  style={{
                    minWidth: "250px",
                    width: "100%",
                    maxWidth: "380px",
                  }}
                >
                  <h6 className="px-3">Advanced Filter</h6>
                  <CDropdownDivider />
                  <CForm id="create-filter-form">
                    <CRow className="px-3 py-2">
                      {/* <CCol className="col-12 mb-2">
                      <CLabel className="font-weight-bold text-dark w-100">
                        Investment Type
                      </CLabel>
                      <CSelect className="w-100">
                        <option value="" disabled>
                          Type
                        </option>
                        <option value="silver">Silver</option>
                        <option value="gold">Gold</option>
                        <option value="diamond">Diamond</option>
                        <option value="platinum">Platinum</option>
                      </CSelect>
                    </CCol> */}
                      <CCol className="col-12 mb-2">
                        <CLabel className="font-weight-bold text-dark w-100">
                          Source Account
                        </CLabel>
                        <CInput
                          type="text"
                          className="w-100"
                          name="source_account"
                          onChange={update}
                        />
                      </CCol>
                      <CCol className="col-12 mb-2">
                        <CLabel className="font-weight-bold text-dark w-100">
                          Beneficiary Account
                        </CLabel>
                        <CInput
                          type="text"
                          className="w-100"
                          name="beneficiary_account"
                          onChange={update}
                        />
                      </CCol>
                      <CCol className="col-12 mb-2">
                        <CLabel className="font-weight-bold text-dark w-100">
                          Title
                        </CLabel>
                        <CInput
                          type="text"
                          className="w-100"
                          name="title"
                          onChange={update}
                        />
                      </CCol>
                      <CCol className="col-12 mb-2">
                        <CLabel className="font-weight-bold text-dark w-100">
                          Amount
                        </CLabel>
                        <CInput
                          type="text"
                          className="w-100"
                          name="amount"
                          onChange={update}
                        />
                      </CCol>
                      <CCol className="col-12 mb-2">
                        <CLabel className="font-weight-bold text-dark w-100">
                          Rollover Type
                        </CLabel>
                        <CSelect
                          className="w-100"
                          name="rollover_type"
                          onChange={update}
                        >
                          <option value="">All</option>
                          <option value="not available">Not Available</option>
                          <option value="investment">Investment</option>
                          <option value="investment + interest">
                            Investment + Interest
                          </option>
                        </CSelect>
                      </CCol>
                      <CCol className="col-12 mb-2">
                        <CLabel className="font-weight-bold text-dark w-100">
                          Status
                        </CLabel>
                        <CSelect
                          className="w-100"
                          name="is_active"
                          onChange={update}
                        >
                          <option value="">All</option>
                          <option value="inactive">Inactive</option>
                          <option value="active">Active</option>
                        </CSelect>
                      </CCol>
                      <CCol className="col-12 col-md-6 mb-2">
                        <CLabel className="font-weight-bold text-dark w-100">
                          Start Date
                        </CLabel>
                        <CInput
                          type="date"
                          className="w-100"
                          name="created_between"
                          onChange={setDateValue("investmentStartDate")}
                        />
                      </CCol>

                      <CCol className="col-12 col-md-6 mb-2">
                        <CLabel className="font-weight-bold text-dark w-100">
                          End Date
                        </CLabel>
                        <CInput
                          type="date"
                          className="w-100"
                          name="created_between"
                          onChange={setDateValue("investmentEndDate")}
                        />
                      </CCol>
                    </CRow>
                    <CDropdownDivider />
                    <div className="d-flex align-items-center justify-content-between px-3 py-1">
                      <a
                        className="cursor-pointer text-primary"
                        role="button"
                        onClick={(e) => refreshFilter(e)}
                      >
                        Reset Filter
                      </a>
                      <CButton
                        color="dark"
                        type="submit"
                        onClick={(e) => submitHandler(e)}
                      >
                        Submit
                      </CButton>
                    </div>
                  </CForm>
                </CDropdownMenu>
              </CDropdown>

              <InvestmentsDataTable
                items={investments}
                loading={showOverlay}
                itemsPerPage={30}
                page={page}
              />

              <CPagination
                activePage
                onActivePageChange={pageChange}
                pages={pages}
                doubleArrows={true}
                align="end"
              />
              <div className="d-flex justify-content-end">
                <div className="d-flex justify-content-end w-25">
                  <CInput
                    type="number"
                    className=" ml-2 w-25"
                    onChange={(e) => setSearchPage(e.target.value)}
                  />
                  <CButton
                    color="primary"
                    className="ml-2"
                    onClick={searchPageHandler}
                  >
                    Search
                  </CButton>
                </div>
              </div>
              <p className="d-flex justify-content-end text-danger">
                {searchPageErr}
              </p>
              <p className="d-flex justify-content-end text-muted">
                search by page number
              </p>
            </CCardBody>
          </CCard>
        </CCol>
      </Overlay>
    </CRow>
  );
};

export default Investments;
