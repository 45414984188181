/* eslint-disable react/prop-types */
import { toast } from "react-toastify";
import {
  ACCOUNT_VERIFY_REQUEST,
  ACCOUNT_VERIFY_SUCCESS,
  ACCOUNT_VERIFY_FAIL,
  ACCOUNT_UPDATE_REQUEST,
  ACCOUNT_UPDATE_SUCCESS,
  ACCOUNT_UPDATE_FAIL,
  ACCOUNT_BLOCK_REQUEST,
  ACCOUNT_BLOCK_SUCCESS,
  ACCOUNT_BLOCK_FAIL,
  ACCOUNT_UNBLOCK_REQUEST,
  ACCOUNT_UNBLOCK_SUCCESS,
  ACCOUNT_UNBLOCK_FAIL,
  ACCOUNT_STATEMENT_REQUEST,
  ACCOUNT_STATEMENT_SUCCESS,
  ACCOUNT_STATEMENT_FAIL,
  ACCOUNT_OVERDRAFT_FAIL,
  ACCOUNT_OVERDRAFT_REQUEST,
  ACCOUNT_OVERDRAFT_SUCCESS,
  ACCOUNT_LIST_FAIL,
  ACCOUNT_LIST_REQUEST,
  ACCOUNT_LIST_SUCCESS,
  UNBLOCK_FUND_REQUEST,
  UNBLOCK_FUND_SUCCESS,
  UNBLOCK_FUND_FAIL,
  BLOCK_FUND_SUCCESS,
  BLOCK_FUND_FAIL,
  BLOCK_FUND_REQUEST,
} from "../_constants/accountConstants";

import { axiosWithAuth, contentSuccess, contentError } from "../../_utils/api";

const updateAccount = (
  id,
  interest_status,
  transfer_limit_status,
  transfer_limit,
  password
) => {
  return async (dispatch) => {
    const payload = {
      interest_status,
      transfer_limit_status,
      password,
    };

    // send limit only if enabled
    if (transfer_limit_status) {
      Object.assign(payload, transfer_limit);
    }

    dispatch({
      type: ACCOUNT_UPDATE_REQUEST,
      payload,
    });
    try {
      const { data } = await axiosWithAuth.patch(
        `/admin/accounts/${id}`,
        payload
      );
      dispatch({ type: ACCOUNT_UPDATE_SUCCESS, payload: data });
      toast.success(contentSuccess);
    } catch (error) {
      dispatch({
        type: ACCOUNT_UPDATE_FAIL,
        payload: error.response.data.error.message,
      });
      toast(contentError(error.response.data.error.message));
    }
  };
};

const unblockFund = (fundId, accountId, password) => {
  return async (dispatch) => {
    dispatch({
      type: UNBLOCK_FUND_REQUEST,
    });

    try {
      const { data } = await axiosWithAuth.post(
        `/admin/accounts/${accountId}/unblock-fund/${fundId}`,
        {
          password,
        }
      );

      dispatch({ type: UNBLOCK_FUND_SUCCESS, payload: data });

      toast.success(contentSuccess);
    } catch (error) {
      dispatch({
        type: UNBLOCK_FUND_FAIL,
        payload: error.response.data.error.message,
      });

      toast(contentError(error.response.data.error.message));
    }
  };
};

const blockFund = (accountId, amount, comment, password, paying_acc = "") => {
  return async (dispatch) => {
    const payload = { amount, comment, password };

    if (paying_acc) Object.assign(payload, { paying_account: paying_acc });

    dispatch({
      type: BLOCK_FUND_REQUEST,
    });

    try {
      const { data } = await axiosWithAuth.post(
        `/admin/accounts/${accountId}/block-fund`,
        payload
      );

      dispatch({ type: BLOCK_FUND_SUCCESS, payload: data });

      toast.success(contentSuccess);
    } catch (error) {
      dispatch({
        type: BLOCK_FUND_FAIL,
        payload: error.response.data.error.message,
      });

      toast(contentError(error.response.data.error.message));
    }
  };
};

const accountVerify = (status, accountReference) => async (dispatch) => {
  const reference = accountReference;
  dispatch({
    type: ACCOUNT_VERIFY_REQUEST,
    payload: { status },
  });
  try {
    const { data } = await axiosWithAuth.patch(
      `/admin/accounts/${reference}/verify`,
      {
        status,
      }
    );
    dispatch({ type: ACCOUNT_VERIFY_SUCCESS, payload: data });
    toast(contentSuccess);
  } catch (error) {
    dispatch({ type: ACCOUNT_VERIFY_FAIL, payload: error.message });
    toast(contentError(error.response.data.error.message), {
      onClose: setTimeout(() => window.location.reload, 4000),
    });
  }
};

const accountVerifyFailed =
  (status, comment, accountReference) => async (dispatch) => {
    const reference = accountReference;

    dispatch({
      type: ACCOUNT_VERIFY_REQUEST,
      payload: { status, comment },
    });
    try {
      const { data } = await axiosWithAuth.patch(
        `/admin/accounts/${reference}/verify`,
        {
          status,
          comment,
        }
      );
      dispatch({ type: ACCOUNT_VERIFY_SUCCESS, payload: data });
      toast(contentSuccess);
    } catch (error) {
      dispatch({ type: ACCOUNT_VERIFY_FAIL, payload: error.message });
      toast(contentError(error.response.data.error.message));
    }
  };

const blockAccount =
  (id, account_id, comment, expires_at, password) => async (dispatch) => {
    dispatch({
      type: ACCOUNT_BLOCK_REQUEST,
      payload: { account_id, comment, expires_at },
    });
    try {
      const { data } = await axiosWithAuth.post(
        `/admin/accounts/${id}/post-no-debit`,
        {
          account_id,
          comment,
          expires_at,
          password,
        }
      );
      dispatch({ type: ACCOUNT_BLOCK_SUCCESS, payload: data });
      toast.success(contentSuccess);
    } catch (error) {
      dispatch({
        type: ACCOUNT_BLOCK_FAIL,
        payload: error.response.data.error.message,
      });
      toast(contentError(error.response.data.error.message));
    }
  };

const unBlockAccount = (id, account_id, password) => async (dispatch) => {
  const data = {
    account_id,
    password,
  };
  const url = `/admin/accounts/${id}/post-no-debit`;
  dispatch({
    type: ACCOUNT_UNBLOCK_REQUEST,
    payload: { account_id, password },
  });
  try {
    await axiosWithAuth.request({ data, url, method: "delete" });
    dispatch({ type: ACCOUNT_UNBLOCK_SUCCESS, payload: data });
    toast.success(contentSuccess);
  } catch (error) {
    dispatch({
      type: ACCOUNT_UNBLOCK_FAIL,
      payload: error.response.data.error.message,
    });
    toast(contentError(error.response.data.error.message));
  }
};

const accountOverDraft = (payload, id) => async (dispatch) => {
  const data = {
    overdraft: payload.overDraftStatus,
    password: payload.overDraftPassword,
  };

  try {
    dispatch({
      type: ACCOUNT_OVERDRAFT_REQUEST,
    });
    const { data: responseData } = await axiosWithAuth.patch(
      `admin/accounts/${id}/overdraft`,
      data
    );
    dispatch({
      type: ACCOUNT_OVERDRAFT_SUCCESS,
      payload: responseData,
    });
    toast(contentSuccess);
  } catch (error) {
    dispatch({ type: ACCOUNT_OVERDRAFT_FAIL, payload: error.message });
    toast(contentError(error.response.data.error.message));
  }
};

const sendAccountStatement = (payload) => async (dispatch) => {
  const { start_date, end_date, id, send_to_account_owner } = payload;
  const data = {
    start_date,
    end_date,
    send_to_account_owner,
  };

  try {
    dispatch({
      type: ACCOUNT_STATEMENT_REQUEST,
    });
    const { data: responseData } = await axiosWithAuth.post(`accounts/${id}/statement`, data);
    dispatch({
      type: ACCOUNT_STATEMENT_SUCCESS,
      payload: responseData.data.message,
    });
    toast.success(responseData.data.message);
  } catch (err) {
    if (err.message === "Request failed with status code 422") {
      const error422 =
        "The account has no transaction on record for this period";
      toast(contentError(error422));
    } else {
      toast(contentError("An Error Occurred"));
    }
    dispatch({ type: ACCOUNT_STATEMENT_FAIL, payload: err.message });
  }
};

const sendAccountList = (payload) => async (dispatch) => {
  const { start_date, end_date, email } = payload;

  try {
    dispatch({
      type: ACCOUNT_LIST_REQUEST,
    });
    const { data: responseData } = await axiosWithAuth.get(
      `admin/exports/users?start_date=${start_date}&end_date=${end_date}&email=${email}`
    );
    dispatch({
      type: ACCOUNT_LIST_SUCCESS,
      payload: responseData.data.message,
    });
    toast(contentSuccess(responseData.data.message));
  } catch (err) {
    if (err.message === "Request failed with status code 422") {
      const error422 = "No Record for this period";
      toast(contentError(error422));
    } else {
      toast(contentError("An Error Occurred"));
    }
    dispatch({ type: ACCOUNT_LIST_FAIL, payload: err.message });
  }
};

export {
  accountVerify,
  accountVerifyFailed,
  updateAccount,
  blockAccount,
  unBlockAccount,
  blockFund,
  unblockFund,
  sendAccountStatement,
  accountOverDraft,
  sendAccountList,
};
