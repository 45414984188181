/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CPagination,
  CForm,
  CDropdown,
  CDropdownDivider,
  CDropdownToggle,
  CDropdownMenu,
  CSelect,
  CLabel,
  CInput,
} from "@coreui/react";
import { CSVLink } from "react-csv";
import moment from "moment";

import CIcon from "@coreui/icons-react";
import { Overlay } from "../../components";

import TransactionDataService from "../../redux/_services/TransactionService";
import TransactionDataTable from "src/components/DataTables/TransactionDataTable";
import { useAccountDetails } from "src/_hooks/accounts";
import { useParams } from "react-router-dom/cjs/react-router-dom";
const getBadge = (status) => {
  switch (status) {
    case "success":
      return "success";
    case "pending":
      return "warning";
    case "failed":
      return "danger";
    case "credit":
      return "success";
    case "debit":
      return "danger";
    default:
      return "";
  }
};

const Transactions = () => {
  const {id} = useParams();
  const [transactions, set_transactions] = useState([]);
  const [pages, set_pages] = useState(1);
  const [filterName, set_filter_name] = useState();
  const [filterVal, set_filter_value] = useState();
  const [query, set_query] = useState();
  const [showOverlay, set_showOverlay] = useState(false);
  const [loading, set_loading] = useState(false);
  const history = useHistory();
  const [currentpage, set_currentpage] = useState();
  const [allBanks, setAllBanks] = useState([]);
  const [prevPageNumber, setPrevPageNumber] = useState("");
  const [searchPage, setSearchPage] = useState("");
  const [searchPageLoading, setSearchPageLoading] = useState("");
  const [searchPageErr, setSearchPageErr] = useState("");
  const pageChange = (newPage) => {
    setPrevPageNumber("");
    set_currentpage(newPage);
  };

  const [dates, setDate] = useState({
    transactionStartDate: "",
    transactionEndDate: "",
  });

  const [currentAccount] = useAccountDetails(id);


  const setDateValue = (prop) => (event) => {
    event.preventDefault();
    setDate({
      ...dates,
      [prop]: event.target.value,
    });
    if (event.target.name === "created_between") {
      set_filter_name(event.target.name);
      set_filter_value(
        `${dates.transactionStartDate}, ${dates.transactionEndDate}`
      );
    }
  };

  let previousPage = sessionStorage.getItem("transactionPage");
  let previousPageQuery = JSON.parse(
    sessionStorage.getItem("transactionPageFilter")
  );
  useEffect(() => {
    if (previousPage) {
      setPrevPageNumber(+previousPage);
      set_currentpage(+previousPage);
    }
    if (previousPageQuery) {
      set_query(previousPageQuery.query);
      set_filter_name(previousPageQuery.filterName);
      set_filter_value(previousPageQuery.filterVal);
    }
  }, []);

  const refreshFilter = (e) => {
    e.preventDefault();
    set_filter_name();
    set_filter_value();
    set_currentpage(1);
    set_query();
    sessionStorage.removeItem("transactionPageFilter");
    document.getElementById("create-filter-form").reset();
  };
  const update = (e) => {
    if (e.target.name === "reference") {
      set_filter_name(e.target.name);
      set_filter_value(e.target.value);
    } else if (e.target.name === "credit_account_number") {
      set_filter_name(e.target.name);
      set_filter_value(e.target.value);
    } else if (e.target.name === "credit_account_name") {
      set_filter_name(e.target.name);
      set_filter_value(e.target.value);
    } else if (e.target.name === "external_account_number") {
      set_filter_name(e.target.name);
      set_filter_value(e.target.value);
    } else if (e.target.name === "external_account_name") {
      set_filter_name(e.target.name);
      set_filter_value(e.target.value);
    } else if (e.target.name === "amount") {
      set_filter_name(e.target.name);
      set_filter_value(e.target.value);
    } else if (e.target.name === "bank_code") {
      set_filter_name(e.target.name);
      set_filter_value(e.target.value);
    } else if (e.target.name === "type") {
      set_filter_name(e.target.name);
      set_filter_value(e.target.value);
    } else if (e.target.name === "status") {
      set_filter_name(e.target.name);
      set_filter_value(e.target.value);
    }
  };
  const submitHandler = (e) => {
    e.preventDefault();
    const queries = `filter[${filterName}]=${filterVal}`;
    if (filterName === undefined) {
      set_query();
      set_showOverlay(true);
      TransactionDataService.getAllTransactions(currentpage)
        .then((response) => {
          set_pages(response.data.data.transactions.meta.last_page);
          set_transactions(response.data.data.transactions.data);
          if (prevPageNumber) {
            set_currentpage(+prevPageNumber);
          } else {
            set_currentpage(response.data.data.transactions.meta.current_page);
          }
        })
        .finally(function () {
          set_showOverlay(false);
          set_loading(false);
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      set_query(queries);
      const filterObj = {
        query: queries,
        filterName: filterName,
        filterVal: filterVal,
      };
      sessionStorage.setItem(
        "transactionPageFilter",
        JSON.stringify(filterObj)
      );
    }
  };

  const searchPageHandler = (e) => {
    e.preventDefault();
    if (searchPage > pages) {
      setSearchPageErr("Selected Number cannot be greater than last page");
      return;
    }
    setSearchPageErr("");
    setSearchPageLoading(true);
    TransactionDataService.getAllTransactions(searchPage)
      .then((response) => {
        set_pages(response.data.data.transactions.meta.last_page);
        set_transactions(response.data.data.transactions.data);

        set_currentpage(response.data.data.transactions.meta.current_page);
      })
      .finally(function () {
        setSearchPageLoading(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  useEffect(() => {
    TransactionDataService.getAllBanks(currentAccount?.account_number)
      .then((res) => {
        setAllBanks(res?.data?.data?.banks ?? []);
      })
      .catch((err) => console.log(err))
      .finally();
    const retrieveTransactions = async () => {
      set_showOverlay(true);
      set_loading(true);
      if (query) {
        if (currentpage == undefined) {
          set_currentpage(1);
        }
        await TransactionDataService.getAllTransactionsFilter(
          query,
          currentpage
        )
          .then((response) => {
            set_pages(response.data.data.transactions.meta.last_page);
            set_transactions(response.data.data.transactions.data);
          })
          .finally(function () {
            set_showOverlay(false);
            set_loading(false);
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        await TransactionDataService.getAllTransactions(currentpage)
          .then((response) => {
            set_pages(response.data.data.transactions.meta.last_page);
            set_transactions(response.data.data.transactions.data);
            if (prevPageNumber) {
              set_currentpage(+prevPageNumber);
            } else {
              set_currentpage(
                response.data.data.transactions.meta.current_page
              );
            }
          })
          .finally(function () {
            set_showOverlay(false);
            set_loading(false);
          })
          .catch((e) => {
            console.log(e);
          });
      }
    };
    retrieveTransactions();
  }, [currentpage]);

  useEffect(() => {
    const retrieveTransactionsFilter = () => {
      if (query) {
        previousPage = 1;
        set_showOverlay(true);
        TransactionDataService.getAllTransactionsFilter(query, currentpage)
          .then((response) => {
            set_transactions(response.data.data.transactions.data);
            set_pages(response.data.data.transactions.meta.last_page);
            set_currentpage(response.data.data.transactions.meta.current_page);
          })
          .finally(function () {
            set_showOverlay(false);
          })
          .catch((e) => {
            console.log(e);
          });
      }
    };
    retrieveTransactionsFilter();
  }, [query]);

  const date = new Date();
  const downloadDate = moment(date).format("DD-MMM-YYYY");
  const transactionFileName = `cloudbank_transactions_information_${downloadDate}.csv`;
  return (
    <div className={"bg-white p-3"}>
      <div className="d-flex p-3">
        <h4 className="mr-auto text-dark">Transactions</h4>
        <CSVLink
          data={
            transactions &&
            transactions.map((x) => ({
              reference: x.transaction_reference,
              credit_account: x.beneficiary && x.beneficiary.account_number,
              credit_account_name:
                x.beneficiary &&
                x.beneficiary.account_name &&
                x.beneficiary.account_name
                  .toString()
                  .replace(/(^\w)|([-\s]\w)/g, (match) => match.toUpperCase()),
              debit_account: x.source && x.source.account_number,
              debit_account_name:
                x.source &&
                x.source.account_name &&
                x.source.account_name
                  .toString()
                  .replace(/(^\w)|([-\s]\w)/g, (match) => match.toUpperCase()),
              amount:
                x.amount &&
                x.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
              bank_code: x.bank && x.bank.bank_name,
              type: x.type,
              status: x.status,
              date_created: x.created_at
                ? x.created_at.slice(0, 10)
                : "Not Available",
            }))
          }
          filename={transactionFileName}
        >
          <CIcon name="cil-print" />
        </CSVLink>
      </div>

      <CRow>
        <Overlay active={showOverlay} marginTop="200px">
          <CCol>
            <CDropdown variant="dropdown" className="mb-3">
              <CDropdownToggle
                color="secondary"
                size="md"
                className="px-2 px-md-3"
              >
                Advanced Filter
              </CDropdownToggle>
              <CDropdownMenu
                style={{
                  minWidth: "250px",
                  width: "100%",
                  maxWidth: "380px",
                }}
              >
                <h6 className="px-3">Advanced Filter</h6>
                <CDropdownDivider />
                <CForm id="create-filter-form">
                  <CRow className="px-3 py-2">
                    <CCol className="col-12 mb-2">
                      <CLabel className="font-weight-bold text-dark w-100">
                        Transaction Reference
                      </CLabel>
                      <CInput
                        className="w-100"
                        name="reference"
                        onChange={update}
                      />
                    </CCol>
                    <CCol className="col-12 mb-2">
                      <CLabel className="font-weight-bold text-dark w-100">
                        Internal Account Number
                      </CLabel>
                      <CInput
                        type="text"
                        className="w-100"
                        name="credit_account_number"
                        onChange={update}
                      />
                    </CCol>
                    <CCol className="col-12 mb-2">
                      <CLabel className="font-weight-bold text-dark w-100">
                        Internal Account Name
                      </CLabel>
                      <CInput
                        type="text"
                        className="w-100"
                        name="credit_account_name"
                        onChange={update}
                      />
                    </CCol>
                    <CCol className="col-12 mb-2">
                      <CLabel className="font-weight-bold text-dark w-100">
                        External Account Number
                      </CLabel>
                      <CInput
                        type="text"
                        className="w-100"
                        name="external_account_number"
                        onChange={update}
                      />
                    </CCol>
                    <CCol className="col-12 mb-2">
                      <CLabel className="font-weight-bold text-dark w-100">
                        External Account Name
                      </CLabel>
                      <CInput
                        type="text"
                        className="w-100"
                        name="external_account_name"
                        onChange={update}
                      />
                    </CCol>
                    <CCol className="col-12 mb-2">
                      <CLabel className="font-weight-bold text-dark w-100">
                        Amount (₦)
                      </CLabel>
                      <CInput
                        type="text"
                        className="w-100"
                        name="amount"
                        onChange={update}
                      />
                    </CCol>
                    <CCol className="col-12 mb-2">
                      <CLabel className="font-weight-bold text-dark w-100">
                        Bank
                      </CLabel>
                      <CSelect
                        type="text"
                        className="w-100"
                        name="bank_code"
                        onChange={update}
                      >
                        <option>Select bank</option>

                        {allBanks.map((item, key) => (
                          <option value={item.nip_bank_code} key={key}>
                            {" "}
                            {item.bank_name}{" "}
                          </option>
                        ))}
                      </CSelect>
                    </CCol>
                    <CCol className="col-12 mb-2">
                      <CLabel className="font-weight-bold text-dark w-100">
                        Transaction Type
                      </CLabel>
                      <CSelect className="w-100" name="type" onChange={update}>
                        <option value="">All</option>
                        <option value="debit">Debit</option>
                        <option value="credit">Credit</option>
                      </CSelect>
                    </CCol>
                    <CCol className="col-12 mb-2">
                      <CLabel className="font-weight-bold text-dark w-100">
                        Transaction Status
                      </CLabel>
                      <CSelect
                        className="w-100"
                        name="status"
                        onChange={update}
                      >
                        <option value="">All</option>
                        <option value="success">Success</option>
                        <option value="pending">Pending</option>
                        <option value="failed">Failed</option>
                      </CSelect>
                    </CCol>
                    <CCol className="col-12  mb-2">
                      <CLabel className="font-weight-bold text-dark w-100">
                        Start Date
                      </CLabel>
                      <CInput
                        type="date"
                        className="w-100"
                        // value={dates.transactionStartDate}
                        name="created_between"
                        onChange={setDateValue("transactionStartDate")}
                      />
                    </CCol>

                    <CCol className="col-12  mb-2">
                      <CLabel className="font-weight-bold text-dark w-100">
                        End Date
                      </CLabel>
                      <CInput
                        type="date"
                        className="w-100"
                        // value={dates.transactionEndDate}
                        name="created_between"
                        onChange={setDateValue("transactionEndDate")}
                      />
                    </CCol>
                  </CRow>
                  <CDropdownDivider />
                  <div className="d-flex align-items-center justify-content-between px-3 py-1">
                    <a
                      className="cursor-pointer text-primary"
                      role="button"
                      onClick={(e) => refreshFilter(e)}
                    >
                      Reset Filter
                    </a>
                    <CButton
                      color="dark"
                      type="submit"
                      onClick={(e) => submitHandler(e)}
                    >
                      Submit
                    </CButton>
                  </div>
                </CForm>
              </CDropdownMenu>
            </CDropdown>
          </CCol>
        </Overlay>
      </CRow>
      <TransactionDataTable
        data={transactions}
        loading={loading}
        page={currentpage}
        itemsPerPage={30}
      />

      <CPagination
        activePage={currentpage}
        onActivePageChange={pageChange}
        pages={pages}
        doubleArrows={true}
        align="end"
      />

      <div className="d-flex justify-content-end">
        <div className="d-flex justify-content-end w-25">
          <CInput
            type="number"
            className=" ml-2 w-25"
            onChange={(e) => setSearchPage(e.target.value)}
          />
          <CButton
            color="primary"
            className="ml-2"
            onClick={searchPageHandler}
          >
            {searchPageLoading ? (
              <div className="spinner-border spinner-border-sm text-light" role="status">
              </div>
            ) : (
              "Search"
            )}
          </CButton>
        </div>
      </div>
      <p className="d-flex justify-content-end text-danger">
            {searchPageErr}
          </p>
      <p className="d-flex justify-content-end text-muted">search by page number</p>
    </div>
  );
};

export default Transactions;
