/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CPagination,
  CDropdown,
  CDropdownDivider,
  CDropdownToggle,
  CDropdownMenu,
  CForm,
  CInput,
  CLabel,
} from "@coreui/react";
import EmailMessagesDataTable from "src/components/DataTables/EmailMessageDataTable";
import { Overlay } from "../../components";
import {
  getEmailTokens,
  searchEmailTokens,
} from "src/redux/_services/MessageService";

const Messages = (props) => {
  const [messages, setMessages] = useState();
  const [lastPage, set_lastpage] = useState(1);
  const [firstPage, setFirstPage] = useState("");
  const [nextPage, setNextPage] = useState("");
  const [prevPage, setPrevPage] = useState("");
  const [currentpage, setCurrentPage] = useState("");
  const [email, setEmail] = useState("");

  const [showOverlay, set_showOverlay] = useState(false);

  const pageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const refreshFilter = (e) => {
    e.preventDefault();
    setEmail("");
    getEmailTokens()
      .then((response) => {
        setMessages(response.data.data.tokens.data);
        set_lastpage(response.data.data.tokens.meta.last_page);
        setFirstPage(response.data.data.tokens.meta.first);
        setPrevPage(response.data.data.tokens.meta.prev);
        setNextPage(response.data.data.tokens.meta.next);
        setCurrentPage(response.data.data.tokens.meta.current_page);
      })
      .finally(function () {
        set_showOverlay(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const submitHandler = (e) => {
    e.preventDefault();
    set_showOverlay(true);
    if (email) {
      searchEmailTokens(email)
        .then((response) => {
          set_showOverlay(false);
          setMessages(response.data.data.tokens.data);
          set_lastpage(response.data.data.tokens.meta.last_page);
          setFirstPage(response.data.data.tokens.meta.first);
          setPrevPage(response.data.data.tokens.meta.prev);
          setNextPage(response.data.data.tokens.meta.next);
          setCurrentPage(response.data.data.tokens.meta.current_page);
        })
        .finally(function () {
          set_showOverlay(false);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  useEffect(() => {
    getEmailTokens();
    const retrieveToken = () => {
      set_showOverlay(true);
      getEmailTokens(currentpage)
        .then((response) => {
          setMessages(response.data.data.tokens.data);
          set_lastpage(response.data.data.tokens.meta.last_page);
          setFirstPage(response.data.data.tokens.meta.first);
          setPrevPage(response.data.data.tokens.meta.prev);
          setNextPage(response.data.data.tokens.meta.next);
          setCurrentPage(response.data.data.tokens.meta.current_page);
        })
        .finally(function () {
          set_showOverlay(false);
        })
        .catch((e) => {
          console.log(e);
        });
    };

    retrieveToken();
  }, []);

  return (
    <>
      <CRow>
        <Overlay active={showOverlay} marginTop="200px">
          <CCol>
            <CCard>
              <CCardHeader>
                <div className="d-flex justify-content-between align-items-center">
                  <h4>Email Tokens</h4>
                </div>
              </CCardHeader>
              <CCardBody>
                <CDropdown variant="dropdown" className="mb-3">
                  <CDropdownToggle
                    color="secondary"
                    size="md"
                    className="px-2 px-md-3"
                  >
                    Advanced Filter
                  </CDropdownToggle>
                  <CDropdownMenu
                    style={{
                      minWidth: "250px",
                      width: "100%",
                      maxWidth: "380px",
                    }}
                  >
                    <h6 className="px-3">Advanced Filter</h6>
                    <CDropdownDivider />
                    <CForm id="create-filter-form">
                      <CRow className="px-3 py-2">
                        <CCol className="col-12 mb-2">
                          <CLabel className="font-weight-bold text-dark w-100">
                            Email
                          </CLabel>
                          <CInput
                            type="text"
                            className="w-100"
                            name="phone_number"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </CCol>
                      </CRow>
                      <CDropdownDivider />
                      <div className="d-flex align-items-center justify-content-between px-3 py-1">
                        <a
                          className="cursor-pointer text-primary"
                          role="button"
                          onClick={(e) => refreshFilter(e)}
                        >
                          Reset Filter
                        </a>
                        <CButton
                          color="dark"
                          type="submit"
                          onClick={(e) => submitHandler(e)}
                        >
                          Submit
                        </CButton>
                      </div>
                    </CForm>
                  </CDropdownMenu>
                </CDropdown>

                <EmailMessagesDataTable
                  items={messages}
                  //   page={currentpage}
                  loading={showOverlay}
                  itemsPerPage={50}
                />
                <div className="p-3  col-sm-12">
                  <CPagination
                    activePage={currentpage}
                    onActivePageChange={pageChange}
                    pages={lastPage}
                    doubleArrows={false}
                    align="end"
                  />
                </div>
              </CCardBody>
            </CCard>
          </CCol>
        </Overlay>
      </CRow>
    </>
  );
};

export default Messages;
