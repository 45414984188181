/* eslint-disable no-unused-expressions */
import { toast } from "react-toastify";
import {
  CREATE_TRANSACTION_REQUEST,
  CREATE_TRANSACTION_SUCCESS,
  CREATE_TRANSACTION_FAIL,
  USER_UPDATEPIN_REQUEST,
  USER_UPDATEPIN_SUCCESS,
  USER_UPDATEPIN_FAIL,
  USER_RESETPIN_REQUEST,
  USER_RESETPIN_SUCCESS,
  USER_RESETPIN_FAIL,
  USER_REQUESTTOKEN_REQUEST,
  USER_REQUESTTOKEN_SUCCESS,
  USER_REQUESTTOKEN_FAIL,
  SAVE_TRANSACTION,
} from "../_constants/transactionConstants";
import {
  axiosWithAuth,
  selectToken,
  contentSuccess,
  contentError,
} from "../../_utils/api";

const saveTransaction = (data) => (dispatch) => {
  try {
    dispatch({ type: SAVE_TRANSACTION, payload: data });
  } catch (error) {
    console.error(error.message);
  }
};

const requestEmailToken = (email) => async (dispatch) => {
  try {
    const token = selectToken();
    dispatch({
      type: USER_REQUESTTOKEN_REQUEST,
      payload: { email },
    });
    const { data } = await axiosWithAuth.post(
      "/pin-reset-token",
      { email },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch({ type: USER_REQUESTTOKEN_SUCCESS, payload: data });
    toast(contentSuccess);
  } catch (error) {
    dispatch({ type: USER_REQUESTTOKEN_FAIL, payload: error.message });
    toast(contentError(error.message));
  }
};

const createTransaction =
  (
    source_account_number,
    beneficiary_account_number,
    beneficiary_account_name,
    amount,
    beneficiary_bank_code,
    transaction_reference,
    pin,
    description,
    beneficiary_bank_name,
    remember,
    channel
  ) =>
  async (dispatch) => {
    dispatch({
      type: CREATE_TRANSACTION_REQUEST,
      payload: {
        source_account_number,
        beneficiary_account_number,
        beneficiary_account_name,
        amount,
        beneficiary_bank_code,
        transaction_reference,
        pin,
        description,
        beneficiary_bank_name,
        remember,
        channel,
      },
    });
    try {
      const token = selectToken;
      const { data } = await axiosWithAuth.post(
        "/admin/transactions",
        {
          source_account_number,
          beneficiary_account_number,
          beneficiary_account_name,
          amount,
          beneficiary_bank_code,
          transaction_reference,
          pin,
          description,
          beneficiary_bank_name,
          remember,
          channel,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("Data", data)

      if (data && (data.status === "success" || data.status === "pending")) {
        dispatch({ type: CREATE_TRANSACTION_SUCCESS, payload: data });
        toast(contentSuccess("Successful"));
      } else {
        toast(contentError("An Error Occurred"));
      }
    } catch (error) {
      console.error("Error creating transaction:", error);
      const errorMessage = error.response?.data?.error?.message || error.message;
      dispatch({ type: CREATE_TRANSACTION_FAIL, payload: errorMessage });
      toast(contentError(errorMessage));
    }
  };

const updatePinToken = (old_pin, pin, pin_confirmation) => async (dispatch) => {
  try {
    const token = selectToken();
    dispatch({
      type: USER_UPDATEPIN_REQUEST,
      payload: { old_pin, pin, pin_confirmation },
    });
    const { data } = await axiosWithAuth.patch(
      "/security/pin",
      {
        old_pin,
        pin,
        pin_confirmation,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch({ type: USER_UPDATEPIN_SUCCESS, payload: data });
    toast(contentSuccess);
    setTimeout(() => {
      window.location.reload();
    }, 3000);
  } catch (error) {
    dispatch({ type: USER_UPDATEPIN_FAIL, payload: error.message });
    toast(contentError(error.message));
  }
};

const resetPinToken = (pin, token) => async (dispatch) => {
  try {
    const authToken = selectToken();
    dispatch({
      type: USER_RESETPIN_REQUEST,
      payload: { pin, token },
    });
    const { data } = await axiosWithAuth.put(
      "/security/pin",
      {
        pin,
        token,
      },
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
    );
    dispatch({ type: USER_RESETPIN_SUCCESS, payload: data });
    toast(contentSuccess);
    setTimeout(() => {
      window.location.reload();
    }, 3000);
  } catch (error) {
    dispatch({ type: USER_RESETPIN_FAIL, payload: error.message });
    toast(contentError(error.message));
  }
};

export {
  saveTransaction,
  createTransaction,
  updatePinToken,
  requestEmailToken,
  resetPinToken,
};
