import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CButton,
  CCol,
  CContainer,
  CForm,
  CInput,
  CInputGroup,
  CRow,
  CSpinner,
  CLabel,
} from "@coreui/react";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { contentSuccess, contentError } from "../../_utils/api";
import TransactionService from "src/redux/_services/TransactionService"


const ReverseTransaction = (props) => {
  const [reference, setReference] = useState("");
  const [password, setPassword] = useState("");
  const [passwordType, setPasswordType] = useState("password");
  const [loading, setLoading] = useState("");
  const [response, setResponse] = useState("");

  useEffect(() => {}, [response]);

  const reverseHandler = (e) => {
    e.preventDefault();

    if (password === "" || reference === "") {
      toast(contentError("Password or Reference cannot be empty "));
      return;
    }

    const payload = {
      password,
      reference,
    };
    setLoading(true);
    TransactionService.reverseTransaction(payload)
      .then((res) => {
        setLoading(false);
        const {data} = res
        if(data.status === 200){
             toast(contentSuccess("Successful"));
             setResponse(data.status);
             setReference("")
             setPassword("")
        }else {
            toast(contentError("An Error Occured"));
        }
       
      })
      .catch((err) => {
        setLoading(false);
        if (err.response.data.error.message) {
          toast(contentError(err.response.data.error.message));
        } else {
          toast(contentError("An Error Occured"));
        }
       
      })
  };

  return (
    <>
      <div>
        <div className="text-center mt-4 mb-4">
          <h4 className="text-dark">
            Reverse Credited failed Transaction
          </h4>
        </div>

        <CContainer className="col-4 bg-white p-4">
          <CRow className="justify-content-center ">
            <CCol className="mx-auto px-0 px-md-3">
              <CForm autoComplete="off">
                <div className>
                  <div className="mb-3">
                    <CLabel>Reference</CLabel>
                    <CInput
                      type="reference"
                      onChange={(e) => setReference(e.target.value)}
                      placeholder="Enter Transaction Reference"
                      autocomplete="new-password"
                      value={reference}
                    />
                  </div>
                  <CInputGroup className="mb-1">
                    <CInput
                      type={passwordType}
                      placeholder="Enter Password"
                      value={password}
                      autocomplete="new-password"
                      onChange={(e) => setPassword(e.target.value)}
                    />

                    {passwordType === "password" ? (
                      <CButton
                        type="button"
                        color="secondary"
                        variant="outline"
                        onClick={() => setPasswordType("text")}
                      >
                        Show
                      </CButton>
                    ) : (
                      <CButton
                        type="button"
                        color="secondary"
                        variant="outline"
                        onClick={() => setPasswordType("password")}
                      >
                        Hide
                      </CButton>
                    )}
                  </CInputGroup>
                </div>
                {loading ? (
                  <CButton className="mt-4 mb-4" color="success" block>
                    <CSpinner color="white" size="sm" /> Loading ...
                  </CButton>
                ) : (
                  <CButton
                    onClick={(e) => reverseHandler(e)}
                    className="mt-4 mb-4"
                    color="success"
                    block
                  >
                    Send
                  </CButton>
                )}
              </CForm>
            </CCol>
          </CRow>
          <ToastContainer autoClose={5000} />{" "}
        </CContainer>
      </div>
    </>
  );
};

export default ReverseTransaction;
