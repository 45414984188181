/* eslint-disable import/no-anonymous-default-export */
import { axiosWithAuth } from "../../_utils/api";
import logout from "./logoutservice";


// const getAllAccounts = (params) => {
//   return axiosWithAuth
//     .get("/admin/accounts", { params })
//     .catch(function (error) {
//       if (error.response) {
//         // logout();
//       }
//     });
// };

const getAllAccounts = (page) => {
  return axiosWithAuth
    .get(`/admin/accounts?include=verification&page[number]=${page}`)
    .catch(function (error) {
      if (error.response) {
        // logout();
      }
    });
};

const getAllAccountsTransactions = (params, id) => {
  const account = id;
    return axiosWithAuth
      .get(
        `/admin/accounts/${account}/transactions?include=source,beneficiary,debitBank,creditBank`,
        {
          params,
        }
      )
      .catch((err) => {
        return err.response
      } );
  
};
const getAllAccountsInvestments = (params, id) => {
  const account = id;
  return axiosWithAuth
    .get(`/admin/accounts/${account}/investments?include=source,beneficiary`, {
      params,
    })
    .catch(function (error) {
      if (error.response) {
        // logout();
      }
    });
};

const getAllBlockedFunds = (params, id) => {
  const account = id;
  return axiosWithAuth
    .get(`/admin/accounts/${account}/block-fund?include=account,accountCount`, {
      params,
    })
    .catch(function (error) {
      if (error.response) {
        // logout();
      }
    });
};
const getAllAccountsInterest = (params, id) => {
  const account = id;
  return axiosWithAuth
    .get(`/admin/accounts/${account}/interests`, { params })
    .catch(function (error) {
      if (error.response) {
        // logout();
      }
    });
};

const updateAccountName = async (name, id)=>{
  const account = id;
  return axiosWithAuth
    .patch(`/admin/accounts/${account}/update-info`, {account_name: name})
    .catch((error)=>{
      if(error.response){
        console.log("error updating user's name")
      }
    })
}

const getAllAccountsTransactionsFilter = async (query, id) => {
  return axiosWithAuth
    .get(
      `/admin/accounts/${id}/transactions?` +
        query +
        `&include=source,beneficiary,debitBank,creditBank`
    )
    .catch(function (error) {
      if (error.response) {
        // logout();
      }
    });
};

const getAllAccountsFilter = async (query, page) => {
  if(page === undefined){
    page = 1
  }
  return await axiosWithAuth
    .get(`/admin/accounts?` + query + `&include=verification,phone_number,email&page[number]=${page}`)
    .catch(function (error) {
      
    });
};

// const getAllAccountsFilter = async (query) => {
//   return await axiosWithAuth
//     .get(`/admin/accounts?` + query)
//     .catch(function (error) {
//       if (error.response) {
//         // logout();
//       }
//     });
// };

const getAllAccountsDebitMandate = (page) => {
  return axiosWithAuth
    .get(`admin/recurring-payments`)
    .catch((error) => error);
};

const getSingleAccountDebitMandate = (id) => {
  return axiosWithAuth
    .get(`admin/accounts/${id}/recurring-payments`)
    .catch((error) => error);
};

const validateAccount = (payload) => {
  return axiosWithAuth
    .post(`/accounts/validate`, payload)
    .catch((error) =>  error.response);
};


export default {
  getAllAccounts,
  getAllAccountsFilter,
  getAllAccountsTransactions,
  getAllAccountsTransactionsFilter,
  getAllAccountsInvestments,
  getAllAccountsInterest,
  updateAccountName,
  getAllAccountsDebitMandate,
  getAllBlockedFunds,
  getSingleAccountDebitMandate,
  validateAccount
  
};
