/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";

import { CBadge, CDataTable } from "@coreui/react";

const getStatusBadge = (status) => {
  switch (status) {
    case "delivered":
      return "success";
    case "inactive":
      return "danger";
    default:
      return "danger";
  }
};

const getStateBadge = (state) => {
  if (state) {
    return "danger";
  } else {
    return "success";
  }
};

const MessagesDataTable = ({ items, loading, itemsPerPage, page }) => {
  const history = useHistory();

  const fields = [
    { key: "phone_number", label: "Phone Number", _style: { width: "10%" } },
    { key: "status", label: "Status", _style: { width: "10%" } },
    {key: "token", label: "Token", _style: { width: "30%" } },
    { key: "type", label: "Type", _style: { width: "10%" } },
    {
      key: "country",
      label: "Country",
      _style: { width: "10%" },
    },
    { key: "date", label: "Date", _style: { width: "10%" } },
    { key: "sent", label: "Total/Delivered", _style: { width: "10%" } },
  ];

  return (
    <CDataTable
      items={items}
      fields={fields}
      columnFilter
    //   tableFilter
      footer
      responsive={true}
      // itemsPerPageSelect={{
      //   label: "Items per page:",
      //   values: [5, 10, 20, 30],
      // }}
      hover
      loading={loading}
      sorter
      itemsPerPage={itemsPerPage}
      activePage
    //   clickableRows
      // This will be modified to reflect the specific account details clicked
    //   onRowClick={
    //       (item) =>
    //     history.push({
    //       pathname: `/accounts/account-details/${item.id}`,
    //       state: page,
    //     })
    //   }
      scopedSlots={{
        phone_number: (item) => (
          <td className="text-capitalize">
            {
              `+${item &&
                item.recipients &&
                item.recipients.items &&
                item.recipients.items[0].recipient}`
            }
          </td>
        ),
        status: (item) => (
          <td>
            <CBadge
              color={getStatusBadge(
                item &&
                  item.recipients &&
                  item.recipients.items &&
                  item.recipients.items[0].status
              )}
              size="md"
              className="text-capitalize"
            >
              {item &&
              item.recipients &&
              item.recipients.items &&
              item.recipients.items[0].status == "delivered"
                ? "Delivered"
                : "Failed"}
            </CBadge>
          </td>
        ),
        token: (item) => (
            <td>
              <>{item && item.body}</>
            </td>
          ),
        type: (item) => (
          <td>
            <>{item && item.type}</>
          </td>
        ),
        country: (item) => (
          <td>
            <>
              {item &&
                item.recipients &&
                item.recipients.items &&
                item.recipients.items[0].recipientCountry}
            </>
          </td>
        ),
        date: (item) => (
          <td>
            <>
              {moment(
                item &&
                  item.recipients &&
                  item.recipients.items &&
                  item.recipients.items[0].statusDatetime
              ).format("DD-MMM-YYYY LTS")}
            </>
          </td>
        ),
        sent: (item) => (
            <td>
              <>
                {`${item &&
                  item.recipients &&
                  item.recipients.totalSentCount} / ${item &&
                    item.recipients &&
                    item.recipients.totalDeliveredCount} `
                  
                  
                  }
              </>
            </td>
          ),
      }}
    />
  );
};

export default MessagesDataTable;
