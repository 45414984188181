/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useHistory, useParams, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { CSVLink } from "react-csv";
import { contentSuccess, contentError } from "../../_utils/api";
import { toast, ToastContainer } from "react-toastify";
import DatePicker from "react-datepicker";
import {
  CButton,
  CForm,
  CLabel,
  CInput,
  CListGroup,
  CListGroupItem,
  CRow,
  CCol,
  CSpinner,
  CSelect,
} from "@coreui/react";

import AccountService from "../../redux/_services/AccountService";

import { adminDebitAccount, getRoles } from "src/redux/_services/adminService";

const DebitAccountDirect = () => {
  const [loading, setLoading] = useState("");
  const [sendLoading, setSendLoading] = useState(false);
  const [accountNumber, setAccountNumber] = useState("");
  const [accountName, setAccountName] = useState("");
  const [amount, setAmount] = useState("");
  const [naration, setNaration] = useState("");
  const [pin, setPin] = useState("");
  const [type, setType] = useState("");

  const resetHandler = (e) => {
    e.preventDefault();
    setAccountNumber("");
    setAccountName("");
    setAmount("");
    setNaration("");
    setPin("");
    setType("");
  };

  const sendHandler = (e) => {
    e.preventDefault();

    if (
      accountName === "" ||
      accountNumber === "" ||
      amount === "" ||
      naration === "" ||
      pin === "" ||
      type === ""
    ) {
      toast(contentError("All field must be filled"));
      return;
    }

    const payload = {
      account_number: accountNumber,
      amount: amount,
      narration: naration,
      pin: pin,
      type: type,
    };
    setSendLoading(true);
    adminDebitAccount(payload)
      .then((res) => {
        setSendLoading(false);
        if (res.data.status === "success") {
          toast(contentSuccess(), {
            onClose: () => resetHandler(e),
          });
        } else {
          toast(contentError("an error occured"));
        }
      })
      .catch((err) => {
        setSendLoading(false);
        if (err.response.data.error.message) {
          toast(contentError(err.response.data.error.message));
        } else {
          toast(contentError("an error occured"));
        }
      });
  };

  useEffect(() => {
    var accountdigit = /^\d{10}$/;

    const payload = `filter[account_number]=${accountNumber}`;

    if (accountNumber.match(accountdigit)) {
      setAccountName("loading");
      AccountService.getAllAccountsFilter(payload)
        .then((res) => {
          if (res.data.data.accounts.data.length === 0) {
            setAccountName("Account doesn't exist");
          } else {
            setAccountName(res.data.data.accounts.data[0].account_name);
          }
        })
        .catch((err) => {
          setAccountName("Something went wrong");
        });
    }
  }, [accountNumber]);

  if (loading) {
    return (
      <CSpinner
        color="primary"
        style={{
          width: "4rem",
          height: "4rem",
          marginTop: "300px",
          marginLeft: "600px",
        }}
      />
    );
  }

  return (
    <>
      <CRow className="justify-content-center mt-4">
        <CCol sm="10" md="4" className="mx-auto px-0 px-md-3 mt-5">
          <CListGroup className="mb-3">
            <CListGroupItem href="#" color="warning">
              Debit or Credit Account
            </CListGroupItem>
          </CListGroup>
          <CForm>
            <div className="mb-3">
              <CLabel className="font-weight-bold text-dark w-100">
                Enter Account Number
              </CLabel>
              <div className="mb-3">
                <CInput
                  value={accountNumber}
                  onChange={(e) => setAccountNumber(e.target.value)}
                  type="number"
                  placeholder="Account Number"
                  autoComplete="false"
                  onWheel={() => document.activeElement.blur()}
                />{" "}
              </div>{" "}
            </div>
            <div className="mb-3">
              <CInput
                type="text"
                readOnly
                value={accountName}
                placeholder="Account Name"
              />{" "}
            </div>{" "}
            <div className="mb-3">
              <CSelect
                id="type"
                name="type"
                onChange={(e) => setType(e.target.value)}
              >
                <option value=""> Choose Type </option>{" "}
                <option value="debit"> Debit </option>{" "}
                <option value="credit"> Credit </option>{" "}
              </CSelect>
            </div>
            <div className="mb-3">
              <CLabel className="font-weight-bold text-dark w-100">
                Enter Amount
              </CLabel>
              <div className="mb-3">
                <CInput
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                  type="number"
                  placeholder="Enter Amount"
                  autoComplete="false"
                  onWheel={() => document.activeElement.blur()}
                />{" "}
              </div>{" "}
            </div>
            <div className="mb-3">
              <CLabel className="font-weight-bold text-dark w-100">
                Narration
              </CLabel>
              <div className="mb-3">
                <CInput
                  value={naration}
                  onChange={(e) => setNaration(e.target.value)}
                  type="text"
                  placeholder="Narration"
                  autoComplete="new-password"
                />{" "}
              </div>{" "}
            </div>
            <div className="mb-3">
              <CLabel className="font-weight-bold text-dark w-100">
                Transaction Pin
              </CLabel>
              <div className="mb-3">
                <CInput
                  value={pin}
                  onChange={(e) => setPin(e.target.value)}
                  type="password"
                  placeholder="Enter Pin"
                  autoComplete="new-password"
                  onWheel={() => document.activeElement.blur()}
                />{" "}
              </div>{" "}
            </div>
            {sendLoading ? (
              <CButton className="mt-4 mb-4" color="success" block>
                <CSpinner color="white" size="sm" /> Loading ...
              </CButton>
            ) : (
              <CButton
                onClick={(e) => sendHandler(e)}
                type="submit"
                className="mt-4 mb-4"
                color="success"
                block
              >
                Send
              </CButton>
            )}
          </CForm>
        </CCol>
      </CRow>

      <ToastContainer autoClose={5000} />
    </>
  );
};

export default DebitAccountDirect;
