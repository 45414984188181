/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { CSVLink } from "react-csv";
import { ChevronLeft, Printer } from "react-feather";

import {
  CImg,
  CNav,
  CNavItem,
  CNavLink,
  CTabPane,
  CTabContent,
  CModal,
  CModalBody,
  CModalHeader,
  CModalFooter,
  CButton,
  CSelect,
  CTextarea,
  CForm,
  CPagination,
  CLabel,
  CDropdown,
  CDropdownDivider,
  CDropdownToggle,
  CDropdownMenu,
  CInput,
  CCol,
  CRow,
  CSpinner,
} from "@coreui/react";

import styles from "../../scss/details.module.scss";
import "../../css/details.css";

import PartnerService from "../../redux/_services/PartnerService";

const PartnerDetails = (props) => {
  const { id } = useParams();
  const [activeKey, setActiveKey] = useState(1);
  const [partnerDetails, setPartnerDetail] = useState("");
  const [showOverlay, set_showOverlay] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const retrievePartnerDetail = () => {
      set_showOverlay(true);
      PartnerService.getSinglePartner(id)
        .then((response) => {
          setPartnerDetail(response.data.data.partner);
        })
        .finally(function () {
          set_showOverlay(false);
        })
        .catch((e) => {
          console.log(e);
        });
    };

    retrievePartnerDetail();
  }, []);

  if (loading) {
    return (
      <CSpinner
        color="primary"
        style={{
          width: "4rem",
          height: "4rem",
          marginTop: "300px",
          marginLeft: "600px",
        }}
      />
    );
  }

  return (
    <>
      <CNav variant="tabs" loading>
        <CNavItem>
          <CNavLink
            href="#"
            className={styles.bgColor}
            active={activeKey === 1}
            onClick={() => setActiveKey(1)}
          >
            Partner Information{" "}
          </CNavLink>{" "}
        </CNavItem>{" "}
      </CNav>
      <CTabContent className="bg-white brand-tab-content py-3 px-4">
        <CTabPane active={activeKey === 1}>
          <div className={styles.tab_info}>
            <h3> Personal Information </h3>{" "}
            <p className="mb-5">
              Basic information like the partner's name, email, and mobile
              number{" "}
            </p>{" "}
            <CImg
              src={
                PartnerDetails?.image === null
                  ? "Not Available"
                  : PartnerDetails?.image
              }
              fluid
              width="133"
              height="133"
              className="rounded-circle mb-4"
              alt="User Image"
            />
            <div className={styles.field_info}>
              <div className={styles.field_item}>
                <div className={styles.field_wrapper}>
                  <span className={styles.field_label}> Business Name </span>{" "}
                  <span className={styles.field_value}>
                    {PartnerDetails && partnerDetails.business_name}{" "}
                  </span>{" "}
                </div>{" "}
              </div>{" "}
              <div className={styles.field_item}>
                <div className={styles.field_wrapper}>
                  <span className={styles.field_label}>
                    {" "}
                    Business Industry{" "}
                  </span>{" "}
                  <span className={styles.field_value}>
                    {PartnerDetails && partnerDetails.business_industry}{" "}
                  </span>{" "}
                </div>{" "}
              </div>{" "}
              <div className={styles.field_item}>
                <div className={styles.field_wrapper}>
                  <span className={styles.field_label}> Business Licence </span>{" "}
                  <span className={styles.field_value}>
                    {PartnerDetails && partnerDetails.has_licence
                      ? "Yes"
                      : "None"}{" "}
                  </span>{" "}
                </div>{" "}
              </div>{" "}
              <div className={styles.field_item}>
                <div className={styles.field_wrapper}>
                  <span className={styles.field_label}>
                    {" "}
                    Expected Turn Over{" "}
                  </span>{" "}
                  <span className={styles.field_value}>
                    {PartnerDetails && partnerDetails.expected_turnover
                      ? PartnerDetails && partnerDetails.expected_turnover
                      : "Not Available"}{" "}
                  </span>{" "}
                </div>{" "}
              </div>{" "}
              <div className={styles.field_item}>
                <div className={styles.field_wrapper}>
                  <span className={styles.field_label}> Full Name </span>{" "}
                  <span className={styles.field_value}>
                    {PartnerDetails &&
                      partnerDetails.owner &&
                      partnerDetails.owner.full_name.replace(
                        /(^\w)|([-\s]\w)/g,
                        (match) => match.toUpperCase()
                      )}{" "}
                  </span>{" "}
                </div>{" "}
              </div>{" "}
              <div className={styles.field_item}>
                <div className={styles.field_wrapper}>
                  <span className={styles.field_label}> Email </span>{" "}
                  <span className={styles.field_value_email}>
                    {" "}
                    {PartnerDetails &&
                      partnerDetails.owner &&
                      partnerDetails.owner.email}{" "}
                  </span>{" "}
                </div>{" "}
              </div>{" "}
              <div className={styles.field_item}>
                <div className={styles.field_wrapper}>
                  <span className={styles.field_label}> Mobile Number </span>{" "}
                  <span className={styles.field_value}>
                    {" "}
                    {PartnerDetails &&
                    partnerDetails.owner &&
                    partnerDetails.owner.telephone === null
                      ? "Not Available"
                      : PartnerDetails &&
                        partnerDetails.owner &&
                        partnerDetails.owner.telephone}{" "}
                  </span>{" "}
                </div>{" "}
              </div>{" "}
            </div>{" "}
          </div>
          <div className={styles.additional_info}>
            <h5 className={styles.additional_info_heading}>
              Additional Information{" "}
            </h5>
            <div className={styles.field_info}>
              <div className={styles.field_item}>
                <div className={styles.field_wrapper}>
                  <span className={styles.field_label}> Referrer ID </span>{" "}
                  <span className={styles.field_value}>
                    {" "}
                    {PartnerDetails &&
                    partnerDetails.owner &&
                    partnerDetails.owner.referrer_id
                      ? PartnerDetails &&
                        partnerDetails.owner &&
                        partnerDetails.owner.referrer_id
                      : "Not Available"}{" "}
                  </span>{" "}
                </div>{" "}
              </div>
              <div className={styles.field_item}>
                <div className={styles.field_wrapper}>
                  <span className={styles.field_label}> Date of Birth </span>{" "}
                  <span className={styles.field_value}>
                    {" "}
                    {PartnerDetails.date_of_birth === null
                      ? "Not Available"
                      : moment(PartnerDetails.date_of_birth).format(
                          "DD-MMM-YYYY"
                        )}
                  </span>{" "}
                </div>{" "}
              </div>{" "}
              <div className={styles.field_long_item}>
                <div className={styles.field_wrapper}>
                  <span className={styles.field_label}> Address </span>{" "}
                  <span className={styles.field_value}>
                    {" "}
                    {PartnerDetails &&
                    partnerDetails.owner &&
                    partnerDetails.owner.address_line_one
                      ? PartnerDetails &&
                        partnerDetails.owner &&
                        partnerDetails.owner.address_line_one
                      : "Not Available"}{" "}
                  </span>{" "}
                </div>{" "}
              </div>{" "}
              <div className={styles.field_item}>
                <div className={styles.field_wrapper}>
                  <span className={styles.field_label}> City </span>{" "}
                  <span className={styles.field_value}>
                    {" "}
                    {PartnerDetails &&
                    partnerDetails.owner &&
                    partnerDetails.owner.city
                      ? PartnerDetails &&
                        partnerDetails.owner &&
                        partnerDetails.owner.city
                      : "Not Available"}{" "}
                  </span>{" "}
                </div>{" "}
              </div>{" "}
              <div className={styles.field_item}>
                <div className={styles.field_wrapper}>
                  <span className={styles.field_label}> State </span>{" "}
                  <span className={styles.field_value}>
                    {" "}
                    {PartnerDetails &&
                    partnerDetails.owner &&
                    partnerDetails.owner.state
                      ? PartnerDetails &&
                        partnerDetails.owner &&
                        partnerDetails.owner.state
                      : "Not Available"}{" "}
                  </span>{" "}
                </div>{" "}
              </div>{" "}
              <div className={styles.field_item}>
                <div className={styles.field_wrapper}>
                  <span className={styles.field_label}>
                    {" "}
                    Email Verified On{" "}
                  </span>{" "}
                  <span className={styles.field_value}>
                    {" "}
                    {PartnerDetails &&
                    partnerDetails.owner &&
                    partnerDetails.owner.email_verified_at === null
                      ? "Not Available"
                      : moment(
                          PartnerDetails &&
                            partnerDetails.owner &&
                            partnerDetails.owner.email_verified_at
                        ).format("DD-MMM-YYYY")}{" "}
                  </span>{" "}
                </div>{" "}
              </div>{" "}
              <div className={styles.field_item}>
                <div className={styles.field_wrapper}>
                  <span className={styles.field_label}>
                    Phone Number Verified On{" "}
                  </span>{" "}
                  <span className={styles.field_value}>
                    {" "}
                    {PartnerDetails &&
                        partnerDetails.owner &&
                        partnerDetails.owner.telephone_verified_at === null
                      ? "Not Available"
                      : moment(PartnerDetails &&
                        partnerDetails.owner &&
                        partnerDetails.owner.telephone_verified_at).format(
                          "DD-MMM-YYYY"
                        )}{" "}
                  </span>{" "}
                </div>{" "}
              </div>{" "}
              <div className={styles.field_item}>
                <div className={styles.field_wrapper}>
                  <span className={styles.field_label}> Date Registered </span>{" "}
                  <span className={styles.field_value}>
                    {" "}
                    {PartnerDetails &&
                        partnerDetails.owner &&
                        partnerDetails.owner.created_at === null
                      ? "Not Available"
                      : moment(PartnerDetails &&
                        partnerDetails.owner &&
                        partnerDetails.owner.created_at).format(
                          "DD-MMM-YYYY"
                        )}{" "}
                  </span>{" "}
                </div>{" "}
              </div>{" "}
            </div>{" "}
          </div>
        </CTabPane>
      </CTabContent>{" "}
      <ToastContainer />{" "}
    </>
  );
};

export default PartnerDetails;
