/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";

import {
  CCard,
  CCardBody,
  CButton,
  CTooltip,
  CModal,
  CModalHeader,
  CForm,
  CModalBody,
  CLabel,
  CInput,
  CModalFooter,
  CSpinner,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { Overlay } from "../../components";

import styles from "../../scss/details.module.scss";
import moment from "moment";
import { axiosWithAuth, selectToken } from "../../_utils/api";

import cardStyles from "../../scss/general.module.scss";
import { useInvestmentDetails } from "../../_hooks/investments";
import { InvestmentTopUp } from "../../redux/_actions/investmentActions";
import Investments from "./Investments";
const InvestmentDetails = () => {
  const { id } = useParams();
  const token = selectToken;
  const [topUpModal, settopUpModal] = useState(false);
  const [amount, set_amount] = useState("");
  const [pin, set_pin] = useState("");
  const [source_account_number, set_source_account] = useState("");
  const [source_account_name, set_source_account_name] = useState("");
  const [showOverlay, set_showOverlay] = useState(false);
  const dispatch = useDispatch();
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;
  const userRole =
    userInfo &&
    userInfo.data &&
    userInfo.data.user &&
    userInfo.data.user.roles &&
    userInfo.data.user.roles[0].name;

  const [currentInvestment, fetchInvestmentData, loading] =
    useInvestmentDetails(id);
  useEffect(() => {
    fetchInvestmentData();
  }, [fetchInvestmentData]);
  const toggleTopUpModal = () => {
    settopUpModal(!topUpModal);
  };
  const topUpInvestment = useSelector((state) => state.topUpInvestment);
  const { loadin } = topUpInvestment;
  const update = (e) => {
    if (e.target.name === "source_account") {
      set_source_account(e.target.value);
    } else if (e.target.name === "amount") {
      set_amount(e.target.value);
    } else if (e.target.name === "pin") {
      set_pin(e.target.value);
    }
  };
  useEffect(() => {
    var accountdigit = /^\d{10}$/;
    var bank_code = "000000";
    const account_number = source_account_number;
    if (source_account_number.match(accountdigit)) {
      set_showOverlay(true);
      axiosWithAuth
        .post("/accounts/validate", {
          source_account_number,
          account_number,
          bank_code,
        })

        .then((res) => {
          const cb_account_name = res.data.data.account_name;
          set_source_account_name(cb_account_name);
        })
        .finally(function () {
          set_showOverlay(false);
        })
        .catch((e) => {
          // toast.error(contentError(e));
        });
    } else if (!source_account_number.match(accountdigit)) {
      set_source_account_name("");
    }

    return () => {
      //
    };
  }, [source_account_number]);
  const submitHandler = (e) => {
    e.preventDefault();
    const source_account = source_account_number;
    dispatch(InvestmentTopUp(amount, pin, source_account, id));
  };
  if (loading) {
    return (
      <CSpinner
        color="primary"
        style={{
          width: "4rem",
          height: "4rem",
          marginTop: "300px",
          marginLeft: "600px",
        }}
      />
    );
  }
  return (
    <>
      <Overlay active={showOverlay} marginTop="200px">
        <CCard className={cardStyles.card_height}>
          <CCardBody>
            <div className={styles.tab_info}>
              <div className="d-flex justify-content-between align-items-center">
                {/* <div> */}
                <h3>Investment Details</h3>

                {currentInvestment.is_active === true &&
                  userRole !== "customer-experience" && (
                    <div className={styles.tab_tooltip}>
                      <CTooltip
                        content="Top-up Investment"
                        placement="bottom"
                        className="pr-2"
                      >
                        <CButton
                          color="success"
                          size="sm"
                          onClick={toggleTopUpModal}
                        >
                          <CIcon name="cilPencil" size="sm" />
                        </CButton>
                      </CTooltip>
                    </div>
                  )}
              </div>
              <div className={styles.field_info}>
                <div className={styles.field_item}>
                  <div className={styles.field_wrapper}>
                    <span className={styles.field_label}>Status</span>
                    <span className={styles.field_value}>
                      <span
                        className={
                          currentInvestment &&
                          currentInvestment.is_active === true
                            ? "text-success"
                            : "text-danger"
                        }
                      >
                        {/* <span className="text-success"> */}{" "}
                        {currentInvestment &&
                        currentInvestment.is_active === true
                          ? "Active"
                          : "Inactive"}
                      </span>
                    </span>
                  </div>
                </div>

                <div className={styles.field_item}>
                  <div className={styles.field_wrapper}>
                    <span className={styles.field_label}>Account Type</span>
                    <span className={styles.field_value}>
                      {" "}
                      {currentInvestment &&
                        currentInvestment.source &&
                        currentInvestment.source.account_number &&
                        currentInvestment.source.type &&
                        currentInvestment.source.type.name}
                    </span>
                  </div>
                </div>

                <div className={styles.field_item}>
                  <div className={styles.field_wrapper}>
                    <span className={styles.field_label}>Investment Type</span>
                    <span className={styles.field_value}>
                      {currentInvestment && currentInvestment.type === null
                        ? "Custom"
                        : currentInvestment &&
                          currentInvestment.type &&
                          currentInvestment.type.name}
                    </span>
                  </div>
                </div>

                <div className={styles.field_item}>
                  <div className={styles.field_wrapper}>
                    <span className={styles.field_label}>Rollover Type</span>
                    <span className={styles.field_value}>
                      {currentInvestment && currentInvestment.type === null
                        ? "Custom"
                        : currentInvestment &&
                          currentInvestment.rollover === null
                        ? "Not Available"
                        : currentInvestment &&
                          currentInvestment.rollover &&
                          currentInvestment.rollover.name}
                    </span>
                  </div>
                </div>

                <div className={styles.field_item}>
                  <div className={styles.field_wrapper}>
                    <span className={styles.field_label}>Debit Account</span>
                    <Link
                      to={`/accounts/account-details/${currentInvestment.source_account}`}
                      className={styles.field_value_link}
                    >
                      {currentInvestment &&
                        currentInvestment.source &&
                        currentInvestment.source.account_number}
                    </Link>
                  </div>
                </div>

                <div className={styles.field_item}>
                  <div className={styles.field_wrapper}>
                    <span className={styles.field_label}>Account Name</span>
                    <Link className={styles.field_value_link}>
                      {currentInvestment &&
                        currentInvestment.source &&
                        currentInvestment.source.account_name}
                    </Link>
                  </div>
                </div>

                <div className={styles.field_item}>
                  <div className={styles.field_wrapper}>
                    <span className={styles.field_label}>Credit Account</span>
                    <Link
                      to={`/accounts/account-details/${currentInvestment.beneficiary_account}`}
                      className={styles.field_value_link}
                    >
                      {currentInvestment &&
                        currentInvestment.beneficiary &&
                        currentInvestment.beneficiary.account_number}
                    </Link>
                  </div>
                </div>

                <div className={styles.field_item}>
                  <div className={styles.field_wrapper}>
                    <span className={styles.field_label}>Title</span>
                    <span className={styles.field_value}>
                      {currentInvestment.title}
                    </span>
                  </div>
                </div>

                <div className={styles.field_item}>
                  <div className={styles.field_wrapper}>
                    <span className={styles.field_label}>Total Amount</span>
                    <span className={styles.field_value}>
                      ₦
                      {currentInvestment &&
                        currentInvestment.total_amount &&
                        currentInvestment.total_amount
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </span>
                  </div>
                </div>

                <div className={styles.field_item}>
                  <div className={styles.field_wrapper}>
                    <span className={styles.field_label}>Duration</span>
                    <span className={styles.field_value}>
                      {" "}
                      {currentInvestment &&
                        currentInvestment.type &&
                        currentInvestment.type.duration}{" "}
                      Months
                    </span>
                  </div>
                </div>

                <div className={styles.field_item}>
                  <div className={styles.field_wrapper}>
                    <span className={styles.field_label}>Interest Rate</span>
                    <span className={styles.field_value}>
                      {" "}
                      {currentInvestment &&
                        currentInvestment.type &&
                        currentInvestment.type.interest_rate}
                      %
                    </span>
                  </div>
                </div>

                <div className={styles.field_item}>
                  <div className={styles.field_wrapper}>
                    <span className={styles.field_label}>
                      Interest Total Amount
                    </span>
                    <span className={styles.field_value}>
                      {" "}
                      ₦
                      {currentInvestment &&
                        currentInvestment.interest &&
                        currentInvestment.interest.total_amount}
                    </span>
                  </div>
                </div>

                <div className={styles.field_item}>
                  <div className={styles.field_wrapper}>
                    <span className={styles.field_label}>Start Date</span>
                    <span className={styles.field_value}>
                      {" "}
                      {moment(currentInvestment.start_at).format("DD-MMM-YYYY")}
                    </span>
                  </div>
                </div>

                <div className={styles.field_item}>
                  <div className={styles.field_wrapper}>
                    <span className={styles.field_label}>Due Date</span>
                    <a className={styles.field_value_link}>
                      {moment(currentInvestment.due_at).format("DD-MMM-YYYY")}
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <CModal show={topUpModal} onClose={toggleTopUpModal} centered>
              <CModalHeader closeButton>Top-up Investment</CModalHeader>
              <CForm>
                <CModalBody>
                  <div className="mb-3">
                    <CLabel htmlFor="transferLimitAmount">
                      Amount <span>*</span>
                    </CLabel>
                    <CInput
                      type="number"
                      id="transferLimitAmount"
                      placeholder="Enter Amount"
                      onChange={update}
                      name="amount"
                      min="10"
                      value={amount}
                    ></CInput>
                  </div>
                  <div className="mb-3">
                    <CLabel htmlFor="transferLimitAmount">
                      Source Account
                    </CLabel>
                    <CInput
                      type="number"
                      maxLength={10}
                      placeholder="Enter Source Account"
                      onChange={update}
                      name="source_account"
                    ></CInput>
                  </div>
                  <div className="mb-3">
                    <CLabel htmlFor="sourceAccountName">
                      Source Account Name
                    </CLabel>
                    <CInput
                      id="sourceAccountName"
                      value={source_account_name}
                      readOnly
                    />
                  </div>
                  <div className="mb-3">
                    <CLabel htmlFor="transferLimitAmount">
                      Transaction Pin
                    </CLabel>
                    <CInput
                      type="password"
                      maxLength={4}
                      placeholder="Enter Pin"
                      name="pin"
                      onChange={update}
                    ></CInput>
                  </div>
                </CModalBody>
                <CModalFooter>
                  <CButton
                    color="success"
                    variant="outline"
                    block
                    onClick={(e) => submitHandler(e)}
                  >
                    {loadin && <i className="fa fa-ellipsis-h fa-spin"></i>}
                    {loadin && (
                      <span>
                        <i className="fa fa-angle-right ml-1"></i>
                      </span>
                    )}
                    {!loadin && <span> Top Up</span>}
                  </CButton>{" "}
                </CModalFooter>
              </CForm>
            </CModal>
          </CCardBody>
        </CCard>
      </Overlay>
      <ToastContainer autoClose={5000} />{" "}
    </>
  );
};

export default InvestmentDetails;
