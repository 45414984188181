import { axiosWithAuth } from "../../_utils/api";

const getMandateSchedule = async () => {
  return axiosWithAuth.get(`/recurring-payments/create`).catch((err) => err);
};

const createMandate = async (payload) => {
  return axiosWithAuth.
  post(`admin/recurring-payments`,payload)
};

const getSingleAccountDebitMandate = async (id) => {
  return axiosWithAuth
    .get(`admin/accounts/${id}/recurring-payments`)
    .catch((error) => error);
};

const getSingleDebitMandate = async  (id) => {
  return axiosWithAuth
    .get(`/admin/recurring-payments/${id}`)
    .catch((error) => error);
};

const deleteDebitMandate = async  (id) => {
  return axiosWithAuth
    .get(`/admin/recurring-payments/${id}/terminate`)
    .catch((error) => error);
};

const getAllDebitMandate = async (page) => {
  return axiosWithAuth
    .get(`admin/recurring-payments`)
    .catch((error) => error);
};

export default {
  getMandateSchedule,
  getSingleAccountDebitMandate,
  getAllDebitMandate,
  createMandate,
  getSingleDebitMandate,
  deleteDebitMandate
} 
 
