import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ToastContainer } from "react-toastify";
import './security.scss';

import {
  CButton,
  CCol,
  CContainer,
  CRow,
  CForm,
  CLabel,
  CInput,
  CFormGroup,
} from "@coreui/react";
import {
  requestEmailToken,
  resetPinToken,
} from "../../redux/_actions/transactionActions";

const emailRegEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const ResetTransactionPin = () => {
  const dispatch = useDispatch();
  const [activeSection, setActiveSection] = useState({
    activeSection: "reset-transaction-pin-email",
  });
  const [email, setEmail] = useState("");
  const [pin, set_pin] = useState("");
  const [token, set_token] = useState("");
  const [disabled, setDisabled] = useState(true);

  const requestToken = useSelector((state) => state.requestToken);
  const { loading } = requestToken;

  const changeSection = (prop) => (event) => {
    event.preventDefault();
    dispatch(requestEmailToken(email));
    setActiveSection({
      ...activeSection,
      activeSection: prop,
    });
  };

  useEffect(() => {
    if (emailRegEx.test(email)) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [email]);

  const update = (e) => {
    if (e.target.name === "email") {
      setEmail(e.target.value);
    } else if (e.target.name === "pin") {
      set_pin(e.target.value);
    } else if (e.target.name === "token") {
      set_token(e.target.value);
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(resetPinToken(pin, token));
  };
  return (
    <CContainer fluid>
      <CRow className="align-items-center mb-md-4 mb-3 border-bottom">
        <h2 className="mb-0 pb-3">Security</h2>
      </CRow>
      <CRow className="justify-content-center">
        <CCol lg="10" xl="8" className="col-12 mx-auto px-0 px-md-3 max-container">
          {/* Start of Reset Transaction Pin Email Step */}

          {activeSection.activeSection === "reset-transaction-pin-email" && (
            <>
              <CRow className="justify-content-center align-items-center mb-5">
                <CCol className="col-12 mx-auto" md="6">
                  <h3 className="mb-2">Reset transaction pin</h3>
                  <p className="text-muted mb-xl-0">
                    We will email you a verification code after you click
                    submit.
                  </p>
                </CCol>
              </CRow>

              <CRow>
                <CCol className="col-12 mx-auto" md="6">
                  <CForm>
                    <CFormGroup>
                      <CLabel>Email Address</CLabel>
                      <CInput
                        type="email"
                        className="form-control"
                        name="email"
                        onChange={update}
                      />
                    </CFormGroup>
                    <CButton
                      block
                      color="primary"
                      type="submit"
                      onClick={changeSection("reset-transaction-pin-main")}
                      disabled={disabled}
                    >
                      {loading && <i className="fa fa-ellipsis-h fa-spin"></i>}
                      {loading && (
                        <span>
                          <i className="fa fa-angle-right ml-1"></i>
                        </span>
                      )}
                      {!loading && (
                        <span>
                          Submit
                          <i className="fa fa-angle-right ml-1"></i>
                        </span>
                      )}
                    </CButton>
                  </CForm>
                </CCol>
              </CRow>
            </>
          )}

          {/* End of Reset Transaction Pin Email Step */}

          {/* Start of Reset Transaction Pin Main Section */}

          {activeSection.activeSection === "reset-transaction-pin-main" && (
            <>
              <CRow className="justify-content-between align-items-center mb-5">
                <CCol className="col-12" md="9" xl="7">
                  <h3 className="mb-2">Reset your transaction pin</h3>
                  <p className="text-muted mb-xl-0">
                    We have sent a verification code to your mail, input it in
                    the verification code input below
                  </p>
                </CCol>
              </CRow>

              <CRow>
                <CCol className="col-12" md="6">
                  <CForm>
                    <CFormGroup>
                      <CLabel>Verification Code</CLabel>
                      <CInput
                        type="text"
                        className="form-control"
                        name="token"
                        onChange={update}
                        maxLength={6}
                      />
                    </CFormGroup>
                    <CFormGroup>
                      <CLabel>New transaction pin</CLabel>
                      <CInput
                        type="password"
                        className="form-control"
                        name="pin"
                        onChange={update}
                      />
                    </CFormGroup>
                    <CFormGroup className="mb-4">
                      <CLabel>Confirm new transaction pin</CLabel>
                      <CInput
                        type="password"
                        className="form-control"
                        name="pin"
                        onChange={update}
                      />
                    </CFormGroup>
                    <CButton
                      block
                      color="primary"
                      type="submit"
                      onClick={(e) => submitHandler(e)}
                    >
                      Reset transaction pin
                    </CButton>
                  </CForm>
                </CCol>
              </CRow>
            </>
          )}

          {/* End of Reset Transaction Pin Main Section */}
        </CCol>
      </CRow>
      <ToastContainer autoClose={500} />{" "}
    </CContainer>
  );
};

export default ResetTransactionPin;
