/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { CSVLink } from "react-csv";
import { ChevronLeft, Printer } from "react-feather";

import {
  CImg,
  CNav,
  CNavItem,
  CNavLink,
  CTabPane,
  CTabContent,
  CModal,
  CModalBody,
  CModalHeader,
  CModalFooter,
  CButton,
  CSelect,
  CTextarea,
  CForm,
  CPagination,
  CLabel,
  CDropdown,
  CDropdownDivider,
  CDropdownToggle,
  CDropdownMenu,
  CInput,
  CCol,
  CRow,
  CSpinner,
} from "@coreui/react";

import styles from "../../scss/details.module.scss";
import "../../css/details.css";
import {
  useUsersDetails,
  useUsersVerificationDetails,
} from "../../_hooks/users";
import { useAllAccounts, useAccountTypes } from "../../_hooks/accounts";
import { verifyUser, verifyFailedUser } from "../../redux/_actions/userActions";
import UserService from "../../redux/_services/UserService";
import TransactionDataTable from "src/components/DataTables/TransactionDataTable";
import AccountsDataTable from "src/components/DataTables/AccountsDataTable";
import UsersDataTable from "src/components/DataTables/UsersDataTable";
const UserDetails = (props) => {
  const { id } = useParams();
  const [activeKey, setActiveKey] = useState(1);
  const [comment, set_comment] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [status, set_status] = useState("");
  const [disabled, setDisabled] = useState("");
  const [modal, setModal] = useState(false);
  const [filterName, set_filter_name] = useState();
  const [filterVal, set_filter_value] = useState();
  const [query, set_query] = useState();
  const [queryAccounts, set_queryAccounts] = useState();
  const [user_transactions_data, set_user_transactions_data] = useState("");
  const [currentUserReferralData, setCurrentUserReferralData] = useState([]);

  const [all_account_data, set_account_data] = useAllAccounts(id);
  const [showOverlay, set_showOverlay] = useState(false);
  const [pages, set_pages] = useState(1);

  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;
  const userRole =
    userInfo &&
    userInfo.data &&
    userInfo.data.user &&
    userInfo.data.user.roles &&
    userInfo.data.user.roles[0].name;

  //////////
  const dispatch = useDispatch();

  const update = (e) => {
    if (e.target.name === "comment") {
      set_comment(e.target.value);
    } else if (e.target.name === "status_type") {
      setSelectedStatus(e.target.value);
      set_status(e.target.value);
    }
  };
  useEffect(() => {
    if (selectedStatus === "Failed") {
      setDisabled(false);
    } else if (selectedStatus === "Verified") {
      setDisabled(true);
    }
  }, [selectedStatus]);
  const submitHandler = () => {
    if (selectedStatus === "Verified") {
      dispatch(verifyUser(id, status.toLowerCase()));
    } else if (selectedStatus === "Failed") {
      dispatch(verifyFailedUser(status.toLowerCase(), comment, id));
    }
  };

  const userVerify = useSelector((state) => state.userVerify);
  const { loadingVerify, success, verify } = userVerify;

  const [currentUser, fetchUsersData, loading] = useUsersDetails(id);
  useEffect(() => {
    fetchUsersData();
    setActiveKey(1);
  }, [fetchUsersData]);
  const [currentUserVerification, fetchUserVerificationData] =
    useUsersVerificationDetails(id);
  useEffect(() => {
    fetchUserVerificationData();
  }, [fetchUserVerificationData]);

  const [account_types_data, fetchAccountTypesData] = useAccountTypes();
  useEffect(() => {
    fetchAccountTypesData();
  }, [fetchAccountTypesData]);
  const savingsId =
    account_types_data && account_types_data[1] && account_types_data[1].id;
  const businessId =
    account_types_data && account_types_data[0] && account_types_data[0].id;
  const toggle = () => {
    setModal(!modal);
  };
  const refreshFilter = (e) => {
    e.preventDefault();
    document.getElementById("create-filter-form").reset();
  };
  useEffect(() => {
    if (success === true && verify.status === "success") {
      toggle();
      fetchUserVerificationData();
    }
  }, [success]);

  const history = useHistory();
  const queryPage = useLocation().search.match(/page=([0-9]+)/, "");
  const currentPage = Number(queryPage && queryPage[1] ? queryPage[1] : 1);
  const [page, setPage] = useState(currentPage);

  const pageChange = (newPage) => {
    currentPage !== newPage &&
      history.push(`/users/user-details?page=${newPage}`);
  };

  const pageChangeTransactions = (newPage) => {
    currentPage !== newPage &&
      history.push(`/users/user-details?page=${newPage}`);
  };

  useEffect(() => {
    currentPage !== page && setPage(currentPage);
  }, [currentPage, page]);

  const getRequestParams = (page) => {
    let params = {};

    if (page) {
      params[`page[number]`] = page + 1;
    }

    return params;
  };

  const updateAccountFilter = (e) => {
    if (e.target.name === "account_name") {
      set_filter_name(e.target.name);
      set_filter_value(e.target.value);
    } else if (e.target.name === "account_number") {
      set_filter_name(e.target.name);
      set_filter_value(e.target.value);
    } else if (e.target.name === "type_id") {
      set_filter_name(e.target.name);
      if (e.target.value === "savings") {
        set_filter_value(savingsId);
      } else if (e.target.value === "business") {
        set_filter_value(businessId);
      }
    } else if (e.target.name === "account_status") {
      set_filter_name(e.target.name);
      set_filter_value(e.target.value);
    } else if (e.target.name === "post_no_debit") {
      set_filter_name(e.target.name);
      set_filter_value(e.target.value);
    } else if (e.target.name === "trashed") {
      set_filter_name(e.target.name);
      set_filter_value(e.target.value);
    }
  };
  const updateTransactionFilter = (e) => {
    if (e.target.name === "transaction_reference") {
      set_filter_name(e.target.name);
      set_filter_value(e.target.value);
    } else if (e.target.name === "credit_account_number") {
      set_filter_name(e.target.name);
      set_filter_value(e.target.value);
    } else if (e.target.name === "credit_account_name") {
      set_filter_name(e.target.name);
      set_filter_value(e.target.value);
    } else if (e.target.name === "debit_account_number") {
      set_filter_name(e.target.name);
      set_filter_value(e.target.value);
    } else if (e.target.name === "debit_account_name") {
      set_filter_name(e.target.name);
      set_filter_value(e.target.value);
    } else if (e.target.name === "amount") {
      set_filter_name(e.target.name);
      set_filter_value(e.target.value);
    } else if (e.target.name === "bank") {
      set_filter_name(e.target.name);
      set_filter_value(e.target.value);
    } else if (e.target.name === "type") {
      set_filter_name(e.target.name);
      set_filter_value(e.target.value);
    } else if (e.target.name === "status") {
      set_filter_name(e.target.name);
      set_filter_value(e.target.value);
    }
  };
  const submitAccountFilterHandler = (e) => {
    e.preventDefault();
    const queries = `filter[${filterName}]=${filterVal}`;
    set_queryAccounts(queries);
  };
  useEffect(() => {
    const retrieveAllUserAccounts = () => {
      const params = getRequestParams(page);
      set_showOverlay(true);
      UserService.getAllUsersAccounts(params, id)
        .then((response) => {
          if (response?.data?.data) {
            set_pages(response?.data?.data?.transactions?.meta?.last_page);
            set_account_data(response?.data?.data?.user["accounts"]);
          }
        })
        .finally(function () {
          set_showOverlay(false);
        })
        .catch((e) => {
          console.log(e);
        });
    };

    retrieveAllUserAccounts();
  }, [page, id]);
  useEffect(() => {
    const retrieveAccountsFilter = () => {
      if (queryAccounts) {
        set_showOverlay(true);
        UserService.getAllUsersAccountsFilter(queryAccounts, id)
          .then((response) => {
            set_account_data(response.data.data.user["accounts"]);
            set_pages(response.data.data.transactions.meta.last_page);
          })
          .finally(function () {
            set_showOverlay(false);
          })
          .catch((e) => {
            console.log(e);
          });
      }
    };
    retrieveAccountsFilter();
  }, [queryAccounts]);
  const submitTransactionsFilterHandler = (e) => {
    e.preventDefault();
    const queries = `filter[${filterName}]=${filterVal}`;
    set_query(queries);
  };
  useEffect(() => {
    const retrieveAllUserTransactions = () => {
      const params = getRequestParams(page);
      set_showOverlay(true);
      UserService.getAllUserTransactions(params, id)
        .then((response) => {
          if (response?.data?.data?.transactions) {
            set_pages(response.data.data.transactions.meta.last_page);
            set_user_transactions_data(response.data.data.transactions.data);
          }
        })
        .finally(function () {
          set_showOverlay(false);
        })
        .catch((e) => {
          console.log(e);
        });
    };

    retrieveAllUserTransactions();
  }, [page, currentPage, id]);
  useEffect(() => {
    const retrieveTransactionsFilter = () => {
      if (query) {
        set_showOverlay(true);
        UserService.getAllUserTransactionsFilter(query, id)
          .then((response) => {
            set_user_transactions_data(response.data.data.transactions.data);
            set_pages(response.data.data.transactions.meta.last_page);
          })
          .finally(function () {
            set_showOverlay(false);
          })
          .catch((e) => {
            console.log(e);
          });
      }
    };
    retrieveTransactionsFilter();
  }, [query]);
  useEffect(() => {
    const fetchUserReferralDetails = () => {
      const params = getRequestParams(page);
      UserService.getAllUserReferrals(params, id)
        .then((response) => {
          const fetchUserReferralData = response.data.data.users.data;
          setCurrentUserReferralData(fetchUserReferralData);
        })
        .catch((e) => {
          console.log(e);
        });
    };

    fetchUserReferralDetails();
  }, [page, currentPage, id]);

  useEffect(() => {
    sessionStorage.setItem("userPage", props.location.state);
  }, []);

  const [dates, setDate] = useState({
    referralStartDate: "",
    referralEndDate: "",
    transactionStartDate: "",
    transactionEndDate: "",
    accountStartDate: "",
    accountEndDate: "",
  });

  const setDateValue = (prop) => (event) => {
    event.preventDefault();
    setDate({
      ...dates,
      [prop]: event.target.value,
    });
  };
  if (loading) {
    return (
      <CSpinner
        color="primary"
        style={{
          width: "4rem",
          height: "4rem",
          marginTop: "300px",
          marginLeft: "600px",
        }}
      />
    );
  }

  const previousPage = sessionStorage.getItem("userPage");
  const date = new Date();
  const downloadDate = moment(date).format("DD-MMM-YYYY");
  const accountFileName = `cloudbank_users_account_information_${downloadDate}.csv`;
  const transactionFileName = `cloudbank_users_transaction_information_${downloadDate}.csv`;
  const referralFileName = `cloudbank_users_referrals_information_${downloadDate}.csv`;

  return (
    <>
      <div className="d-flex justify-content-end">
        <CButton
          onClick={() => history.push(`/users/${previousPage}`)}
          className="px-4 mb-4"
          color="success"
        >
          <ChevronLeft />
          Back
        </CButton>
      </div>
      <CNav variant="tabs" loading>
        <CNavItem>
          <CNavLink
            href="#"
            className={styles.bgColor}
            active={activeKey === 1}
            onClick={() => setActiveKey(1)}
          >
            Personal Information{" "}
          </CNavLink>{" "}
        </CNavItem>{" "}
        <CNavItem>
          <CNavLink
            href="#"
            active={activeKey === 2}
            onClick={() => setActiveKey(2)}
          >
            Accounts{" "}
          </CNavLink>{" "}
        </CNavItem>{" "}
        <CNavItem>
          <CNavLink
            href="#"
            active={activeKey === 3}
            onClick={() => setActiveKey(3)}
          >
            Transactions{" "}
          </CNavLink>{" "}
        </CNavItem>{" "}
        <CNavItem>
          <CNavLink
            href="#"
            active={activeKey === 4}
            onClick={() => setActiveKey(4)}
          >
            Referrals{" "}
          </CNavLink>{" "}
        </CNavItem>{" "}
      </CNav>
      <CTabContent className="bg-white brand-tab-content py-3 px-4">
        <CTabPane active={activeKey === 1}>
          <div className={styles.tab_info}>
            <h3> Personal Information </h3>{" "}
            <p className="mb-5">
              Basic information like the user 's name, email, gender and mobile
              number{" "}
            </p>{" "}
            <CImg
              src={
                currentUser.thumbnail === null
                  ? "Not Available"
                  : currentUser.thumbnail
              }
              fluid
              width="133"
              height="133"
              className="rounded-circle mb-4"
              alt="User Image"
            />
            <div className={styles.field_info}>
              <div className={styles.field_item}>
                <div className={styles.field_wrapper}>
                  <span className={styles.field_label}> Full Name </span>{" "}
                  <span className={styles.field_value}>
                    {" "}
                    {currentUser.full_name &&
                      currentUser.full_name.replace(
                        /(^\w)|([-\s]\w)/g,
                        (match) => match.toUpperCase()
                      )}{" "}
                  </span>{" "}
                </div>{" "}
              </div>{" "}
              <div className={styles.field_item}>
                <div className={styles.field_wrapper}>
                  <span className={styles.field_label}> Email </span>{" "}
                  <span className={styles.field_value_email}>
                    {" "}
                    {currentUser.email}{" "}
                  </span>{" "}
                </div>{" "}
              </div>{" "}
              <div className={styles.field_item}>
                <div className={styles.field_wrapper}>
                  <span className={styles.field_label}> Mobile Number </span>{" "}
                  <span className={styles.field_value}>
                    {" "}
                    {currentUser.telephone === null
                      ? "Not Available"
                      : currentUser.telephone}{" "}
                  </span>{" "}
                </div>{" "}
              </div>{" "}
              <div className={styles.field_item}>
                <div className={styles.field_wrapper}>
                  <span className={styles.field_label}> Gender </span>{" "}
                  <span className={styles.field_value}>
                    {" "}
                    {currentUser.gender === null
                      ? "Not Available"
                      : currentUser.gender && currentUser.gender}{" "}
                  </span>{" "}
                </div>{" "}
              </div>{" "}
            </div>{" "}
          </div>
          <div className={styles.additional_info}>
            <h5 className={styles.additional_info_heading}>
              Additional Information{" "}
            </h5>
            <div className={styles.field_info}>
              <div className={styles.field_item}>
                <div className={styles.field_wrapper}>
                  <span className={styles.field_label}> Referrer ID </span>{" "}
                  <span className={styles.field_value}>
                    {" "}
                    {currentUser.referral_code}{" "}
                  </span>{" "}
                </div>{" "}
              </div>
              <div className={styles.field_item}>
                <div className={styles.field_wrapper}>
                  <span className={styles.field_label}> Date of Birth </span>{" "}
                  <span className={styles.field_value}>
                    {" "}
                    {currentUser.date_of_birth === null
                      ? "Not Available"
                      : moment(currentUser.date_of_birth).format("DD-MMM-YYYY")}
                  </span>{" "}
                </div>{" "}
              </div>{" "}
              <div className={styles.field_long_item}>
                <div className={styles.field_wrapper}>
                  <span className={styles.field_label}> Address </span>{" "}
                  <span className={styles.field_value}>
                    {" "}
                    {currentUser.address_line_one === null
                      ? "Not Available"
                      : currentUser.address_line_one}{" "}
                  </span>{" "}
                </div>{" "}
              </div>{" "}
              <div className={styles.field_item}>
                <div className={styles.field_wrapper}>
                  <span className={styles.field_label}> City </span>{" "}
                  <span className={styles.field_value}>
                    {" "}
                    {currentUser.city === null
                      ? "Not Available"
                      : currentUser.city}{" "}
                  </span>{" "}
                </div>{" "}
              </div>{" "}
              <div className={styles.field_item}>
                <div className={styles.field_wrapper}>
                  <span className={styles.field_label}> State </span>{" "}
                  <span className={styles.field_value}>
                    {" "}
                    {currentUser.state === null
                      ? "Not Available"
                      : currentUser.state}{" "}
                  </span>{" "}
                </div>{" "}
              </div>{" "}
              {/* <div className={styles.field_item}>
                <div className={styles.field_wrapper}>
                  <span className={styles.field_label}> Occupation </span>{" "}
                  <span className={styles.field_value}>
                    {" "}
                    Frontend Developer{" "}
                  </span>{" "}
                </div>{" "}
              </div>{" "} */}
              <div className={styles.field_item}>
                <div className={styles.field_wrapper}>
                  <span className={styles.field_label}>
                    {" "}
                    Email Verified On{" "}
                  </span>{" "}
                  <span className={styles.field_value}>
                    {" "}
                    {currentUser.email_verified_at === null
                      ? "Not Available"
                      : moment(currentUser.email_verified_at).format(
                          "DD-MMM-YYYY"
                        )}{" "}
                  </span>{" "}
                </div>{" "}
              </div>{" "}
              <div className={styles.field_item}>
                <div className={styles.field_wrapper}>
                  <span className={styles.field_label}>
                    Phone Number Verified On{" "}
                  </span>{" "}
                  <span className={styles.field_value}>
                    {" "}
                    {currentUser.telephone_verified_at === null
                      ? "Not Available"
                      : moment(currentUser.telephone_verified_at).format(
                          "DD-MMM-YYYY"
                        )}{" "}
                  </span>{" "}
                </div>{" "}
              </div>{" "}
              <div className={styles.field_item}>
                <div className={styles.field_wrapper}>
                  <span className={styles.field_label}> Date Registered </span>{" "}
                  <span className={styles.field_value}>
                    {" "}
                    {currentUser.created_at === null
                      ? "Not Available"
                      : moment(currentUser.created_at).format(
                          "DD-MMM-YYYY"
                        )}{" "}
                  </span>{" "}
                </div>{" "}
              </div>{" "}
            </div>{" "}
          </div>
          <div className={styles.section_info}>
            <div className={styles.section_top}>
              <h4 className="mb-0"> Verification </h4>{" "}
              {userRole === "customer-experience" ? (
                ""
              ) : (
                <a onClick={toggle} className={styles.section_link}>
                  Verify User{" "}
                </a>
              )}{" "}
            </div>
            <div className="my-2">
              <div className={styles.field_info}>
                <div className={styles.field_item}>
                  <div className={styles.field_wrapper}>
                    <span className={styles.field_label}> Status</span>{" "}
                    <span className={styles.field_value}>
                      <span
                        className={
                          currentUserVerification &&
                          currentUserVerification.verification === null
                            ? "badge badge-warning"
                            : currentUserVerification &&
                              currentUserVerification.verification &&
                              currentUserVerification.verification.status &&
                              currentUserVerification.verification.status ===
                                "failed"
                            ? "badge badge-danger"
                            : currentUserVerification &&
                              currentUserVerification.verification &&
                              currentUserVerification.verification.status ===
                                "verified"
                            ? "badge badge-success"
                            : ""
                        }
                      >
                        {/* <span className="badge badge-warning"> */}{" "}
                        {currentUserVerification.verification === null
                          ? "pending"
                          : currentUserVerification &&
                            currentUserVerification.verification &&
                            currentUserVerification.verification.status}{" "}
                      </span>{" "}
                    </span>{" "}
                  </div>{" "}
                </div>{" "}
              </div>{" "}
            </div>
            <div className={styles.section_document}>
              {currentUser.identity_card === null ? (
                <span className={styles.section_document_link}>
                  Not Available
                </span>
              ) : (
                <a
                  href={currentUser.identity_card}
                  className={styles.section_document_link}
                  target="_blank"
                  rel="noreferrer"
                >
                  View Identity Card
                </a>
              )}
              {currentUser.utility_bill === null ? (
                <span className={styles.section_document_link}>
                  Not Available
                </span>
              ) : (
                <a
                  href={currentUser.utility_bill}
                  className={styles.section_document_link}
                  target="_blank"
                  rel="noreferrer"
                >
                  View Utility Bill
                </a>
              )}
            </div>
            <div className={styles.section_note_section}>
              <h5 className={styles.section_note_heading}> Comments </h5>
              <div className={styles.section_note_card}>
                {currentUserVerification.verification === null && (
                  <p>No Comment</p>
                )}
                {currentUserVerification &&
                  currentUserVerification.verification &&
                  currentUserVerification.verification.comment && (
                    <div className={styles.section_note_text}>
                      {currentUserVerification.verification.comment}
                    </div>
                  )}
              </div>{" "}
            </div>{" "}
          </div>
          <CModal show={modal} onClose={toggle} centered>
            <CModalHeader closeButton> Verify User </CModalHeader>{" "}
            <CForm>
              <CModalBody>
                <div className="mb-3">
                  <CLabel htmlFor="status"> Status </CLabel>{" "}
                  <CSelect
                    id="status_type"
                    name="status_type"
                    onChange={update}
                  >
                    <option> Choose Verification Status </option>{" "}
                    <option> Verified </option> <option> Failed </option>{" "}
                  </CSelect>{" "}
                </div>
                <div className="mb-3">
                  <CLabel htmlFor="status"> Add Comment </CLabel>{" "}
                  <CTextarea
                    onChange={update}
                    name="comment"
                    disabled={disabled}
                  />{" "}
                </div>{" "}
              </CModalBody>{" "}
              <CModalFooter>
                <CButton color="primary" onClick={(e) => submitHandler(e)}>
                  {" "}
                  {loadingVerify && (
                    <i className="fa fa-ellipsis-h fa-spin"> </i>
                  )}{" "}
                  {loadingVerify && (
                    <span>
                      <i className="fa fa-angle-right ml-1"> </i>{" "}
                    </span>
                  )}{" "}
                  {!loadingVerify && (
                    <span>
                      Submit <i className="fa fa-angle-right ml-1"> </i>{" "}
                    </span>
                  )}{" "}
                </CButton>{" "}
              </CModalFooter>{" "}
            </CForm>{" "}
          </CModal>{" "}
        </CTabPane>
        <CTabPane active={activeKey === 2}>
          <div className={styles.tab_info}>
            <div className="d-flex align-items-center justify-content-between mb-5">
              <div>
                <h3> A Brief summary </h3>{" "}
                <p>
                  The basic information about the user 's account(s) are
                  displayed here{" "}
                </p>{" "}
              </div>{" "}
              <CSVLink
                data={
                  all_account_data &&
                  all_account_data.map((x) => ({
                    account_name: x.account_name,
                    account_number: x.account_number,
                    type: x.type && x.type && x.type.name,
                    book_balance: x.book_balance,
                    status: x.status,
                    post_no_debit_enabled:
                      x.post_no_debit_enabled === true ? "True" : "False",
                    deleted_at: x.deleted_at === null ? "Available" : "Deleted",
                  }))
                }
                filename={accountFileName}
              >
                <Printer size={15} />
              </CSVLink>
            </div>{" "}
          </div>
          <CDropdown variant="dropdown" className="mb-3">
            <CDropdownToggle
              color="secondary"
              size="md"
              className="px-2 px-md-3"
            >
              Advanced Filter{" "}
            </CDropdownToggle>{" "}
            <CDropdownMenu
              style={{ minWidth: "250px", width: "100%", maxWidth: "380px" }}
            >
              <h6 className="px-3"> Advanced Filter </h6> <CDropdownDivider />
              <CForm id="create-filter-form">
                <CRow className="px-3 py-2">
                  <CCol className="col-12 mb-2">
                    <CLabel className="font-weight-bold text-dark w-100">
                      Account Name{" "}
                    </CLabel>{" "}
                    <CInput
                      type="text"
                      className="w-100"
                      name="account_name"
                      onChange={updateAccountFilter}
                    />
                  </CCol>
                  <CCol className="col-12 mb-2">
                    <CLabel className="font-weight-bold text-dark w-100">
                      Account Number{" "}
                    </CLabel>{" "}
                    <CInput
                      type="text"
                      className="w-100"
                      name="account_number"
                      onChange={updateAccountFilter}
                    />
                  </CCol>
                  <CCol className="col-12 mb-2">
                    <CLabel className="font-weight-bold text-dark w-100">
                      Account Type{" "}
                    </CLabel>{" "}
                    <CSelect
                      className="w-100"
                      name="type_id"
                      onChange={updateAccountFilter}
                    >
                      <option value="">All</option>
                      <option value="savings">Savings</option>
                      <option value="business">Business</option>
                    </CSelect>{" "}
                  </CCol>
                  <CCol className="col-12 mb-2">
                    <CLabel className="font-weight-bold text-dark w-100">
                      Account Status
                    </CLabel>
                    <CSelect
                      className="w-100"
                      name="account_status"
                      onChange={updateAccountFilter}
                    >
                      <option value="">All</option>
                      <option value="active">Active</option>
                      <option value="inactive">Inactive</option>
                    </CSelect>
                  </CCol>
                  {/* <CCol className="col-12 mb-2">
                    <CLabel className="font-weight-bold text-dark w-100">
                      Post No Debit{" "}
                    </CLabel>{" "}
                    <CSelect className="w-100">
                      <option value="" disabled>
                        Status{" "}
                      </option>{" "}
                      <option value="true"> True </option>{" "}
                      <option value="false"> False </option>{" "}
                    </CSelect>{" "}
                  </CCol> */}
                  <CCol className="col-12 mb-2">
                    <CLabel className="font-weight-bold text-dark w-100">
                      Account State
                    </CLabel>
                    <CSelect
                      className="w-100"
                      name="trashed"
                      onChange={updateAccountFilter}
                    >
                      <option value="with">All</option>
                      <option value="available">Available</option>
                      <option value="only">Deleted</option>
                    </CSelect>
                  </CCol>{" "}
                  <CCol className="col-12 col-md-6 mb-2">
                    <CLabel className="font-weight-bold text-dark w-100">
                      Start Date
                    </CLabel>
                    <CInput
                      type="date"
                      className="w-100"
                      value={dates.startDate}
                      name="created_between"
                      onChange={setDateValue("startDate")}
                    />
                  </CCol>
                  <CCol className="col-12 col-md-6 mb-2">
                    <CLabel className="font-weight-bold text-dark w-100">
                      End Date
                    </CLabel>
                    <CInput
                      type="date"
                      className="w-100"
                      value={dates.endDate}
                      name="created_between"
                      onChange={setDateValue("endDate")}
                    />
                  </CCol>
                </CRow>{" "}
                <CDropdownDivider />
                <div className="d-flex align-items-center justify-content-between px-3 py-1">
                  <a
                    className="cursor-pointer text-primary"
                    role="button"
                    onClick={(e) => refreshFilter(e)}
                  >
                    Reset Filter
                  </a>
                  <CButton
                    color="dark"
                    type="submit"
                    onClick={(e) => submitAccountFilterHandler(e)}
                  >
                    Submit
                  </CButton>
                </div>{" "}
              </CForm>{" "}
            </CDropdownMenu>{" "}
          </CDropdown>

          <AccountsDataTable items={all_account_data} itemsPerPage={30} />

          <CPagination
            activePage
            onActivePageChange={pageChange}
            pages={1}
            doubleArrows={false}
            align="end"
          />
        </CTabPane>
        <CTabPane active={activeKey === 3}>
          <div className={styles.tab_info}>
            <div className="d-flex align-items-center justify-content-between mb-5">
              <div>
                <h3>Transactions</h3>{" "}
                <p>
                  All the user 's accounts' transactions - both debit and credit
                  are populated in the table here{" "}
                </p>{" "}
              </div>{" "}
              <CSVLink
                data={
                  user_transactions_data &&
                  user_transactions_data.map((x) => ({
                    transaction_reference: x.transaction_reference,
                    credit_account_number:
                      x.beneficiary && x.beneficiary.account_number,
                    credit_account_name:
                      x.beneficiary &&
                      x.beneficiary.account_name &&
                      x.beneficiary.account_name
                        .toString()
                        .replace(/(^\w)|([-\s]\w)/g, (match) =>
                          match.toUpperCase()
                        ),
                    debit_account_number: x.source && x.source.account_number,
                    debit_account_name:
                      x.source &&
                      x.source.account_name &&
                      x.source.account_name
                        .toString()
                        .replace(/(^\w)|([-\s]\w)/g, (match) =>
                          match.toUpperCase()
                        ),
                    amount:
                      x.amount &&
                      x.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                    bank_code: x.bank && x.bank.bank_name,
                    type: x.type,
                    status: x.status,
                    created_at: x.created_at
                      ? x.created_at.slice(0, 10)
                      : "Not Available",
                  }))
                }
                filename={transactionFileName}
              >
                <Printer size={15} />
              </CSVLink>
            </div>{" "}
          </div>
          <CDropdown variant="dropdown" className="mb-3">
            <CDropdownToggle
              color="secondary"
              size="md"
              className="px-2 px-md-3"
            >
              Advanced Filter{" "}
            </CDropdownToggle>{" "}
            <CDropdownMenu
              style={{ minWidth: "250px", width: "100%", maxWidth: "380px" }}
            >
              <h6 className="px-3"> Advanced Filter </h6> <CDropdownDivider />
              <CForm id="create-filter-form">
                <CRow className="px-3 py-2">
                  <CCol className="col-12 mb-2">
                    <CLabel className="font-weight-bold text-dark w-100">
                      Transaction Reference{" "}
                    </CLabel>{" "}
                    <CInput
                      className="w-100"
                      name="transaction_reference"
                      onChange={updateTransactionFilter}
                    />
                  </CCol>{" "}
                  <CCol className="col-12 mb-2">
                    <CLabel className="font-weight-bold text-dark w-100">
                      Credit Account Number{" "}
                    </CLabel>{" "}
                    <CInput
                      type="text"
                      className="w-100"
                      name="credit_account_number"
                      onChange={updateTransactionFilter}
                    />
                  </CCol>{" "}
                  <CCol className="col-12 mb-2">
                    <CLabel className="font-weight-bold text-dark w-100">
                      Credit Account Name{" "}
                    </CLabel>{" "}
                    <CInput
                      type="text"
                      className="w-100"
                      name="credit_account_name"
                      onChange={updateTransactionFilter}
                    />
                  </CCol>{" "}
                  <CCol className="col-12 mb-2">
                    <CLabel className="font-weight-bold text-dark w-100">
                      Debit Account Number{" "}
                    </CLabel>{" "}
                    <CInput
                      type="text"
                      className="w-100"
                      name="debit_account_number"
                      onChange={updateTransactionFilter}
                    />
                  </CCol>{" "}
                  <CCol className="col-12 mb-2">
                    <CLabel className="font-weight-bold text-dark w-100">
                      Debit Account Name{" "}
                    </CLabel>{" "}
                    <CInput
                      type="text"
                      className="w-100"
                      name="debit_account_name"
                      onChange={updateTransactionFilter}
                    />
                  </CCol>{" "}
                  <CCol className="col-12 mb-2">
                    <CLabel className="font-weight-bold text-dark w-100">
                      Amount(₦){" "}
                    </CLabel>{" "}
                    <CInput
                      type="text"
                      className="w-100"
                      name="amount"
                      onChange={updateTransactionFilter}
                    />
                  </CCol>{" "}
                  <CCol className="col-12 mb-2">
                    <CLabel className="font-weight-bold text-dark w-100">
                      Bank{" "}
                    </CLabel>{" "}
                    <CInput
                      type="text"
                      className="w-100"
                      name="bank"
                      onChange={updateTransactionFilter}
                    />
                  </CCol>{" "}
                  <CCol className="col-12 mb-2">
                    <CLabel className="font-weight-bold text-dark w-100">
                      Transaction Type{" "}
                    </CLabel>{" "}
                    <CSelect
                      className="w-100"
                      onChange={updateTransactionFilter}
                      name="type"
                    >
                      <option value="">All</option>
                      <option value="debit">Debit</option>
                      <option value="credit">Credit</option>
                    </CSelect>{" "}
                  </CCol>{" "}
                  <CCol className="col-12 mb-2">
                    <CLabel className="font-weight-bold text-dark w-100">
                      Transaction Status{" "}
                    </CLabel>{" "}
                    <CSelect
                      className="w-100"
                      name="status"
                      onChange={updateTransactionFilter}
                    >
                      <option value="">All</option>
                      <option value="success">Success</option>
                      <option value="pending">Pending</option>
                      <option value="failed">Failed</option>
                    </CSelect>{" "}
                  </CCol>{" "}
                  <CCol className="col-12 col-md-6 mb-2">
                    <CLabel className="font-weight-bold text-dark w-100">
                      Start Date{" "}
                    </CLabel>{" "}
                    <CInput
                      type="date"
                      className="w-100"
                      value={dates.transactionStartDate}
                      name="created_between"
                      onChange={setDateValue("transactionStartDate")}
                    />{" "}
                  </CCol>
                  <CCol className="col-12 col-md-6 mb-2">
                    <CLabel className="font-weight-bold text-dark w-100">
                      End Date{" "}
                    </CLabel>{" "}
                    <CInput
                      type="date"
                      className="w-100"
                      value={dates.transactionEndDate}
                      name="created_between"
                      onChange={setDateValue("transactionEndDate")}
                    />{" "}
                  </CCol>{" "}
                </CRow>{" "}
                <CDropdownDivider />
                <div className="d-flex align-items-center justify-content-between px-3 py-1">
                  <a
                    className="cursor-pointer text-primary"
                    role="button"
                    onClick={(e) => refreshFilter(e)}
                  >
                    Reset Filter{" "}
                  </a>{" "}
                  <CButton
                    color="dark"
                    type="submit"
                    onClick={(e) => submitTransactionsFilterHandler(e)}
                  >
                    Submit{" "}
                  </CButton>{" "}
                </div>{" "}
              </CForm>{" "}
            </CDropdownMenu>{" "}
          </CDropdown>
          <TransactionDataTable
            data={user_transactions_data}
            loading={showOverlay}
            itemsPerPage={30}
            page={page}
          />
          <CPagination
            activePage
            onActivePageChange={pageChangeTransactions}
            pages={5}
            doubleArrows={false}
            align="end"
          />
        </CTabPane>
        <CTabPane active={activeKey === 4}>
          <div className={styles.tab_info}>
            <div className="d-flex align-items-center justify-content-between mb-5">
              <div>
                <h3> Referrals </h3>{" "}
                <p>
                  {" "}
                  Collection of People that have been reffered by the user{" "}
                </p>{" "}
              </div>{" "}
              <CSVLink
                data={
                  currentUserReferralData &&
                  currentUserReferralData.map((x) => ({
                    full_name:
                      x.full_name &&
                      x.full_name.replace(/(^\w)|([-\s]\w)/g, (match) =>
                        match.toUpperCase()
                      ),
                    email: x.email,
                    gender: x.gender === null ? "Not Available" : x.gender,
                    verification_status:
                      x.verification === null
                        ? "pending"
                        : x.verification && x.verification.status,
                    date_registered: x.created_at,
                  }))
                }
                filename={referralFileName}
              >
                <Printer size={15} />
              </CSVLink>
            </div>{" "}
          </div>

          <UsersDataTable items={currentUserReferralData} itemsPerPage={30} />

          <CPagination
            activePage
            onActivePageChange={pageChange}
            pages={1}
            doubleArrows={true}
            align="end"
          />
        </CTabPane>{" "}
      </CTabContent>{" "}
      <ToastContainer />{" "}
    </>
  );
};

export default UserDetails;
