/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useCallback } from "react";
import { axiosWithAuth, selectToken } from "../_utils/api";
// import logout from "../redux/_services/logoutservice";

export const useInvestmentDetails = (id) => {
  const [currentInvestment, setCurrentInvestment] = useState([]);
  const token = selectToken;

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const fetchInvestmentDetails = useCallback(() => {
    const fetcInvestmentData = async () => {
      const req = async () => {
        try {
          setLoading(true);
          const { data } = await axiosWithAuth.get(
            `/admin/investments/${id}?include=source,beneficiary,investmentInterest`
          );
          setCurrentInvestment(data.data.investment);
        } catch ({ response }) {
          setError(response);
          if (response) {
            // logout();
          }
        } finally {
          setLoading(false);
        }
      };
      req();
    };
    fetcInvestmentData();
  }, [id, token]);

  useEffect(() => {
    fetchInvestmentDetails();
  }, [fetchInvestmentDetails]);
  return [currentInvestment, fetchInvestmentDetails, loading, error];
};

export const useInvestmentTypes = () => {
  const [investmentTypesData, setInvestmentTypesData] = useState([]);
  const token = selectToken;

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const fetchInvestmentsTypes = useCallback(() => {
    const fetchInvestmentTypesData = async () => {
      const req = async () => {
        try {
          setLoading(true);
          const { data } = await axiosWithAuth.get(`/investment-types`);
          setInvestmentTypesData(data.data.investmentTypes);
        } catch ({ response }) {
          setError(response);
          if (response.status) {
            // logout();
          }
        } finally {
          setLoading(false);
        }
      };
      req();
    };
    fetchInvestmentTypesData();
  }, [token]);

  useEffect(() => {
    fetchInvestmentsTypes();
  }, [fetchInvestmentsTypes]);
  return [investmentTypesData, fetchInvestmentsTypes, loading, error];
};

export const useRolloverTypes = () => {
  const [rolloverTypesData, setRolloverTypesData] = useState([]);
  const token = selectToken;

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const fetchRolloverTypes = useCallback(() => {
    const fetchRolloverTypesData = async () => {
      const req = async () => {
        try {
          setLoading(true);
          const { data } = await axiosWithAuth.get(`/rollover-types`);
          setRolloverTypesData(data.data.rolloverTypes);
        } catch ({ response }) {
          setError(response);
          if (response.status) {
            // logout();
          }
        } finally {
          setLoading(false);
        }
      };
      req();
    };
    fetchRolloverTypesData();
  }, [token]);

  useEffect(() => {
    fetchRolloverTypes();
  }, [fetchRolloverTypes]);
  return [rolloverTypesData, fetchRolloverTypes, loading, error];
};
