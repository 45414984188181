import {
CREATE_ACCOUNT_FAIL,
CREATE_ACCOUNT_REQUEST,
CREATE_ACCOUNT_SUCCESS,

} from "../_constants/adminConstants"

function createAccountReducer(state = {}, action) {
    switch (action.type) {
      case CREATE_ACCOUNT_REQUEST:
        return { loading: true };
      case CREATE_ACCOUNT_SUCCESS:
        return {
          loading: false,
          createAccountData: action.payload,
        };
      case CREATE_ACCOUNT_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  }



  export {
    createAccountReducer
  }