export const SAVE_TRANSACTION = "SAVE_TRANSACTION";
export const CREATE_TRANSACTION_REQUEST = "CREATE_TRANSACTION_REQUEST";
export const CREATE_TRANSACTION_SUCCESS = "CREATE_TRANSACTION_SUCCESS";
export const CREATE_TRANSACTION_FAIL = "CREATE_TRANSACTION_FAIL";

export const USER_UPDATEPIN_REQUEST = "USER_UPDATEPIN_REQUEST";
export const USER_UPDATEPIN_SUCCESS = "USER_UPDATEPIN_SUCCESS";
export const USER_UPDATEPIN_FAIL = "USER_UPDATEPIN_FAIL";

export const USER_RESETPIN_REQUEST = "USER_RESETPIN_REQUEST";
export const USER_RESETPIN_SUCCESS = "USER_RESETPIN_SUCCESS";
export const USER_RESETPIN_FAIL = "USER_RESETPIN_FAIL";

export const USER_REQUESTTOKEN_REQUEST = "USER_REQUESTTOKEN_REQUEST";
export const USER_REQUESTTOKEN_SUCCESS = "USER_REQUESTTOKEN_SUCCESS";
export const USER_REQUESTTOKEN_FAIL = "USER_REQUESTTOKEN_FAIL";
