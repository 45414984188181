/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useHistory, useParams, Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { contentSuccess, contentError } from "../../_utils/api";
import {
  CNav,
  CNavItem,
  CNavLink,
  CTabPane,
  CTabContent,
  CButton,
  CDataTable,
  CSpinner,
  CRow,
  CCol,
  CModal,
  CModalBody,
  CModalHeader,
  CForm,
  CInput,
  CInputGroup,
  CListGroup,
  CListGroupItem,
  CSelect
} from "@coreui/react";

import { ChevronLeft } from "react-feather";
import styles from "../../scss/details.module.scss";
import "../../css/details.css";
import { getRolesDetails, getPermissionsDropDown, addRolesPermission, deleteRolesPermission } from "src/redux/_services/adminService";

const RoleDetails = (props) => {
  const { id } = useParams();
  const [activeKey, setActiveKey] = useState(1);
  const [loading, setLoading] = useState("");
  const [roleDetails, setRoleDetails] = useState("");
  const [permission, setPermission] = useState([]);
  const [currentpage, set_currentpage] = useState();
  const [lastPage, set_lastpage] = useState("");
  const [addPermissionModal, setAddPermissionModal] = useState(false);
  const [permissionDropDown, setPermissionDropDown] = useState([]);
  const [addPermission, setAddPermission] = useState("");
  const [addPermissionLoading, setAddPermissionLoading] = useState(false)
  const [permissions, setPermissions] = useState([]);

  const toggleAddPermissionModal = () => {
    setAddPermissionModal(!addPermissionModal);
  };

  const history = useHistory();

  const pageChange = (newPage) => {
    set_currentpage(newPage);
  };

  const deletePermissionHandler = (e, permissionId) => {
    e.preventDefault();

    setLoading(true);
    deleteRolesPermission(id, permissionId)
    .then((res) => {
      getRolesDetails(id)
      .then((res) => {
        setRoleDetails(res.data.data.role);
        setPermission(res.data.data.permission);
      })
      .catch((err) => err)
    })
    .catch((err) => {
      if (err.response.data.error.message) {
        toast(contentError(err.response.data.error.message));
      } else {
        toast(contentError("An Error Occured"));
      }
    })
    .finally(() => setLoading(false));
  };

  const addPermissionHandler = (e) => {
    e.preventDefault();
    if (addPermission === "") {
      toast(contentError("Permission cannot be empty"));
      return;
    }

    setAddPermissionLoading(true);
    addRolesPermission(id, addPermission)
      .then((res) => {
        getRolesDetails(id)
        .then((res) => {
          setRoleDetails(res.data.data.role);
          setPermission(res.data.data.permission);
        })
        .catch((err) => err)
      })
      .catch((err) => {
        if (err.response.data.error.message) {
          toast(contentError(err.response.data.error.message));
        } else {
          toast(contentError("An Error Occured"));
        }
      })
      .finally(() => setAddPermissionLoading(false));
  };

  useEffect(() => {
    setLoading(true);
    getRolesDetails(id)
      .then((res) => {
        setRoleDetails(res.data.data.role);
        setPermission(res.data.data.permission);
      })
      .catch((err) => err)
      .finally(() => {
        setLoading(false);
      });
      getPermissionsDropDown()
      .then((res) => {
        setPermissionDropDown(res.data.data.permissions);
      })
      .catch((err) => err);
  }, [currentpage]);

  if (loading) {
    return (
      <CSpinner
        color="primary"
        style={{
          width: "4rem",
          height: "4rem",
          marginTop: "300px",
          marginLeft: "600px",
        }}
      />
    );
  }

  return (
    <>
      <div className="d-flex justify-content-end">
        <CButton
          onClick={() => history.goBack()}
          className="px-4 mb-4"
          color="success"
        >
          <ChevronLeft />
          Back
        </CButton>
      </div>
      <CNav variant="tabs">
        <CNavItem>
          <CNavLink
            href="#"
            active={activeKey === 1}
            onClick={() => setActiveKey(1)}
          >
            Role Details
          </CNavLink>
        </CNavItem>
      </CNav>

      <CTabContent className="bg-white brand-tab-content py-3 px-4">
        <CTabPane active={activeKey === 1}>
          <p className="mb-5">
            Basic information about the role and it's permissions
          </p>
          <div className={styles.field_info}>
            <div className={styles.field_item}>
              <div className={styles.field_wrapper}>
                <span className={styles.field_label}>Role Name</span>

                <p className={styles.field_value_link}>{roleDetails?.name}</p>
              </div>
            </div>
          </div>
          <div className={styles.field_info}>
            <div className={styles.field_item}>
              <div className={styles.field_wrapper}>
                <span className={styles.field_label}>Description</span>

                <p className={styles.field_value_link}>
                  {roleDetails?.description
                    ? roleDetails.description
                    : "Not Available"}
                </p>
              </div>
            </div>
          </div>
          <h2>Permissions</h2>
          <div className="my-5">
            <CButton color="success" onClick={toggleAddPermissionModal}>
              Add Permission
            </CButton>
          </div>
          <CModal
            show={addPermissionModal}
            onClose={toggleAddPermissionModal}
            centered
          >
            <CModalHeader closeButton className="text-danger">
              CLOSE
            </CModalHeader>
            <CModalBody>
              <CListGroup className="mb-3">
                <CListGroupItem href="#" color="warning">
                  Add Permissions to Internal an User
                </CListGroupItem>
              </CListGroup>
              <CRow className="justify-content-center">
                <CCol>
                  <CForm autoComplete="off">
                    <div className>
                      <CInputGroup className="mb-3">
                        <CSelect
                          className="w-100"
                          name="status"
                          defaultValue={""}
                          onChange={(e) => setAddPermission(e.target.value)}
                        >
                          <option value="">Select Permission</option>
                          {permissionDropDown &&
                            permissionDropDown.map((permission, index) => (
                              <option
                                key={index}
                                value={permission.id}
                                className="text-capitalize"
                              >
                                {permission?.display_name}
                              </option>
                            ))}
                        </CSelect>{" "}
                      </CInputGroup>
                    </div>
                    {addPermissionLoading ? (
                      <CButton className="mt-4 mb-4" color="warning" block>
                        <CSpinner color="white" size="sm" /> Loading ...
                      </CButton>
                    ) : (
                      <CButton
                        onClick={addPermissionHandler}
                        className="mt-4 mb-4"
                        color="success"
                        block
                      >
                        Add Permission
                      </CButton>
                    )}
                  </CForm>
                </CCol>
              </CRow>
              <ToastContainer autoClose={5000} />{" "}
            </CModalBody>
          </CModal>
          <div className="col-12 col-md-8">
            <DataTable
              items={roleDetails?.permissions}
              loading={loading}
              itemsPerPage={30}
              deleteAction={deletePermissionHandler}
            />
          </div>
        </CTabPane>
      </CTabContent>

      <ToastContainer autoClose={3000} />
    </>
  );
};

export default RoleDetails;

const DataTable = ({ items, loading, itemsPerPage, deleteAction }) => {
  const history = useHistory();

  const fields = [
    { key: "permission", label: "Permission" },
    { key: "description", label: "Description" },
    { key: "remove", label: "Remove" },
  ];

  return (
    <CDataTable
      items={items}
      fields={fields}
      columnFilter
      footer
      responsive={true}
      hover
      loading={loading}
      sorter
      itemsPerPage={itemsPerPage}
      activePage
      // clickableRows
      This
      will
      be
      modified
      to
      reflect
      the
      specific
      account
      details
      clicked
      // onRowClick={(item) =>
      //   history.push({
      //     pathname: `/admin/permissions/${item.id}`,
      //     state: page,
      //   })
      // }
      scopedSlots={{
        permission: (item) => (
          <td className="text-capitalize">{item && item.display_name}</td>
        ),
        description: (item) => (
          <td className="text-capitalize">
            {item && item.description ? item.description : "Not Available"}
          </td>
        ),
        remove: (item) => (
          <td className="text-capitalize text-danger">
            <CButton
              color="danger"
              size="sm"
              onClick={(e) => deleteAction(e, item.id)}
            >
              Delete
            </CButton>
          </td>
        ),
      }}
    
    />
  );
};
