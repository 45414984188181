import React, { useState, useEffect } from "react";
import { CRow, CPagination, CSpinner, CDataTable } from "@coreui/react";
import { useHistory, useLocation } from "react-router-dom";
import { getRoles } from "src/redux/_services/adminService";

const Roles = (props) => {
  const [roles, setRoles] = useState([]);
  const [showOverlay, set_showOverlay] = useState(false);
  const [loading, setLoading] = useState("");
  const [currentpage, set_currentpage] = useState();
  const [lastPage, set_lastpage] = useState(1);
  const history = useHistory();

  const pageChange = (newPage) => {
    set_currentpage(newPage);
  };

  useEffect(() => {
    const retrieveRoles = () => {
      set_showOverlay(true);
      setLoading(true);
      getRoles(currentpage)
        .then((res) => {
          setRoles(res.data.data.roles.data);
          set_currentpage(res.data.data.roles.meta.current_page);
          set_lastpage(res.data.data.roles.meta.last_page);
        })
        .finally(() => {
          set_showOverlay(false);
          setLoading(false);
        })
        .catch((err) => {});
    };

    retrieveRoles();
  }, []);

  if (loading) {
    return (
      <CSpinner
        color="primary"
        style={{
          width: "4rem",
          height: "4rem",
          marginTop: "300px",
          marginLeft: "600px",
        }}
      />
    );
  }

  return (
    <>
      <div className="bg-white p-3">
        <div className="d-flex justify-content-between align-items-center p-3">
          <h4 className="text-dark">Current Roles</h4>
        </div>

        <CRow>
          <div className="col-12 col-md-8">
          <DataTable items={roles} loading={showOverlay} itemsPerPage={30} />
          </div>

          <div className="p-3  col-sm-12">
            <CPagination
              activePage={currentpage}
              onActivePageChange={pageChange}
              pages={lastPage}
              doubleArrows={false}
              align="end"
            />
          </div>
        </CRow>
      </div>
    </>
  );
};

export default Roles;

const DataTable = ({ items, loading, itemsPerPage, page }) => {
  const history = useHistory();

  const fields = [
    { key: "roles", label: "Roles" },
    { key: "description", label: "Description" },
  ];

  return (
    <CDataTable
      items={items}
      fields={fields}
      columnFilter
      footer
      responsive={true}
      hover
      loading={loading}
      sorter
      itemsPerPage={itemsPerPage}
      // activePage
      clickableRows
      This
      will
      be
      modified
      to
      reflect
      the
      specific
      account
      details
      clicked
      onRowClick={(item) =>
        history.push({
          pathname: `/admin/roles/${item.id}`,
          state: page,
        })
      }
      scopedSlots={{
        roles: (item) => (
          <td className="text-capitalize">{item && item.name}</td>
        ),
        description: (item) => (
          <td className="text-capitalize">
            {item && item.description ? item.description : "Not Available"}
          </td>
        ),
      }}
    />
  );
};
