import { createStore,  applyMiddleware } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import { CookieStorage } from "redux-persist-cookie-storage";
import thunk from "redux-thunk";
import logger from "redux-logger";
import createSagaMiddleware from "redux-saga";
import { routerMiddleware } from "react-router-redux";
import { composeWithDevTools } from "redux-devtools-extension";
import Cookies from "js-cookie";
// import Cookies from 'cookies-js'
// import storage from "redux-persist/lib/storage";
import reducer from "./rootReducer";

const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware];
if (process.env.NODE_ENV === "development") {
  middlewares.push(logger);
}


const persistConfig = {
  key: "root",
  //comment out storage to use local storage and comment out all whats below it in the config obj
  // storage
  storage: new CookieStorage(Cookies),
  whitelist: ["userSignin"],
};

const rootReducer = (state, action) => {
  if (action.type === "USER_LOGOUT") {
    state = undefined;
  }
  return reducer(state, action);
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(
  persistedReducer,
  composeWithDevTools(
    applyMiddleware(...middlewares, thunk, routerMiddleware())
  )
);
export const persistor = persistStore(store, window.PRELOADED_STATE);
