/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useHistory } from "react-router-dom";
import { CBadge, CDataTable } from "@coreui/react";
import moment from "moment";

const getInvestmentStatusBadge = (investmentStatus) => {
  if (investmentStatus) {
    return "success";
  }

  if (!investmentStatus) {
    return "danger";
  }
};

const InvestmentsDataTable = ({ items, loading, itemsPerPage, page }) => {
  const investmentFields = [
    {
      key: "type",
      label: "Investment Type",
      _style: { width: "15%" },
    },
    { key: "source_account", _style: { width: "18%" } },
    { key: "beneficiary_account", _style: { width: "18%" } },
    "title",
    { key: "amount", label: "Amount (₦)" },
    "rollover_type",
    "status",
    { key: "created_at", label: "Date Created" },
  ];

  const history = useHistory();

  return (
    <CDataTable
      items={items}
      fields={investmentFields}
      columnFilter
      tableFilter
      footer
      responsive={true}
      // itemsPerPageSelect={{
      //   label: "Items per page:",
      //   values: [5, 10, 20, 30],
      // }}
      hover
      loading={loading}
      sorter
      itemsPerPage={itemsPerPage}
      activePage
      clickableRows
      // This will be modified to reflect the specific account details clicked
      onRowClick={(item) =>
        history.push(`/investments/investment-details/${item.id}`)
      }
      scopedSlots={{
        status: (item) => (
          <td className="text-capitalize">
            <CBadge color={getInvestmentStatusBadge(item.is_active)} size="md">
              {item.is_active === true ? "Active" : "Inactive"}
            </CBadge>
          </td>
        ),
        type: (item) => (
          <td className="text-capitalize">
            {item.type === null ? "Custom" : <>{item.type.name}</>}
          </td>
        ),
        created_at: (item) => (
          <td>
            <>{moment(item.created_at).format("DD-MMM-YYYY")}</>
          </td>
        ),
        source_account: (item) => (
          <td>
            <>
              {item &&
                item.source &&
                item.source.account_number &&
                item.source.account_number}
            </>
          </td>
        ),
        beneficiary_account: (item) => (
          <td>
            <>
              {item &&
                item.beneficiary &&
                item.beneficiary.account_number &&
                item.beneficiary.account_number}
            </>
          </td>
        ),
        rollover_type: (item) => (
          <td className="text-capitalize">
            <>
              {item.rollover_type === null
                ? "Not Available"
                : item && item.rollover && item.rollover.name}
            </>
          </td>
        ),
        amount: (item) => (
          <td>
            <>
              {item &&
                item.total_amount &&
                item.total_amount
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </>
          </td>
        ),
      }}
    />
  );
};

export default InvestmentsDataTable;
