import React from "react";
import Register from "./views/pages/register/Register";
import Page404 from "./views/pages/page404/Page404";
import Page500 from "./views/pages/page500/Page500";

import ResetPassword from "./views/pages/resetpassword/ResetPassword";
import Dashboard from "./views/dashboard/Dashboard";
// import Users from "./views/users/Users";
import UserDetails from "./views/users/UserDetails";
import Accounts from "./views/accounts/Accounts";
import AccountDetails from "./views/accounts/AccountDetails";
import AccountsDirectDebit from "./views/accounts/AccountsDirectDebit";
import Investments from "./views/investments/Investments";
import CreateInvestment from "./views/investments/CreateInvestment";
import InvestmentDetails from "./views/investments/InvestmentDetails";
import Transactions from "./views/transactions/Transactions";
import CreateTransaction from "./views/transactions/CreateTransaction";
import TransactionDetails from "./views/transactions/TransactionDetails";
import DebitManTransactionDetails from "./views/transactions/DebitManTransactions";
import ReverseTransaction from "./views/transactions/ReverseTransactions";
import ChangePassword from "./views/security/ChangePassword";
import ChangeTransactionPin from "./views/security/ChangeTransactionPin";
import ResetTransactionPin from "./views/security/ResetTransactionPin";
import Profile from "./views/profile/Profile";
import CreateAccountAdmin from "./views/admin/Accounts";
import AdminAccountDetails from "./views/admin/AdminAccountDetails";
import Teams from "./views/admin/Teams";
import TeamsDetails from "./views/admin/TeamsDetails";
import TeamLead from "./views/accounts/TeamLead";
import TeamLeadDetails from "./views/accounts/TeamDetails";
import TeamLeadAnalytics from "./views/accounts/TeamLeadAnalytics";
import Messages from "./views/message/Message";
import SendMessage from "./views/message/SendMessage";
import EmailMessage from "./views/message/EmailMessage";
import Partners from "./views/patners/Partner";
import PartnersDetail from "./views/patners/PartnerDetail";
import Roles from "./views/admin/Roles";
import RolesDetails from "./views/admin/RolesDetails";
import Permissions from "./views/admin/Permissions";
import PermissionDetails from "./views/admin/PermissionDetails";
import DebitAccount from "./views/admin/DebitAccount";
// const Dashboard = React.lazy(() => import('./views/dashboard/Dashboard'));
const Users = React.lazy(() => import("./views/users/Users"));
// const UserDetails = React.lazy(() => import('./views/users/UserDetails'));
// const Accounts = React.lazy(() => import('./views/accounts/Accounts'));
// const AccountDetails = React.lazy(() => import('./views/accounts/AccountDetails'));
// const Investments = React.lazy(() => import('./views/investments/Investments'));
// const InvestmentDetails = React.lazy(() => import('./views/investments/InvestmentDetails'));
// const Transactions = React.lazy(() => import('./views/transactions/Transactions'));
// const TransactionDetails = React.lazy(() => import('./views/transactions/TransactionDetails'));

const routes = [
  { path: "/", exact: true, name: "Home" },
  { path: "/register", exact: true, name: "Register", component: Register },
  { path: "/404", exact: true, name: "Page Not Found", component: Page404 },
  { path: "/500", exact: true, name: "Page Unavailable", component: Page500 },
  { path: "/analytics", name: "Analytics", component: Dashboard },
  { path: "/reset-password", name: "Reset Password", component: ResetPassword },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
  },
  // { path: "/users", exact: true, name: "Users", component: Users },
  { path: "/users", exact: true, name: "Users", component: Users },

  {
    path: "/users/user-details/:id",
    name: "User Details",
    component: UserDetails,
  },
  { path: "/accounts", exact: true, name: "Accounts", component: Accounts },
  {
    path: "/accounts/account-details/:id",
    name: "Account Details",
    component: AccountDetails,
  },

  {
    path: "/direct-debit",
    exact: true,
    name: "Accounts DirectDebit",
    component: AccountsDirectDebit,
  },

  { path: "/team-lead", exact: true, name: "Team Lead", component: TeamLead },
  {
    path: "/team-lead/:id",
    name: "Team Lead Details",
    component: TeamLeadDetails,
  },
  {
    path: "/team-analytics",
    name: "Team Analytics",
    component: TeamLeadAnalytics,
  },
  {
    path: "/investments",
    exact: true,
    name: "Investments",
    component: Investments,
  },
  {
    path: "/investments/create-investment",
    exact: true,
    name: "Create Investment",
    component: CreateInvestment,
  },
  {
    path: "/investments/investment-details/:id",
    name: "Investment Details",
    component: InvestmentDetails,
  },
  {
    path: "/transactions",
    exact: true,
    name: "Transactions",
    component: Transactions,
  },

  {
    path: "/transactions/create-transaction",
    exact: true,
    name: "Create Transaction",
    component: CreateTransaction,
  },
  {
    path: "/transactions/transaction-details/:id",
    name: "Transaction Details",
    component: TransactionDetails,
  },
  {
    path: "/transactions/mandate-transaction-details/:id",
    name: "Debit Mandate Transaction Details",
    component: DebitManTransactionDetails,
  },
  {
    path: "/transactions/reversals",
    name: "Reverse Transactions",
    component: ReverseTransaction,
  },
  {
    path: "/security/change-password",
    name: "Change Password",
    component: ChangePassword,
  },
  {
    path: "/security/change-transaction-pin",
    name: "Change Transaction Pin",
    component: ChangeTransactionPin,
  },
  {
    path: "/security/reset-transaction-pin",
    name: "Reset Transaction Pin",
    component: ResetTransactionPin,
  },
  {
    path: "/admin/create-account",
    name: "Create Account",
    exact: true,
    component: CreateAccountAdmin,
  },
  {
    path: "/admin/account-details/:id",
    name: "Admin Account Details",
    component: AdminAccountDetails,
  },
  {
    path: "/admin/teams",
    name: "Teams",
    exact: true,
    component: Teams,
  },
  {
    path: "/admin/teams/:id",
    name: "Teams Detail",
    component: TeamsDetails,
  },
  {
    path: "/messages",
    exact: true,
    name: "Message Bird",
    component: Messages,
  },
  {
    path: "/send-messages",
    exact: true,
    name: "Send Message",
    component: SendMessage,
  },
  {
    path: "/email-token",
    exact: true,
    name: "Email Token",
    component: EmailMessage,
  },
  {
    path: "/partners",
    exact: true,
    name: "Partners",
    component: Partners,
  },
  {
    path: "/partners/:id",
    name: "Partners Detail",
    component: PartnersDetail,
  },
  {
    path: "/admin/roles",
    exact: true,
    name: "Roles",
    component: Roles,
  },
  {
    path: "/admin/roles/:id",
    name: "Roles Details",
    component: RolesDetails,
  },
  {
    path: "/admin/permissions",
    exact: true,
    name: "Permissions",
    component: Permissions,
  },
  {
    path: "/admin/permissions/:id",
    name: "Permission Details",
    component: PermissionDetails,
  },
  {
    path: "/admin/debit-account",
    exact: true,
    name: "Debit Account",
    component: DebitAccount,
  },
];

export default routes;
