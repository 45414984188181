import React from "react";
import CIcon from "@coreui/icons-react";

const _nav = [
    {
        _tag: "CSidebarNavItem",
        name: "Users",
        to: "/users/all/1",
        icon: "cil-user-plus",
      },
      {
        _tag: "CSidebarNavItem",
        name: "Accounts",
        to: "/accounts",
        icon: "cil-graph",
      },
      {
        _tag: "CSidebarNavDropdown",
        name: "Message",
        icon: "cilEnvelopeLetter",
        
        _children: [
          {
            _tag: "CSidebarNavItem",
            name: "Telephone Token",
            icon: "cilEnvelopeLetter",
            
            to: "/messages",
          },
          {
            _tag: "CSidebarNavItem",
            name: "Email Token",
            icon: "cilEnvelopeLetter",
            
            to: "/email-token",
          },
        ]
        
      },
      
      {
        _tag: "CSidebarNavDropdown",
        name: "Transactions",
        icon: "cil-list-rich",
        _children: [
            {
              _tag: "CSidebarNavItem",
              name: "View Transactions",
              to: "/transactions",
            },
        ]
    },
    {
        _tag: "CSidebarNavDropdown",
        name: "Investments",
        icon: "cil-list-numbered",
        _children: [
          {
            _tag: "CSidebarNavItem",
            name: "View Investments",
            to: "/investments",
          },
        ]
    },
    {
        _tag: "CSidebarNavDropdown",
        name: "Security",
        icon: "cil-lock-locked",
        _children: [
          {
            _tag: "CSidebarNavItem",
            name: "Change Password",
            to: "/security/change-password",
          },
        ]
    },
    ]
export default _nav;
