import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CButton,
  CCard,
  CCardBody,
  CCol,
  CContainer,
  CForm,
  CInput,
  CInputGroup,
  CInputGroupPrepend,
  CInputGroupText,
  CRow,
  CModal,
  CModalHeader,
  CModalBody,
  CSelect,
  CPagination,
  CCardHeader,
  CSpinner,
  CNav,
  CNavItem,
  CNavLink,
  CTabPane,
  CTabContent,
  CDataTable,
} from "@coreui/react";
import { Overlay } from "../../components";
import { Printer, ChevronLeft } from "react-feather";
import AdminDataTable from "src/components/DataTables/AdminDataTable";
import CIcon from "@coreui/icons-react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CSVLink } from "react-csv";
import moment from "moment";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { contentSuccess, contentError } from "../../_utils/api";
import { createAccount } from "src/redux/_actions/adminActions";
import {
  // getRoles,

  getTeamLead,
  getTeamDetails,
  getTeamLeadMembers,
} from "src/redux/_services/adminService";
import TeamDetailsDataTable from "src/components/DataTables/TeamDetailsDataTable";
import styles from "../../scss/details.module.scss";

const TeamDetails = (props) => {
  const { id } = useParams();
  const [activeKey, setActiveKey] = useState(1);
  const [showOverlay, set_showOverlay] = useState(false);
  const [loading, setLoading] = useState("");
  const [currentpage, set_currentpage] = useState();
  const [lastPage, set_lastpage] = useState(1);
  const [teamDetails, setTeamDetails] = useState("");
  const [searchPage, setSearchPage] = useState("");
  const [searchPageErr, setSearchPageErr] = useState("");
  const [teamMembers, setTeamMembers] = useState("");
  const history = useHistory();

  const pageChange = (newPage) => {
    set_currentpage(newPage);
  };
  const searchPageHandler = (e) => {
    e.preventDefault();
    if (searchPage > lastPage) {
      setSearchPageErr("Selected Number cannot be greater than last page");
      return;
    }
    setSearchPageErr("");
    setLoading(true);
    getTeamLeadMembers(id, searchPage)
      .then((res) => {
        setTeamMembers(res.data.data.members.data);
        set_currentpage(res.data.data.members.meta.current_page);
        set_lastpage(res.data.data.members.meta.last_page);
      })
      .finally(function () {
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    const retrieveTeams = () => {
      set_showOverlay(true);
      setLoading(true);
      getTeamDetails(id)
        .then((res) => {
          setTeamDetails(res.data.data);
        })
        .finally(() => {
          set_showOverlay(false);
          setLoading(false);
        })
        .catch((err) => {});
    };
    const retrieveTeamMembers = () => {
      set_showOverlay(true);
      setLoading(true);
      getTeamLeadMembers(id, currentpage)
        .then((res) => {
          setTeamMembers(res.data.data.members.data);
          set_currentpage(res.data.data.members.meta.current_page);
          set_lastpage(res.data.data.members.meta.last_page);
        })
        .finally(() => {
          set_showOverlay(false);
          setLoading(false);
        })
        .catch((err) => {});
    };

    retrieveTeams();
    retrieveTeamMembers();
  }, [currentpage]);

  if (loading) {
    return (
      <CSpinner
        color="primary"
        style={{
          width: "4rem",
          height: "4rem",
          marginTop: "300px",
          marginLeft: "600px",
        }}
      />
    );
  }

  return (
    <>
      <div className="d-flex justify-content-end">
        <CButton onClick={history.goBack} className="px-4 mb-4" color="success">
          <ChevronLeft />
          Back
        </CButton>
      </div>

      <CNav variant="tabs">
        <CNavItem>
          <CNavLink
            href="#"
            active={activeKey === 1}
            onClick={() => setActiveKey(1)}
          >
            Team Details
          </CNavLink>
        </CNavItem>
        <CNavItem>
          <CNavLink
            href="#"
            active={activeKey === 2}
            onClick={() => setActiveKey(2)}
          >
            Teams Members
          </CNavLink>
        </CNavItem>
      </CNav>
      <CTabContent className="bg-white brand-tab-content py-3 px-4">
        <CTabPane active={activeKey === 1}>
          <p className="mb-5">
            Basic information about the team like the name, date created etc
          </p>
          <div className={styles.field_info}>
            <div className={styles.field_item}>
              <div className={styles.field_wrapper}>
                <span className={styles.field_label}>Team Name</span>

                <p className={styles.field_value_link}>
                  {teamDetails && teamDetails.team && teamDetails.team.name}
                </p>
              </div>
            </div>

            <div className={styles.field_item}>
              <div className={styles.field_wrapper}>
                <span className={styles.field_label}>Date Created</span>

                <p className={styles.field_value_link}>
                  {moment(
                    teamDetails &&
                      teamDetails.team &&
                      teamDetails.team.created_at
                  ).format("DD-MMM-YYYY LTS")}
                </p>
              </div>
            </div>

            <div className={styles.field_item}>
              <div className={styles.field_wrapper}>
                <span className={styles.field_label}>Date Deleted</span>

                <p className={styles.field_value_link}>
                  {teamDetails &&
                  teamDetails.team &&
                  teamDetails.team.deleted_at
                    ? moment(
                        teamDetails &&
                          teamDetails.team &&
                          teamDetails.team.deleted_at
                      ).format("DD-MMM-YYYY LTS")
                    : "Not Available"}
                </p>
              </div>
            </div>

            <div className={styles.field_item}>
              <div className={styles.field_wrapper}>
                <span className={styles.field_label}>Date Updated</span>

                <p className={styles.field_value_link}>
                  {teamDetails &&
                  teamDetails.team &&
                  teamDetails.team.updateded_at
                    ? moment(
                        teamDetails &&
                          teamDetails.team &&
                          teamDetails.team.updateded_at
                      ).format("DD-MMM-YYYY LTS")
                    : "Not Available"}
                </p>
              </div>
            </div>

            <div className={styles.field_item}>
              <div className={styles.field_wrapper}>
                <span className={styles.field_label}>Total Member</span>

                <p className={styles.field_value_link}>
                  {teamDetails && teamDetails.totalMemberCount}
                </p>
              </div>
            </div>
          </div>
        </CTabPane>
        <CTabPane active={activeKey === 2}>
          <div className="d-flex align-items-center justify-content-between mb-5">
            <div>
              <h3>Team Members</h3>
              <p>A look at all the accounts that belong to a team</p>
            </div>
          </div>
          <DataTable items={teamMembers} itemsPerPage={30} loading={loading} />
          <CPagination
            activePage={currentpage}
            onActivePageChange={pageChange}
            pages={lastPage}
            doubleArrows={true}
            align="end"
          />
          <div className="d-flex justify-content-end">
            <div className="d-flex justify-content-end w-25">
              <CInput
                type="number"
                className=" ml-2 w-25"
                onChange={(e) => setSearchPage(e.target.value)}
              />
              <CButton
                color="primary"
                className="ml-2"
                onClick={searchPageHandler}
              >
                Search
              </CButton>
            </div>
          </div>
          <p className="d-flex justify-content-end text-danger">
            {searchPageErr}
          </p>
          <p className="d-flex justify-content-end text-muted">
            search by page number
          </p>
        </CTabPane>
      </CTabContent>
    </>
  );
};

export default TeamDetails;

const DataTable = ({ items, loading, itemsPerPage, page }) => {
  const history = useHistory();

  const fields = [
    { key: "name", label: "Full Name" },
    { key: "email", label: "Email" }, 
      { key: "telephone", label: "Telephone" },

    { key: "gender", label: "Gender" },
    { key: "created_at", label: "Created Date" },
    { key: "updated_at", label: "Updated Date" },
  ];

  return (
    <CDataTable
      items={items}
      fields={fields}
      columnFilter
      footer
      responsive={true}
      hover
      loading={loading}
      sorter
      itemsPerPage={itemsPerPage}
      activePage
      clickableRows
      This
      will
      be
      modified
      to
      reflect
      the
      specific
      account
      details
      clicked
      // onRowClick={(item) => history.push({pathname:`/admin/account-details/${item.id}`,state: page})}
      scopedSlots={{
        name: (item) => (
          <td className="text-capitalize">{item && item.full_name}</td>
        ),
        email: (item) => (
          <td className="text-capitalize">{item && item.email}</td>
        ),
        telephone: (item) => (
          <td className="text-capitalize">{item && item.telephone || "Not Available"}</td>
        ),
        gender: (item) => (
          <td className="text-capitalize">{item && item.gender || "Not Available"}</td>
        ),
        created_at: (item) => (
          <td>
            <>
              {item && item.created_at
                ? moment(item && item.created_at).format("DD-MMM-YYYY LTS")
                : "Not Available"}
            </>
          </td>
        ),
        updated_at: (item) => (
          <td>
            <>
              {item && item.updated_at
                ? moment(item && item.updated_at).format("DD-MMM-YYYY LTS")
                : "Not Available"}
            </>
          </td>
        ),
      }}
    />
  );
};
