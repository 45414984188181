import React from "react";
import CIcon from "@coreui/icons-react";

const _nav = [
 
    {
        _tag: "CSidebarNavItem",
        name: "Users",
        to: "/users/all/1",
        icon: "cil-user-plus",
      },
  {
    _tag: "CSidebarNavItem",
    name: "Send Message",
    icon: "cilEnvelopeLetter",
    to: "/send-messages", 
  },
 

 
 
];

export default _nav;
