/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";

import {
  CBadge,
  CDataTable,
} from "@coreui/react";

const getStatusBadge = (status) => {
  switch (status) {
    case "success":
      return "success";
    case "failed":
      return "danger";
    case "pending":
     return "warning";
     case "terminated":
       return "danger"
    default:
      return "";
  }
};

const getStateBadge = (state) => {
  if (state) {
    return "danger";
  } else {
    return "success";
  }
};

const AccountsDebitMandateDataTable = ({items, loading, itemsPerPage, page}) => {
  const history = useHistory();

  const fields = [
    { key: "source_account_name", label: "Source Name", _style: { width: "20%" } },
    { key: "source_account_number",label: "Source Account", _style: { width: "15%" } },
    { key: "beneficiary_account_name", label: "Beneficiary Name", _style: { width: "20%" } },
    { key: "beneficiary_account_number", label: "Beneficaily Account", _style: { width: "15%" } },
    { key: "beneficiary_bank", label: "Beneficiary Bank", _style: { width: "15%" } },
    { key: "amount", _style: { width: "15%" } },

    
    {
      key: "book_balance",
      label: "Balance (₦)",
      _style: { width: "18%" },
    },
    { key: "account_status", label: "Status" },
    { key: "created_at", label: "Date Created" },
    { key: "start_date", label: "Start Date" },
    { key: "end_date", label: "End Date" },
    { key: "schedule", label: "Schedule" },
   

 
  ];

  return (
    <CDataTable
      items={items}
      fields={fields}
      columnFilter
      tableFilter
      footer
      responsive={true}
      // itemsPerPageSelect={{
      //   label: "Items per page:",
      //   values: [5, 10, 20, 30],
      // }}
      hover
      loading={loading}
      sorter
      itemsPerPage={itemsPerPage}
      activePage
      clickableRows
      // This will be modified to reflect the specific account details clicked
      onRowClick={(item) => 
        // history.push({pathname:`/direct-debit/debit-details/${item.id}`, state: page})
        history.push({pathname:`/transactions/mandate-transaction-details/${item.id}`, state: page})
      }
      scopedSlots={{
        source_account_name: (item) => (
          <td className="text-capitalize">
             {item.source && item.source.account_name &&
                item.source.account_name.replace(/(^\w)|([-\s]\w)/g, (match) =>
                  match.toUpperCase()
                )}
          </td>
        ),
        beneficary_account_name: (item) => (
            <td className="text-capitalize">
              {item.source && item.source.account_name &&
                item.source.account_name.replace(/(^\w)|([-\s]\w)/g, (match) =>
                  match.toUpperCase()
                )}
            </td>
          ),
          beneficiary_bank: (item) => (
            <td>
              <>
                {item &&
                item.beneficiary_bank_name
                  
                
                    }
              </>
            </td>
          ),
        account_status: (item) => (
          <td>
            <CBadge
              color={getStatusBadge(item.status)}
              size="md"
              className="text-capitalize"
            >
              {item.status}
            </CBadge>
          </td>
        ),

      
      
        book_balance: (item) => (
          <td>
            <>
              {item &&
              item.source && 
                item.source.available_balance
              
                  }
            </>
          </td>
        ),
        created_at: (item) => (
          <td>
            <>
              {moment(item &&
              item.created_at).format("DD-MMM-YYYY LTS")
               
              
                  }
            </>
          </td>
        ),
        start_date: (item) => (
            <td>
              <>
                {moment(item &&
                item.start_date).format("DD-MMM-YYYY LTS")
                 
                
                    }
              </>
            </td>
          ),
          end_date: (item) => (
            <td>
              <>
                {moment(item &&
                item.end_date).format("DD-MMM-YYYY LTS")
                 
                
                    }
              </>
            </td>
          ),
          schedule: (item) => (
            <td className="text-capitalize">
              {item && 
                item.schedule.replace(/_/g, " "
                )}
            </td>
          ),
      
      }}
    />
  )
}

export default AccountsDebitMandateDataTable