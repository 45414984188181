/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useHistory, useLocation, useParams, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CSVLink } from "react-csv";
import Select from "react-select";
import {
  CBadge,
  CNav,
  CNavItem,
  CNavLink,
  CTabPane,
  CTabContent,
  CModal,
  CModalBody,
  CModalHeader,
  CModalFooter,
  CButton,
  CSelect,
  CTextarea,
  CForm,
  CDataTable,
  CPagination,
  CTooltip,
  CLabel,
  CInput,
  CListGroup,
  CListGroupItem,
  CDropdown,
  CDropdownDivider,
  CDropdownToggle,
  CDropdownMenu,
  CRow,
  CCol,
  CSpinner,
  CInputRadio,
  CInputCheckbox,
} from "@coreui/react";
import commonStyles from "../../scss/common-form.module.scss";
import CIcon from "@coreui/icons-react";
import { Printer, ChevronLeft } from "react-feather";
import styles from "../../scss/details.module.scss";
import "../../css/details.css";
// import accountInvestmentsData from "./accountInvestmentsData";
// import accountInterestData from "./accountInterestData";
import {
  useAccountDetails,
  // useAccountTypes,
  // useKycDetails,
} from "../../_hooks/accounts";
import AccountService from "../../redux/_services/AccountService";
import BankService from "src/redux/_services/BankService";

import {
  accountOverDraft,
  updateAccount,
  blockAccount,
  unBlockAccount,
  sendAccountStatement,
  accountVerify,
  accountVerifyFailed,
} from "../../redux/_actions/accountActions";
import InvestmentsDataTable from "src/components/DataTables/InvestmentsDataTable";
import TransactionDataTable from "src/components/DataTables/TransactionDataTable";
import AccountsDebitMandateDataTable from "src/components/DataTables/AccountDebitMandateDataTable";
import { accountVerifyReducer } from "src/redux/_reducer/accountReducers";
import { contentError, contentSuccess } from "src/_utils/api";
import DebitMandateService from "src/redux/_services/DebitMandateService";
import { UpdateAccount } from "./account-detail-actions/UpdateAccount";
import { DeleteAccount } from "./account-detail-actions/DeleteAccount";
import AccountBlockFundDataTable from "src/components/DataTables/AccountBlockFundDataTable";

const getInterestStatusBadge = (interestStatus) => {
  if (interestStatus === "paid") {
    return "success";
  }

  if (interestStatus === "unpaid") {
    return "danger";
  }
};

const getStatusBadge = (status) => {
  switch (status) {
    case "active":
      return "success";
    case "inactive":
      return "danger";
    default:
      return "";
  }
};

const getOverDrawnBadge = (status) => {
  switch (status) {
    case true:
      return "success";
    case false:
      return "danger";
    default:
      return "";
  }
};

const getVerifyBadge = (status) => {
  switch (status) {
    case "verified":
      return "success";
    case "failed":
      return "danger";
    case "pending":
      return "warning";
    default:
      return "";
  }
};

const re = /^[0-9\b]+$/;

const AccountDetails = (props) => {
  const { id } = useParams();
  const [activeKey, setActiveKey] = useState(1);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [restoreModal, setRestoreModal] = useState(false);
  const [postNoDebitModal, setPostNoDebitModal] = useState(false);
  const [deletepostNoDebitModal, setDeletePostNoDebitModal] = useState(false);
  const [disabled, setDisabled] = useState("");
  const [interestStatus, setInterestStatus] = useState(false);
  const [password, setPassword] = useState("");
  const [startDate, set_expires_at] = useState("");
  const [modal, setModal] = useState(false);
  const [overdraftModal, setOverDraftModal] = useState(false);
  const [overDraftStatus, setOverDraftStatus] = useState("");
  const [debitMandateModal, setDebitMandateModal] = useState(false);
  const [debitMandateAccountNumber, setDebitMandateAccountNumber] =
    useState("");
  const [debitMandateBankName, setDebitMandateBankName] = useState("");
  const [debitMandateDesc, setDebitMandateDesc] = useState("");
  const [debitMandateStartDate, setDebitMandateStartDate] = useState("");
  const [debitMandateEndDate, setDebitMandateEndDate] = useState("");
  const [debitMandateSchedule, setDebitMandateSchedule] = useState("");
  const [mandateSchedules, setMandateSchedules] = useState("");
  const [createMandateLoading, setCreateMandateLoading] = useState(false);
  const [debitMandatePassword, setDebitMandatePassword] = useState("");
  const [debitMandateBeneficiary, setDebitMandateBeneficiary] = useState("");
  const [debitMandateSuccess, setDebitMandateSuccess] = useState(false);
  const [debitMandateRem, setDebitMandateRem] = useState(false);
  const [banks, setBanks] = useState([]);
  const [mandateType, setMandateType] = useState("");
  const [channel, setChannel] = useState("");
  const [mandateFormErr, setMandateFormErr] = useState("");
  const [debitMandateAmount, setDebitMandateAmount] = useState(null);
  const [overDraftPassword, setOverDraftPassword] = useState(false);
  const [overDraftSuccess, setOverDraftSuccess] = useState(false);
  // const [loadingVerify, setLoadingVerify] = useState('')
  const [comment, set_comment] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [status, set_status] = useState("");
  const [account_transactions_data, set_account_transactions_data] = useState(
    []
  );
  const [account_investments_data, set_account_investments_data] = useState([]);
  const [account_interest_data, set_account_interest_data] = useState([]);
  const [accountMandate, setAccountMandate] = useState([]);
  const [accountBlockFund, setAccountBlockFund] = useState([]);
  const [showAccountMandate, setShowAccountMandate] = useState("false");
  const [filterName, set_filter_name] = useState();
  const [filterVal, set_filter_value] = useState();
  const [query, set_query] = useState();
  const [overdraftSuccess, setOverdraftSuccess] = useState("");
  const [currentAccount, fetcAccountData, loading] = useAccountDetails(id);
  const [transferLimitStatus, setTransferLimitStatus] = useState(
    currentAccount.transfer_limit_enabled ?? false
  );

  const [transferLimit, setTransferLimit] = useState({
    daily_transfer_limit: 0,
    weekly_transfer_limit: 0,
    monthly_transfer_limit: 0,
  });
  const [allowLimits, setAllowLimits] = useState({
    daily: false,
    weekly: false,
    monthly: false,
  });
  // get account details ///
  const [accountStatementStartDate, setAccountStatementStartDate] =
    useState("");
  const [accountStatementEndDate, setAccountStatementEndDate] = useState(
    new Date()
  );
  const [accountStatementError, setAccountStatementError] = useState(false);
  const [accountStatementSendUser, setAccountStatementSendUser] = useState("");
  const [unBlockAccountError, setUnBlockAccountError] = useState("");
  const [blockAccountError, setBlockAccountError] = useState("");
  const [pages, set_pages] = useState(1);
  const [openBlockFundModal, setOpenBlockFundModal] = useState(false);
  const blockedFund = useSelector((state) => state?.blockFund);
  const unblockFundState = useSelector((state) => state?.unBlockFund);

  //change account name
  const [changeName, setChangeName] = useState('')

  const changingName = (e) => {
    setChangeName(e.target.value)
  }

  const handleOpenBlockFundModal = () => setOpenBlockFundModal(true);
  const handleCloseBlockFundModal = () => setOpenBlockFundModal(false);

  const BLOCK_FUNDS_ACCESS = ["admin", "super-admin", "treasury"];

  useEffect(() => {
    fetcAccountData();
  }, [fetcAccountData]);

  // const [currentKyc, fetcKycData, loading] = useKycDetails(id);
  // useEffect(() => {
  //   fetcKycData();
  // }, [fetcKycData]);
  const accountUpdate = useSelector((state) => state.accountUpdate);
  const { loadingUpdate, accountUpdateData, successUpdate } = accountUpdate;
  const accountBlock = useSelector((state) => state.accountBlock);
  const { loadingBlock, successBlock, accountBlockData } = accountBlock;
  const pnbUnBlock = useSelector((state) => state.pnbUnBlock);
  const { loadin, success, unBlockData } = pnbUnBlock;
  const accountStatementLoading = useSelector(
    (state) => state.accountStatement.loading
  );
  const userSignin = useSelector((state) => state.userSignin);
  const accountVerifyData = useSelector((state) => state.accountVerify);
  const accountOverdraft = useSelector((state) => state.accountOverdraft);

  const { userInfo } = userSignin;
  const userRole =
    userInfo &&
    userInfo.data &&
    userInfo.data.user &&
    userInfo.data.user.roles &&
    userInfo.data.user.roles[0].name;

  const toggle = () => {
    setModal(!modal);
  };

  const toggleOverdraft = () => {
    setOverDraftModal(!overdraftModal);
    setOverDraftPassword("");
    setOverDraftStatus("");
  };

  const toggleEditModal = () => {
    setEditModal(!editModal);

    setPassword("");
  };

  const handleTransferLimitChange = (e) => {
    setTransferLimit({ ...transferLimit, [e.target.name]: e.target.value });
  };

  const handleTransferStatusChange = (e) => {
    const status = e.target.value === "true";
    setTransferLimitStatus(status);
  };

  const handleAllowLimitsChange = (e) => {
    setAllowLimits({ ...allowLimits, [e.target.name]: e.target.checked });
  };

  const handleInterestEnabledChange = (e) => {
    const status = e.target.value === "true";
    setInterestStatus(status);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const toggleDeleteModal = () => {
    setDeleteModal(!deleteModal);
  };

  const toggleRestoreModal = () => {
    setRestoreModal(!restoreModal);
  };

  const togglePostNoDebitModal = () => {
    setPostNoDebitModal(!postNoDebitModal);
    setPassword("");
    setBlockAccountError("");
  };

  const toggleDeletePostNoDebitModal = () => {
    setDeletePostNoDebitModal(!deletepostNoDebitModal);
    setPassword("");
    setUnBlockAccountError("");
  };

  const toggleDebitMandateModal = () => {
    setDebitMandateModal(!debitMandateModal);
    setDebitMandateBeneficiary("");
    setDebitMandateBankName("");
    setMandateType("");
    setDebitMandateAccountNumber("");
    setDebitMandateDesc("");
    setDebitMandateEndDate("");
    setDebitMandateSchedule("");
    setDebitMandateRem("");
    setDebitMandateStartDate("");
    setDebitMandatePassword("");
    setDebitMandateAmount("");
    setDebitMandateSchedule("");
    setChannel("");
  };

  useEffect(() => {
    if (
      accountVerifyData.success === true &&
      accountVerifyData.loading === false
    ) {
      toggle();
      fetcAccountData();
    }
  }, [accountVerifyData.success]);
  useEffect(() => {
    if (
      accountOverdraft.success === true &&
      accountOverdraft.loading === false
    ) {
      toggleOverdraft();
      fetcAccountData();
    }
  }, [accountOverdraft.success]);

  useEffect(() => {
    if (successBlock === true && accountBlockData.status === "success") {
      togglePostNoDebitModal();
      fetcAccountData();
    }
  }, [successBlock]);

  useEffect(() => {
    if (success === true) {
      toggleDeletePostNoDebitModal();
      fetcAccountData();
    }
  }, [success]);
  useEffect(() => {
    if (successUpdate === true && accountUpdateData.status === "success") {
      toggleEditModal();
      fetcAccountData();
    }
  }, [successUpdate]);

  useEffect(() => {
    sessionStorage.setItem("accountPage", props.location.state);
  }, []);
  const interestFields = [
    { key: "amount", label: "Amount (₦)", _style: { width: "25%" } },
    { key: "month", _style: { width: "25%" } },
    { key: "year", _style: { width: "25%" } },
    { key: "status", _style: { width: "25%" } },
  ];

  const dispatch = useDispatch();
  const history = useHistory();
  const queryPage = useLocation().search.match(/page=([0-9]+)/, "");
  const currentPage = Number(queryPage && queryPage[1] ? queryPage[1] : 1);
  const [page, setPage] = useState(currentPage);

  useEffect(() => {
    // This will be modified to reflect the specific pagination page clicked

    currentPage !== page && setPage(currentPage);
  }, [currentPage, page]);

  const updateFilter = (e) => {
    if (e.target.name === "account_name") {
      set_filter_name(e.target.name);
      set_filter_value(e.target.value);
    } else if (e.target.name === "account_number") {
      set_filter_name(e.target.name);
      set_filter_value(e.target.value);
    } else if (e.target.name === "type") {
      set_filter_name(e.target.name);
      if (e.target.value === "Debit") {
        set_filter_value("debit");
      } else if (e.target.value === "Credit") {
        set_filter_value("credit");
      }
    } else if (e.target.name === "transaction_reference") {
      set_filter_name(e.target.name);
      set_filter_value(e.target.value);
    } else if (e.target.name === "bank_code") {
      set_filter_name(e.target.name);
      set_filter_value(e.target.value);
    } else if (e.target.name === "amount") {
      set_filter_name(e.target.name);
      set_filter_value(e.target.value);
    } else if (e.target.name === "status") {
      set_filter_name(e.target.name);
      if (e.target.value === "Success") {
        set_filter_value("success");
      } else if (e.target.value === "Pending") {
        set_filter_value("pending");
      } else if (e.target.value === "Failed") {
        set_filter_value("failed");
      }
    }
  };

  const getRequestParams = (page) => {
    let params = {};

    if (page) {
      params[`page[number]`] = page - 0;
    }

    return params;
  };

  const pageChange = (newPage) => {
    // This will be modified to reflect the specific pagination page clicked

    currentPage !== newPage &&
      history.push(`/accounts/account-details/${id}?page=${newPage}`);
  };

  const submitFilterHandler = (e) => {
    e.preventDefault();
    const queries = `filter[${filterName}]=${filterVal}`;
    set_query(queries);
  };

  useEffect(() => {
    const retrieveAccountsTransactionsFilter = () => {
      if (query) {
        AccountService.getAllAccountsTransactionsFilter(query, id)
          .then((response) => {
            const accountData = response.data.data.transactions.data;
            set_account_transactions_data(accountData);
          })
          .catch((e) => {
            console.log(e);
          });
      }
    };
    retrieveAccountsTransactionsFilter();
  }, [query, id]);

  useEffect(() => {
    const retrieveAccountTransactions = () => {
      const params = getRequestParams(page);
      AccountService.getAllAccountsTransactions(params, id)
        .then((response) => {
          const accountData = response.data.data.transactions.data;
          set_pages(response.data.data.transactions.meta.last_page);
          set_account_transactions_data(accountData);
        })
        .catch((e) => {
          console.log(e);
        });
    };

    retrieveAccountTransactions();
  }, [page, currentPage, id]);
  useEffect(() => {
    const retrieveAccountInvestments = () => {
      const params = getRequestParams(page);
      AccountService.getAllAccountsInvestments(params, id)
        .then((response) => {
          const investmentData = response.data.data.investments.data;
          set_account_investments_data(investmentData);
        })
        .catch((e) => {
          console.log(e);
        });
    };

    retrieveAccountInvestments();
  }, [page, id]);
  useEffect(() => {
    const retrieveBlockedFund = () => {
      const params = getRequestParams(page);
      AccountService.getAllBlockedFunds(params, id)
        .then((response) => {
          const blockedFundData = response.data.data.Blocked_funds;
          setAccountBlockFund(blockedFundData);
        })
        .catch((e) => {
          console.log(e);
        });
    };

    if (BLOCK_FUNDS_ACCESS.includes(userRole)) {
      retrieveBlockedFund();
    }
  }, [page, id, blockedFund?.success, unblockFundState?.success]);

  useEffect(() => {
    const retrieveAccountInterests = () => {
      const params = getRequestParams(page);
      AccountService.getAllAccountsInterest(params, id)
        .then((response) => {
          const interestData = response.data.data.interests.data;
          set_account_interest_data(interestData);
        })
        .catch((e) => {
          console.log(e);
        });
    };

    retrieveAccountInterests();
  }, [page, id]);

  useEffect(() => {
    const retrieveAccountMandate = () => {
      const params = getRequestParams(page);
      DebitMandateService.getSingleAccountDebitMandate(id, params)
        .then((response) => {
          const mandateData = response.data.data.mandates;
          setAccountMandate(mandateData);
        })
        .catch((e) => {
          console.log(e);
        });
    };

    retrieveAccountMandate();
  }, [page, id, debitMandateSuccess]);

  useEffect(() => {
    if (currentAccount?.account_number) {
      BankService.getAllBanks(currentAccount?.account_number)
        .then((response) => {
          const data = response?.data?.data?.banks;
          setBanks(data ?? []);
        })
        .catch((e) => {
          console.log(e);
        });
    }

    DebitMandateService.getMandateSchedule()
      .then((response) => {
        const data = response.data.data;
        setMandateSchedules(data);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [currentAccount?.account_number]);

  const [dates, setDate] = useState({
    investmentStartDate: "",
    investmentEndDate: "",
    interestStartDate: "",
    interestEndDate: "",
    transactionStartDate: "",
    transactionEndDate: "",
  });

  const setDateValue = (prop) => (event) => {
    event.preventDefault();
    setDate({
      ...dates,
      [prop]: event.target.value,
    });
  };

  const updateStatus = (e) => {
    if (e.target.name === "comment") {
      set_comment(e.target.value);
    } else if (e.target.name === "status_type") {
      setSelectedStatus(e.target.value);
      set_status(e.target.value);
    }
  };
  useEffect(() => {
    if (selectedStatus === "Failed") {
      setDisabled(false);
    } else if (selectedStatus === "Verified") {
      setDisabled(true);
    }
  }, [selectedStatus]);

  const update = (e) => {
    if (
      e.target.name === "password" ||
      e.target.name === "deletePNBlockPassword"
    ) {
      setPassword(e.target.value);
    } else if (e.target.name === "comment") {
      set_comment(e.target.value);
    } else if (e.target.name === "deletePNBPassword") {
      setPassword(e.target.value);
    }
  };

  useEffect(() => {
    const isEnabled = currentAccount.transfer_limit_enabled;
    const isEnabledDaily = !!currentAccount?.transfer_limit?.daily;
    const isEnabledWeekly = !!currentAccount?.transfer_limit?.weekly;
    const isEnabledMonthly = !!currentAccount?.transfer_limit?.monthly;

    // update last user choices
    setTransferLimitStatus(isEnabled);
    setInterestStatus(currentAccount.interest_enabled);

    setAllowLimits({
      daily: isEnabled && isEnabledDaily,
      weekly: isEnabled && isEnabledWeekly,
      monthly: isEnabled && isEnabledMonthly,
    });

    // update initial state to last data
    setTransferLimit({
      daily_transfer_limit: currentAccount?.transfer_limit?.daily,
      weekly_transfer_limit: currentAccount?.transfer_limit?.weekly,
      monthly_transfer_limit: currentAccount?.transfer_limit?.monthly,
    });
  }, [currentAccount?.transfer_limit_enabled, currentAccount?.transfer_limit]);

  useEffect(() => {
    var accountdigit = /^\d{10}$/;
    if (mandateType === "Other banks") {
      setChannel("external");
      const payload = {
        source_account_number: currentAccount.account_number,
        account_number: debitMandateAccountNumber,
        bank_code: debitMandateBankName && debitMandateBankName.nip_bank_code,
      };

      if (debitMandateAccountNumber.match(accountdigit)) {
        setDebitMandateBeneficiary("loading");
        AccountService.validateAccount(payload)
          .then((res) => {
            if (res.data.error) {
              setDebitMandateBeneficiary("An error occured. try again");
            } else {
              setDebitMandateBeneficiary(res.data.data.account_name);
            }
          })
          .catch((err) => {
            setDebitMandateBeneficiary("Something went wrong");
          });
      }
    } else {
      setChannel("internal");

      const payload = {
        source_account_number: currentAccount.account_number,
        account_number: debitMandateAccountNumber,
        bank_code: "000000",
      };
      if (debitMandateAccountNumber.match(accountdigit)) {
        setDebitMandateBeneficiary("loading");
        AccountService.validateAccount(payload)
          .then((res) => {
            if (res.data.error) {
              setDebitMandateBeneficiary(res.data.error.message);
            } else {
              setDebitMandateBeneficiary(res.data.data.account_name);
            }
          })
          .catch((err) => {
            setDebitMandateBeneficiary("Something went wrong");
          });
      }
    }
  }, [debitMandateAccountNumber, debitMandateBankName]);

  const submitAccountHandler = (e) => {
    e.preventDefault();

    const transfer_limit = {};

    if (allowLimits.daily && transferLimit?.daily_transfer_limit) {
      Object.assign(transfer_limit, {
        daily_transfer_limit: transferLimit?.daily_transfer_limit,
      });
    }

    if (allowLimits.weekly && transferLimit?.weekly_transfer_limit) {
      Object.assign(transfer_limit, {
        weekly_transfer_limit: transferLimit?.weekly_transfer_limit,
      });
    }

    if (allowLimits.monthly && transferLimit?.monthly_transfer_limit) {
      Object.assign(transfer_limit, {
        monthly_transfer_limit: transferLimit?.monthly_transfer_limit,
      });
    }

    dispatch(
      updateAccount(
        id,
        interestStatus,
        transferLimitStatus,
        transfer_limit,
        password
      )
    );
  };

  const changeAccountNameHandler = () => {
    AccountService.updateAccountName(changeName, id)
      .then((response) => {
        toast.success(response.data.message);
      })
      .catch((e) => {
        toast.error("Failed to update account name");
        console.log(e);
      });
  }; 

  const Filter = (e) => {
    e.preventDefault();
    if (password === "") {
      setBlockAccountError("Password Cannot be Empty");
      return;
    }
    setBlockAccountError("");
    let expires_at;
    if (startDate === "" || startDate === null) {
      expires_at = "";
    } else {
      expires_at = moment(startDate)
        .format("YYYY-MM-DD HH:mm")
        .replace(/[, ]+/g, " ");
    }

    const account_id = id;
    dispatch(blockAccount(id, account_id, comment, expires_at, password));
  };
  const submitUnHandler = (e) => {
    e.preventDefault();
    if (password === "") {
      setUnBlockAccountError("Password cannot be empty");
      return;
    }
    setUnBlockAccountError("");
    const account_id = id;
    dispatch(unBlockAccount(id, account_id, password));
  };

  const transactionRef = uuidv4().replace(/-/g, "");

  const submitDebitMandateHandler = (e) => {
    e.preventDefault();
  
    // Declare startDate and endDate before using them
    let startDate = moment(debitMandateStartDate)
      .format("YYYY-MM-DD")
      .replace(/[, ]+/g, " ");
    let endDate = moment(debitMandateEndDate)
      .format("YYYY-MM-DD")
      .replace(/[, ]+/g, " ");
  
    if (
      debitMandateAccountNumber === "" ||
      debitMandateBeneficiary === "" ||
      debitMandateAmount === "" ||
      channel === "" ||
      debitMandateSchedule === "" ||
      debitMandateDesc === "" ||
      debitMandatePassword === "" ||
      startDate === "" ||
      endDate === ""
    ) {
      setMandateFormErr("All fields marked must be filled");
      return;
    }
    
    setMandateFormErr("");
  
    const payload = {
      account_id: id,
      beneficiary_account_number: debitMandateAccountNumber,
      beneficiary_account_name: debitMandateBeneficiary,
      beneficiary_bank_name: debitMandateBankName
        ? debitMandateBankName && debitMandateBankName.bank_name
        : "CLOUD MICROFINANCE BANK",
      beneficiary_bank_code: debitMandateBankName
        ? debitMandateBankName && debitMandateBankName.nip_bank_code
        : "000000",
      amount: debitMandateAmount,
      channel: channel,
      schedule: debitMandateSchedule,
      description: debitMandateDesc,
      pin: debitMandatePassword,
      remember: debitMandateRem ? 1 : 0,
      start_date: startDate,
      end_date: endDate,
      transaction_reference: transactionRef,
    };
  
    setCreateMandateLoading(true);
    DebitMandateService.createMandate(payload)
      .then((res) => {
        setCreateMandateLoading(false);
  
        if (res.data.status === "success") {
          toast(contentSuccess(res.data.status), {
            onClose: setTimeout(() => toggleDebitMandateModal(), 4000),
          });
          setDebitMandateSuccess(true);
        } else {
          toast(contentError("An Error Occured"));
        }
      })
      .catch((err) => {
        setCreateMandateLoading(false);
        toast(contentError(err.response.data.error.message));
      });
  };
  
  if (loading) {
    return (
      <CSpinner
        color="primary"
        style={{
          width: "4rem",
          height: "4rem",
          marginTop: "300px",
          marginLeft: "600px",
        }}
      />
    );
  }  

  const submitHandler = () => {
    if (selectedStatus === "Verified") {
      dispatch(accountVerify(status.toLowerCase(), id));
    } else if (selectedStatus === "Failed") {
      dispatch(accountVerifyFailed(status.toLowerCase(), comment, id));
    }
  };

  const submitOverDraftHandler = () => {
    if (overDraftStatus === "" || overDraftPassword === "") {
      return toast(contentError("One or both field is empty"));
    }
    const payload = {
      overDraftStatus,
      overDraftPassword,
    };

    dispatch(accountOverDraft(payload, id, history));
  };

  const sendAccountStatementHandler = (e) => {
    e.preventDefault();
    if (
      accountStatementStartDate === null ||
      accountStatementEndDate === null ||
      accountStatementStartDate === "" ||
      accountStatementSendUser === ""
    ) {
      setAccountStatementError(true);
      return;
    } else {
    }
    setAccountStatementError(false);
    const account_id = id;
    const payload = {
      start_date: accountStatementStartDate
        ? accountStatementStartDate.toISOString().slice(0, 10)
        : "",
      end_date: accountStatementEndDate
        ? accountStatementEndDate.toISOString().slice(0, 10)
        : "",
      id: account_id,
      send_to_account_owner: accountStatementSendUser,
    };

    dispatch(sendAccountStatement(payload));
  };

  let debitMandateStatus =
    accountMandate && accountMandate.data && accountMandate.data.length > 0;

  const date = new Date();
  const downloadDate = moment(date).format("DD-MMM-YYYY");
  const accountTransactionFileName = `cloudbank_accounts_transaction_${downloadDate}.csv`;
  const accountInvestmentFileName = `cloudbank_accounts_investment_${downloadDate}.csv`;
  const accountInterestFileName = `cloudbank_accounts_interest_${downloadDate}.csv`;
  return (
    <>
      <div className="d-flex justify-content-end">
        {/* <CButton onClick={() => history.push(`/accounts/`)} className="px-4 mb-4" color="success"> */}
        <CButton onClick={history.goBack} className="px-4 mb-4" color="success">
          <ChevronLeft />
          Back
        </CButton>
      </div>
      <CNav variant="tabs">
        <CNavItem>
          <CNavLink
            href="#"
            active={activeKey === 1}
            onClick={() => setActiveKey(1)}
          >
            Account Information
          </CNavLink>
        </CNavItem>
        <CNavItem>
          <CNavLink
            href="#"
            active={activeKey === 2}
            onClick={() => setActiveKey(2)}
          >
            Transactions
          </CNavLink>
        </CNavItem>
        {/* <CNavItem>
          <CNavLink
            href="#"
            active={activeKey === 3}
            onClick={() => setActiveKey(3)}
          >
            KYC
          </CNavLink>
        </CNavItem> */}
        <CNavItem>
          <CNavLink
            href="#"
            active={activeKey === 4}
            onClick={() => setActiveKey(4)}
          >
            Investment
          </CNavLink>
        </CNavItem>
        <CNavItem>
          <CNavLink
            href="#"
            active={activeKey === 5}
            onClick={() => setActiveKey(5)}
          >
            Interest
          </CNavLink>
        </CNavItem>
        {userRole === "customer-experience" ? (
          ""
        ) : (
          <CNavItem>
            <CNavLink
              href="#"
              active={activeKey === 6}
              onClick={() => setActiveKey(6)}
            >
              Account Statement
            </CNavLink>
          </CNavItem>
        )}
        <CNavItem>
          <CNavLink
            href="#"
            active={activeKey === 7}
            onClick={() => setActiveKey(7)}
          >
            Debit Mandate
          </CNavLink>
        </CNavItem>
        {BLOCK_FUNDS_ACCESS.includes(userRole) ? (
          <CNavItem>
            <CNavLink
              href="#"
              active={activeKey === 8}
              onClick={() => setActiveKey(8)}
            >
              Block Fund
            </CNavLink>
          </CNavItem>
        ) : null}
      </CNav>

      <CTabContent className="bg-white brand-tab-content py-3 px-4">
        <CTabPane active={activeKey === 1}>
          <div className={styles.tab_info}>
            <div className="d-flex justify-content-between align-items-center">
              <h3>Account Information</h3>
              {userRole === "customer-experience" ? (
                ""
              ) : (
                <div className={styles.tab_tooltip}>
                  <CTooltip
                    content="Update Account"
                    placement="bottom"
                    className="pr-2"
                  >
                    <CButton
                      color="success"
                      size="sm"
                      onClick={toggleEditModal}
                    >
                      <CIcon name="cilPencil" size="sm" />
                    </CButton>
                  </CTooltip>

                  {/* Only One of Delete Account and Edit Account will show at a time depending on the current state */}
                  {currentAccount.deleted_at === null ? (
                    <CTooltip
                      content="Delete Account"
                      placement="bottom"
                      className="pr-2"
                    >
                      <CButton
                        color="danger"
                        size="sm"
                        onClick={toggleDeleteModal}
                      >
                        <CIcon name="cilTrash" size="sm" />
                      </CButton>
                    </CTooltip>
                  ) : (
                    ""
                  )}
                  {currentAccount.deleted_at === null ? (
                    ""
                  ) : (
                    <CTooltip
                      content="Restore Account"
                      placement="bottom"
                      className=""
                    >
                      <CButton
                        color="success"
                        size="sm"
                        onClick={toggleRestoreModal}
                      >
                        <CIcon name="cilReload" size="sm" />
                      </CButton>
                    </CTooltip>
                  )}
                </div>
              )}
            </div>

            <p className="mb-5">
              Basic information about the account like the name, number, type
              and balance
            </p>

            <div className={styles.field_info}>
              <div className={styles.field_item}>
                <div className={styles.field_wrapper}>
                  <span className={styles.field_label}>Owner ID</span>
                  {/* <a className={styles.field_value_link}> */}
                  <Link
                    to={`/users/user-details/${currentAccount.user_id}`}
                    className={styles.field_value_link}
                  >
                    {currentAccount.user_id}
                  </Link>
                  {/* </a> */}
                </div>
              </div>
              <div className={styles.field_item}>
                <div className={styles.field_wrapper}>
                  <span className={styles.field_label}>Account Type</span>
                  <span className={styles.field_value}>
                    {currentAccount &&
                      currentAccount.type &&
                      currentAccount.type.name}
                  </span>
                </div>
              </div>
              <div className={styles.field_item}>
                <div className={styles.field_wrapper}>
                  <span className={styles.field_label}>Account Name</span>
                  <span className={styles.field_value}>
                    {currentAccount &&
                      currentAccount.account_name &&
                      currentAccount.account_name.replace(
                        /(^\w)|([-\s]\w)/g,
                        (match) => match.toUpperCase()
                      )}
                  </span>
                </div>
              </div>
              <div className={styles.field_item}>
                <div className={styles.field_wrapper}>
                  <span className={styles.field_label}>Account Number</span>
                  <span className={styles.field_value}>
                    {currentAccount &&
                      currentAccount.account_number &&
                      currentAccount.account_number}
                  </span>
                </div>
              </div>
              <div className={styles.field_item}>
                <div className={styles.field_wrapper}>
                  <span className={styles.field_label}>Book Balance</span>
                  <span className={styles.field_value}>
                    ₦
                    {currentAccount &&
                      currentAccount.book_balance &&
                      currentAccount.book_balance
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </span>
                </div>
              </div>
              <div className={styles.field_item}>
                <div className={styles.field_wrapper}>
                  <span className={styles.field_label}>Account Balance</span>
                  <span className={styles.field_value}>
                    ₦
                    {currentAccount &&
                      currentAccount.available_balance &&
                      currentAccount.available_balance
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className={styles.additional_info}>
            <h5 className={styles.additional_info_heading}>
              Additional Information
            </h5>

            <div className={styles.field_info}>
              <div className={styles.field_item}>
                <div className={styles.field_wrapper}>
                  <span className={styles.field_label}>
                    Transfer Limit Enabled
                  </span>
                  <span className={styles.field_value}>
                    {currentAccount.transfer_limit_enabled === true
                      ? "True"
                      : "False"}
                  </span>
                </div>
              </div>
              <div className={styles.field_item}>
                <div className={styles.field_wrapper}>
                  <span className={styles.field_label}>
                    Daily Transfer Limit
                  </span>
                  <span className={styles.field_value}>
                    {currentAccount?.transfer_limit?.daily === null ? "" : "₦"}
                    {currentAccount?.transfer_limit?.daily === null
                      ? "Not Set"
                      : currentAccount?.transfer_limit?.daily
                        ?.toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </span>
                </div>
              </div>
              <div className={styles.field_item}>
                <div className={styles.field_wrapper}>
                  <span className={styles.field_label}>
                    Weekly Transfer Limit
                  </span>
                  <span className={styles.field_value}>
                    {currentAccount?.transfer_limit?.weekly === null ? "" : "₦"}
                    {currentAccount?.transfer_limit?.weekly === null
                      ? "Not Set"
                      : currentAccount?.transfer_limit?.weekly
                        ?.toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </span>
                </div>
              </div>
              <div className={styles.field_item}>
                <div className={styles.field_wrapper}>
                  <span className={styles.field_label}>
                    Monthly Transfer Limit
                  </span>
                  <span className={styles.field_value}>
                    {currentAccount?.transfer_limit?.monthly === null
                      ? ""
                      : "₦"}
                    {currentAccount?.transfer_limit?.monthly === null
                      ? "Not Set"
                      : currentAccount?.transfer_limit?.monthly
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </span>
                </div>
              </div>
              <div className={styles.field_item}>
                <div className={styles.field_wrapper}>
                  <span className={styles.field_label}>Interest Enabled</span>
                  <span className={styles.field_value}>
                    {" "}
                    {currentAccount.interest_enabled === true
                      ? "True"
                      : "False"}
                  </span>
                </div>
              </div>
              <div className={styles.field_item}>
                <div className={styles.field_wrapper}>
                  <span className={styles.field_label}>Overdraft Enabled</span>
                  <span className={styles.field_value}>
                    {" "}
                    {currentAccount.overdraw_enabled === true
                      ? "True"
                      : "False"}
                  </span>
                </div>
              </div>
              <div className={styles.field_item}>
                <div className={styles.field_wrapper}>
                  <span className={styles.field_label}>Account Status</span>
                  <span className={styles.field_value}>
                    <CBadge
                      color={getStatusBadge(currentAccount.status)}
                      size="md"
                      className="text-capitalize"
                    >
                      {currentAccount.status}
                    </CBadge>
                  </span>
                </div>
              </div>
            </div>
          </div>

          {(userRole === "verification" ||
            userRole === "admin" ||
            (userRole === "super-admin" &&
              currentAccount &&
              currentAccount.type &&
              currentAccount.type.name === "Business")) && (
              <div className={styles.section_document}>
                <div className="d-flex justify-content-between">
                  <h4 className="mb-4"> Verification </h4>{" "}
                  <div onClick={toggle} className={styles.section_link}>
                    Verify Account
                  </div>
                </div>
                <div className={styles.field_info}>
                  <div className={styles.field_item}>
                    <div className={styles.field_wrapper}>
                      <span className={styles.field_label}>Status</span>
                      <span className={styles.field_value}>
                        <CBadge
                          color={getVerifyBadge(
                            currentAccount.verification &&
                            currentAccount.verification.status
                          )}
                          size="md"
                          className="text-capitalize"
                        >
                          {currentAccount.verification &&
                            currentAccount.verification.status}
                        </CBadge>
                      </span>
                    </div>
                  </div>
                </div>
                {currentAccount &&
                  currentAccount.kyc &&
                  currentAccount.kyc.cac_document === null ? (
                  <span className={styles.section_document_link}>
                    CAC Not Available
                  </span>
                ) : (
                  <a
                    href={
                      currentAccount &&
                      currentAccount.kyc &&
                      currentAccount.kyc.cac_document
                    }
                    className={styles.section_document_link}
                    target="_blank"
                    rel="noreferrer"
                  >
                    View CAC Document
                  </a>
                )}
                {(userRole === "verification" ||
                  userRole === "admin" ||
                  (userRole === "super-admin" &&
                    currentAccount &&
                    currentAccount.type &&
                    currentAccount.type.name === "Business")) && (
                    <div className="mt-4">
                      <h5 className={styles.section_note_heading}>Comments</h5>

                      <div className={styles.section_note_card}>
                        <div className={styles.section_note_text}>
                          {currentAccount &&
                            currentAccount.verification &&
                            currentAccount.verification.comment === null
                            ? "Not Available"
                            : currentAccount &&
                            currentAccount.verification &&
                            currentAccount.verification.comment}
                        </div>
                      </div>
                    </div>
                  )}
                <CModal show={modal} onClose={toggle} centered>
                  <CModalHeader closeButton> Verify Account </CModalHeader>{" "}
                  <CForm>
                    <CModalBody>
                      <div className="mb-3">
                        <CLabel htmlFor="status"> Status </CLabel>{" "}
                        <CSelect
                          id="status_type"
                          name="status_type"
                          onChange={updateStatus}
                        >
                          <option> Choose Verification Status </option>{" "}
                          <option> Verified </option>
                          <option> Failed </option>{" "}
                        </CSelect>{" "}
                      </div>
                      <div className="mb-3">
                        <CLabel htmlFor="status"> Add Comment </CLabel>{" "}
                        <CTextarea
                          onChange={updateStatus}
                          name="comment"
                          disabled={disabled}
                        />{" "}
                      </div>{" "}
                    </CModalBody>{" "}
                    <CModalFooter>
                      <CButton color="primary" onClick={(e) => submitHandler(e)}>
                        {" "}
                        {accountVerifyData.loading && (
                          <i className="fa fa-ellipsis-h fa-spin"> </i>
                        )}{" "}
                        {accountVerifyData.loading && (
                          <span>
                            <i className="fa fa-angle-right ml-1"> </i>{" "}
                          </span>
                        )}{" "}
                        {!accountVerifyData.loading && (
                          <span>
                            Submit <i className="fa fa-angle-right ml-1"> </i>{" "}
                          </span>
                        )}{" "}
                      </CButton>{" "}
                    </CModalFooter>{" "}
                  </CForm>{" "}
                </CModal>{" "}
              </div>
            )}

          <div className={styles.section_info}>
            {(userRole === "treasury" ||
              userRole === "admin" ||
              userRole === "super-admin") && (
                <div className={styles.section_document}>
                  <div className="d-flex justify-content-between">
                    <h4 className="mb-4"> Overdraft </h4>{" "}
                    <div
                      onClick={toggleOverdraft}
                      className={styles.section_link}
                    >
                      {currentAccount.overdraw_enabled === true
                        ? "Disable"
                        : "Enable"}
                    </div>
                  </div>
                  <div className={styles.field_info}>
                    <div className={styles.field_item}>
                      <div className={styles.field_wrapper}>
                        <span className={styles.field_label}>Status</span>
                        <span className={styles.field_value}>
                          <CBadge
                            color={getOverDrawnBadge(
                              currentAccount && currentAccount.overdraw_enabled
                            )}
                            size="md"
                            className="text-capitalize"
                          >
                            {currentAccount && currentAccount.overdraw_enabled
                              ? "True"
                              : "False"}
                          </CBadge>
                        </span>
                      </div>
                    </div>
                  </div>
                  <CModal
                    show={overdraftModal}
                    onClose={toggleOverdraft}
                    centered
                  >
                    <CModalHeader closeButton>Account Overdraft</CModalHeader>{" "}
                    <CForm>
                      <CModalBody>
                        <div className="mb-3">
                          <CLabel> Status </CLabel>{" "}
                          <CSelect
                            id="overdraft"
                            name="overdraft"
                            onChange={(e) => setOverDraftStatus(e.target.value)}
                          >
                            <option value=""> Choose Overdraft Status </option>{" "}
                            <option value={1}> Enable </option>
                            <option value={0}> Disable </option>{" "}
                          </CSelect>{" "}
                        </div>
                        <div className="mb-3">
                          <CInput
                            onChange={(e) => setOverDraftPassword(e.target.value)}
                            name="overdraft_password"
                            type="password"
                            placeholder="Enter Password"
                          />{" "}
                        </div>{" "}
                      </CModalBody>{" "}
                      <CModalFooter>
                        <CButton
                          color="primary"
                          onClick={(e) => submitOverDraftHandler(e)}
                        >
                          {" "}
                          {accountOverdraft && accountOverdraft.loading && (
                            <i className="fa fa-ellipsis-h fa-spin"> </i>
                          )}{" "}
                          {accountOverdraft && accountOverdraft.loading && (
                            <span>
                              <i className="fa fa-angle-right ml-1"> </i>{" "}
                            </span>
                          )}{" "}
                          {!accountOverdraft.loading && (
                            <span>
                              Submit <i className="fa fa-angle-right ml-1"> </i>{" "}
                            </span>
                          )}{" "}
                        </CButton>{" "}
                      </CModalFooter>{" "}
                    </CForm>{" "}
                  </CModal>{" "}
                </div>
              )}
          </div>

          <div className={styles.section_info}>
            {(userRole === "treasury" ||
              userRole === "admin" ||
              userRole === "super-admin") &&
              (currentAccount.post_no_debit_enabled === false ? (
                <div className={styles.section_top}>
                  <h4 className="mb-0">Post No Debit</h4>
                  {userRole === "customer-experience" ? (
                    ""
                  ) : (
                    <a
                      onClick={togglePostNoDebitModal}
                      className={styles.section_link}
                    >
                      Create PND
                      {/* Or Delete PND */}
                    </a>
                  )}
                </div>
              ) : (
                <div className={styles.section_top}>
                  <h4 className="mb-0">Post No Debit</h4>
                  {userRole === "customer-experience" ? (
                    ""
                  ) : (
                    <a
                      onClick={toggleDeletePostNoDebitModal}
                      className={styles.section_link}
                    >
                      Remove PND
                      {/* Or Delete PND */}
                    </a>
                  )}
                </div>
              ))}

            <div className="my-2">
              <div className={styles.field_info}>
                <div className={styles.field_item}>
                  <div className={styles.field_wrapper}>
                    <span className={styles.field_label}>Status</span>
                    <span className={styles.field_value}>
                      <span
                        className={
                          currentAccount.post_no_debit_enabled === true
                            ? "badge badge-success"
                            : "badge badge-danger"
                        }
                      >
                        {currentAccount.post_no_debit_enabled === true
                          ? "True"
                          : "False"}
                      </span>
                    </span>
                  </div>
                </div>
              </div>
              {currentAccount.post_no_debit_enabled === true && (
                <div className={styles.field_info}>
                  <div className={styles.field_item}>
                    <div className={styles.field_wrapper}>
                      <span className={styles.field_label}>
                        Expiry Date / Time
                      </span>
                      <span className={styles.field_value}>
                        {currentAccount &&
                          currentAccount.post_no_debit &&
                          currentAccount.post_no_debit[0] &&
                          currentAccount.post_no_debit[0].expires_at === null
                          ? "Not Available"
                          : `${moment(
                            currentAccount &&
                            currentAccount.post_no_debit &&
                            currentAccount.post_no_debit[0] &&
                            currentAccount.post_no_debit[0].expires_at
                          ).format("DD-MMM-YYYY")}`}
                      </span>
                    </div>
                  </div>
                </div>
              )}
            </div>
            {currentAccount.post_no_debit_enabled === true && (
              <div className="mt-4">
                <h5 className={styles.section_note_heading}>Comments</h5>

                <div className={styles.section_note_card}>
                  <div className={styles.section_note_text}>
                    {currentAccount &&
                      currentAccount.post_no_debit &&
                      currentAccount.post_no_debit[0] &&
                      currentAccount.post_no_debit[0].comment === null
                      ? "Not Available"
                      : currentAccount &&
                      currentAccount.post_no_debit &&
                      currentAccount.post_no_debit[0] &&
                      currentAccount.post_no_debit[0].comment}
                  </div>
                  <div className={styles.section_note_meta}>
                    <span className={styles.section_added}>
                      Added on{" "}
                      <span className={styles.section_date}>
                        {" "}
                        {currentAccount &&
                          currentAccount.post_no_debit &&
                          currentAccount.post_no_debit[0] &&
                          currentAccount.post_no_debit[0].created_at === null
                          ? "Not Available"
                          : `${moment(
                            currentAccount &&
                            currentAccount.post_no_debit &&
                            currentAccount.post_no_debit[0] &&
                            currentAccount.post_no_debit[0].created_at
                          ).format("DD-MMM-YYYY")}`}
                      </span>{" "}
                      at{" "}
                      <span className={styles.section_time}>
                        {" "}
                        {currentAccount &&
                          currentAccount.post_no_debit &&
                          currentAccount.post_no_debit[0] &&
                          currentAccount.post_no_debit[0].created_at === null
                          ? "Not Available"
                          : `${moment(
                            currentAccount &&
                            currentAccount.post_no_debit &&
                            currentAccount.post_no_debit[0] &&
                            currentAccount.post_no_debit[0].created_at
                          ).format("hh-mm")}`}
                      </span>
                    </span>
                    <span className={styles.section_sep}>|</span> By &nbsp;
                    <span className={styles.section_author}>
                      {/* Treasury Department */}
                      Not Available
                    </span>
                  </div>
                </div>
              </div>
            )}
          </div>

          {/* Edit Account Modal */}
          <UpdateAccount
            show={editModal}
            onClose={toggleEditModal}
            interestStatus={interestStatus}
            tranferLimitStatus={transferLimitStatus}
            password={password}
            loadingUpdate={loadingUpdate}
            allowLimits={allowLimits}
            transferLimits={transferLimit}
            submitAccountHandler={submitAccountHandler}
            allowLimitsChange={handleAllowLimitsChange}
            handlePasswordChange={handlePasswordChange}
            transferLimitChange={handleTransferLimitChange}
            interestEnabledChange={handleInterestEnabledChange}
            transferLimitStatusChange={handleTransferStatusChange}
            changeAccountNameHandler={changeAccountNameHandler}
            changingName={changingName}
            changeName={changeName}
          />

          {/* Delete Account Modal */}
          <DeleteAccount show={deleteModal} onClose={toggleDeleteModal} />

          {/* Restore Account Modal */}
          <CModal show={restoreModal} onClose={toggleRestoreModal} centered>
            <CModalHeader closeButton>Restore Account</CModalHeader>
            <CForm>
              <CModalBody>
                <div className="mb-3">
                  <CLabel htmlFor="restorePassword">Password</CLabel>
                  <CInput
                    id="restorePassword"
                    type="password"
                    placeholder="Enter Password"
                  ></CInput>
                </div>
              </CModalBody>
              <CModalFooter>
                <CButton color="success" variant="outline" block>
                  Yes I am sure, I want to restore this account
                </CButton>{" "}
              </CModalFooter>
            </CForm>
          </CModal>

          {/* Post No Debit Modal */}
          <CModal
            show={postNoDebitModal}
            onClose={togglePostNoDebitModal}
            centered
          >
            <CModalHeader closeButton>Post No Debit</CModalHeader>
            <CForm autoComplete="off">
              <CModalBody>
                {/* <CListGroup className="mb-3">
                  <CListGroupItem href="#" color="warning">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam
                  </CListGroupItem>
                </CListGroup> */}
                <div className="mb-3">
                  <div className="d-flex align-items-center mb-2">
                    <CLabel htmlFor="dateTimeLocal" className="mb-0">
                      Choose Expiry Date and Time
                    </CLabel>
                    <CTooltip
                      content="PNB would be permanent if you do not choose an expiry date and time"
                      placement="bottom"
                    >
                      <span className="brand-tooltip">?</span>
                    </CTooltip>
                  </div>
                  {/* <CInput 
                     id="dateTimeLocal"
                    // type="datetime-local"
                    // onChange={(date) => set_expires_at(date)}
                  // >


                  {/* </CInput> */}
                  <DatePicker
                    isClearable
                    placeholderText="YY//MM/DD"
                    dateFromat="yyyy-MM-dd"
                    className="form-control"
                    selected={startDate}
                    minDate={date}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    // showTimeSelect
                    onChange={(date) => set_expires_at(date)}
                    fixedHeight={22}
                  />
                </div>
                <div className="mb-3">
                  <CLabel htmlFor="commentPNB">Add Reason for PNB</CLabel>
                  <CTextarea onChange={update} name="comment" />
                </div>
                <div className="mb-3">
                  <CLabel htmlFor="deletePNBPassword">Password</CLabel>
                  <CInput
                    name="deletePNBPassword"
                    id="deletePNBPassword"
                    type="password"
                    placeholder="Enter Password"
                    autoComplete="new-password"
                    onChange={update}
                  ></CInput>
                  <p className="text-danger">{blockAccountError}</p>
                </div>
              </CModalBody>
              <CModalFooter>
                <CButton
                  color="danger"
                  variant="outline"
                  block
                  onClick={(e) => Filter(e)}
                >
                  {loadingBlock && <i className="fa fa-ellipsis-h fa-spin"></i>}
                  {loadingBlock && (
                    <span>
                      <i className="fa fa-angle-right ml-1"></i>
                    </span>
                  )}
                  {!loadingBlock && (
                    <span>
                      I understand the consequences, create Post No Debit on
                      this account
                    </span>
                  )}
                </CButton>{" "}
              </CModalFooter>
            </CForm>
          </CModal>
          <CModal
            show={deletepostNoDebitModal}
            onClose={toggleDeletePostNoDebitModal}
            centered
          >
            <CModalHeader closeButton>Post No Debit</CModalHeader>
            <CForm>
              <CModalBody>
                <CListGroup className="mb-3">
                  {/* <CListGroupItem href="#" color="warning">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam
                  </CListGroupItem> */}
                </CListGroup>

                <div className="mb-3">
                  <CLabel htmlFor="deletePNBPassword">Password</CLabel>
                  <CInput
                    name="deletePNBlockPassword"
                    id="deletePNBlockPassword"
                    type="password"
                    value={password}
                    placeholder="Enter Password"
                    onChange={update}
                  ></CInput>
                  <p className="text-danger">{unBlockAccountError}</p>
                </div>
              </CModalBody>

              <CModalFooter>
                <CButton
                  color="danger"
                  variant="outline"
                  block
                  onClick={(e) => submitUnHandler(e)}
                >
                  {loadin && <i className="fa fa-ellipsis-h fa-spin"></i>}
                  {loadin && (
                    <span>
                      <i className="fa fa-angle-right ml-1"></i>
                    </span>
                  )}
                  {!loadin && (
                    <span>
                      I understand the consequences, of delete Post No Debit on
                      this account
                    </span>
                  )}
                </CButton>{" "}
              </CModalFooter>
            </CForm>
          </CModal>

          <div className={styles.section_info}>
            {(userRole === "treasury" ||
              userRole === "admin" ||
              userRole === "super-admin") && (
                <div className={styles.section_document}>
                  <div className="d-flex justify-content-between">
                    <h4 className="mb-4"> Direct Debit Mandate </h4>{" "}
                    <div className={styles.section_link}>
                      {debitMandateStatus && debitMandateStatus ? (
                        <div>
                          <span onClick={() => setActiveKey(7)} className="mr-4">
                            View
                          </span>
                          <span onClick={toggleDebitMandateModal}>Create</span>
                        </div>
                      ) : (
                        <span onClick={toggleDebitMandateModal}>Create</span>
                      )}
                    </div>
                  </div>
                  <div className={styles.field_info}>
                    <div className={styles.field_item}>
                      <div className={styles.field_wrapper}>
                        <span className={styles.field_label}>Status</span>
                        <span className={styles.field_value}>
                          {debitMandateStatus && debitMandateStatus ? (
                            <span className="badge badge-success"> True </span>
                          ) : (
                            <span className="badge badge-danger"> False</span>
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                  <CModal
                    show={debitMandateModal}
                    onClose={toggleDebitMandateModal}
                    centered
                  >
                    <CModalHeader closeButton>Create Debit Mandate</CModalHeader>{" "}
                    <CForm autoComplete="false">
                      <CModalBody>
                        <div className="mb-3">
                          <CLabel> Mandate Type *</CLabel>{" "}
                          <CSelect
                            id="debit_mandate"
                            name="debit_mandate"
                            onChange={(e) => setMandateType(e.target.value)}
                          >
                            <option value="">choose transaction type</option>
                            {mandateSchedules &&
                              mandateSchedules.transfer_channels.map((x) => (
                                <option
                                  value={x.value}
                                  key={x.value}
                                  className="text-capitalize"
                                >
                                  {" "}
                                  {x.name}
                                </option>
                              ))}
                          </CSelect>{" "}
                        </div>
                        {mandateType === "Other banks" && (
                          <div className="mb-3">
                            <Select
                              options={banks}
                              getOptionLabel={(options) => options["bank_name"]}
                              getOptionValue={(options) =>
                                options["nip_bank_code"]
                              }
                              onChange={(selectedOption) =>
                                setDebitMandateBankName(selectedOption)
                              }
                            />
                          </div>
                        )}
                        <div className="mb-3">
                          <CInput
                            value={debitMandateAccountNumber}
                            onChange={(e) =>
                              setDebitMandateAccountNumber(e.target.value)
                            }
                            name="debit_mandate_account_number *"
                            type="number"
                            placeholder="Account Number"
                            autoComplete="false"
                            onWheel={() => document.activeElement.blur()}
                          />{" "}
                        </div>{" "}
                        <div className="mb-3">
                          <CInput
                            name="debit_mandate_beneficiary"
                            type="text"
                            readOnly
                            value={debitMandateBeneficiary}
                            placeholder="Beneficiary name *"
                          />{" "}
                        </div>{" "}
                        <div className="mb-3">
                          <CInput
                            onChange={(e) => setDebitMandateDesc(e.target.value)}
                            name="debit_mandate_desc"
                            type="text"
                            placeholder="Description"
                          />{" "}
                        </div>{" "}
                        <div className="mb-4">
                          <CLabel className="font-weight-bold text-dark w-100">
                            Start Date *
                          </CLabel>
                          <DatePicker
                            dateFormat="dd-MM-yyyy"
                            className="form-control"
                            selected={debitMandateStartDate}
                            isClearable
                            placeholderText="DD/MM/YY"
                            minDate={new Date()}
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            selectsStart
                            startDate={debitMandateStartDate}
                            endDate={debitMandateEndDate}
                            onChange={(date) => {
                              setDebitMandateStartDate(date);
                            }}
                          />
                        </div>
                        <div className="mb-4">
                          <CLabel className="font-weight-bold text-dark w-100">
                            End Date *
                          </CLabel>
                          <DatePicker
                            className="form-control"
                            dateFormat="dd-MM-yyyy"
                            isClearable
                            placeholderText="DD/MM/YY"
                            selected={debitMandateEndDate}
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            selectsEnd
                            startDate={debitMandateStartDate}
                            // endDate={accountStatementEndDate}
                            minDate={debitMandateStartDate}
                            onChange={(date) => setDebitMandateEndDate(date)}
                          />
                        </div>
                        <div className="mb-3">
                          <CInput
                            onChange={(e) =>
                              setDebitMandateAmount(e.target.value)
                            }
                            name="debit_mandate_amount *"
                            type="number"
                            placeholder="Enter Amount"
                            onWheel={() => document.activeElement.blur()}
                          />{" "}
                        </div>{" "}
                        <div className="mb-3">
                          <CLabel> Shedule </CLabel>{" "}
                          <CSelect
                            id="debit_mandate"
                            name="debit_mandate"
                            onChange={(e) =>
                              setDebitMandateSchedule(e.target.value)
                            }
                          >
                            <option>choose transaction schedule</option>
                            {mandateSchedules &&
                              mandateSchedules.schedules.map((x) => (
                                <option
                                  value={x.name}
                                  key={x.name}
                                  className="text-capitalize"
                                >
                                  {" "}
                                  {x.value}
                                </option>
                              ))}
                          </CSelect>{" "}
                        </div>
                        <div className="mb-3">
                          <CInput
                            onChange={(e) =>
                              setDebitMandatePassword(e.target.value)
                            }
                            name="debit_mandate_password *"
                            type="password"
                            placeholder="Enter Pin"
                            autoComplete="false"
                            value={debitMandatePassword}
                          />{" "}
                        </div>{" "}
                        <div className="mb-3 ml-3">
                          <div>
                            <CInputCheckbox
                              onChange={(e) =>
                                setDebitMandateRem(e.target.checked)
                              }
                            />
                            <p>Remember</p>
                          </div>
                          <p className="text-danger">{mandateFormErr}</p>
                        </div>
                      </CModalBody>{" "}
                      <CModalFooter>
                        <CButton
                          color="primary"
                          onClick={(e) => submitDebitMandateHandler(e)}
                        >
                          {" "}
                          {createMandateLoading && (
                            <i className="fa fa-ellipsis-h fa-spin"> </i>
                          )}{" "}
                          {!createMandateLoading && (
                            <span>
                              Submit <i className="fa fa-angle-right ml-1"> </i>{" "}
                            </span>
                          )}{" "}
                        </CButton>{" "}
                      </CModalFooter>{" "}
                    </CForm>{" "}
                  </CModal>{" "}
                </div>
              )}
          </div>
        </CTabPane>
        <CTabPane active={activeKey === 2}>
          <div className={styles.tab_info}>
            <div className="d-flex align-items-center justify-content-between mb-5">
              <div>
                <h3>Transactions</h3>
                <p>
                  A look at all the transactions that have been carried out on
                  the account
                </p>
              </div>

              <CSVLink
                data={
                  account_transactions_data &&
                  account_transactions_data.map((x) => ({
                    reference: x.transaction_reference,
                    credit_account:
                      x.beneficiary && x.beneficiary.account_number,
                    credit_account_name:
                      x.beneficiary &&
                      x.beneficiary.account_name
                        .toString()
                        .replace(/(^\w)|([-\s]\w)/g, (match) =>
                          match.toUpperCase()
                        ),
                    debit_account: x.source && x.source.account_number,
                    debit_account_name:
                      x.source &&
                      x.source.account_name
                        .toString()
                        .replace(/(^\w)|([-\s]\w)/g, (match) =>
                          match.toUpperCase()
                        ),
                    amount:
                      x.amount &&
                      x.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                    bank: x.bank && x.bank.bank_name,
                    type: x.type,
                    status: x.status,
                    date_created: x.created_at
                      ? x.created_at.slice(0, 10)
                      : "Not Available",
                  }))
                }
                filename={accountTransactionFileName}
              >
                <CIcon name="cil-print" />
              </CSVLink>
            </div>
          </div>

          <CDropdown variant="dropdown" className="mb-3">
            <CDropdownToggle
              color="secondary"
              size="md"
              className="px-2 px-md-3"
            >
              Advanced Filter
            </CDropdownToggle>
            <CDropdownMenu
              style={{ minWidth: "250px", width: "100%", maxWidth: "380px" }}
            >
              <h6 className="px-3">Advanced Filter</h6>
              <CDropdownDivider />
              <CForm>
                <CRow className="px-3 py-2">
                  <CCol className="col-12 mb-2">
                    <CLabel className="font-weight-bold text-dark w-100">
                      Transaction Reference
                    </CLabel>
                    <CInput
                      className="w-100"
                      onChange={updateFilter}
                      name="transaction_reference"
                    />
                  </CCol>
                  <CCol className="col-12 mb-2">
                    <CLabel className="font-weight-bold text-dark w-100">
                      Credit Account Number
                    </CLabel>
                    <CInput
                      type="text"
                      className="w-100"
                      onChange={updateFilter}
                      name="account_number"
                    />
                  </CCol>
                  <CCol className="col-12 mb-2">
                    <CLabel className="font-weight-bold text-dark w-100">
                      Credit Account Name
                    </CLabel>
                    <CInput
                      type="text"
                      className="w-100"
                      onChange={updateFilter}
                      name="account_name"
                    />
                  </CCol>
                  <CCol className="col-12 mb-2">
                    <CLabel className="font-weight-bold text-dark w-100">
                      Debit Account Number
                    </CLabel>
                    <CInput
                      type="text"
                      className="w-100"
                      name="account_number"
                    />
                  </CCol>
                  <CCol className="col-12 mb-2">
                    <CLabel className="font-weight-bold text-dark w-100">
                      Debit Account Name
                    </CLabel>
                    <CInput
                      type="text"
                      className="w-100"
                      onChange={updateFilter}
                      name="account_name"
                    />
                  </CCol>
                  <CCol className="col-12 mb-2">
                    <CLabel className="font-weight-bold text-dark w-100">
                      Amount (₦)
                    </CLabel>
                    <CInput
                      type="text"
                      className="w-100"
                      onChange={updateFilter}
                      name="amount"
                    />
                  </CCol>
                  <CCol className="col-12 mb-2">
                    <CLabel className="font-weight-bold text-dark w-100">
                      Bank
                    </CLabel>
                    <CInput
                      type="text"
                      className="w-100"
                      onChange={updateFilter}
                      name="bank_code"
                    />
                  </CCol>
                  <CCol className="col-12 mb-2">
                    <CLabel className="font-weight-bold text-dark w-100">
                      Transaction Type
                    </CLabel>
                    <CSelect
                      className="w-100"
                      onChange={updateFilter}
                      name="type"
                      defaultValue={""}
                    >
                      <option value="">All</option>
                      <option value="debit">Debit</option>
                      <option value="credit">Credit</option>
                    </CSelect>
                  </CCol>
                  <CCol className="col-12 mb-2">
                    <CLabel className="font-weight-bold text-dark w-100">
                      Transaction Status
                    </CLabel>
                    <CSelect
                      className="w-100"
                      onChange={updateFilter}
                      name="status"
                      defaultValue={""}
                    >
                      <option value="">All</option>
                      <option value="success">Success</option>
                      <option value="pending">Pending</option>
                      <option value="failed">Failed</option>
                    </CSelect>
                  </CCol>
                  <CCol className="col-12 col-md-6 mb-2">
                    <CLabel className="font-weight-bold text-dark w-100">
                      Start Date
                    </CLabel>
                    <CInput
                      type="date"
                      className="w-100"
                      value={dates.transactionStartDate}
                      onChange={setDateValue("transactionStartDate")}
                    />
                  </CCol>

                  <CCol className="col-12 col-md-6 mb-2">
                    <CLabel className="font-weight-bold text-dark w-100">
                      End Date
                    </CLabel>
                    <CInput
                      type="date"
                      className="w-100"
                      value={dates.transactionEndDate}
                      onChange={setDateValue("transactionEndDate")}
                    />
                  </CCol>
                </CRow>
                <CDropdownDivider />
                <div className="d-flex align-items-center justify-content-between px-3 py-1">
                  <a className="cursor-pointer text-primary" role="button">
                    Reset Filter
                  </a>
                  <CButton
                    color="dark"
                    type="submit"
                    onClick={(e) => submitFilterHandler(e)}
                  >
                    Submit
                  </CButton>
                </div>
              </CForm>
            </CDropdownMenu>
          </CDropdown>

          <TransactionDataTable
            data={account_transactions_data}
            loading={loading}
            itemsPerPage={30}
            page={page}
          />
          <CPagination
            activePage
            onActivePageChange={pageChange}
            pages={pages}
            doubleArrows={false}
            align="end"
          />
        </CTabPane>
        <CTabPane active={activeKey === 4}>
          <div className={styles.tab_info}>
            <div className="d-flex align-items-center justify-content-between mb-5">
              <div>
                <h3>Investments</h3>
                <p>All Account's Investments</p>
              </div>
              <CSVLink
                data={
                  account_investments_data &&
                  account_investments_data.map((x) => ({
                    investment_type: x.investment_type,
                    source_account: x.source_account,
                    beneficial_account: x.beneficial_account,
                    title: x.title,
                    amount:
                      x.amount &&
                      x.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                    rollover_type: x.rollover_type,
                    type: x.type && x.type.name,
                    status: x.status,
                    date_created: x.created_at
                      ? x.created_at.slice(0, 10)
                      : "Not Available",
                  }))
                }
                filename={accountInvestmentFileName}
              >
                <CIcon name="cil-print" />
              </CSVLink>
            </div>
          </div>

          <CDropdown variant="dropdown" className="mb-3">
            <CDropdownToggle
              color="secondary"
              size="md"
              className="px-2 px-md-3"
            >
              Advanced Filter
            </CDropdownToggle>
            <CDropdownMenu
              style={{ minWidth: "250px", width: "100%", maxWidth: "380px" }}
            >
              <h6 className="px-3">Advanced Filter</h6>
              <CDropdownDivider />
              <CForm>
                <CRow className="px-3 py-2">
                  <CCol className="col-12 mb-2">
                    <CLabel className="font-weight-bold text-dark w-100">
                      Investment Type
                    </CLabel>
                    <CSelect className="w-100" defaultValue={""}>
                      <option value="">All</option>
                      <option value="silver">Silver</option>
                      <option value="gold">Gold</option>
                      <option value="diamond">Diamond</option>
                      <option value="platinum">Platinum</option>
                    </CSelect>
                  </CCol>
                  <CCol className="col-12 mb-2">
                    <CLabel className="font-weight-bold text-dark w-100">
                      Source Account
                    </CLabel>
                    <CInput type="text" className="w-100" />
                  </CCol>
                  <CCol className="col-12 mb-2">
                    <CLabel className="font-weight-bold text-dark w-100">
                      Beneficiary Account
                    </CLabel>
                    <CInput type="text" className="w-100" />
                  </CCol>
                  <CCol className="col-12 mb-2">
                    <CLabel className="font-weight-bold text-dark w-100">
                      Title
                    </CLabel>
                    <CInput type="text" className="w-100" />
                  </CCol>
                  <CCol className="col-12 mb-2">
                    <CLabel className="font-weight-bold text-dark w-100">
                      Amount
                    </CLabel>
                    <CInput type="text" className="w-100" />
                  </CCol>
                  <CCol className="col-12 mb-2">
                    <CLabel className="font-weight-bold text-dark w-100">
                      Rollover Type
                    </CLabel>
                    <CSelect className="w-100" defaultValue={""}>
                      <option value="">All</option>
                      <option value="not available">Not Available</option>
                      <option value="investment">Investment</option>
                      <option value="investment + interest">
                        Investment + Interest
                      </option>
                    </CSelect>
                  </CCol>
                  <CCol className="col-12 mb-2">
                    <CLabel className="font-weight-bold text-dark w-100">
                      Status
                    </CLabel>
                    <CSelect className="w-100" defaultValue={""}>
                      <option value="">All</option>
                      <option value="inactive">Inactive</option>
                      <option value="active">Active</option>
                    </CSelect>
                  </CCol>
                  <CCol className="col-12 col-md-6 mb-2">
                    <CLabel className="font-weight-bold text-dark w-100">
                      Start Date
                    </CLabel>
                    <CInput
                      type="date"
                      className="w-100"
                      value={dates.investmentStartDate}
                      onChange={setDateValue("investmentStartDate")}
                    />
                  </CCol>

                  <CCol className="col-12 col-md-6 mb-2">
                    <CLabel className="font-weight-bold text-dark w-100">
                      End Date
                    </CLabel>
                    <CInput
                      type="date"
                      className="w-100"
                      value={dates.investmentEndDate}
                      onChange={setDateValue("investmentEndDate")}
                    />
                  </CCol>
                </CRow>
                <CDropdownDivider />
                <div className="d-flex align-items-center justify-content-between px-3 py-1">
                  <a className="cursor-pointer text-primary" role="button">
                    Reset Filter
                  </a>
                  <CButton color="dark" type="submit">
                    Submit
                  </CButton>
                </div>
              </CForm>
            </CDropdownMenu>
          </CDropdown>

          <InvestmentsDataTable
            items={account_investments_data}
            itemsPerPage={30}
          />
          <CPagination
            activePage
            onActivePageChange={pageChange}
            pages={1}
            doubleArrows={true}
            align="end"
          />
        </CTabPane>
        <CTabPane active={activeKey === 5}>
          <div className={styles.tab_info}>
            <div className="d-flex align-items-center justify-content-between mb-5">
              <div>
                <h3>Interests</h3>
                <p>All Interest accrued on this account</p>
              </div>
              <CSVLink
                data={
                  account_interest_data &&
                  account_interest_data.map((x) => ({
                    amount:
                      x.amount &&
                      x.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                    month: x.month,
                    year: x.year,
                    status: x.status,
                  }))
                }
                filename={accountInterestFileName}
              >
                <CIcon name="cil-print" />
              </CSVLink>
            </div>
          </div>

          <CDropdown variant="dropdown" className="mb-3">
            <CDropdownToggle
              color="secondary"
              size="md"
              className="px-2 px-md-3"
            >
              Advanced Filter
            </CDropdownToggle>
            <CDropdownMenu
              style={{ minWidth: "250px", width: "100%", maxWidth: "380px" }}
            >
              <h6 className="px-3">Advanced Filter</h6>
              <CDropdownDivider />
              <CForm>
                <CRow className="px-3 py-2">
                  <CCol className="col-12 mb-2">
                    <CLabel className="font-weight-bold text-dark w-100">
                      Amount
                    </CLabel>
                    <CInput type="text" className="w-100" />
                  </CCol>
                  <CCol className="col-12 mb-2">
                    <CLabel className="font-weight-bold text-dark w-100">
                      Month
                    </CLabel>
                    <CInput type="text" className="w-100" />
                  </CCol>
                  <CCol className="col-12 mb-2">
                    <CLabel className="font-weight-bold text-dark w-100">
                      Year
                    </CLabel>
                    <CInput type="text" className="w-100" />
                  </CCol>
                  <CCol className="col-12 mb-2">
                    <CLabel className="font-weight-bold text-dark w-100">
                      Status
                    </CLabel>
                    <CSelect className="w-100" defaultValue={""}>
                      <option value="">All</option>
                      <option value="paid">Paid</option>
                      <option value="unpaid">Unpaid</option>
                    </CSelect>
                  </CCol>
                  <CCol className="col-12 col-md-6 mb-2">
                    <CLabel className="font-weight-bold text-dark w-100">
                      Start Date
                    </CLabel>
                    <CInput
                      type="date"
                      className="w-100"
                      value={dates.interestStartDate}
                      onChange={setDateValue("interestStartDate")}
                    />
                  </CCol>

                  <CCol className="col-12 col-md-6 mb-2">
                    <CLabel className="font-weight-bold text-dark w-100">
                      End Date
                    </CLabel>
                    <CInput
                      type="date"
                      className="w-100"
                      value={dates.interestEndDate}
                      onChange={setDateValue("interestEndDate")}
                    />
                  </CCol>
                </CRow>
                <CDropdownDivider />
                <div className="d-flex align-items-center justify-content-between px-3 py-1">
                  <a className="cursor-pointer text-primary" role="button">
                    Reset Filter
                  </a>
                  <CButton color="dark" type="submit">
                    Submit
                  </CButton>
                </div>
              </CForm>
            </CDropdownMenu>
          </CDropdown>

          {/* <CDataTable
            items={account_interest_data}
            fields={interestFields}
            columnFilter
            footer
            responsive={true}
            itemsPerPageSelect
            hover
            sorter
            itemsPerPage={30}
            activePage
            scopedSlots={{
              status: (item) => (
                <td className="text-capitalize">
                  <CBadge color={getInterestStatusBadge(item.status)} size="md">
                    {item.status}
                  </CBadge>
                </td>
              ),
            }}
          /> */}
          <CPagination
            activePage
            onActivePageChange={pageChange}
            pages={1}
            doubleArrows={true}
            align="end"
          />
        </CTabPane>
        <CTabPane active={activeKey === 6}>
          {/* <CCard> */}
          <CForm>
            <CRow className="mt-4">
              <CCol sm="10" md="3" className="mx-auto px-0 px-md-3 mt-5">
                <div>
                  <p> Request an account statement for this account </p>{" "}
                </div>{" "}
                <div className="mb-4">
                  <CLabel className="font-weight-bold text-dark w-100">
                    Start Date
                  </CLabel>
                  <DatePicker
                    dateFormat="dd-MM-yyyy"
                    className="form-control"
                    selected={accountStatementStartDate}
                    isClearable
                    placeholderText="DD/MM/YY"
                    maxDate={accountStatementEndDate}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    selectsStart
                    startDate={accountStatementStartDate}
                    endDate={accountStatementEndDate}
                    onChange={(date) => {
                      setAccountStatementStartDate(date);
                    }}
                  />
                </div>
                <div className="mb-4">
                  <CLabel className="font-weight-bold text-dark w-100">
                    End Date
                  </CLabel>
                  <DatePicker
                    className="form-control"
                    dateFormat="dd-MM-yyyy"
                    isClearable
                    placeholderText="DD/MM/YY"
                    selected={accountStatementEndDate}
                    maxDate={new Date()}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    selectsEnd
                    startDate={accountStatementStartDate}
                    // endDate={accountStatementEndDate}
                    minDate={accountStatementStartDate}
                    onChange={(date) => setAccountStatementEndDate(date)}
                  />
                </div>
                <div className="mb-4">
                  <CLabel className="font-weight-bold text-dark w-100">
                    Send to Account Owner
                  </CLabel>
                  <CSelect
                    className="w-100"
                    name="send"
                    defaultValue={accountStatementSendUser}
                    onChange={(e) =>
                      setAccountStatementSendUser(e.target.value)
                    }
                  >
                    <option value="">-- Select Option --</option>
                    <option value={1}>Yes</option>
                    <option value={0}>No</option>
                  </CSelect>
                </div>
                {accountStatementError ? (
                  <p className="text-danger">
                    Start Date, End Date and Send to account owner must be
                    filled
                  </p>
                ) : (
                  ""
                )}
                <div className="mt-4">
                  {accountStatementLoading ? (
                    <>
                      <CButton color="success" type="submit" block>
                        <CSpinner color="white" size="sm" /> Requesting...
                      </CButton>
                    </>
                  ) : (
                    <CButton
                      color="success"
                      type="submit"
                      block
                      onClick={(e) => sendAccountStatementHandler(e)}
                    >
                      Submit Request
                    </CButton>
                  )}
                </div>
              </CCol>
            </CRow>
          </CForm>
          {/* </CCard> */}
          {/* end of send account statement
  to user */}
        </CTabPane>{" "}
        {/* Same as */}{" "}
        <CTabPane active={activeKey === 7}>
          <div className={styles.tab_info}>
            <div>
              <div className="">
                <h3>Debit Mandate</h3>
                <p>Account's Debit Mandate</p>
              </div>

              <AccountsDebitMandateDataTable
                items={accountMandate?.data}
                itemsPerPage={30}
                page={page}
              />
              <div className="p-3  col-sm-12">
                <CPagination
                  activePage
                  onActivePageChange={pageChange}
                  pages={pages}
                  doubleArrows={true}
                  align="end"
                />
              </div>
            </div>
          </div>
        </CTabPane>
        {BLOCK_FUNDS_ACCESS.includes(userRole) ? (
          <CTabPane active={activeKey === 8}>
            <div className={styles.tab_info}>
              <div>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="">
                    <h3>Block Fund</h3>
                    <p>Block user's fund</p>
                  </div>

                  <div className={styles.tab_tooltip}>
                    <CTooltip
                      content="Update Account"
                      placement="bottom"
                      className="pr-2"
                    >
                      <CButton
                        color="success"
                        size="sm"
                        onClick={handleOpenBlockFundModal}
                      >
                        <CIcon name="cilPencil" size="sm" />
                      </CButton>
                    </CTooltip>
                  </div>
                </div>

                <AccountBlockFundDataTable
                  itemsPerPage={30}
                  items={accountBlockFund}
                  accountId={currentAccount?.id}
                  onClose={handleCloseBlockFundModal}
                  openBlockFundModal={openBlockFundModal}
                  handleOpenBlockFundModal={handleOpenBlockFundModal}
                />
                <div className="p-3  col-sm-12">
                  <CPagination
                    activePage
                    onActivePageChange={pageChange}
                    pages={pages}
                    doubleArrows={true}
                    align="end"
                  />
                </div>
              </div>
            </div>
          </CTabPane>
        ) : null}
      </CTabContent>
      <ToastContainer autoClose={5000} />
    </>
  );
};

export default AccountDetails;