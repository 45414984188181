/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useHistory } from "react-router-dom";

import {
  CBadge,
  CDataTable,
} from "@coreui/react";

const getStatusBadge = (status) => {
  switch (status) {
    case "active":
      return "success";
    case "inactive":
      return "danger";
    default:
      return "";
  }
};

const getStateBadge = (state) => {
  if (state) {
    return "danger";
  } else {
    return "success";
  }
};

const AccountsDataTable = ({items, loading, itemsPerPage, page}) => {
  const history = useHistory();

  const fields = [
    { key: "account_name", _style: { width: "20%" } },
    { key: "account_number", _style: { width: "15%" } },
    { key: "type", label: "Type", _style: { width: "13%" } },
    
    {
      key: "book_balance",
      label: "Book Balance (₦)",
      _style: { width: "18%" },
    },
    { key: "account_status", label: "Status" },
    { key: "post_no_debit_enabled", label: "Post No Debit" },

    { key: "deleted_at", label: "State" },
  ];

  return (
    <CDataTable
      items={items}
      fields={fields}
      columnFilter
      tableFilter
      footer
      responsive={true}
      // itemsPerPageSelect={{
      //   label: "Items per page:",
      //   values: [5, 10, 20, 30],
      // }}
      hover
      loading={loading}
      sorter
      itemsPerPage={itemsPerPage}
      clickableRows
      // This will be modified to reflect the specific account details clicked
      onRowClick={(item) =>
        history.push({pathname:`/accounts/account-details/${item.id}`, state: page})
      }
      scopedSlots={{
        account_name: (item) => (
          <td className="text-capitalize">
            {item.account_name &&
              item.account_name.replace(/(^\w)|([-\s]\w)/g, (match) =>
                match.toUpperCase()
              )}
          </td>
        ),
        account_status: (item) => (
          <td>
            <CBadge
              color={getStatusBadge(item.status)}
              size="md"
              className="text-capitalize"
            >
              {item.status}
            </CBadge>
          </td>
        ),

        deleted_at: (item) => (
          <td>
            <CBadge
              color={getStateBadge(item.deleted_at)}
              size="md"
              className="text-capitalize"
            >
              {item.deleted_at === null ? "Available" : "Deleted"}
            </CBadge>
          </td>
        ),
        type: (item) => (
          <td>
            <>{item && item.type && item.type.name}</>
          </td>
        ),
        book_balance: (item) => (
          <td>
            <>
              {item &&
                item.book_balance &&
                item.book_balance
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </>
          </td>
        ),
        post_no_debit_enabled: (item) => (
          <td>
            <>
              {item && item.post_no_debit_enabled === true ? (
                <>True</>
              ) : (
                <>False</>
              )}
            </>
          </td>
        ),
      }}
    />
  )
}

export default AccountsDataTable