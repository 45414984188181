import { axiosWithAuth } from "../../_utils/api";

const getAllPartners = (page) => {
    return axiosWithAuth
      .get(`/admin/partners?include=owner&page[number]=${page}`)
  };
  const getSinglePartner = (id) => {
    return axiosWithAuth
      .get(`/admin/partners/${id}?include=owner`)
  };

  const getAllPartnersFilter = async (query, page) => {
    if(page === undefined){
      page = 1
    }
    return await axiosWithAuth
      .get(`/admin/partners?` + query + `&include=owner&page[number]=${page}`)
    
  };

  export default {
      getAllPartners,
      getAllPartnersFilter,
      getSinglePartner
  }