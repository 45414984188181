import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { useSelector } from "react-redux";

import "./scss/style.scss";
// Containers
import TheLayout from "./containers/TheLayout";
import VerifyLayout from "./containers/VerifyLayout";
import AdminLayout from "./containers/AdminLayout";
import AccountLayout from "./containers/AccountLayout";
import TreasuryLayout from "./containers/TreasuryLayout";
import CXLayout from "./containers/CXLayout";
import { isLogin } from "./_utils/api";
import IdleTimer from "react-idle-timer";
import logout from "./redux/_services/logoutservice";
import ControlLayout from "./containers/ControlLayout";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MarketingLayout from "./containers/MarketingLayout";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Pages
const Login = React.lazy(() => import("./views/pages/login/Login"));
const ResetPassword = React.lazy(() =>
  import("./views/pages/resetpassword/ResetPassword")
);
// const Profile = React.lazy(() => import("./views/profile/Profile"));

const PrivateRoute = ({ component: Component, ...rest }) => {
  const userSignin = useSelector((state) => state.userSignin);

  const { userInfo } = userSignin;
  const userRole =
    userInfo &&
    userInfo.data &&
    userInfo.data.user &&
    userInfo.data.user.roles &&
    userInfo.data.user.roles[0].name;

  return (
    <Route
      {...rest}
      render={(props) =>
        (isLogin() && userRole === "admin") ||
        (isLogin() && userRole === "super-admin") ? (
          <AdminLayout {...props} />
        ) : (isLogin() && userRole === "account-manager") ||
          (isLogin() && userRole === "general-marketer") ? (
          <AccountLayout {...props} />
        ) : isLogin() && userRole === "treasury" ? (
          <TreasuryLayout {...props} />
        ) : isLogin() && userRole === "verification" ? (
          <VerifyLayout {...props} />
        ) : isLogin() && userRole === "customer-experience" ? (
          <CXLayout {...props} />
        ) : isLogin() && userRole === "internal-control" ? (
          <ControlLayout {...props} />
        ) : isLogin() && userRole === "marketting" ? (
          <MarketingLayout {...props} />
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};

class App extends Component {
  constructor(props) {
    super(props);
    this.idleTimer = null;
    this.handleOnIdle = this.handleOnIdle.bind(this);
  }

  handleOnIdle(event) {
    logout();
  }
  render() {
    return (
      <>
        <IdleTimer
          ref={(ref) => {
            this.idleTimer = ref;
          }}
          timeout={1000 * 60 * 11}
          onIdle={this.handleOnIdle}
          debounce={250}
        />
        <Router>
          <React.Suspense fallback={loading}>
            <Switch>
              <Route
                exact
                path="/login"
                name="Login Page"
                render={(props) => <Login {...props} />}
              />
              <Route
                exact
                path="/reset-password"
                name="Reset password Page"
                render={(props) => <ResetPassword {...props} />}
              />
              {/* <Route
              exact
              path="/profile"
              name="Profile"
              render={(props) => <Profile {...props} />}
            /> */}
              {/*
              <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
              <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} /> */}
              <PrivateRoute path="/" name="Home" />
            </Switch>
          </React.Suspense>
        </Router>
        <ToastContainer />
      </>
    );
  }
}

export default App;
