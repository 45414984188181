/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useHistory , useParams} from "react-router-dom";
import moment from "moment";

import { CBadge, CDataTable } from "@coreui/react";

const getStatusBadge = (status) => {
  switch (status) {
    case "delivered":
      return "success";
    case "inactive":
      return "danger";
    default:
      return "danger";
  }
};

const getStateBadge = (state) => {
  if (state) {
    return "danger";
  } else {
    return "success";
  }
};

const TeamLeadDataTable = ({ items, loading, itemsPerPage, page }) => {
   
  const history = useHistory();
  const fields = [
    { key: "team_name", label: "Name"},
    { key: "email", label: "Email"},
    { key: "created_at", label: "Created Date"},
    {key: "updated_at", label: "Updated Date"},
  ];

  return (
    <CDataTable
      items={items}
      fields={fields}
      columnFilter
    //   tableFilter
      footer
      responsive={true}
      // itemsPerPageSelect={{
      //   label: "Items per page:",
      //   values: [5, 10, 20, 30],
      // }}
      hover
      loading={loading}
      sorter
      itemsPerPage={itemsPerPage}
      activePage
      clickableRows
      This will be modified to reflect the specific account details clicked
      onRowClick={
          (items) =>
        history.push({
          pathname: `/team-lead/${items.id}/`,
          state: page,
        })
      }
      scopedSlots={{
        team_name: (item) => (
          <td className="text-capitalize">
            {
              item &&
                item.account_manager &&
                item.account_manager.full_name ||   item &&
                item.general_marketer &&
                item.general_marketer.full_name
            }
          </td>
        ),
        email: (item) => (
          <td className="text-capitalize">
            {
              item &&
                item.account_manager &&
                item.account_manager.email || item &&
                item.general_marketer &&
                item.general_marketer.email
            }
          </td>
        ),
        created_at: (item) => (
          <td>
            <>
              {
             item &&
             item.account_manager ? 
              moment(
                item &&
                item.account_manager &&
                item.account_manager.created_at
                  
              ).format("DD-MMM-YYYY LTS") :

              moment(
                item &&
                item.general_marketer &&
                item.general_marketer.created_at
                  
              ).format("DD-MMM-YYYY LTS")
           
            }
            </>
          </td>
        ),
        updated_at: (item) => (
            <td>
              <>
                {
                  item &&
                  item.account_manager ? 
                  moment(
                    item &&
                    item.account_manager &&
                    item.account_manager.updated_at
                      
                  ).format("DD-MMM-YYYY LTS") :

                  moment(
                    item &&
                    item.general_marketer &&
                    item.general_marketer.updated_at
                      
                  ).format("DD-MMM-YYYY LTS")
              }
              </>
            </td>
          ),
      }}
    />
  );
};

export default TeamLeadDataTable;
