import React from "react";

import CXSidebar from "./CXSidebar";
import TheContent from "./TheContent";
import TheFooter from "./TheFooter";
import TheHeader from "./TheHeader";

const CXLayout = () => {
  return (
    <div className="c-app c-default-layout">
      <CXSidebar />
      <div className="c-wrapper">
        <TheHeader />
        <div className="c-body pb-5 pb-md-4">
          <TheContent />
        </div>
        <TheFooter />
      </div>
    </div>
  );
};

export default CXLayout;
