import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAIL,
  VERIFY_USER_REQUEST,
  VERIFY_USER_SUCCESS,
  VERIFY_USER_FAIL,
  USER_UPDATEPASSWORD_REQUEST,
  USER_UPDATEPASSWORD_FAIL,
  USER_UPDATEPASSWORD_SUCCESS,
  USER_RESETPASSWORD_REQUEST,
  USER_RESETPASSWORD_SUCCESS,
  USER_RESETPASSWORD_FAIL,
  USER_RESETPASSWORDTOKEN_REQUEST,
  USER_RESETPASSWORDTOKEN_SUCCESS,
  USER_RESETPASSWORDTOKEN_FAIL,
  USER_LOGOUT
} from "../_constants/userConstants";

function userSigninReducer(state = {}, action) {
  switch (action.type) {
    case USER_LOGIN_REQUEST:
      return { loading: true };
    case USER_LOGIN_SUCCESS:
      return { loading: false, userInfo: action.payload };
    case USER_LOGIN_FAIL:
      return { loading: false, error: action.payload };
    case USER_LOGOUT:
      return {};
    default:
      return state;
  }
}

function verifyUserReduer(state = {}, action) {
  switch (action.type) {
    case VERIFY_USER_REQUEST:
      return { loadingVerify: true };
    case VERIFY_USER_SUCCESS:
      return { loadingVerify: false, verify: action.payload, success: true };
    case VERIFY_USER_FAIL:
      return { loadingVerify: false, error: action.payload };
    default:
      return state;
  }
}

function userUpdatePasswordReducer(state = {}, action) {
  switch (action.type) {
    case USER_UPDATEPASSWORD_REQUEST:
      return { loading: true };
    case USER_UPDATEPASSWORD_SUCCESS:
      return { loading: false, userInfo: action.payload, success: true };
    case USER_UPDATEPASSWORD_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

function resetPasswordReducer(state = {}, action) {
  switch (action.type) {
    case USER_RESETPASSWORD_REQUEST:
      return { loading: true };
    case USER_RESETPASSWORD_SUCCESS:
      return { loading: false, userInfo: action.payload, success: true };
    case USER_RESETPASSWORD_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

function requestPasswordTokenReducer(state = {}, action) {
  switch (action.type) {
    case USER_RESETPASSWORDTOKEN_REQUEST:
      return { loading: true };
    case USER_RESETPASSWORDTOKEN_SUCCESS:
      return {
        loading: false,
        requestPasswordToken: action.payload,
        success: true,
      };
    case USER_RESETPASSWORDTOKEN_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

export {
  userSigninReducer,
  verifyUserReduer,
  userUpdatePasswordReducer,
  requestPasswordTokenReducer,
  resetPasswordReducer,
};
