import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";

import {
  CButton,
  CCard,
  CCardBody,
  CCol,
  CContainer,
  CRow,
  CForm,
  CLabel,
  CInput,
  CFormGroup,
} from "@coreui/react";
import { updateUserPassword } from "../../redux/_actions/userActions";

const ChangePassword = () => {
  const dispatch = useDispatch();
  const [current_password, set_current_password] = useState("");
  const [password, set_password] = useState("");

  const update = (e) => {
    if (e.target.name === "current_password") {
      set_current_password(e.target.value);
    } else if (e.target.name === "password") {
      set_password(e.target.value);
    }
  };
  const userUpdatePassword = useSelector((state) => state.userUpdatePassword);
  const { loading } = userUpdatePassword;

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(updateUserPassword(current_password, password));
  };

  return (
    <CContainer fluid>
      <CRow className="align-items-center mb-md-4 mb-3 border-bottom">
        <h2 className="mb-0 pb-3">Security</h2>
      </CRow>
      <CRow className="justify-content-center">
        <CCol lg="10" xl="8" className="col-12 px-0 px-md-3 max-container">
          <CRow className="justify-content-between align-items-center mb-5">
            <CCol className="col-12" md="9" xl="7">
              <h3 className="mb-2">Change your password</h3>
              <p className="text-muted mb-xl-0">
                We will email you a confirmation after changing your password,
                so please expect that email after submitting.
              </p>
            </CCol>
            <CCol className="col-12" xl="auto">
              <CButton color="dark" href="/reset-password">
                Forgot your password?
              </CButton>
            </CCol>
          </CRow>

          <CRow>
            <CCol className="col-12 order-md-2" md="6">
              <CCard className="bg-light border ms-md-4">
                <CCardBody>
                  <p className="mb-2 text-body">Password requirements</p>
                  <p className="small text-muted mb-2">
                    To create a new password, you have to meet all of the
                    following requirements:
                  </p>
                  <ul className="small text-muted ps-4 mb-0">
                    <li>Minimum of 8 characters</li>
                    <li>At least one special character</li>
                    <li>At least one number</li>
                    <li>Can't be the same as a previous password</li>
                  </ul>
                </CCardBody>
              </CCard>
            </CCol>

            <CCol className="col-12" md="6">
              <CForm>
                <CFormGroup>
                  <CLabel>Current password</CLabel>
                  <CInput
                    type="password"
                    className="form-control"
                    name="current_password"
                    onChange={update}
                  />
                </CFormGroup>
                <CFormGroup>
                  <CLabel>New password</CLabel>
                  <CInput
                    type="password"
                    className="form-control"
                    name="password"
                    onChange={update}
                  />
                </CFormGroup>
                <CFormGroup className="mb-4">
                  <CLabel>Confirm new password</CLabel>
                  <CInput
                    type="password"
                    className="form-control"
                    name="password"
                    onChange={update}
                  />
                </CFormGroup>
                <CButton
                  block
                  color="primary"
                  type="submit"
                  onClick={(e) => submitHandler(e)}
                >
                  {loading && <i className="fa fa-ellipsis-h fa-spin"> </i>}{" "}
                  {loading && (
                    <span>
                      <i className="fa fa-angle-right ml-1"> </i>{" "}
                    </span>
                  )}
                  {!loading && (
                    <span>
                      Update password{" "}
                      <i className="fa fa-angle-right ml-1"> </i>{" "}
                    </span>
                  )}
                </CButton>
              </CForm>
            </CCol>
          </CRow>
        </CCol>
      </CRow>
      <ToastContainer autoClose={500} />{" "}
    </CContainer>
  );
};

export default ChangePassword;
