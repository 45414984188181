/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CSpinner,
} from "@coreui/react";
import moment from "moment";
import { Printer, ChevronLeft } from "react-feather";

import styles from "../../scss/general.module.scss";
import {
  useDebitTransactions,
  useBankDetails,
} from "../../_hooks/transactions";
import TransactionService from "../../redux/_services/TransactionService";
import { thousandOperator } from "../../components";
import { useAccountDetails } from "src/_hooks/accounts";
const TransactionDetail = (props) => {
  const { id } = useParams();
  const [total, setTotal] = useState();
  const [sourceBank, setSourceBank] = useState();
  const [receiveBank, setReceiveBank] = useState();
  const [currentTransactions, fetchDebitData, loading] =
    useDebitTransactions(id);

  const history = useHistory();
  useEffect(() => {
    fetchDebitData();
  }, [fetchDebitData]);

  const length =
    currentTransactions &&
    currentTransactions.charges &&
    currentTransactions.charges.length;

  const initialValue = parseFloat(
    currentTransactions && currentTransactions.amount
  );
  const charge = parseFloat(
    currentTransactions &&
      currentTransactions.charges &&
      currentTransactions.charges[0] &&
      currentTransactions.charges[0].amount
  );
  function calculateTotal() {
    setTotal(initialValue + charge);
  }
  const printReceipt = () => window.print();

  useEffect(() => {
    sessionStorage.setItem("transactionPage", props.location.state);
  }, []);

  useEffect(() => {
    calculateTotal();
  }, [id, currentTransactions]);
  const amount =
    currentTransactions.amount &&
    currentTransactions.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  let creditBank;
  let debitBank;
  useEffect(() => {
    if (currentTransactions.debit_bank_code === "000000") {
      debitBank = {
        nip_bank_code: "000000",
        bank_name: "CloudBank Microfinance Bank",
      };
      setSourceBank(debitBank);
    }
    if (currentTransactions.credit_bank_code === "000000") {
      creditBank = {
        nip_bank_code: "000000",
        bank_name: "CloudBank Microfinance Bank",
      };
      setReceiveBank(creditBank);
    } else {
      if (currentTransactions?.source?.account_number) {
        TransactionService.getAllBanks(
          currentTransactions?.source?.account_number
        )
          .then((response) => {
            const data = response?.data?.data?.banks;
            if (!!data) {
              debitBank = data.filter(
                (x) => x.nip_bank_code === currentTransactions.debit_bank_code
              );
              creditBank = data.filter(
                (x) => x.nip_bank_code === currentTransactions.credit_bank_code
              );
              setSourceBank(debitBank);
              setReceiveBank(creditBank);
            }
          })
          .catch((e) => {
            console.log(e);
          });
      }
    }
  }, [currentTransactions]);

  if (loading) {
    return (
      <CSpinner
        color="primary"
        style={{
          width: "4rem",
          height: "4rem",
          marginTop: "300px",
          marginLeft: "600px",
        }}
      />
    );
  }

  return (
    <>
      <div className="d-flex justify-content-end">
        <CButton
          onClick={() => history.push(`/transactions/`)}
          className="px-4 mb-4"
          color="success"
        >
          <ChevronLeft />
          Back
        </CButton>
      </div>
      <CCard className={styles.card_height}>
        <CCardHeader className="d-flex align-items-center justify-content-between">
          <h4>Transaction Details</h4>
          <CButton variant="outline" color="success" onClick={printReceipt}>
            Print Receipt
          </CButton>
        </CCardHeader>
        <CCardBody>
          <CRow className="mb-2">
            <CCol size="6">
              <h6 className="mb-2">Sender's Details</h6>
              <p className="mb-1 text-capitalize">
                {" "}
                {currentTransactions && currentTransactions.source
                  ? currentTransactions &&
                    currentTransactions.source.account_name &&
                    currentTransactions.source.account_name.replace(
                      /(^\w)|([-\s]\w)/g,
                      (match) => match.toUpperCase()
                    )
                  : currentTransactions &&
                    currentTransactions.external_account_name &&
                    currentTransactions.external_account_name.replace(
                      /(^\w)|([-\s]\w)/g,
                      (match) => match.toUpperCase()
                    )}{" "}
              </p>
              <p className="mb-1">
                {currentTransactions && currentTransactions.source
                  ? currentTransactions &&
                    currentTransactions.source.account_number
                  : currentTransactions &&
                    currentTransactions.external_account_number}{" "}
              </p>
              <p className="mb-1 text-capitalize">
                {currentTransactions &&
                currentTransactions.debit_bank_code === "000000"
                  ? "CloudBank Microfinance Bank"
                  : sourceBank && sourceBank[0] && sourceBank[0].bank_name}
              </p>
            </CCol>
            <CCol size="6" className="text-right">
              <h6 className="mb-2">Receiver's Details</h6>
              <p className="mb-1 text-capitalize">
                {currentTransactions && currentTransactions.beneficiary
                  ? currentTransactions &&
                    currentTransactions.beneficiary &&
                    currentTransactions.beneficiary.account_name.replace(
                      /(^\w)|([-\s]\w)/g,
                      (match) => match.toUpperCase()
                    )
                  : currentTransactions &&
                    currentTransactions.external_account_name}
              </p>
              <p className="mb-1">
                {currentTransactions && currentTransactions.beneficiary
                  ? currentTransactions &&
                    currentTransactions.beneficiary &&
                    currentTransactions.beneficiary.account_number
                  : currentTransactions &&
                    currentTransactions.external_account_number}
              </p>
              <p className="mb-1 text-capitalize">
                {currentTransactions &&
                currentTransactions.credit_bank_code === "000000"
                  ? "CloudBank Microfinance Bank"
                  : receiveBank && receiveBank[0] && receiveBank[0].bank_name}
              </p>
            </CCol>
          </CRow>

          <CRow>
            <CCol size="12" className="my-5">
              <p className="font-weight-bold mb-2 font-2xl text-uppercase">
                Transaction Status:{" "}
                <span className="text-success">
                  {" "}
                  {currentTransactions.status}
                </span>
              </p>
              <p className="font-weight-bold">
                Transaction Reference:{" "}
                {currentTransactions.transaction_reference}
              </p>
            </CCol>
          </CRow>

          <div className="table-responsive-xl">
            <table className="table border-bottom">
              <thead>
                <tr>
                  <th scope="col">Amount in words</th>
                  <th scope="col">Date</th>
                  <th scope="col">Type</th>
                  <th scope="col" className="text-right">
                    Amount(₦)
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">{currentTransactions.amount_in_words}</th>
                  <td>
                    {" "}
                    {moment(currentTransactions.created_at).format(
                      "DD-MMM-YYYY"
                    )}
                  </td>
                  <td>
                    <span
                      className={`text-capitalize ${
                        currentTransactions.type === "credit"
                          ? "text-success"
                          : "text-danger"
                      }`}
                    >
                      {currentTransactions.type} (₦)
                    </span>
                  </td>
                  <td className="text-right">
                    {currentTransactions &&
                      currentTransactions.amount &&
                      currentTransactions.amount
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </td>
                </tr>
                <tr>
                  <td className="w-50">{currentTransactions.narration}</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
                {length === 0 ? (
                  ""
                ) : (
                  <>
                    {" "}
                    <tr>
                      <td colSpan="2">&nbsp;</td>
                      <td>Charges (₦)</td>
                      <td className="text-right">{thousandOperator(charge)}</td>
                    </tr>
                  </>
                )}
                <tr>
                  <td colSpan="2">&nbsp;</td>
                  <td>Total (₦)</td>
                  <td className="text-right font-weight-bold">
                    {" "}
                    {length === 0 ? amount : total && thousandOperator(total)}
                  </td>
                </tr>{" "}
              </tbody>
            </table>
          </div>
        </CCardBody>
      </CCard>
    </>
  );
};

export default TransactionDetail;
