import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  CCreateElement,
  CSidebar,
  CSidebarBrand,
  CSidebarNav,
  CSidebarNavDivider,
  CSidebarNavTitle,
  CSidebarMinimizer,
  CSidebarNavDropdown,
  CSidebarNavItem,
} from "@coreui/react";

import navigation from "./_cxnav";

const CXSidebar = () => {
    const dispatch = useDispatch();
    const show = useSelector((state) => state.changeState.sidebarShow);
    return (
        <CSidebar
        show={show}
        onShowChange={(val) => dispatch({ type: "set", sidebarShow: val })}
      >
        <CSidebarBrand to="/">
          {/* <Logo /> */}
        </CSidebarBrand>
        <CSidebarNav>
          <CCreateElement
            items={navigation}
            components={{
              CSidebarNavDivider,
              CSidebarNavDropdown,
              CSidebarNavItem,
              CSidebarNavTitle,
            }}
          />
        </CSidebarNav>
        <CSidebarMinimizer className="c-d-md-down-none" />
      </CSidebar>
    )
}
export default CXSidebar;
