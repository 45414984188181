import { useState, useEffect, useCallback } from "react";
import { axiosWithAuth, selectToken } from "../_utils/api";

export const useAllAccounts = (id) => {
  const token = selectToken;
  const [all_account_data, set_all_account_data] = useState([]);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const fetchAllAccount = useCallback(() => {
    const fetchAllAccountData = async () => {
      const req = async () => {
        try {
          setLoading(true);
          const { data } = await axiosWithAuth.get(
            `/admin/users/${id}?include=accounts`
          );
          set_all_account_data(data.data.user["accounts"]);
        } catch ({ response }) {
          setError(response);
        } finally {
          setLoading(false);
        }
      };
      req();
    };
    fetchAllAccountData();
  }, [id, token]);

  useEffect(() => {
    fetchAllAccount();
  }, [fetchAllAccount]);
  return [all_account_data, fetchAllAccount, loading, error];
};

export const useAllTransactions = (id) => {
  const user = id;
  const token = selectToken;
  const [all_transactions_data, set_all_transactions_data] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const fetchAllTransactions = useCallback(() => {
    const fetchAllTransactionsData = async () => {
      const req = async () => {
        try {
          setLoading(true);
          const { data } = await axiosWithAuth.get(
            `/admin/users/${user}/transactions?include=source,beneficiary`
          );
          set_all_transactions_data(data.data.transactions.data);
          // set_all_transactions_data(data.data.user["accounts"]);
        } catch ({ response }) {
          setError(response);
        } finally {
          setLoading(false);
        }
      };
      req();
    };
    fetchAllTransactionsData();
  }, [user, token]);

  useEffect(() => {
    fetchAllTransactions();
  }, [fetchAllTransactions]);
  return [all_transactions_data, fetchAllTransactions, loading, error];
};

export const useAccountDetails = (id) => {
  const [currentAccount, setCurrentAccount] = useState([]);
  const token = selectToken;

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const fetchAccountDetails = useCallback(() => {
    const fetcAccountData = async () => {
      const req = async () => {
        try {
          setLoading(true);
          const { data } = await axiosWithAuth.get(
            `/admin/accounts/${id}?include=bans,kyc,verification,transferLimits`
          );
          setCurrentAccount(data.data.account);
        } catch ({ response }) {
          setError(response);
        } finally {
          setLoading(false);
        }
      };
      req();
    };
    fetcAccountData();
  }, [id, token]);

  useEffect(() => {
    fetchAccountDetails();
  }, [fetchAccountDetails]);
  return [currentAccount, fetchAccountDetails, loading, error];
};

export const useAccountTypes = () => {
  const [account_types_data, set_account_data] = useState([]);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const fetchAccountTypes = useCallback(() => {
    const fetchAccountTypesData = async () => {
      const req = async () => {
        try {
          setLoading(true);
          const { data } = await axiosWithAuth.get(`/account-types`);
          set_account_data(data.data["account-types"].data);
        } catch ({ response }) {
          setError(response);
        } finally {
          setLoading(false);
        }
      };
      req();
    };
    fetchAccountTypesData();
  }, []);

  useEffect(() => {
    fetchAccountTypes();
  }, [fetchAccountTypes]);
  return [account_types_data, fetchAccountTypes, loading, error];
};

export const useKycDetails = (id) => {
  const [currentKyc, setCurrentKyc] = useState([]);
  const token = selectToken;

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const fetchKycDetails = useCallback(() => {
    const fetcKycData = async () => {
      const req = async () => {
        try {
          setLoading(true);
          const { data } = await axiosWithAuth.get(`/admin/kycs/${id}`);
          setCurrentKyc(data.data.kyc);
        } catch ({ response }) {
          setError(response);
       
        } finally {
          setLoading(false);
        }
      };
      req();
    };
    fetcKycData();
  }, [id, token]);

  useEffect(() => {
    fetchKycDetails();
  }, [fetchKycDetails]);
  return [currentKyc, fetchKycDetails, loading, error];
};
