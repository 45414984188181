/* eslint-disable import/no-anonymous-default-export */
import { axiosWithAuth, selectToken } from "../../_utils/api";
import logout from "./logoutservice";

const getAllTransactions = async (page) => {

  return await axiosWithAuth
    .get(
      `/admin/transactions?include=source,beneficiary,creditBank,debitBank&page[number]=${page}`
    )
    .catch((err) => err);
};

const getAllTransactionsFilter = async (query, page) => {
 
  return await axiosWithAuth
    .get(`/admin/transactions?` + query + `&include=source,beneficiary,creditBank,debitBank&page[number]=${page}`)
    .catch(function (error) {
      if (error.response) {
        // logout();
      }
    });
};

const getAllInvestments = async (params) => {

  return await axiosWithAuth
    .get("/admin/investments?include=source,beneficiary", { params })
    .catch(function (error) {
      if (error.response) {
        // logout();
      }
    });
};

const getAllInvestmentsFilter = async (query) => {
  return await axiosWithAuth
    .get(`/admin/investments?` + query + `&include=source,beneficiary`)
    .catch(function (error) {
    
      if (error.response) {
        // logout();
      }
    });
};

const getAllBanks = async (source) => {
  console.log("🚀 ~ file: TransactionService.js:46 ~ getAllBanks ~ source:", source)
  return axiosWithAuth
    .get(`/banks/nip?source=${source}`)
    .catch(function (error) {
      if (error.response) {
        // logout();
      }
    });
};

const reverseTransaction = async (payload) => {
  return await axiosWithAuth
  .post(`/admin/transactions/reverse`, payload)
}

export default {
  getAllTransactions,
  getAllInvestments,
  getAllTransactionsFilter,
  getAllInvestmentsFilter,
  getAllBanks,
  reverseTransaction
};
