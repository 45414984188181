import {
  ADD_INVESTMENT_REQUEST,
  ADD_INVESTMENT_SUCCESS,
  ADD_INVESTMENT_FAIL,
  INVESTMENT_TOPUP_REQUEST,
  INVESTMENT_TOPUP_SUCCESS,
  INVESTMENT_TOPUP_FAIL,
  SAVE_INVESTMENT_ACCOUNT,
} from "../_constants/investmentConstants";

function saveInvestmmentAccountReducer(
  state = { saveInvestmentAccountData: {} },
  action
) {
  switch (action.type) {
    case SAVE_INVESTMENT_ACCOUNT:
      return {
        loading: false,
        ...state,
        saveInvestmentAccountData: action.payload,
      };
    default:
      return state;
  }
}

function investmentCreateReducer(state = { investmentCreateData: {} }, action) {
  switch (action.type) {
    case ADD_INVESTMENT_REQUEST:
      return { loadin: true };
    case ADD_INVESTMENT_SUCCESS:
      return {
        loadin: false,
        investmentCreateData: action.payload,
        success: true,
      };
    case ADD_INVESTMENT_FAIL:
      return { loadin: false, error: action.payload };
    default:
      return state;
  }
}

function investmentTopupReducer(state = { investmentTopupData: {} }, action) {
  switch (action.type) {
    case INVESTMENT_TOPUP_REQUEST:
      return { loading: true };
    case INVESTMENT_TOPUP_SUCCESS:
      return {
        loading: false,
        investmentTopupData: action.payload,
        success: true,
      };
    case INVESTMENT_TOPUP_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

export {
  investmentCreateReducer,
  saveInvestmmentAccountReducer,
  investmentTopupReducer,
};
