import {
  CButton,
  CForm,
  CLabel,
  CInput,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
} from "@coreui/react";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { blockFund } from "src/redux/_actions/accountActions";

export const BlockFund = (props) => {
  const { onClose, show, accountId } = props;
  const [password, setPassword] = useState("");
  const [payingAcc, setPayingAcc] = useState("");
  const [comment, setComment] = useState("");
  const [amount, setAmount] = useState("");
  const [attachPayingAcc, setAttachPayingAcc] = useState(false);
  const blockedFund = useSelector((state) => state?.blockFund);
  const dispatch = useDispatch();

  const updateAmount = (e) => setAmount(e.target.value);
  const updateComment = (e) => setComment(e.target.value);
  const updatePassword = (e) => setPassword(e.target.value);
  const updatePayingAcc = (e) => setPayingAcc(e.target.value);
  const handleAttachPayingAcc = (e) => setAttachPayingAcc(e.target.checked);

  const reset = () => {
    setAmount("");
    setComment("");
    setPassword("");
    setPayingAcc("");
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const _amount = parseFloat(amount);
    if (!(accountId && password && !!_amount && comment)) return;
    const paying_acc = attachPayingAcc
      ? payingAcc.replace(/[^0-9]/g, "")
      : null;

    dispatch(blockFund(accountId, _amount, comment, password, paying_acc));
  };

  useEffect(() => {
    if (blockedFund?.success) {
      // reset on successful call
      reset();
      onClose();
    }
  }, [blockedFund?.success]);

  return (
    <CModal show={show} onClose={onClose} centered>
      <CModalHeader closeButton>Block Fund</CModalHeader>
      <CForm onSubmit={handleSubmit}>
        <CModalBody>
          <CLabel htmlFor="commentInput">
            Amount <span style={{ color: "red" }}>*</span>
          </CLabel>

          <CInput
            type="text"
            inputMode="decimal"
            label="Amount"
            autoSave="off"
            className="mb-3"
            id="amountInput"
            autoCorrect="off"
            placeholder="Amount"
            defaultValue={amount}
            autoComplete="new-password"
            valid={!!parseFloat(amount)}
            invalid={isNaN(+amount)}
            onChange={updateAmount}
          />

          <CLabel htmlFor="commentInput">
            Comment <span style={{ color: "red" }}>*</span>
          </CLabel>

          <CInput
            type="text"
            autoSave="off"
            label="Comment"
            className="mb-3"
            autoCorrect="off"
            id="commentInput"
            placeholder="Comment..."
            autoComplete="new-password"
            defaultValue={comment}
            valid={!!comment}
            invalid={!comment}
            onChange={updateComment}
          />

          <div className="d-flex justify-content-between mb-2">
            <CLabel htmlFor="allowPayingAccount">Attach Paying Account</CLabel>

            <label className="c-switch c-switch-pill c-switch-opposite-primary">
              <input
                type="checkbox"
                name="allowPayingAccount"
                onChange={handleAttachPayingAcc}
                className="c-switch-input"
                checked={attachPayingAcc}
              />
              <span className="c-switch-slider"></span>
            </label>
          </div>

          {attachPayingAcc ? (
            <>
              <CLabel htmlFor="payingAccount">Paying Account (Optional)</CLabel>
              <CInput
                type="text"
                className="mb-3"
                id="payingAccountInput"
                label="Paying Account (optional)"
                name="payingAccount"
                placeholder="Paying Account Number"
                defaultValue={payingAcc}
                autoCorrect="off"
                autoSave="off"
                onChange={updatePayingAcc}
              />
            </>
          ) : null}

          <CLabel htmlFor="passwordInput">
            Password <span style={{ color: "red" }}>*</span>
          </CLabel>

          <CInput
            type="password"
            className="mb-3"
            id="passwordInput"
            label="Password"
            name="password"
            placeholder="password"
            defaultValue={password}
            autoCorrect="off"
            autoSave="off"
            autoComplete="new-password"
            onChange={updatePassword}
          />
        </CModalBody>
        <CModalFooter>
          {password && !!parseFloat(amount) && comment ? (
            <CButton block color="success" variant="outline" type="submit">
              {blockedFund?.loading
                ? "Loading..."
                : "Yes I am sure, I want to block"}
            </CButton>
          ) : null}
        </CModalFooter>
      </CForm>
    </CModal>
  );
};
