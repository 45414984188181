import {
  CButton,
  CForm,
  CInput,
  CLabel,
  CListGroup,
  CListGroupItem,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
} from "@coreui/react";

export const DeleteAccount = (props) => {
  const { onClose, show } = props;
  return (
    <CModal show={show} onClose={onClose} centered>
      <CModalHeader closeButton>Delete Account</CModalHeader>
      <CForm>
        <CModalBody>
          <CListGroup className="mb-3">
            <CListGroupItem href="#" color="danger">
              This action is reversible
            </CListGroupItem>
          </CListGroup>
          <div className="mb-3">
            <CLabel htmlFor="deletePassword">Password</CLabel>
            <CInput
              id="deletePassword"
              type="password"
              placeholder="Enter Password"
            ></CInput>
          </div>
        </CModalBody>
        <CModalFooter>
          <CButton color="danger" variant="outline" block>
            Yes I am sure, I want to delete this account
          </CButton>{" "}
        </CModalFooter>
      </CForm>
    </CModal>
  );
};
