import {
  ACCOUNT_VERIFY_REQUEST,
  ACCOUNT_VERIFY_SUCCESS,
  ACCOUNT_VERIFY_FAIL,
  ACCOUNT_UPDATE_REQUEST,
  ACCOUNT_UPDATE_SUCCESS,
  ACCOUNT_UPDATE_FAIL,
  ACCOUNT_BLOCK_REQUEST,
  ACCOUNT_BLOCK_SUCCESS,
  ACCOUNT_BLOCK_FAIL,
  ACCOUNT_UNBLOCK_REQUEST,
  ACCOUNT_UNBLOCK_SUCCESS,
  ACCOUNT_UNBLOCK_FAIL,
  ACCOUNT_STATEMENT_REQUEST,
  ACCOUNT_STATEMENT_SUCCESS,
  ACCOUNT_STATEMENT_FAIL,
  ACCOUNT_OVERDRAFT_REQUEST,
  ACCOUNT_OVERDRAFT_SUCCESS,
  ACCOUNT_OVERDRAFT_FAIL,
  ACCOUNT_LIST_REQUEST,
  ACCOUNT_LIST_FAIL,
  ACCOUNT_LIST_SUCCESS,
  UNBLOCK_FUND_REQUEST,
  UNBLOCK_FUND_SUCCESS,
  UNBLOCK_FUND_FAIL,
  BLOCK_FUND_REQUEST,
  BLOCK_FUND_SUCCESS,
  BLOCK_FUND_FAIL
} from "../_constants/accountConstants";

function accountVerifyReducer(state = {}, action) {
  switch (action.type) {
    case ACCOUNT_VERIFY_REQUEST:
      return { loading: true };
    case ACCOUNT_VERIFY_SUCCESS:
      return {
        loading: false,
        accountVerifyData: action.payload,
        success: true,
      };
    case ACCOUNT_VERIFY_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

function accountUpdateReducer(state = {}, action) {
  switch (action.type) {
    case ACCOUNT_UPDATE_REQUEST:
      return { loadingUpdate: true };
    case ACCOUNT_UPDATE_SUCCESS:
      return {
        loadingUpdate: false,
        accountUpdateData: action.payload,
        successUpdate: true,
      };
    case ACCOUNT_UPDATE_FAIL:
      return { loadingUpdate: false, error: action.payload };
    default:
      return state;
  }
}

function accountUnBlockReducer(state = {}, action) {
  switch (action.type) {
    case ACCOUNT_UNBLOCK_REQUEST:
      return { loading: true };
    case ACCOUNT_UNBLOCK_SUCCESS:
      return {
        unBlockData: action.payload,
        success: true,
      };
    case ACCOUNT_UNBLOCK_FAIL:
      return { loading: false, erro: action.payload };
    default:
      return state;
  }
}

function unBlockFundReducer(state = {}, action) {
  switch (action.type) {
    case UNBLOCK_FUND_REQUEST:
      return { loading: true };

    case UNBLOCK_FUND_SUCCESS:
      return {
        unBlockFundData: action.payload,
        success: true,
        loading: false,
      };
      
    case UNBLOCK_FUND_FAIL:
      return { loading: false, erro: action.payload };
    default:
      return state;
  }
}

function blockFundReducer(state = {}, action) {
  switch (action.type) {
    case BLOCK_FUND_REQUEST:
      return { loading: true };
    case BLOCK_FUND_SUCCESS:
      return {
        blockFundData: action.payload,
        success: true,
        loading: false,
      };
    case BLOCK_FUND_FAIL:
      return { loading: false, erro: action.payload };
    default:
      return state;
  }
}

function accountBlockReducer(state = {}, action) {
  switch (action.type) {
    case ACCOUNT_BLOCK_REQUEST:
      return { loadingBlock: true };
    case ACCOUNT_BLOCK_SUCCESS:
      return {
        loadingBlock: false,
        accountBlockData: action.payload,
        successBlock: true,
      };
    case ACCOUNT_BLOCK_FAIL:
      return { loadingBlock: false, error: action.payload };
    default:
      return state;
  }
}
function accountStatementReducer(state = {}, action) {
  switch (action.type) {
    case ACCOUNT_STATEMENT_REQUEST:
      return { loading: true };
    case ACCOUNT_STATEMENT_SUCCESS:
      return {
        loading: false,
        accountStatement: action.payload,
        success: true,
      };
    case ACCOUNT_STATEMENT_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

function accountOverdraftReducer(state = {}, action) {
  switch (action.type) {
    case ACCOUNT_OVERDRAFT_REQUEST:
      return { loading: true };
    case ACCOUNT_OVERDRAFT_SUCCESS:
      return {
        loading: false,
        accountOVERDRAFT: action.payload,
        success: true,
      };
    case ACCOUNT_OVERDRAFT_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}
function accountListReducer(state = {}, action) {
  switch (action.type) {
    case ACCOUNT_LIST_REQUEST:
      return { loading: true };
    case ACCOUNT_LIST_SUCCESS:
      return {
        loading: false,
        accountList: action.payload,
        success: true,
      };
    case ACCOUNT_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}
export {
  accountOverdraftReducer,
  accountVerifyReducer,
  accountUpdateReducer,
  accountUnBlockReducer,
  blockFundReducer,
  unBlockFundReducer,
  accountBlockReducer,
  accountStatementReducer,
  accountListReducer
};
