import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import {
  CButton,
  CCol,
  CContainer,
  CRow,
  CForm,
  CLabel,
  CInput,
  CFormGroup,
} from "@coreui/react";
import { updatePinToken } from "../../redux/_actions/transactionActions";
const ChangeTransactionPin = () => {
  const dispatch = useDispatch();
  const [old_pin, set_old_pin] = useState("");
  const [pin, set_pin] = useState("");
  const [pin_confirmation, set_pin_confirmation] = useState("");

  const update = (e) => {
    if (e.target.name === "old_pin") {
      set_old_pin(e.target.value);
    } else if (e.target.name === "pin") {
      set_pin(e.target.value);
    } else if (e.target.name === "pin_confirmation") {
      set_pin_confirmation(e.target.value);
    }
  };

  const updatePin = useSelector((state) => state.updatePin);
  const { loading } = updatePin;

  const submitHandler = (e) => {
    e.preventDefault();

    dispatch(updatePinToken(old_pin, pin, pin_confirmation));
  };

  return (
    <CContainer fluid>
      <CRow className="align-items-center mb-md-4 mb-3 border-bottom">
        <h2 className="mb-0 pb-3">Security</h2>
      </CRow>
      <CRow className="justify-content-center">
        <CCol lg="10" xl="8" className="col-12 px-0 px-md-3">
          <CRow className="justify-content-between align-items-center mb-5">
            <CCol className="col-12" md="9" xl="7">
              <h3 className="mb-2">Change your transaction pin</h3>
              <p className="text-muted mb-xl-0">
                We will email you a confirmation after changing your transaction
                pin, so please expect that email after submitting.
              </p>
            </CCol>
            <CCol className="col-12" xl="auto">
              <CButton color="dark" href="/security/reset-transaction-pin">
                Forgot your transaction pin?
              </CButton>
            </CCol>
          </CRow>

          <CRow>
            <CCol className="col-12" md="6">
              <CForm>
                <CFormGroup>
                  <CLabel>Current transaction pin</CLabel>
                  <CInput
                    type="password"
                    className="form-control"
                    name="old_pin"
                    onChange={update}
                    maxLength={4}
                  />
                </CFormGroup>
                <CFormGroup>
                  <CLabel>New transaction pin</CLabel>
                  <CInput
                    type="password"
                    className="form-control"
                    name="pin"
                    onChange={update}
                    maxLength={4}
                  />
                </CFormGroup>
                <CFormGroup className="mb-4">
                  <CLabel>Confirm new transaction pin</CLabel>
                  <CInput
                    type="password"
                    className="form-control"
                    name="pin_confirmation"
                    onChange={update}
                    maxLength={4}
                  />
                </CFormGroup>
                <CButton
                  block
                  color="primary"
                  type="submit"
                  onClick={(e) => submitHandler(e)}
                >
                  {loading && <i className="fa fa-ellipsis-h fa-spin"> </i>}{" "}
                  {loading && (
                    <span>
                      <i className="fa fa-angle-right ml-1"> </i>{" "}
                    </span>
                  )}
                  {!loading && (
                    <span>
                      Update transaction pin
                      <i className="fa fa-angle-right ml-1"> </i>{" "}
                    </span>
                  )}
                </CButton>
              </CForm>
            </CCol>
          </CRow>
        </CCol>
      </CRow>
      <ToastContainer autoClose={500} />{" "}
    </CContainer>
  );
};

export default ChangeTransactionPin;
