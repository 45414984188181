/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  CCard,
  CCardBody,
  CCol,
  CRow,
  CButton,
  CSelect,
  CTextarea,
  CForm,
  CLabel,
  CInput,
} from "@coreui/react";
import { ToastContainer } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { Overlay } from "../../components";
import styles from "../../scss/details.module.scss";
import commonStyles from "../../scss/common-form.module.scss";
import "../../css/common-form.css";
import { useBankDetails } from "../../_hooks/transactions";
import { axiosWithAuth, selectToken } from "../../_utils/api";
import {
  saveTransaction,
  createTransaction,
} from "../../redux/_actions/transactionActions";

const transactionType = {
  external: "Other Banks",
  internal: "CloudBank to CloudBank",
};


const CreateTransaction = () => {
  const [step, setStep] = useState(1);
  const token = selectToken;

  const [input, setInput] = useState({
    amount: "",
    channel: "",
    remember: false,
    sourceAccount: "",
    sourceAccountName: "",
    beneficiaryBankName: "",
    beneficiaryAccount: "",
    beneficiary_bank_name: "",
    beneficiary_account_name: "",
    transferDescription: "",
  });
  console.log(input)

  const [showOverlay, set_showOverlay] = useState(false);
  const [source_account_name, set_source_account_name] = useState("");
  const [beneficiary_account_name, set_beneficiary_account_name] = useState("");
  const [selected, setSelected] = useState("");
  const [disabled, setDisabled] = useState("");
  const [bank_code, set_beneficiary_bank_code] = useState("");
  const [bank_name, set_beneficiary_bank_name] = useState("");
  const [pin, set_pin] = useState("");

  const transaction_ref = uuidv4();
  const transaction_reference = transaction_ref.replace(/[-]/g, "");

  const dispatch = useDispatch();

  const transactionSave = useSelector((state) => state.transactionSave);
  const { transactionSaveData } = transactionSave;
  const transactionCreate = useSelector((state) => state.transactionCreate);
  const { loading, success } = transactionCreate;

  const prevStep = () => {
    if (step === 2) {
      setStep(step - 1);
    }
  };

  useEffect(() => {
    setStep(step);
  }, [step]);

  useEffect(() => {
    if (success === true) {
      setStep(step - 1);
      setInput({
        amount: "",
        sourceAccount: "",
        sourceAccountName: "",
        beneficiaryBankName: "",
        beneficiaryAccount: "",
        beneficiary_account_name: "",
        transferDescription: "",
      });
      set_source_account_name("");
    }
  }, [success]);

  const formatPrice = (value) => {
    if (value === 0 || !value) {
      return;
    } else {
      let val = (value / 1).toFixed(2).replace(",", ".");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  };

  const [bankData, fetchBankData] = useBankDetails(input.sourceAccount);

  useEffect(() => {
    fetchBankData();
  }, [fetchBankData]);

  const update = (e) => {
    if (e.target.name === "transfer_type") {
      setSelected(e.target.value);
    } else if (e.target.name === "bank_code") {
      set_beneficiary_bank_code(e.target.value.replace(/\D/g, ""));
      set_beneficiary_bank_name(
        e.target.value.replace(/[0-9]/g, "").replace(/[, ]+/g, " ").trim()
      );
    }
    if (e.target.name === "pin") {
      set_pin(e.target.value);
    }
  };

  useEffect(() => {
    var accountdigit = /^\d{10}$/;

    if (input.sourceAccount.length < 10) {
      return "Account digit must not be less than 10"
    }

    const bank_code = "000000";
    const account_number = input.sourceAccount;
    if (input.sourceAccount.match(accountdigit)) {
      set_showOverlay(true);
      axiosWithAuth
        .post("/accounts/validate", {
          source_account_number: account_number,
          account_number,
          bank_code,
        })

        .then((res) => {
          const cb_account_name = res.data.data.account_name;
          set_source_account_name(cb_account_name);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(function () {
          set_showOverlay(false);
        });

    } else if (!input.sourceAccount.match(accountdigit)) {
      set_source_account_name("");
    }

    return () => {
      //
    };
  }, [input.sourceAccount]);

  useEffect(() => {
    var accountdigit = /^\d{10}$/;

    if (input.sourceAccount.length < 10) {
      return "Account digit must not be less than 10"
    }

    const account_number = input.beneficiaryAccount;
    const source_account_number = input.sourceAccount;
    if (input.beneficiaryAccount.match(accountdigit)) {
      set_showOverlay(true);

      axiosWithAuth
        .post("/accounts/validate", {
          source_account_number,
          account_number,
          bank_code,
        })
        .then((res) => {
          const account_name = res.data.data.account_name;
          set_beneficiary_account_name(account_name);
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(function () {
          set_showOverlay(false);
        });
    } else if (!account_number.match(accountdigit)) {
      set_beneficiary_account_name("");
    }

    return () => {
      //
    };
  }, [input.beneficiaryAccount]);

  useEffect(() => {
    if (selected === transactionType.external) {
      setDisabled(false);
    } else if (selected === transactionType.internal) {
      setDisabled(true);
      setInput({ ...input, beneficiary_bank_name: "Cloud Bank" });
      set_beneficiary_bank_code(type);
    }
  }, [selected]);

  let type = null;

  if (selected === "CloudBank to CloudBank") {
    type = "000000";
  }

  const handleOnChange = (prop) => (event) => {
    setInput({
      ...input,
      [prop]: event.target.value,
    });
  };

  const nextStep = () => {
    if (step === 1) {
      setStep(step + 1);
    }
    dispatch(
      saveTransaction({
        source_account_name,
        beneficiary_account_name,
        transaction_reference,
        bank_name,
      })
    );
  };

  const transactionHandler = () => {
    const source_account_number = input.sourceAccount;
    const beneficiary_account_number = input.beneficiaryAccount;
    const amount = input.amount;
    const beneficiary_bank_code = bank_code;
    const description = input.transferDescription;
    const beneficiary_bank_name = input.beneficiary_bank_name;
    const remember = input.remember;
    const channel = getTransactionChannel(selected);

    console.log(
        source_account_number,
        beneficiary_account_number,
        amount, beneficiary_bank_code,
        description, beneficiary_bank_name,
        remember, channel
    )

    try {
      dispatch(
        createTransaction(
          source_account_number,
          beneficiary_account_number,
          beneficiary_account_name,
          amount,
          beneficiary_bank_code,
          transaction_reference,
          pin,
          description,
          beneficiary_bank_name,
          remember,
          channel
        )
      );
    } catch (e) {
      console.log(e);
      alert(e.message);
    }
  };

  return (
    <>
      <Overlay active={showOverlay} marginTop="200px">
        <h4 className="mb-2">Create Transactions</h4>
        <div className="d-flex align-items-center justify-content-center mt-4 mt-md-0 mb-4">
          <span
            className={step === 1 ? "stepper current" : "stepper"}
            onClick={prevStep}
          >
            1
          </span>
          <span
            className={step === 2 ? "stepper current" : "stepper"}
            onClick={nextStep}
          >
            2
          </span>
        </div>
        <CForm className="px-md-4" id="create-filter-form">
          {step === 1 && (
            <CRow className={commonStyles.container}>
              <CCol sm="10" md="8" className="col-12 px-0 px-md-3 mx-auto">
                <CCard>
                  <CCardBody>
                    <div className="mb-3">
                      <CLabel htmlFor="amount">Amount</CLabel>
                      <CInput
                        type="number"
                        id="amount"
                        placeholder="Enter Amount"
                        value={input.amount}
                        onWheel={() => document.activeElement.blur()}
                        onChange={handleOnChange("amount")}
                      />
                    </div>

                    <div className="mb-3">
                      <CLabel htmlFor="sourceAccount">
                        Source Account Number
                      </CLabel>
                      <CInput
                        type="number"
                        id="sourceAccount"
                        placeholder="Enter Source Account Number"
                        value={input.sourceAccount}
                        onChange={handleOnChange("sourceAccount")}
                        onWheel={() => document.activeElement.blur()}
                        maxLength={10}
                      />
                    </div>

                    <div className="mb-3">
                      <CLabel htmlFor="sourceAccountName">
                        Source Account Name
                      </CLabel>
                      <CInput
                        id="sourceAccountName"
                        value={source_account_name}
                        readOnly
                      />
                    </div>
                    <div className="mb-3">
                      <CLabel htmlFor="transferSelect"> Transfer Type</CLabel>
                      <CSelect
                        id="transferSelect"
                        name="transfer_type"
                        onChange={update}
                      >
                        <option>Please select an option</option>
                        <option>{transactionType.internal}</option>
                        <option>{transactionType.external}</option>
                      </CSelect>
                    </div>
                    <div className="mb-3">
                      <CLabel htmlFor="bankSelect">Beneficiary Bank</CLabel>
                      <CSelect
                        name="bank_code"
                        id="bankSelect"
                        value={input.beneficiarybankName}
                        onChange={update}
                        disabled={disabled}
                      >
                        <option value="" disabled={disabled}>
                          Please select an option
                        </option>
                        <option></option>
                        {bankData.map(({ bank_name, nip_bank_code }, index) => (
                          <option
                            key={index}
                            value={[nip_bank_code, bank_name]}
                          >
                            {bank_name}
                          </option>
                        ))}
                      </CSelect>
                    </div>

                    <div className="mb-3">
                      <CLabel htmlFor="beneficiaryAccount">
                        Beneficiary Account Number
                      </CLabel>
                      <CInput
                        type="number"
                        id="beneficiaryAccount"
                        placeholder="Enter Beneficiary Account Number"
                        value={input.beneficiaryAccount}
                        onChange={handleOnChange("beneficiaryAccount")}
                        onWheel={() => document.activeElement.blur()}
                        maxLength={10}
                      />
                    </div>

                    <div className="mb-3">
                      <CLabel>Beneficiary Account Name</CLabel>
                      <CInput
                        id="beneficiaryAccountName"
                        value={beneficiary_account_name}
                        onChange={handleOnChange("beneficiary_account_name")}
                        readOnly
                      />
                    </div>

                    <div className="mb-3">
                      <CLabel htmlFor="transferDescription">Description</CLabel>
                      <CTextarea
                        id="transferDescription"
                        placeholder="Transfer Description"
                        value={input.transferDescription}
                        onChange={handleOnChange("transferDescription")}
                      />
                    </div>

                    <CButton
                      color="primary"
                      block
                      className="mt-4"
                      onClick={nextStep}
                    >
                      Continue
                    </CButton>
                  </CCardBody>
                </CCard>
              </CCol>
            </CRow>
          )}

          {step === 2 && (
            <CRow className={commonStyles.container}>
              <CCol sm="10" md="8" className="col-12 mx-auto px-0 px-md-3">
                <CCard className="px-3 py-2 mb-3">
                  <h5 className="py-3 mb-0 text-center">Review Transaction</h5>

                  <CRow className="py-2">
                    <CCol xs="5" className={styles.field_label}>
                      Amount:
                    </CCol>
                    <CCol xs="7" className={styles.field_value}>
                      {input.amount && <>&#8358;{formatPrice(input.amount)}</>}
                    </CCol>
                  </CRow>

                  <CRow className="py-2">
                    <CCol xs="5" className={styles.field_label}>
                      Source Account Number:
                    </CCol>
                    <CCol xs="7" className={styles.field_value}>
                      {input.sourceAccount}
                    </CCol>
                  </CRow>

                  <CRow className="py-2">
                    <CCol xs="5" className={styles.field_label}>
                      Source Account Name:
                    </CCol>
                    <CCol xs="7" className={styles.field_value}>
                      {transactionSaveData.source_account_name}
                    </CCol>
                  </CRow>

                  <CRow className="py-2">
                    <CCol xs="5" className={styles.field_label}>
                      Beneficiary Bank:
                    </CCol>
                    <CCol xs="7" className={styles.field_value}>
                      {transactionSaveData.bank_name}
                    </CCol>
                  </CRow>

                  <CRow className="py-2">
                    <CCol xs="5" className={styles.field_label}>
                      Beneficiary Account Number:
                    </CCol>
                    <CCol xs="7" className={styles.field_value}>
                      {input.beneficiaryAccount}
                    </CCol>
                  </CRow>

                  <CRow className="py-2">
                    <CCol xs="5" className={styles.field_label}>
                      Beneficiary Account Name:
                    </CCol>
                    <CCol xs="7" className={styles.field_value}>
                      {transactionSaveData.beneficiary_account_name}
                    </CCol>
                  </CRow>

                  <CRow className="py-2">
                    <CCol xs="5" className={styles.field_label}>
                      Description:
                    </CCol>
                    <CCol xs="7" className={styles.field_value}>
                      {input.transferDescription}
                    </CCol>
                  </CRow>

                  <CRow className="py-3">
                    <CCol xs="4" className={styles.field_label}>
                      <p className="text-primary">Transaction Pin</p>
                    </CCol>
                    <CCol xs="8">
                      <CInput
                        type="password"
                        placeholder="4 digit pin"
                        name="pin"
                        onChange={update}
                      />
                    </CCol>
                  </CRow>
                </CCard>

                <div className="mt-0 d-flex justify-content-between">
                  <CButton color="danger" onClick={prevStep}>
                    Previous
                  </CButton>
                  <CButton color="primary" onClick={transactionHandler}>
                    {loading && <i className="fa fa-ellipsis-h fa-spin"> </i>}{" "}
                    {loading && (
                      <span>
                        <i className="fa fa-angle-right ml-1"> </i>{" "}
                      </span>
                    )}{" "}
                    {!loading && (
                      <span>
                        Create Transaction
                        <i className="fa fa-angle-right ml-1"> </i>{" "}
                      </span>
                    )}
                  </CButton>
                </div>
              </CCol>
            </CRow>
          )}
        </CForm>
        <ToastContainer autoClose={5000} />{" "}
      </Overlay>
    </>
  );
};

const getTransactionChannel = (selected = transactionType.internal) => {
  if (selected === transactionType.internal) return "internal";
  if (selected === transactionType.external) return "external";
  return "";
};

export default CreateTransaction;
