export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAIL = "USER_LOGIN_FAIL";

export const VERIFY_USER_REQUEST = "VERIFY_USER_REQUEST";
export const VERIFY_USER_SUCCESS = "VERIFY_USER_SUCCESS";
export const VERIFY_USER_FAIL = "VERIFY_USER_FAIL";

export const USER_FETCH_REQUEST = "USER_FETCH_REQUEST";
export const USER_FETCH_SUCCESS = "USER_FETCH_SUCCESS";
export const USER_FETCH_FAIL = "USER_FETCH_FAIL";

export const USER_UPDATEPASSWORD_REQUEST = "  USER_UPDATEPASSWORD_REQUEST";
export const USER_UPDATEPASSWORD_FAIL = "  USER_UPDATEPASSWORD_FAIL";
export const USER_UPDATEPASSWORD_SUCCESS = " USER_UPDATEPASSWORD_SUCCESS";

export const USER_RESETPASSWORD_REQUEST = "  USER_RESETPASSWORD_REQUEST";
export const USER_RESETPASSWORD_FAIL = "  USER_RESETPASSWORD_FAIL";
export const USER_RESETPASSWORD_SUCCESS = " USER_RESETPASSWORD_SUCCESS";

export const USER_RESETPASSWORDTOKEN_REQUEST =
  "USER_RESETPASSWORDTOKEN_REQUEST";
export const USER_RESETPASSWORDTOKEN_SUCCESS =
  "USER_RESETPASSWORDTOKEN_SUCCESS";
export const USER_RESETPASSWORDTOKEN_FAIL = "USER_RESETPASSWORDTOKEN_FAIL";

export const USER_LOGOUT = "USER_LOGOUT";


export const CLEAR_ALL = "CLEAR_ALL"
