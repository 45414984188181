import { useState, useEffect, useCallback } from "react";
import { axiosWithAuth, selectToken } from "../_utils/api";
export const useDebitTransactions = (id) => {
  const [currentTransactions, setCurrentTransactions] = useState([]);
  const token = selectToken;

  const [loadin, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const fetchDebitTransactions = useCallback(() => {
    const fetchDebitData = async () => {
      const req = async () => {
        try {
          setLoading(true);
          const { data } = await axiosWithAuth.get(
            `/admin/transactions/${id}?include=charges,source,beneficiary`
          );
          setCurrentTransactions(data.data.transaction);
        } catch ({ response }) {
          setError(response);
        } finally {
          setLoading(false);
        }
      };
      req();
    };
    fetchDebitData();
  }, [id, token]);

  useEffect(() => {
    fetchDebitTransactions();
  }, [fetchDebitTransactions]);
  return [currentTransactions, fetchDebitTransactions, loadin, error];
};
export const useBankDetails = (source) => {
  const [bankData, set_bank_data] = useState([]);
  const token = selectToken;

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const fetchBankData = useCallback(() => {
    const fetchData = async () => {
      const req = async () => {
        try {
          setLoading(true);
          if(source){
            const { data } = await axiosWithAuth.get(
              `/banks/nip?source=${source}`
            );
            set_bank_data(data.data.banks);
          }
        } catch ({ response }) {
          setError(response);
        } finally {
          setLoading(false);
        }
      };
      req();
    };
    fetchData();
  }, [token, source]);

  useEffect(() => {
    fetchBankData();
  }, [fetchBankData]);
  return [bankData, fetchBankData, loading, error];
};
