import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CButton,
  CCard,
  CCardBody,
  CCol,
  CContainer,
  CForm,
  CInput,
  CInputGroup,
  CInputGroupPrepend,
  CInputGroupText,
  CRow,
  CModal,
  CModalHeader,
  CModalBody,
  CSelect,
  CPagination,
  CCardHeader,
  CSpinner,
  CDropdown,
  CDropdownDivider,
  CDropdownToggle,
  CDropdownMenu,
  CLabel,
  CMediaBody,
  CListGroup,
  CListGroupItem,
} from "@coreui/react";
import { Overlay } from "../../components";
import AdminDataTable from "src/components/DataTables/AdminDataTable";
import CIcon from "@coreui/icons-react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CSVLink } from "react-csv";
import moment from "moment";
import { useHistory, useLocation } from "react-router-dom";
import { contentSuccess, contentError } from "../../_utils/api";
import { createAccount } from "src/redux/_actions/adminActions";
import {
  // getRoles,
  getTeams,
  switchTeams,
} from "src/redux/_services/adminService";
import TeamsDataTable from "src/components/DataTables/TeamsDataTable";

const Register = (props) => {
  const [teams, setTeams] = useState([]);
  const [roles, setRoles] = useState([]);
  const [showOverlay, set_showOverlay] = useState(false);
  const [pages, set_pages] = useState(1);
  const [loading, setLoading] = useState("");
  const [modal, setModal] = useState(false);
  const [switchModal, setSwitchModal] = useState(false);
  const [currentpage, set_currentpage] = useState();
  const [lastPage, set_lastpage] = useState(1);
  const history = useHistory();

  const pageChange = (newPage) => {
    set_currentpage(newPage);
  };

  const toggleSwitch = () => {
    setSwitchModal(!switchModal);
  };

  const date = new Date();

  useEffect(() => {
    const retrieveTeams = () => {
      set_showOverlay(true);
      setLoading(true);
      getTeams(currentpage)
        .then((res) => {
   
          setTeams(res.data.data.teams.data);
          set_currentpage(res.data.data.teams.meta.current_page)
          set_lastpage(res.data.data.teams.meta.last_page)
         
        })
        .finally(() => {
          set_showOverlay(false);
          setLoading(false);
        })
        .catch((err) => {});
    };

    retrieveTeams();
  }, []);

  if (loading) {
    return (
      <CSpinner
        color="primary"
        style={{
          width: "4rem",
          height: "4rem",
          marginTop: "300px",
          marginLeft: "600px",
        }}
      />
    );
  }

  return (
    <>
      <div className="bg-white p-3">
        <div className="d-flex justify-content-between align-items-center p-3">
          <h4 className="text-dark">Teams</h4>
        </div>
        <div className="p-3">
         
          <SwitchTeam toggle={toggleSwitch} modal={switchModal} teams={teams} />
        </div>
        <CRow>
          <TeamsDataTable
            items={teams}
            loading={showOverlay}
            itemsPerPage={30}
          />
          <div className="p-3  col-sm-12">
            <CPagination
              activePage={currentpage}
              onActivePageChange={pageChange}
              pages={lastPage}
              doubleArrows={false}
              align="end"
            />
          </div>
        </CRow>
      </div>
    </>
  );
};

export default Register;

const SwitchTeam = ({ toggle, modal, teams }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordType, setPasswordType] = useState("password");
  const [switchLoading, setSwitchLoading] = useState("");
  const [reload, setReload] = useState(null);
  const [id, setId] = useState("");

  const resetHandler = (e) => {
    e.preventDefault();
    toggle();
    setPassword("");
    setEmail("");
    setId("");
  };

  const switchHandler = (e) => {
    e.preventDefault();

    if (password === "" || email == "") {
      toast(contentError("Password or Email cannot be empty "));
      return;
    }

    const payload = {
      id,
      password,
      email,
    };
    setSwitchLoading(true);
    switchTeams(payload)
      .then((res) => {
        setSwitchLoading(false);
        toast(contentSuccess(res.data), {
          onClose: () => resetHandler(e),
        });
      })
      .catch((err) => {
        if (err.response.data.error.message) {
          toast(contentError(err.response.data.error.message));
        } else {
          toast(contentError("An Error Occured"));
        }
        setSwitchLoading(false);
      });
  };

  return (
    <>
      <CButton onClick={toggle} className="mr-1 mb-4" color="success">
        Switch Team
      </CButton>
      <CModal show={modal} onClose={toggle} centered>
        <CModalHeader closeButton className="text-danger">
          Cancel
        </CModalHeader>
        <CModalBody>
          <CContainer>
            <CRow className="justify-content-center ">
              <CCol className="mx-auto px-0 px-md-3">
                <CListGroup>
                  <CListGroupItem href="#" color="warning" className="mb-3">
                    Switch Users or Accounts between Teams
                  </CListGroupItem>
                </CListGroup>
                <CForm autoComplete="off">
                  <div className>
                    <div>
                      <CInputGroup className="">
                        <CSelect
                          className="w-100"
                          name="status"
                          defaultValue={""}
                          onChange={(e) => setId(e.target.value)}
                        >
                          <option value="">Select Team</option>
                          {teams &&
                            teams.map((teams, index) => (
                              <option
                                key={index}
                                value={teams.id}
                                className="text-capitalize"
                              >
                                {teams.name.replace("-", " ")}
                              </option>
                            ))}
                        </CSelect>{" "}
                      </CInputGroup>
                    </div>
                    <div className="mb-3">
                      <CLabel>Email</CLabel>
                      <CInput
                        type="email"
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Enter Email"
                        autocomplete="new-password"
                        value={email}
                      />
                    </div>
                    <CInputGroup className="mb-1">
                      <CInput
                        type={passwordType}
                        placeholder="Enter Password"
                        value={password}
                        autocomplete="new-password"
                        onChange={(e) => setPassword(e.target.value)}
                      />

                      {passwordType === "password" ? (
                        <CButton
                          type="button"
                          color="secondary"
                          variant="outline"
                          onClick={() => setPasswordType("text")}
                        >
                          Show
                        </CButton>
                      ) : (
                        <CButton
                          type="button"
                          color="secondary"
                          variant="outline"
                          onClick={() => setPasswordType("password")}
                        >
                          Hide
                        </CButton>
                      )}
                    </CInputGroup>
                  </div>
                  {switchLoading ? (
                    <CButton className="mt-4 mb-4" color="success" block>
                      <CSpinner color="white" size="sm" /> Loading ...
                    </CButton>
                  ) : (
                    <CButton
                      onClick={(e) => switchHandler(e)}
                      className="mt-4 mb-4"
                      color="success"
                      block
                    >
                      Switch Account
                    </CButton>
                  )}
                </CForm>
              </CCol>
            </CRow>
            <ToastContainer autoClose={5000} />{" "}
          </CContainer>
        </CModalBody>
      </CModal>
    </>
  );
};
