/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useCallback } from "react";
import { axiosWithAuth, selectToken } from "../_utils/api";
import logout from "../redux/_services/logoutservice";
export const useUsersDetails = (id) => {
  const [currentUser, setCurrentUser] = useState([]);
  const token = selectToken;

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const fetchUsersDetails = useCallback(() => {
    const fetchUsersData = async () => {
      const req = async () => {
        try {
          setLoading(true);
          const { data } = await axiosWithAuth.get(`/admin/users/${id}`);
          setCurrentUser(data.data.user);
        } catch ({ response }) {
          setError(response);
          if (response) {
            // logout();
          }
        } finally {
          setLoading(false);
        }
      };
      req();
    };
    fetchUsersData();
  }, [id, token]);

  useEffect(() => {
    fetchUsersDetails();
  }, [fetchUsersDetails]);
  return [currentUser, fetchUsersDetails, loading, error];
};

export const useUsersVerificationDetails = (id) => {
  const [currentUserVerification, setCurrentUserVerification] = useState([]);
  const token = selectToken;

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const fetchUsersVerifcationDetails = useCallback(() => {
    const fetchUserVerificationData = async () => {
      const req = async () => {
        try {
          setLoading(true);
          const { data } = await axiosWithAuth.get(
            `/admin/users/${id}?include=verification`
          );
          setCurrentUserVerification(data.data.user);
        } catch ({ response }) {
          setError(response);
          if (response) {
            // logout();
          }
        } finally {
          setLoading(false);
        }
      };
      req();
    };
    fetchUserVerificationData();
  }, [id, token]);

  useEffect(() => {
    fetchUsersVerifcationDetails();
  }, [fetchUsersVerifcationDetails]);
  return [
    currentUserVerification,
    fetchUsersVerifcationDetails,
    loading,
    error,
  ];
};
